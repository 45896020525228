import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useCurrentPermissionsQuery(groupRef?: string) {
  return useQuery(
    ['permissions', 'current', groupRef],
    () => {
      if (groupRef) {
        return api.getCurrentPermissions(groupRef)
      }
    },
    {
      enabled: !!groupRef,
    }
  )
}

export default useCurrentPermissionsQuery
