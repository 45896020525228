import { BaseGridData, GridResponseHeading, GridResponseRow } from '../../../services/data/types/grid-data'
import LiquidityMonitor from '../../../services/data/types/liquidity'

function parseLiquidityData(data?: LiquidityMonitor): BaseGridData {
  const headings: GridResponseHeading[] =
    data?.headings.map((heading) => ({
      ...heading,
      position: 'maingrid',
      can_slice_by: false,
      aggregation_type: null,
      can_aggregate_by: false,
    })) || []

  const rows: GridResponseRow[] =
    data?.rows.map((row, index) => ({
      ...row,
      row_ref: index.toString(),
      row_description: '',
      aggregate_group: '',
      asset_ref: null,
    })) || []

  return {
    headings,
    rows,
    panels: [],
    aggregates: [],
  }
}

export default parseLiquidityData
