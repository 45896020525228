import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'
import { AuditFilters } from '../../../services/data/types/audit-trail'

function useAuditLogsQuery(filters: AuditFilters) {
  return useQuery(
    ['audit', 'logs', filters],
    () => {
      return api.getAuditLogs(filters)
    },
    { keepPreviousData: true }
  )
}

export default useAuditLogsQuery
