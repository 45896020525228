import { useCallback, useEffect, useRef, useState } from 'react'

type InitialState = {
  open: boolean
}

function useOpenStateDebounced(state?: InitialState, delay: number = 120) {
  const [isOpen, setIsOpen] = useState(!!state?.open)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  function clearDebounce() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }

  const open = useCallback(() => {
    clearDebounce()
    timeoutRef.current = setTimeout(() => {
      setIsOpen(true)
    }, delay)
  }, [delay])

  const close = useCallback(() => {
    clearDebounce()
    setIsOpen(false)
  }, [])

  const toggle = useCallback(() => {
    clearDebounce()
    setIsOpen((state) => !state)
  }, [])

  // cancel opening in case it's still not open
  const cancelOpenEvent = useCallback(() => {
    if (!isOpen) {
      clearDebounce()
    }
  }, [])

  // clear on unmount
  useEffect(() => clearDebounce, [])

  return { isOpen, open, close, toggle, cancelOpenEvent }
}

export default useOpenStateDebounced
