const intFormatter = new Intl.NumberFormat('en-GB', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const floatFormatter = new Intl.NumberFormat('en-GB', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const percentFormatter = new Intl.NumberFormat('en-GB', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export function formatInt(value: unknown) {
  if (typeof value === 'number') {
    return intFormatter.format(value)
  }
  return ''
}

export function formatFloat(value: unknown, decimalScale?: number) {
  if (typeof value !== 'number') {
    return ''
  }

  if (decimalScale) {
    const formatter = new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: 2,
      maximumFractionDigits: decimalScale,
    })
    return formatter.format(value)
  }

  return floatFormatter.format(value)
}

export function formatPercent(value: unknown, decimalScale?: number) {
  if (typeof value !== 'number') {
    return ''
  }

  if (decimalScale) {
    const formatter = new Intl.NumberFormat('en-GB', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: decimalScale,
    })
    return formatter.format(value + 0)
  }

  // `value + 0` is used to avoid -0%, so we always get 0%
  return percentFormatter.format(value + 0)
}
