import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useAddPermissionMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, AddPermissionParams>(['addPermission'], {
    mutationFn: ({ groupRef, permissionTag }) => api.addPermission(groupRef, permissionTag),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type AddPermissionParams = {
  groupRef: string
  permissionTag: string
}

export default useAddPermissionMutation
