import { ChevronRight, Upload } from '@mui/icons-material'
import Bookmark from '@mui/icons-material/Bookmark'
import Download from '@mui/icons-material/Download'
import { Badge, Chip, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { ReloadButton } from '../../../components/buttons/reload-button'
import PageHeaderContainer from '../../../components/page-header-container'
import { PortfolioComplianceResponse } from '../../../services/data/types/compliance'
import { ComplianceIndicator } from '../../compliance/components/compliance-indicator'
import { NewOrderMenuButton } from './new-order-menu-button'

type PortfolioGridHeaderProps = {
  portfolioTitle: string | null
  currency: string
  compliance: PortfolioComplianceResponse | null
  isComplianceLoading: boolean
  showLoading: boolean
  isNavOpen: boolean
  isViewDirty: boolean
  isGridsOnlyUser: boolean
  onOpenNavClick: MouseEventHandler
  onNewTradeClick: () => void
  onNewCashflowClick: () => void
  onViewOptionsClick: MouseEventHandler
  onUploadClick: MouseEventHandler
  onExportPortfolio: () => void
  onReloadData: () => void
  onOpenCompliance: () => void
}

function PortfolioGridHeader(props: PortfolioGridHeaderProps) {
  const {
    portfolioTitle,
    currency,
    compliance,
    isComplianceLoading,
    showLoading,
    isNavOpen,
    isViewDirty,
    isGridsOnlyUser,
    onOpenNavClick,
    onNewTradeClick,
    onNewCashflowClick,
    onViewOptionsClick,
    onUploadClick,
    onExportPortfolio,
    onReloadData,
    onOpenCompliance,
  } = props

  const { t } = useTranslation('portfolio')

  return (
    <PageHeaderContainer>
      <Stack flex="1" direction="row" alignItems="center" gap={1}>
        {!isNavOpen && (
          <IconButton onClick={onOpenNavClick}>
            <ChevronRight color="primary" />
          </IconButton>
        )}
        {portfolioTitle && (
          <Typography variant="h6" noWrap>
            {portfolioTitle}
          </Typography>
        )}
        {currency && <Chip label={<Typography color="gray.700">{currency}</Typography>} />}

        <ReloadButton loading={showLoading} onClick={onReloadData} />

        {!isGridsOnlyUser && (
          <ComplianceIndicator isLoading={isComplianceLoading} compliance={compliance} onClick={onOpenCompliance} />
        )}
      </Stack>

      <Stack direction="row" alignItems="center">
        {!isGridsOnlyUser && (
          <>
            <NewOrderMenuButton onNewTrade={onNewTradeClick} onNewCashflow={onNewCashflowClick} />

            <Divider orientation="vertical" sx={{ height: '16px' }} />

            <Tooltip title={t('upload_button')} enterDelay={500} arrow disableInteractive>
              <IconButton onClick={onUploadClick} sx={{ color: 'gray.300', padding: '4px', margin: '4px' }}>
                <Upload fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        )}

        <Tooltip title={t('download_portfolio_positions')} enterDelay={500} arrow disableInteractive>
          <IconButton onClick={onExportPortfolio} sx={{ color: 'gray.300', padding: '4px', margin: '4px' }}>
            <Download fontSize="small" />
          </IconButton>
        </Tooltip>

        <Divider orientation="vertical" sx={{ height: '16px' }} />

        <Tooltip title={t('view_selector')} enterDelay={500} arrow disableInteractive>
          <IconButton onClick={onViewOptionsClick} sx={{ color: 'gray.300', padding: '4px', margin: '4px' }}>
            <Badge variant="dot" invisible={!isViewDirty} sx={{ '.MuiBadge-dot': { backgroundColor: 'arc.info' } }}>
              <Bookmark fontSize="small" />
            </Badge>
          </IconButton>
        </Tooltip>
      </Stack>
    </PageHeaderContainer>
  )
}

export default PortfolioGridHeader
