import { useLocation, useNavigate } from 'react-router-dom'
import DataEngine, { DataEngineModule } from '../../components/data-engine'

const dataEngineModulePath: { [key in DataEngineModule]: string } = {
  [DataEngineModule.Datasets]: '/openfin/views/data-engine/datasets',
  [DataEngineModule.Assets]: '/openfin/views/data-engine/assets',
  [DataEngineModule.Classifications]: '/openfin/views/data-engine/classifications',
  [DataEngineModule.Models]: '/openfin/views/data-engine/models',
}

function DataEngineView() {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const defaultModule = DataEngineModule.Datasets
  const selectedModule: DataEngineModule =
    (Object.entries(dataEngineModulePath).find(([_, path]) => {
      return pathname.startsWith(path)
    })?.[0] as DataEngineModule) || defaultModule

  function handleTabTitleChange(title: string) {
    document.title = title
  }

  function handleModuleClick(module: DataEngineModule, param?: string | number) {
    const url = param ? `${dataEngineModulePath[module]}/${param}` : dataEngineModulePath[module]
    navigate(url)
  }

  return (
    <DataEngine
      selectedModule={selectedModule}
      modulePaths={dataEngineModulePath}
      onModuleClick={handleModuleClick}
      onTabTitleChange={handleTabTitleChange}
    />
  )
}

export default DataEngineView
