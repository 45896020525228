import { Search } from '@mui/icons-material'
import { InputAdornment, SxProps, TextField, debounce } from '@mui/material'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

type QuickSearchFieldProps = {
  placeholder?: string
  autoFocus?: boolean
  onValueChange: (value: string) => void
  sx?: SxProps
  inputSx?: SxProps
}

const QuickSearchField = forwardRef((props: QuickSearchFieldProps, ref) => {
  const { placeholder, autoFocus, onValueChange, sx, inputSx } = props

  const { t } = useTranslation()

  return (
    <TextField
      autoFocus={autoFocus}
      inputRef={ref}
      size="small"
      placeholder={placeholder || t('common:search')}
      onChange={debounce((event) => onValueChange(event.target.value), 500)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search sx={{ fontSize: 20 }} />
          </InputAdornment>
        ),
        sx: {
          height: '32px',
          fontSize: '14px',
          ...inputSx,
        },
      }}
      sx={{
        backgroundColor: 'gray.main',
        borderRadius: '4px',
        minWidth: '200px',
        maxWidth: '300px',
        width: '100%',
        ...sx,
      }}
    />
  )
})

export default QuickSearchField
