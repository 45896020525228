import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { ConsignNewTicketPayload } from '../../../services/data/types/trade-ticket'

export function useConsignNewTicketMutation() {
  const response = useMutation<AxiosResponse, AxiosError, ConsignNewTicketPayload>({
    mutationFn: api.consignNewTicket,
  })

  return { ...response, error: parseError(response.error) }
}
