import { Dialog } from '@mui/material'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import useCreateUserMutation, { CreateUserErrorCode } from '../data/use-create-user-mutation'
import UserForm from './user-form'

type AddUserModalProps = {
  open: boolean
  onClose: () => void
}

const initialFormValues = {
  username: '',
  password: '',
  firstname: '',
  lastname: '',
  email: '',
  timezone: '',
}

const initialFormErrors = {
  general: '',
  username: '',
}

function AddUserModal(props: AddUserModalProps) {
  const { open, onClose } = props
  const { t } = useTranslation('settings')

  const { mutate: createUser } = useCreateUserMutation()

  const [formValues, setFormValues] = useState(initialFormValues)
  const [formErrors, setFormErrors] = useState(initialFormErrors)

  function handleChange(name: string, value: string) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    createUser(formValues, {
      onSuccess: () => {
        queryClient.invalidateQueries(['tenant', 'users'])
        handleClose()
      },
      onError: (error) => {
        const errorCode = error.response?.data?.error.code

        if (errorCode === CreateUserErrorCode.USERNAME_TAKEN) {
          setFormErrors({ ...formErrors, username: 'settings:user_form.username_taken' })
        } else {
          setFormErrors({ ...formErrors, general: 'common:general_error_message' })
        }
      },
    })
  }

  function handleClose() {
    setFormValues(initialFormValues)
    setFormErrors(initialFormErrors)
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('user_form.add_user')} onClose={handleClose} />
        <ModalContent>
          <UserForm type="add" values={formValues} errors={formErrors} onChange={handleChange} />
        </ModalContent>
        <ModalActions confirmLabel={t('common:add')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default AddUserModal
