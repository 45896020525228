import * as FlexLayout from 'flexlayout-react'

export const loginModel = FlexLayout.Model.fromJson({
  global: {
    tabSetEnableTabStrip: false,
  },
  layout: {
    id: 'main',
    type: 'row',
    weight: 100,
    children: [
      {
        type: 'tabset',
        weight: 100,
        children: [
          {
            type: 'tab',
            name: 'Login',
            component: 'login',
          },
        ],
      },
    ],
  },
})

export const appModelJson: FlexLayout.IJsonModel = {
  global: {
    splitterSize: 8,
    tabSetTabStripHeight: 32,
  },
  layout: {
    id: 'main',
    type: 'row',
    weight: 100,
    children: [
      {
        type: 'tabset',
        weight: 100,
        children: [
          {
            type: 'tab',
            name: 'Portfolio Grid',
            component: 'app',
          },
        ],
      },
    ],
  },
}

export const appModelStorage = {
  key: 'flex-layout-model',
  getModel: (): FlexLayout.Model => {
    const item = sessionStorage.getItem(appModelStorage.key)
    const modelJson = item ? JSON.parse(item) : appModelJson
    return FlexLayout.Model.fromJson(modelJson)
  },
  setModel: (model: FlexLayout.Model) => {
    const modelString = JSON.stringify(model.toJson())
    sessionStorage.setItem(appModelStorage.key, modelString)
  },
  clear: () => {
    sessionStorage.removeItem(appModelStorage.key)
  },
}
