import { useLocation, useNavigate } from 'react-router-dom'
import Login, { LoginUseLocationType } from '../../components/login'

function LoginFlexView() {
  const navigate = useNavigate()
  const { state } = useLocation() as LoginUseLocationType

  function handleSuccess(tenantRef: string | null) {
    if (tenantRef) {
      navigate(state?.from || '/portfolios')
    } else {
      navigate('/login/tenant', { state })
    }
  }

  function handleForgotPassword() {
    navigate('/forgot-password')
  }

  return <Login onSuccess={handleSuccess} onForgotPassword={handleForgotPassword} />
}

export default LoginFlexView
