import { useNavigate } from 'react-router-dom'
import Portfolios from '../../components/portfolios'

function PortfoliosSettingsFlexView() {
  const navigate = useNavigate()

  return <Portfolios onPortfolioClick={(portfolioRef) => navigate(`/settings/portfolios/${portfolioRef}`)} />
}

export default PortfoliosSettingsFlexView
