import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useDatapointHistoryQuery(assetRef: string, datapointRef: string, datasetRef?: string) {
  return useQuery({
    queryKey: ['asset', assetRef, 'datapoint', datapointRef, 'dataset', datasetRef, 'history'],
    queryFn: () => {
      if (assetRef && datapointRef) {
        return api.getDatapointHistory(assetRef, datapointRef, datasetRef)
      }
    },
  })
}

export default useDatapointHistoryQuery
