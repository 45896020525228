import { useTranslation } from 'react-i18next'
import CollapsibleSideNavigation from '../../../components/collapsible-side-navigation'
import ListItem from '../../../components/list-item'
import ListItemGroup from '../../../components/list-item-group'
import { LiquidityNavigator } from '../../../services/data/types/liquidity'

type LiquidityMonitorNavProps = {
  open: boolean
  selected: string
  navigator?: LiquidityNavigator
  onClick: (assetType: string) => void
  onCloseClick: () => void
}

function LiquidityMonitorNav(props: LiquidityMonitorNavProps) {
  const { open, selected, navigator, onClick, onCloseClick } = props
  const { t } = useTranslation('portfolio')

  return (
    <CollapsibleSideNavigation title={t('liquidity')} open={open} onClose={onCloseClick}>
      <ListItemGroup title={t('asset_types')} divider={false} isDefaultOpen>
        {navigator?.asset_types.map((asset_type) => (
          <ListItem
            key={asset_type.asset_type_tag}
            title={asset_type.asset_type_name}
            isSelected={asset_type.asset_type_tag === selected}
            onClick={() => onClick(asset_type.asset_type_tag)}
          />
        ))}
      </ListItemGroup>
    </CollapsibleSideNavigation>
  )
}

export default LiquidityMonitorNav
