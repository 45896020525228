import { useNavigate } from 'react-router-dom'
import UserDetails from '../../components/user-details'

function UserDetailsFlexView() {
  const navigate = useNavigate()

  return <UserDetails onBack={() => navigate('/settings/users')} />
}

export default UserDetailsFlexView
