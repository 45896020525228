import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { ChannelAction, connectAuthChannel } from '../../../../services/openfin/fin'
import Settings, { SettingsModule } from '../../components/settings'

function SettingsView() {
  const { t } = useTranslation('settings')
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const settingModulePath: { [key in SettingsModule]: string } = {
    [SettingsModule.Tenants]: '/openfin/views/settings/tenants',
    [SettingsModule.Tenant]: '/openfin/views/settings/tenant',
    [SettingsModule.Users]: '/openfin/views/settings/users',
    [SettingsModule.Groups]: '/openfin/views/settings/groups',
    [SettingsModule.Portfolios]: '/openfin/views/settings/portfolios',
    [SettingsModule.Views]: '/openfin/views/settings/views',
    [SettingsModule.Compliance]: '/openfin/views/settings/compliance',
    [SettingsModule.Counterparties]: '/openfin/views/settings/counterparties',
    [SettingsModule.GeneralLedger]: '/openfin/views/settings/general-ledger',
    [SettingsModule.LegalEntities]: '/openfin/views/settings/legal-entities',
    [SettingsModule.NonTenantedUsers]: '/openfin/views/settings/non-tenanted-users',
    [SettingsModule.Chains]: '/openfin/views/settings/chains',
    [SettingsModule.AuditTrail]: '/openfin/views/settings/audit-trail',
  }

  const settingsSubModuleHeader: { [key in SettingsModule]: string } = {
    [SettingsModule.Tenant]: '',
    [SettingsModule.Users]: 'Users / User Details',
    [SettingsModule.Groups]: '',
    [SettingsModule.Portfolios]: '',
    [SettingsModule.Views]: '',
    [SettingsModule.Compliance]: '',
    [SettingsModule.Counterparties]: '',
    [SettingsModule.GeneralLedger]: '',
    [SettingsModule.Tenants]: '',
    [SettingsModule.LegalEntities]: '',
    [SettingsModule.NonTenantedUsers]: '',
    [SettingsModule.Chains]: '',
    [SettingsModule.AuditTrail]: '',
  }

  const defaultModule = SettingsModule.Users
  const selectedModule: SettingsModule =
    (Object.entries(settingModulePath).find(([_, path]) => {
      return pathname.startsWith(path)
    })?.[0] as SettingsModule) || defaultModule

  const selectedModulePathLength = settingModulePath[selectedModule].length
  const isNestedRoute = selectedModulePathLength < pathname.length

  useEffect(() => {
    const title = isNestedRoute ? settingsSubModuleHeader[selectedModule] : t(selectedModule)
    document.title = title
  }, [selectedModule, isNestedRoute])

  function handleModuleClick(name: SettingsModule) {
    navigate(settingModulePath[name])
  }

  async function handleSwitchTenantSuccess() {
    const client = await connectAuthChannel()
    client.dispatch(ChannelAction.switchTenant)
  }

  return (
    <Settings
      selectedModule={selectedModule}
      isSubModuleOpen={isNestedRoute}
      onModuleClick={handleModuleClick}
      onSwitchTenantSuccess={handleSwitchTenantSuccess}
    />
  )
}

export default SettingsView
