import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { ReconcileTxnsPayload } from '../../../services/data/types/reconciliation'

export function useReconciliationGridQuery(bankAccountId: string | undefined) {
  const response = useQuery({
    queryKey: ['reconciliation', bankAccountId, 'grid'],
    queryFn: () => {
      if (!bankAccountId) {
        return null
      }
      return api.getReconciliationGrid(bankAccountId)
    },
  })
  return { ...response, error: parseError(response.error as AxiosError) }
}

type ReconcileParams = {
  bankAccountId: string
  payload: ReconcileTxnsPayload
}

export function useReconcileTransactionsMutation() {
  const response = useMutation<AxiosResponse, AxiosError, ReconcileParams>({
    mutationFn: ({ bankAccountId, payload }) => {
      return api.reconcileTransactions(bankAccountId, payload)
    },
  })

  return { ...response, error: parseError(response.error) }
}

type UnreconcileParams = {
  bankAccountId: string
  recordId: number
}

export function useUnreconcileRecordMutation() {
  const response = useMutation<AxiosResponse, AxiosError, UnreconcileParams>({
    mutationFn: ({ bankAccountId, recordId }) => {
      return api.unreconcileRecord(bankAccountId, recordId)
    },
  })

  return { ...response, error: parseError(response.error) }
}
