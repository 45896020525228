import { useNavigate } from 'react-router-dom'
import Portfolios from '../../components/portfolios'

function PortfoliosSettingsView() {
  const navigate = useNavigate()

  return (
    <Portfolios onPortfolioClick={(portfolioRef) => navigate(`/openfin/views/settings/portfolios/${portfolioRef}`)} />
  )
}

export default PortfoliosSettingsView
