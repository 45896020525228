import { useLocation, useNavigate } from 'react-router-dom'
import Settings, { SettingsModule } from '../../components/settings'

function SettingsFlexView() {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const settingModulePath: { [key in SettingsModule]: string } = {
    [SettingsModule.Tenant]: '/settings/tenant',
    [SettingsModule.Users]: '/settings/users',
    [SettingsModule.Groups]: '/settings/groups',
    [SettingsModule.Portfolios]: '/settings/portfolios',
    [SettingsModule.Views]: '/settings/views',
    [SettingsModule.Compliance]: '/settings/compliance',
    [SettingsModule.Counterparties]: '/settings/counterparties',
    [SettingsModule.GeneralLedger]: '/settings/general-ledger',
    [SettingsModule.Tenants]: '/settings/tenants',
    [SettingsModule.LegalEntities]: '/settings/legal-entities',
    [SettingsModule.NonTenantedUsers]: '/settings/non-tenanted-users',
    [SettingsModule.Chains]: '/settings/chains',
    [SettingsModule.AuditTrail]: '/settings/audit-trail',
  }

  const defaultModule = SettingsModule.Users
  const selectedModule: SettingsModule =
    (Object.entries(settingModulePath).find(([_, path]) => {
      return pathname.startsWith(path)
    })?.[0] as SettingsModule) || defaultModule

  const selectedModulePathLength = settingModulePath[selectedModule].length
  const isNestedRoute = selectedModulePathLength < pathname.length

  function handleItemClick(name: SettingsModule) {
    navigate(settingModulePath[name])
  }

  function handleSwitchTenantSuccess() {
    window.location.reload()
  }

  return (
    <Settings
      isSubModuleOpen={isNestedRoute}
      selectedModule={selectedModule || defaultModule}
      onModuleClick={handleItemClick}
      onSwitchTenantSuccess={handleSwitchTenantSuccess}
    />
  )
}

export default SettingsFlexView
