import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { AddModelPayload } from '../../../services/data/types/models'

function useAddModelMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, AddModelProps>({
    mutationFn: ({ datasetRef, payload }) => api.addModel(datasetRef, payload),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type AddModelProps = {
  datasetRef: string
  payload: AddModelPayload
}

export default useAddModelMutation
