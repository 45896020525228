const { BASE_URL, VITE_API_BASE_URL, VITE_ENABLE_BROWSER_VERSION, VITE_ENABLE_UNREADY_FEATURES, VITE_SENTRY_DSN_URL } =
  import.meta.env

const baseUrl = BASE_URL || '/'

let baseApiUrl = 'https://dev.alphainitiatives.net'
if (VITE_API_BASE_URL !== true && VITE_API_BASE_URL) {
  baseApiUrl = VITE_API_BASE_URL
}

const enableBrowserVersion = VITE_ENABLE_BROWSER_VERSION === 'true'
const enableUnreadyFeatures = VITE_ENABLE_UNREADY_FEATURES === 'true'

const sentryDsnUrl =
  VITE_SENTRY_DSN_URL || 'https://f51741bfb575badf7ed65b5c2ae22d70@o4506002999803904.ingest.sentry.io/4506003035717632'

const agGridLicenseKey =
  'Using_this_AG_Grid_Enterprise_key_( AG-049087 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Alpha Initiatives Ltd )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Arcfina )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Arcfina )_need_to_be_licensed___( Arcfina )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 12 October 2024 )____[v2]_MTcyODY4NzYwMDAwMA==b44516783cbcfb95f3a9b72ed0115454'

export default {
  baseUrl,
  baseApiUrl,
  enableBrowserVersion,
  enableUnreadyFeatures,
  sentryDsnUrl,
  agGridLicenseKey,
}
