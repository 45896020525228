const idGenerator = (function* () {
  let i = 0
  while (true) {
    yield i
    i += 1
  }
})()

export function newId() {
  return idGenerator.next().value as number
}

export function newLocalKey() {
  const id = idGenerator.next().value as number
  return `local_key_${id}`
}
