import { Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { isFuture, isToday } from 'date-fns'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import NoMoreOptionsAlert from '../../../components/no-more-options-alert'
import { queryClient } from '../../../services/data/react-query'
import { dateFormat, datePlaceholder, formatNaiveDate } from '../../../utils/dates'
import useAddDatasetMutation from '../data/use-add-dataset-mutation'
import useAvailableDatasetsQuery from '../data/use-available-datasets-query'

type AddDatasetModalProps = {
  open: boolean
  groupRef: string
  onClose: () => void
}

function AddDatasetModal(props: AddDatasetModalProps) {
  const { open, groupRef, onClose } = props
  const { t } = useTranslation('settings')

  const [expiryDate, setExpiryDate] = useState<Date | null>(null)
  const [datasetRef, setDatasteRef] = useState('')
  const [isSaveDisabled, setIsSaveDisabled] = useState(false)

  const addDataset = useAddDatasetMutation()

  const availableDatasetsResponse = useAvailableDatasetsQuery(groupRef)
  const availableDatasets = availableDatasetsResponse.data?.data || []
  const hasOptions = availableDatasets.length > 0

  useEffect(() => {
    if (open) {
      availableDatasetsResponse.refetch()
    }
  }, [open])

  useEffect(() => {
    setIsSaveDisabled(!hasOptions)
  }, [hasOptions])

  function handleDatasetChange(event: SelectChangeEvent) {
    setDatasteRef(event.target.value)
  }

  function handleExpiryChange(date: Date | null) {
    if (!date || isToday(date) || isFuture(date)) {
      setIsSaveDisabled(false)
    } else {
      setIsSaveDisabled(true)
    }
    setExpiryDate(date)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    addDataset.mutate(
      {
        groupRef,
        datasetRef: datasetRef,
        expiry: expiryDate && formatNaiveDate(expiryDate),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['group', groupRef, 'datasets', 'current'])
          handleClose()
        },
      }
    )
  }

  function handleClose() {
    addDataset.reset()
    setIsSaveDisabled(false)
    onClose()
    setExpiryDate(null)
    setDatasteRef('')
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('datasets.add_dataset')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={addDataset.error} />
          {!hasOptions && <NoMoreOptionsAlert />}
          <FormControl disabled={!hasOptions}>
            <InputLabel required>{t('datasets.dataset')}</InputLabel>
            <Select
              required
              displayEmpty
              label={t('datasets.dataset')}
              value={datasetRef}
              onChange={handleDatasetChange}
            >
              {availableDatasets.map((dataset) => (
                <MenuItem key={dataset.dataset_ref} value={dataset.dataset_ref}>
                  {dataset.dataset_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat={dateFormat}
                label={t('datasets.expiry')}
                disabled={!hasOptions}
                value={expiryDate}
                disablePast
                onChange={(date) => handleExpiryChange(date!)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="off"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: datePlaceholder,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('common:add')} confirmDisabled={isSaveDisabled} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default AddDatasetModal
