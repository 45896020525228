import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Outlet, matchPath, useLocation, useParams, useSearchParams } from 'react-router-dom'
import AppLayout from '../../../components/layouts/app-layout'
import useOpenState from '../../../utils/hooks/use-open-state'
import { useAuth } from '../../auth/data/auth-context'
import useDatasetQuery from '../data/use-dataset-query'
import useSecurityMasterNavigatorQuery from '../data/use-security-master-navigator-query'
import DataEngineNav from './data-engine-nav'
import { DataEngineOutletContext } from './use-data-engine-outlet-context'

export enum DataEngineModule {
  Datasets = 'datasets',
  Assets = 'assets',
  Classifications = 'classifications',
  Models = 'models',
}

export const dataEngineTranslationKeys = {
  [DataEngineModule.Datasets]: 'configuration',
  [DataEngineModule.Assets]: 'asset_static',
  [DataEngineModule.Classifications]: 'classification_static',
  [DataEngineModule.Models]: 'models',
}

type DataEngineProps = {
  selectedModule: DataEngineModule
  modulePaths: { [key in DataEngineModule]: string }
  onModuleClick: (module: DataEngineModule, param?: string | number) => void
  onTabTitleChange?: (portfolioName: string) => void
}

function DataEngine(props: DataEngineProps) {
  const { selectedModule, modulePaths, onModuleClick, onTabTitleChange } = props
  const { t } = useTranslation('dataEngine')

  const { pathname } = useLocation()
  const { datasetRef, assetTag, classificationId, modelRef } = useParams()
  const [searchParams] = useSearchParams()
  const viewName = searchParams.get('viewName')

  const { user } = useAuth()

  const nav = useOpenState({ open: true })
  const [isLoading, setIsLoading] = useState(false)

  const navigatorQuery = useSecurityMasterNavigatorQuery()
  const navigator = navigatorQuery.data?.data
  const datasetQuery = useDatasetQuery(datasetRef)
  const dataset = datasetQuery.data?.data

  const assetType = navigator?.asset_types.find((a) => a.asset_type_tag === assetTag)
  const model = navigator?.models.find((m) => m.model_ref === modelRef)
  const classification = navigator?.classifications.find((c) => c.classification_id.toString() === classificationId)

  const datasetName = dataset?.dataset_name || ''
  const assetTypeName = assetType?.asset_type_name || ''
  const modelName = model?.model_name || ''
  const classificationName = classification?.classification_name || ''

  useEffect(() => {
    if (onTabTitleChange) {
      const module = t(dataEngineTranslationKeys[selectedModule])
      const title = datasetName ? `${module} / ${datasetName}` : module
      onTabTitleChange(title)
    }
  }, [datasetName, selectedModule])

  function getActiveBreadcrumbs() {
    const assetBreadcrumbName = [assetTypeName, viewName].filter(Boolean).join(' / ')

    const breadcrumbsByModule = {
      [DataEngineModule.Assets]: [{ name: assetBreadcrumbName, path: modulePaths.assets }],
      [DataEngineModule.Classifications]: [{ name: classificationName, path: modulePaths.classifications }],
      [DataEngineModule.Models]: [{ name: modelName, path: modulePaths.models }],
      [DataEngineModule.Datasets]: [
        { name: t('configuration'), path: modulePaths.datasets },
        { name: datasetName, path: `${modulePaths.datasets}/:datasetRef` },
        {
          name: classificationName,
          path: `${modulePaths.datasets}/:datasetRef/classifications/:classificationId`,
        },
        {
          name: modelName,
          path: `${modulePaths.datasets}/:datasetRef/models/:modelRef`,
        },
      ],
    }

    const selectedBreadcrumbs = breadcrumbsByModule[selectedModule]
    const activeBreadcrumbs: { name: string; path: string }[] = []

    selectedBreadcrumbs.forEach((breadcrumb) => {
      const pathMatch = matchPath({ path: breadcrumb.path, end: false }, pathname)

      if (pathMatch) {
        activeBreadcrumbs.push({
          name: breadcrumb.name,
          path: pathMatch.pathname,
        })
      }
    })

    return activeBreadcrumbs
  }

  const breadcrumbs = getActiveBreadcrumbs()

  const outletContext: DataEngineOutletContext = {
    showLoading: isLoading,
    setIsLoading,
    isNavOpen: nav.isOpen,
    onNavOpen: nav.open,
    breadcrumbs,
  }

  if (pathname.endsWith(`/data-engine`)) {
    const firstAssetType = navigator?.asset_types[0]

    if (!firstAssetType) {
      return null
    }

    const to = `${modulePaths['assets']}/${firstAssetType.asset_type_tag}`
    return <Navigate replace to={to} />
  }

  return (
    <AppLayout direction="row">
      <DataEngineNav
        open={nav.isOpen}
        showConfiguration={!!user?.is_superuser}
        navigator={navigator}
        selectedModule={selectedModule}
        onCloseClick={nav.close}
        onModuleClick={onModuleClick}
      />

      <Outlet context={outletContext} />
    </AppLayout>
  )
}

export default DataEngine
