import { Box, Button, Chip, CircularProgress, Divider, Menu, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FilterMenuLabel from '../../../components/filters/filter-menu-label'
import { AssetImport } from '../../../services/data/types/asset-import'
import { formatDate } from '../../../utils/dates'
import useCompleteAssetImportMutation from '../data/use-complete-asset-import-mutation'

type AssetImportsMenuProps = {
  open: boolean
  anchorEl: HTMLElement | null
  assetImports: AssetImport[]
  onRequestImport: () => void
  onRequestSync: () => void
  onRefetchImports: () => void
  onClose: () => void
}

function AssetImportsMenu(props: AssetImportsMenuProps) {
  const { open, anchorEl, assetImports, onRequestImport, onRequestSync, onRefetchImports, onClose } = props

  const { t } = useTranslation('dataEngine')

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          mt: 1,
          width: 320,
          maxHeight: '80%',
        },
      }}
    >
      <Stack direction="column" pr={2}>
        <FilterMenuLabel>{t('asset_imports.asset_imports')}</FilterMenuLabel>

        <Stack direction="row" pt={1} px={2} gap={2}>
          <Button variant="text" onClick={onRequestImport} sx={{ fontSize: 13 }}>
            {t('asset_imports.add_new_assets')}
          </Button>
          <Button variant="text" onClick={onRequestSync} sx={{ fontSize: 13 }}>
            {t('asset_imports.sync.button_label')}
          </Button>
        </Stack>
      </Stack>

      {assetImports.map((assetImport, index) => (
        <ImportItem
          key={assetImport.id}
          showDivider={assetImports.length !== index + 1}
          assetImport={assetImport}
          onRefetchImports={onRefetchImports}
        />
      ))}
    </Menu>
  )
}

export default AssetImportsMenu

type ImportItemProps = {
  showDivider: boolean
  assetImport: AssetImport
  onRefetchImports: () => void
}

function ImportItem(props: ImportItemProps) {
  const { showDivider, assetImport, onRefetchImports } = props

  const { t } = useTranslation('dataEngine')

  const completeImportMutation = useCompleteAssetImportMutation()

  const [importStatus, setImportStatus] = useState<{ status: 'still_pending' | 'imported' } | null>(null)

  const imported = importStatus?.status === 'imported' || assetImport.status === 'imported'
  const stillPending = importStatus?.status === 'still_pending' && assetImport.status === 'pending'
  const isLoading = completeImportMutation.isLoading

  function handleCompleteImport(importId: number) {
    completeImportMutation.mutate(
      { importId },
      {
        onSuccess: (data) => {
          const imported = !!data.data?.imported

          if (imported) {
            onRefetchImports()
          }

          setImportStatus({ status: imported ? 'imported' : 'still_pending' })
        },
      }
    )
  }

  return (
    <Stack key={assetImport.id} component="li" sx={{ mx: 2, my: 4, px: 1 }}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="body2">{assetImport.identifier_name}</Typography>
        <Chip size="small" label={assetImport.assets_number} />
        <Box
          ml="auto"
          sx={{ width: 8, height: 8, borderRadius: 100, backgroundColor: imported ? 'success.main' : 'arc.info' }}
        />
      </Stack>

      <Typography variant="caption" color="gray.700" mt="4px">
        {formatDate(assetImport.requested_at, 'yyyy-MM-dd HH:mm:ss')}
      </Typography>

      {stillPending && (
        <Typography mt={1} fontSize="12px" color="arc.info">
          {t('asset_imports.status.still_pending')}
        </Typography>
      )}

      {imported && (
        <Typography mt={1} fontSize="12px" color="success.main">
          {t('asset_imports.status.imported')}
        </Typography>
      )}

      {!imported && (
        <Button
          variant="contained"
          size="small"
          disabled={isLoading}
          onClick={() => handleCompleteImport(assetImport.id)}
          sx={{ mt: 2, alignSelf: 'start' }}
        >
          {isLoading && <CircularProgress size={24} color="inherit" sx={{ position: 'absolute' }} />}
          <Typography color="inherit" variant="inherit" sx={{ opacity: isLoading ? 0 : 1 }}>
            {t('asset_imports.import')}
          </Typography>
        </Button>
      )}

      {showDivider && <Divider sx={{ mt: 4, mx: -1 }} />}
    </Stack>
  )
}
