import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useViewShareOptionsQuery(viewRef: string | undefined, options: { enabled?: boolean }) {
  const enabled = options.enabled ?? true

  return useQuery({
    queryKey: ['view', viewRef, 'share-options'],
    queryFn: () => {
      if (viewRef) {
        return api.getViewShareOptions(viewRef)
      }
    },
    enabled: !!viewRef && enabled,
  })
}

export default useViewShareOptionsQuery
