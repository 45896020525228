import Axios, { AxiosError } from 'axios'
import config from '../../config'
import session from '../session'

const axios = Axios.create({
  baseURL: config.baseApiUrl,
})

axios.interceptors.request.use(
  (config) => {
    const token = session.getToken()

    if (!token) {
      return config
    }

    config.headers.set('Authorization', `Bearer ${token}`)

    return config
  },
  (error) => Promise.reject(error)
)

type CheckAuthorizationInterceptorParams = {
  onUnauthorized: () => void
}

export function createCheckAuthTokenOnErrorInterceptor(params: CheckAuthorizationInterceptorParams) {
  return axios.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const token = session.getToken()
      if (token) {
        const url = `${config.baseApiUrl}/user/self`
        const headers = {
          Authorization: `Bearer ${token}`,
        }

        const response = await fetch(url, { headers: headers })

        if (response.status === 403) {
          params.onUnauthorized()
        }
      }

      return Promise.reject(error)
    }
  )
}

export default axios
