import { useNavigate, useParams } from 'react-router-dom'
import SecurityDetails from '../../components/security-details'
import usePortfoliosQuery from '../../data/use-portfolios-query'

function SecurityDetailsFlexView() {
  const navigate = useNavigate()
  const { portfolioRef } = useParams()

  const portfoliosResponse = usePortfoliosQuery()
  const portfolioList = portfoliosResponse.data?.data || []
  const currentPortfolio = portfolioList.find((portfolio) => portfolio.portfolio_ref === portfolioRef)
  const portfolioName = currentPortfolio?.portfolio_name

  function handleNavigateToPortfolio() {
    if (portfolioRef) {
      navigate(`/portfolios/${portfolioRef}`)
    }
  }

  return (
    <SecurityDetails hidePageHeader portfolioName={portfolioName} onNavigateToPortfolio={handleNavigateToPortfolio} />
  )
}

export default SecurityDetailsFlexView
