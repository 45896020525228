import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import SecurityDetails from '../../components/security-details'
import usePortfoliosQuery from '../../data/use-portfolios-query'

function SecurityDetailsView() {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { portfolioRef } = useParams()

  const portfoliosResponse = usePortfoliosQuery()
  const portfolioList = portfoliosResponse.data?.data || []
  const currentPortfolio = portfolioList.find((portfolio) => portfolio.portfolio_ref === portfolioRef)
  const portfolioName = currentPortfolio?.portfolio_name

  useEffect(() => {
    if (portfolioName) {
      document.title = `${portfolioName} / ${t('security_details.title')}`
    }
  }, [portfolioName])

  function handleNavigateToPortfolio() {
    if (portfolioRef) {
      navigate(`/openfin/views/portfolios/grid/${portfolioRef}`)
    }
  }

  return <SecurityDetails portfolioName={portfolioName} onNavigateToPortfolio={handleNavigateToPortfolio} />
}

export default SecurityDetailsView
