import { Dialog } from '@mui/material'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import { RegularPortfolio } from '../../../services/data/types/portfolio'
import useEditPortfolioQuery from '../data/use-edit-portfolio-mutation'
import PortfolioForm from './portfolio-form'

type EditPortfolioModalProps = {
  currentPortfolio: RegularPortfolio
  open: boolean
  onClose: () => void
}

function EditPortfolioModal(props: EditPortfolioModalProps) {
  const { currentPortfolio, open, onClose } = props
  const { t } = useTranslation('settings')

  const initialFormValues = {
    portfolioName: currentPortfolio.portfolio_name,
    ledgerTag: currentPortfolio.pot_tag,
    legalEntity: currentPortfolio.lei,
    baseCurrency: currentPortfolio.base_currency,
    chartOfAccounts: currentPortfolio.chart_ref,
  }

  const { mutate: editPortfolio, error: mutationError, reset: resetEditPortfolio } = useEditPortfolioQuery()

  const [formValues, setFormValues] = useState(initialFormValues)

  function handleChange(name: string, value: string) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    const createPortfolioParams = {
      portfolio_name: formValues.portfolioName,
      lei: formValues.legalEntity,
      currency: formValues.baseCurrency,
      chart_ref: formValues.chartOfAccounts,
      pot_tag: formValues.ledgerTag,
    }

    editPortfolio(
      { portfolioPayload: createPortfolioParams, portfolioRef: currentPortfolio.portfolio_ref },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['portfolio', 'details'])
          handleClose()
        },
      }
    )
  }

  function handleClose() {
    setFormValues(initialFormValues)
    resetEditPortfolio()
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('portfolio_form.edit_portfolio')} onClose={handleClose} />
        <ModalContent>
          <PortfolioForm errorMessage={mutationError} values={formValues} onChange={handleChange} />
        </ModalContent>
        <ModalActions confirmLabel={t('common:edit')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default EditPortfolioModal
