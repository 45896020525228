import { MoreVert } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  Link,
  List,
  ListItem,
  Menu,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import { MouseEvent, SyntheticEvent, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import TableContainerHeader from '../../../components/table-container-header'
import { queryClient } from '../../../services/data/react-query'
import { GridDataViewUpdateBody } from '../../../services/data/types/grid-data-view'
import { GroupClass, GroupOption, ViewListItem, ViewType } from '../../../services/data/types/view'
import useOpenState from '../../../utils/hooks/use-open-state'
import { getInitials } from '../../../utils/strings'
import QuickSearchField from '../../data-engine/components/quick-search-field'
import ViewEditModal, { ViewEditParams } from '../../portfolios/components/view-edit-modal'
import ViewOptionsContextMenu from '../../portfolios/components/view-options-context-menu'
import ViewShareModal from '../../portfolios/components/view-share-modal'
import useDeleteGridDataViewMutation from '../../portfolios/data/use-delete-grid-data-view-mutation'
import useUpdateGridDataViewMutation from '../../portfolios/data/use-update-grid-data-view-mutation'
import useGetViewsQuery from '../data/use-get-views-mutation'

type ContextMenuState = {
  element: HTMLElement
  view: ViewListItem
}

function Views() {
  const { t } = useTranslation('settings')

  const editViewModal = useOpenState()
  const shareViewModal = useOpenState()
  const deleteViewModal = useOpenState()

  const updateView = useUpdateGridDataViewMutation()
  const deleteView = useDeleteGridDataViewMutation()

  const [viewType, setViewType] = useState<ViewType>('positions_grid')
  const [quickSearch, setQuickSearch] = useState('')
  const [currentView, setCurrentView] = useState<ContextMenuState | undefined>(undefined)

  const viewListResponse = useGetViewsQuery(viewType, { includeSharedWith: true })
  const viewList = viewListResponse.data?.data.views || []

  const filteredPortfolioViews = viewList.filter(
    (view) =>
      view.view_name.toLocaleLowerCase().includes(quickSearch.toLocaleLowerCase()) ||
      view.group_owner.group_name.toLocaleLowerCase().includes(quickSearch.toLocaleLowerCase()) ||
      view.shared_with?.some((group) => group.group_name.toLocaleLowerCase().includes(quickSearch.toLocaleLowerCase()))
  )

  function handleViewTypeChange(_event: SyntheticEvent, newViewType: ViewType) {
    setViewType(newViewType)
  }

  function handleOptionsClick(view: any, event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    event.stopPropagation()

    setCurrentView({
      element: event.currentTarget,
      view: view,
    })
  }

  function handleContextMenuClose() {
    const isAnyModalOpen = deleteViewModal.isOpen || editViewModal.isOpen || shareViewModal.isOpen

    if (isAnyModalOpen) {
      return
    }

    setCurrentView(undefined)
  }

  function handleEditViewSubmit(params: ViewEditParams) {
    const viewRef = currentView?.view.view_ref

    if (!viewRef || !currentView) {
      return
    }

    // todo: continue here
    // const prevOptions = currentView.view.view_options!

    const body: GridDataViewUpdateBody = {
      view_name: params.viewName,
      owner_group_ref: params.ownerGroupRef,
      // view_options: {
      //   ...prevOptions,
      //   asset_types: params.assetTypes,
      // },
    }

    if (!body) {
      return
    }

    updateView.mutate(
      { viewRef, body },
      {
        onSuccess: () => {
          editViewModal.close()
          setCurrentView(undefined)
          queryClient.invalidateQueries(['views', viewType])
          queryClient.invalidateQueries(['portfolio'], { exact: false })
          queryClient.invalidateQueries(['transaction'], { exact: false })
        },
      }
    )
  }

  function handleViewDelete() {
    const viewRef = currentView?.view.view_ref

    if (!viewRef) {
      return
    }

    deleteView.mutate(
      { viewRef },
      {
        onSuccess: async () => {
          handleDeleteViewModalClose()
          setCurrentView(undefined)
          queryClient.invalidateQueries(['views', viewType])
          queryClient.invalidateQueries(['portfolio'], { exact: false })
          queryClient.invalidateQueries(['transaction'], { exact: false })
        },
      }
    )
  }

  function isSelected(viewRef: string) {
    return currentView?.view.view_ref === viewRef
  }

  function handleDeleteViewModalClose() {
    deleteView.reset()
    deleteViewModal.close()
  }

  return (
    <>
      <Box p={2}>
        <TableContainer component={Paper}>
          <Stack gap={4}>
            <TableContainerHeader
              title={t('views_list_table.title')}
              action={
                <QuickSearchField
                  placeholder={t('views_list_table.quick_search_placeholder')}
                  onValueChange={setQuickSearch}
                  sx={{ minWidth: '350px' }}
                  inputSx={{ height: '42px' }}
                />
              }
            />

            <Tabs
              value={viewType}
              onChange={handleViewTypeChange}
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              TabIndicatorProps={{
                style: {
                  borderRadius: 4,
                },
              }}
            >
              <Tab
                label={t('views_list_table.portfolio_views')}
                value={'positions_grid'}
                sx={{ textTransform: 'none', px: 0, mr: 5 }}
              />
              <Tab
                label={t('views_list_table.transaction_views')}
                value="transactions_grid"
                sx={{ textTransform: 'none', px: 0, mr: 5 }}
              />
              <Tab
                label={t('views_list_table.data_engine_views')}
                value="asset_static_grid"
                sx={{ textTransform: 'none', px: 0, mr: 5 }}
              />
            </Tabs>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('views_list_table.view_name')}</TableCell>
                  <TableCell>{t('views_list_table.owner')}</TableCell>
                  <TableCell>{t('views_list_table.shared_with')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  '& .MuiTableRow-root.MuiTableRow-hover.Mui-selected .MuiTableCell-root': {
                    backgroundColor: 'transparent',
                    color: 'white',
                  },
                  '& .MuiTableRow-root.Mui-selected:hover .MuiTableCell-root': {
                    backgroundColor: 'primary.light',
                    color: 'white',
                  },
                }}
              >
                {filteredPortfolioViews.map((view) => {
                  return (
                    <TableRow
                      hover
                      selected={isSelected(view.view_ref)}
                      key={view.view_ref}
                      sx={{
                        '&.Mui-selected .action-cell': {
                          opacity: 1,
                        },
                        '&.MuiTableRow-hover.Mui-selected': {
                          backgroundColor: 'primary.light',
                        },
                        '&.MuiTableRow-hover:hover.Mui-selected': {
                          backgroundColor: 'primary.light',
                        },
                      }}
                    >
                      <TableCell>{view.view_name}</TableCell>
                      <TableCell>{view.group_owner.group_name}</TableCell>
                      <TableCell>{<SharedWith sharedWith={view.shared_with || []} />}</TableCell>
                      <TableCell className="action-cell" align="right">
                        <Tooltip title={t('options')} placement="top" arrow disableInteractive>
                          <IconButton onClick={(event) => handleOptionsClick(view, event)}>
                            <MoreVert />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Stack>
        </TableContainer>
      </Box>

      <ViewOptionsContextMenu
        anchorEl={currentView?.element}
        canModify={!!currentView?.view.can_modify}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onEditClick={editViewModal.open}
        onShareClick={shareViewModal.open}
        onDeleteClick={deleteViewModal.open}
        onClose={handleContextMenuClose}
      />

      <ViewEditModal
        open={editViewModal.isOpen}
        viewRef={currentView?.view.view_ref}
        currentName={currentView?.view.view_name || ''}
        currentOwner={currentView?.view.group_owner}
        initialAssetTypes={currentView?.view.view_options?.asset_types}
        assetTypes={undefined}
        isLoading={updateView.isLoading}
        error={updateView.error}
        onSubmit={handleEditViewSubmit}
        onClose={editViewModal.close}
      />
      <ViewShareModal
        open={shareViewModal.isOpen}
        viewRef={currentView?.view.view_ref}
        onClose={shareViewModal.close}
      />
      <ConfirmationModal
        error={deleteView.error}
        isLoading={deleteView.isLoading}
        open={deleteViewModal.isOpen}
        onConfirmButtonClick={handleViewDelete}
        onCloseButtonClick={handleDeleteViewModalClose}
        confirmButtonText={t('common:delete')}
        title={t('common:delete')}
      >
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey={'common:are_you_sure_you_want_to_delete'}
            values={{ subject: currentView?.view.view_name }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </ConfirmationModal>
    </>
  )
}

export default Views

type SharedWithProps = {
  sharedWith: GroupOption[]
}

function SharedWith({ sharedWith }: SharedWithProps) {
  const { t } = useTranslation('settings')
  const anchorElement = useRef<HTMLAnchorElement>(null)
  const remainingMembersMenu = useOpenState()

  const totalShares = sharedWith.length

  function handleUserClickOnLinkMenu(event: MouseEvent) {
    event.stopPropagation()
    remainingMembersMenu.open()
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {!totalShares ? (
        <Typography color="gray.700">-</Typography>
      ) : (
        sharedWith.map((share, index) => {
          return index < 2 && <SharedWithChip key={share.group_ref} share={share} />
        })
      )}
      {totalShares > 2 && (
        <Link ref={anchorElement} onClick={handleUserClickOnLinkMenu} sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
          {t('more', { count: totalShares - 2 })}
        </Link>
      )}
      <Menu
        open={remainingMembersMenu.isOpen}
        anchorEl={anchorElement.current}
        onClose={remainingMembersMenu.close}
        onClick={(event) => event.stopPropagation()}
        sx={{ '& .MuiPaper-root': { minWidth: 'unset' } }}
      >
        <List sx={{ p: 0 }}>
          {totalShares > 2 &&
            sharedWith.map(
              (share, index) =>
                index >= 2 && (
                  <ListItem key={share.group_ref}>
                    <SharedWithChip share={share} />
                  </ListItem>
                )
            )}
        </List>
      </Menu>
    </Stack>
  )
}

type SharedWithChipProps = {
  share: { group_name: string; group_ref: string; group_class: GroupClass }
}

function SharedWithChip({ share }: SharedWithChipProps) {
  const { t } = useTranslation('settings')

  const initials = getInitials(share.group_name)

  return (
    <Tooltip title={t(`views_list_table.${share.group_class}`)} arrow disableInteractive>
      <Chip
        avatar={<Avatar>{initials}</Avatar>}
        label={<Typography fontSize="inherit">{share.group_name}</Typography>}
        sx={{
          color: 'inherit',
          cursor: 'inherit',
          '& .MuiChip-avatar': {
            color: 'gray.light',
            backgroundColor: 'gray.400',
          },
        }}
      />
    </Tooltip>
  )
}
