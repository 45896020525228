import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useFlexLayoutContext } from '../../../../components/layouts/flex-layout-context'
import TransactionsGrid from '../../components/transactions-grid'
import usePortfoliosQuery from '../../data/use-portfolios-query'

function TransactionsGridFlexView() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { portfolioRef } = useParams()
  const { openView, updateTabName, getActiveTabId } = useFlexLayoutContext()
  const tabId = useMemo(() => getActiveTabId(), [])

  const portfoliosResponse = usePortfoliosQuery()
  const portfolios = portfoliosResponse.data?.data

  useEffect(() => {
    if (!portfolioRef && portfoliosResponse.isSuccess) {
      const portfolio = portfolios?.[0]
      if (portfolio) {
        navigate(`/transactions/${portfolio.portfolio_ref}`)
      }
    }
  }, [portfolioRef, portfoliosResponse])

  function handlePortfolioSelect(portfolioRef: string) {
    navigate(`/transactions/${portfolioRef}`)
  }

  function handleCorrectTransaction(transactionRef: string) {
    if (portfolioRef) {
      openView(
        `/trade-ticket/${portfolioRef}/transactions/${transactionRef}/correct`,
        t('tradeTicket:transaction_correction')
      )
    }
  }

  function handleCloseOutTransaction(segmentRef: string) {
    if (portfolioRef) {
      openView(
        `/trade-ticket/${portfolioRef}/transactions/segments/${segmentRef}/close`,
        t('tradeTicket:close_out_transaction')
      )
    }
  }

  function handleConfirmTrade(dealRef: string) {
    if (portfolioRef) {
      openView(
        `/trade-ticket/${portfolioRef}/transactions/deals/${dealRef}/confirm`,
        t('tradeTicket:trade_confirmation')
      )
    }
  }

  function handleNewTrade() {
    openView(`/trade-ticket/${portfolioRef}`, t('tradeTicket:trade_ticket'))
  }

  function handleNewCashflow() {
    openView(`/trade-ticket/${portfolioRef}?cashflow`, t('tradeTicket:cashflow'))
  }

  function handleTabNameUpdate(name: string | undefined) {
    if (tabId && name) {
      updateTabName(tabId, name)
    }
  }

  return (
    <TransactionsGrid
      portfolioRef={portfolioRef}
      onPortfolioSelect={handlePortfolioSelect}
      onCorrectTransaction={handleCorrectTransaction}
      onCloseOutTransaction={handleCloseOutTransaction}
      onConfirmTrade={handleConfirmTrade}
      onNewTrade={handleNewTrade}
      onNewCashflow={handleNewCashflow}
      onTabNameUpdate={handleTabNameUpdate}
    />
  )
}

export default TransactionsGridFlexView
