import { Dialog } from '@mui/material'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import { ClassificationValuePayload } from '../../../services/data/types/classifications'
import { formatNaiveDate } from '../../../utils/dates'
import useCreateClassificationValueMutation from '../data/use-create-classification-value-mutation'
import ClassificationValueForm, { ClassificationValueFormValues } from './classification-value-form'

type AddClassificationValueModalProps = {
  open: boolean
  classificationId: string | number
  isBucketed?: boolean
  onClose: () => void
}

const initialFormValues: ClassificationValueFormValues = {
  tag: '',
  description: '',
  bucket_value: '',
  expiry: '',
}

function AddClassificationValueModal(props: AddClassificationValueModalProps) {
  const { open, classificationId, isBucketed, onClose } = props
  const { t } = useTranslation('dataEngine')

  const {
    error: createClassificationValueError,
    mutate: createClassificationValue,
    reset: createClassificationValueReset,
  } = useCreateClassificationValueMutation()

  const [formValues, setFormValues] = useState(initialFormValues)

  function handleChange(name: string, value: string | number) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    const bucketValue = formValues.bucket_value ? Number(formValues.bucket_value) : null
    const formattedDate = formValues.expiry ? formatNaiveDate(formValues.expiry) : null

    const payload: ClassificationValuePayload = {
      tag: formValues.tag,
      desc: formValues.description,
      expired: formattedDate,
      bucket_start: bucketValue,
    }

    createClassificationValue(
      { classificationId, payload },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['classification', classificationId])
          handleClose()
        },
      }
    )
  }

  function handleClose() {
    onClose()
    createClassificationValueReset()
    setFormValues(initialFormValues)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('classification_values_table.add')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={createClassificationValueError} />
          <ClassificationValueForm values={formValues} isBucketed={isBucketed} onChange={handleChange} />
        </ModalContent>
        <ModalActions confirmLabel={t('common:create')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default AddClassificationValueModal
