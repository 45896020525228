import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import { queryClient } from '../../../services/data/react-query'
import useRemoveDatapointMutation from '../data/use-remove-datapoint-mutation'

type RemoveDatapointModalProps = {
  open: boolean
  datasetRef: string
  datapointRef: string
  datapointName: string
  onClose: () => void
}

function RemoveDatapointModal(props: RemoveDatapointModalProps) {
  const { open, datasetRef, datapointRef, datapointName, onClose } = props
  const { t } = useTranslation('dataEngine')

  const {
    mutate: removeDatapoint,
    error: removeDatapointError,
    reset: removeDatapointReset,
  } = useRemoveDatapointMutation()

  function handleRemove() {
    const removeProps = {
      datasetRef,
      datapointRef,
    }

    removeDatapoint(removeProps, {
      onSuccess: () => {
        handleClose()
        queryClient.invalidateQueries(['dataset', datasetRef, 'datapoints'])
      },
    })
  }

  function handleClose() {
    removeDatapointReset()
    onClose()
  }

  return (
    <ConfirmationModal
      error={removeDatapointError}
      open={open}
      title={t('datapoint_table.remove_datapoint')}
      confirmButtonText={t('common:remove')}
      onCloseButtonClick={onClose}
      onConfirmButtonClick={handleRemove}
    >
      <Typography variant="body1">
        {t('datapoint_table.remove_datapoint_confirmation_text', { name: datapointName })}
      </Typography>
    </ConfirmationModal>
  )
}

export default RemoveDatapointModal
