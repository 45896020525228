import { Dialog, Stack, TextField, Typography } from '@mui/material'
import { ChangeEvent, FormEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { extractDatapointFormatAndValue, formatDatapointValue } from '../../../services/data/datapoint-formatting'
import { ParsedGridData } from '../../../services/data/grid-data-parsing'
import { UpdatedGridCell } from '../../../services/data/types/grid-data'
import useCreateOrderBatchMutation from '../data/use-create-order-batch-mutation'

type ModellerSendToTradingModal = {
  open: boolean
  portfolioRef?: string
  updatedCells: UpdatedGridCell[]
  gridData: ParsedGridData | null
  onTradingCreated: () => void
  onClose: () => void
}

function ModellerSendToTradingModal(props: ModellerSendToTradingModal) {
  const { open, portfolioRef, updatedCells, gridData, onTradingCreated, onClose } = props

  const { t } = useTranslation('dataTable')

  const [name, setName] = useState('')

  const createOrderBatchMutation = useCreateOrderBatchMutation()

  const changeCellsUpdates = useMemo(() => {
    const changeHeadings = gridData?.visibleHeadings.filter((heading) => heading.meta.position === 'change') || []
    const changeCellsUpdates = updatedCells
      .filter((cell) => {
        const isChangeCell = !!changeHeadings.find((heading) => {
          return cell.datapoint_ref === heading.meta.datapoint_ref
        })
        return isChangeCell
      })
      .map((cell) => {
        const row = gridData?.rows?.find((row) => row.rowRef === cell.y_ref)
        const [datapointType, datapointValue] = extractDatapointFormatAndValue(cell.datapoint_value)

        const assetRef = row?.assetRef || ''
        const rowDescription = row?.rowDescription || assetRef || t('not_found_placeholder')
        const value = typeof datapointValue === 'number' ? datapointValue : 0
        const formattedValue = formatDatapointValue(datapointType, datapointValue)

        return { assetRef, rowDescription, value, formattedValue }
      })
      .filter((update) => update.value !== 0)

    return changeCellsUpdates
  }, [gridData, updatedCells])

  const canSubmit = changeCellsUpdates.length > 0 && !!name

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value)
  }

  function handleSave(event: FormEvent) {
    event.preventDefault()

    if (!portfolioRef) {
      return
    }

    createOrderBatchMutation.mutate(
      {
        batch_name: name,
        orders: changeCellsUpdates.map((update) => {
          return {
            asset_ref: update.assetRef,
            allocations: [
              {
                portfolio_ref: portfolioRef,
                amount: update.value,
              },
            ],
          }
        }),
      },
      {
        onSuccess: () => {
          onTradingCreated()
          handleClose()
        },
      }
    )
  }

  function handleClose() {
    setName('')
    createOrderBatchMutation.reset()
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSave}>
        <ModalTitle title={t('send_to_trading')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={createOrderBatchMutation.error} />

          <TextField
            required
            name="orderBatchName"
            label={t('order_batch_name')}
            value={name}
            autoComplete="off"
            inputProps={{ maxLength: 30 }}
            onChange={handleNameChange}
          />

          <Typography variant="subtitle2">{t('proposed_orders')}</Typography>

          {!changeCellsUpdates.length && <Typography>-</Typography>}

          {changeCellsUpdates.map((update, i) => (
            <Stack key={i} direction="row" alignItems="center">
              <Typography flex="1" variant="caption" color="gray.700">
                {update.rowDescription}
              </Typography>
              <Typography flex="1" variant="body2">
                {update.formattedValue}
              </Typography>
            </Stack>
          ))}
        </ModalContent>
        <ModalActions
          confirmLabel={t('send_to_trading')}
          isLoading={createOrderBatchMutation.isLoading}
          confirmDisabled={!canSubmit}
          onCancel={handleClose}
        />
      </form>
    </Dialog>
  )
}

export default ModellerSendToTradingModal
