import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useAuditNavigatorQuery() {
  return useQuery(['audit', 'navigator'], () => {
    return api.getAuditNavigator()
  })
}

export default useAuditNavigatorQuery
