import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { PortfolioUploadParams } from '../../../services/data/types/portfolio'

export default function usePortfolioUploadCommitFileMutation() {
  const response = useMutation<AxiosResponse, AxiosError, Params>({
    mutationFn: ({ params, file }) => api.portfolioUploadCommitFile(params, file),
  })

  return { ...response, error: parseError(response.error) }
}

type Params = {
  params: PortfolioUploadParams
  file: File
}
