import { Dialog, TextField } from '@mui/material'
import { ChangeEvent, FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import useAddTenantMutation from '../data/use-add-tenant-mutation'

type AddTenantModalProps = {
  isOpen: boolean
  onClose: () => void
}

const initialFormValues = {
  tenantName: '',
}

function AddTenantModal(props: AddTenantModalProps) {
  const { isOpen, onClose } = props

  const { t } = useTranslation('settings')

  const addTenantMutation = useAddTenantMutation()

  const [formValues, setFormValues] = useState(initialFormValues)

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target
    setFormValues({ ...formValues, [name]: value })
  }

  function handleClose() {
    setFormValues(initialFormValues)
    onClose()
    addTenantMutation.reset()
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    const addTenantParams = {
      tenant_name: formValues.tenantName,
    }

    addTenantMutation.mutate(addTenantParams, {
      onSuccess: () => {
        queryClient.invalidateQueries(['tenants'])
        handleClose()
      },
    })
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle onClose={handleClose} title={t('tenants_page.tenant_modals.add_new_tenant')} />
        <ModalContent>
          <Alert severity="error" message={addTenantMutation.error} />
          <TextField
            required
            name="tenantName"
            label={t('tenants_page.tenant_modals.tenant_name')}
            value={formValues.tenantName}
            onChange={handleChange}
            sx={{ flex: 1 }}
          />
        </ModalContent>
        <ModalActions isLoading={addTenantMutation.isLoading} confirmLabel={t('common:add')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default AddTenantModal
