import { Dialog } from '@mui/material'
import { FormEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import { Classification, ClassificationValuePayload } from '../../../services/data/types/classifications'
import { formatNaiveDate } from '../../../utils/dates'
import useEditClassificationValueMutation from '../data/use-edit-classification-value-mutation'
import ClassificationValueForm, { ClassificationValueFormValues } from './classification-value-form'

type EditClassificationValueModalProps = {
  open: boolean
  classificationId: string | number
  classificationValue?: Classification['values'][number]
  isBucketed?: boolean
  onClose: () => void
}

function EditClassificationValueModal(props: EditClassificationValueModalProps) {
  const { open, classificationValue, classificationId, isBucketed, onClose } = props
  const { t } = useTranslation('dataEngine')

  const {
    error: editClassificationValueError,
    mutate: editClassificationValue,
    reset: editClassificationValueReset,
  } = useEditClassificationValueMutation()

  const initialFormValues = useMemo<ClassificationValueFormValues>(() => {
    return {
      tag: classificationValue?.tag || '',
      description: classificationValue?.desc || '',
      expiry: classificationValue?.expired || '',
      bucket_value: String(classificationValue?.bucket_start || ''),
    }
  }, [classificationValue])

  const [formValues, setFormValues] = useState(initialFormValues)

  useEffect(() => {
    setFormValues(initialFormValues)
  }, [initialFormValues])

  function handleChange(name: string, value: string) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    const valueId = classificationValue?.value_id

    if (!valueId) {
      return
    }

    const bucketValue = formValues.bucket_value ? Number(formValues.bucket_value) : null
    const formattedDate = formValues.expiry ? formatNaiveDate(formValues.expiry) : null

    const payload: ClassificationValuePayload = {
      tag: formValues.tag,
      desc: formValues.description,
      expired: formattedDate,
      bucket_start: bucketValue,
    }

    editClassificationValue(
      { classificationId, valueId, payload },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['classification', classificationId])
          handleClose()
        },
      }
    )
  }

  function handleClose() {
    onClose()
    editClassificationValueReset()
    setFormValues(initialFormValues)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('classification_values_table.edit')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={editClassificationValueError} />
          <ClassificationValueForm type="edit" values={formValues} isBucketed={isBucketed} onChange={handleChange} />
        </ModalContent>
        <ModalActions confirmLabel={t('common:save')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default EditClassificationValueModal
