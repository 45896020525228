import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { Stack } from '@mui/system'
import { FormEvent, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ConfirmationModal from '../../../components/confirmation-modal'
import { MenuItemLabel } from '../../../components/menu-item-label'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import { GroupOption } from '../../../services/data/types/view'
import { getInitials } from '../../../utils/strings'
import useShareViewMutation from '../data/use-share-view-mutation'
import useUnshareViewMutation from '../data/use-unshare-view-mutation'
import useViewShareOptionsQuery from '../data/use-view-share-options-query'

type ViewShareModalProps = {
  open: boolean
  viewRef: string | undefined
  onClose: () => void
}

function ViewShareModal(props: ViewShareModalProps) {
  const { open, viewRef, onClose } = props

  const { t } = useTranslation('portfolio')

  const [ownerGroupRefs, setOwnerGroupRefs] = useState<string[]>([])
  const [shareToRemove, setShareToRemove] = useState<{ groupRef: string; name: string }>()

  const viewShareOptionsResponse = useViewShareOptionsQuery(viewRef, { enabled: open })
  const viewShareOptions = viewShareOptionsResponse.data?.data

  const shareView = useShareViewMutation()
  const unshareView = useUnshareViewMutation()

  const disabled = ownerGroupRefs.length === 0 || shareView.isLoading || viewShareOptionsResponse.isLoading

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (!viewRef) {
      return
    }

    const body = { groups: ownerGroupRefs }

    shareView.mutate(
      { viewRef, body },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['view', viewRef, 'share-options'])
          queryClient.invalidateQueries(['views'], { exact: false })
          setOwnerGroupRefs([])
        },
      }
    )
  }

  function handleUnshare() {
    if (!viewRef || !shareToRemove) {
      return
    }

    const groupRef = shareToRemove.groupRef

    unshareView.mutate(
      { viewRef, groupRef },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['view', viewRef, 'share-options'])
          queryClient.invalidateQueries(['views'], { exact: false })
          handleUnshareClose()
        },
      }
    )
  }

  function handleRemoveClick(groupRef: string, name: string) {
    setShareToRemove({
      groupRef,
      name,
    })
  }

  function handleUnshareClose() {
    setShareToRemove(undefined)
  }

  function handleChange(event: SelectChangeEvent<string[]>) {
    const value = event.target.value
    const newValue = typeof value === 'string' ? value.split(',') : value
    setOwnerGroupRefs(newValue)
  }

  function handleClose() {
    onClose()
    setOwnerGroupRefs([])
  }

  if (!viewShareOptions) {
    return
  }

  const availableTenants = viewShareOptions.available.filter((option) => option.group_class === 'tenant')
  const availableGroups = viewShareOptions.available.filter((option) => option.group_class === 'group')
  const availableUsers = viewShareOptions.available.filter((option) => option.group_class === 'user')

  const sharedTenants = viewShareOptions.shared.filter((option) => option.group_class === 'tenant')
  const sharedGroups = viewShareOptions.shared.filter((option) => option.group_class === 'group')
  const sharedUsers = viewShareOptions.shared.filter((option) => option.group_class === 'user')

  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalTitle title={t('share_view')} onClose={handleClose} />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <Alert severity="error" message={unshareView.error} />
          <Stack direction="row" justifyContent={'space-between'} alignItems="center">
            <FormControl sx={{ width: '100%' }}>
              <InputLabel>{t('share_with')}</InputLabel>
              <Select multiple value={ownerGroupRefs} label={t('share_with')} onChange={handleChange}>
                {availableTenants.length > 0 && <ListSubheader>{t('company')}</ListSubheader>}
                {availableTenants.map((option) => (
                  <MenuItem key={option.group_ref} value={option.group_ref}>
                    {option.group_name}
                  </MenuItem>
                ))}
                {availableGroups.length > 0 && <ListSubheader>{t('groups')}</ListSubheader>}
                {availableGroups.map((option) => (
                  <MenuItem key={option.group_ref} value={option.group_ref}>
                    {option.group_name}
                  </MenuItem>
                ))}
                {availableUsers.length > 0 && <ListSubheader>{t('users')}</ListSubheader>}
                {availableUsers.map((option) => (
                  <MenuItem key={option.group_ref} value={option.group_ref}>
                    {option.group_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DialogActions sx={{ pr: 0 }}>
              <Button variant="contained" color="primary" type="submit" disabled={disabled}>
                {shareView.isLoading && <CircularProgress size={24} color="inherit" sx={{ position: 'absolute' }} />}
                <Typography color="inherit" variant="inherit" sx={{ opacity: shareView.isLoading ? 0 : 1 }}>
                  {t('common:share')}
                </Typography>
              </Button>
            </DialogActions>
          </Stack>
        </form>
        <Stack gap={2}>
          {viewShareOptions.shared.length > 0 && (
            <Typography variant="subtitle2" sx={{ mt: 1, mb: 0.5 }}>
              {t('shared_with')}
            </Typography>
          )}
          {sharedTenants.length > 0 && (
            <Stack>
              <MenuItemLabel label={t('company')} sx={{ px: 0 }} />
              <Stack gap={1}>
                {sharedTenants.map((option) => (
                  <SharedWithRow key={option.group_ref} option={option} onRemoveClick={handleRemoveClick} />
                ))}
              </Stack>
            </Stack>
          )}
          {sharedGroups.length > 0 && (
            <Stack>
              <MenuItemLabel label={t('groups')} sx={{ px: 0 }} />
              <Stack gap={1}>
                {sharedGroups.map((option) => (
                  <SharedWithRow key={option.group_ref} option={option} onRemoveClick={handleRemoveClick} />
                ))}
              </Stack>
            </Stack>
          )}
          {sharedUsers.length > 0 && (
            <Stack>
              <MenuItemLabel label={t('users')} sx={{ px: 0 }} />
              <Stack gap={1}>
                {sharedUsers.map((option) => (
                  <SharedWithRow key={option.group_ref} option={option} onRemoveClick={handleRemoveClick} />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </ModalContent>

      <ConfirmationModal
        title={t('common:are_you_sure')}
        confirmButtonText={t('common:remove')}
        open={!!shareToRemove}
        onCloseButtonClick={handleUnshareClose}
        onConfirmButtonClick={handleUnshare}
      >
        <Trans
          t={t}
          i18nKey={'common:are_you_sure_you_want_to_unshare_view'}
          values={{ subject: shareToRemove?.name || '' }}
        />
      </ConfirmationModal>
    </Dialog>
  )
}

export default ViewShareModal

type SharedWithRowProps = {
  option: GroupOption
  onRemoveClick: (groupRef: string, name: string) => void
}

function SharedWithRow(props: SharedWithRowProps) {
  const { option, onRemoveClick } = props
  const { t } = useTranslation()

  return (
    <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between" sx={{ pl: 1.5 }}>
      <Avatar sx={{ backgroundColor: 'gray.400', height: 24, width: 24, fontSize: 12 }}>
        {getInitials(option.group_name)}
      </Avatar>
      <Typography variant="body2" sx={{ marginRight: 'auto' }}>
        {option.group_name}
      </Typography>
      <Button onClick={() => onRemoveClick(option.group_ref, option.group_name)} sx={{ color: 'gray.300' }}>
        {t('common:remove')}
      </Button>
    </Stack>
  )
}
