import { ChevronRight } from '@mui/icons-material'
import { Chip, IconButton, Stack, Typography } from '@mui/material'
import { MouseEventHandler } from 'react'
import { ReloadButton } from '../../../components/buttons/reload-button'
import PageHeaderContainer from '../../../components/page-header-container'

type ComplianceHeaderProps = {
  portfolioTitle: string
  currency: string
  showLoading: boolean
  isNavOpen: boolean
  onOpenNavClick: MouseEventHandler
  onReloadData: () => void
}

function ComplianceHeader(props: ComplianceHeaderProps) {
  const { portfolioTitle, currency, showLoading, isNavOpen, onOpenNavClick, onReloadData } = props

  return (
    <PageHeaderContainer>
      <Stack direction="row" alignItems="center" gap={1}>
        {!isNavOpen && (
          <IconButton onClick={onOpenNavClick}>
            <ChevronRight color="primary" />
          </IconButton>
        )}
        {portfolioTitle && <Typography variant="h6">{portfolioTitle}</Typography>}
        {currency && <Chip label={<Typography color="gray.700">{currency}</Typography>} />}
        <ReloadButton loading={showLoading} onClick={onReloadData} />
      </Stack>
    </PageHeaderContainer>
  )
}

export default ComplianceHeader
