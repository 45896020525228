import { Chip, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { TradeTicketResponse } from '../../../../../../services/data/types/trade-ticket'

type CurrencySelectorProps = {
  label: string
  currency: string
  currencies: TradeTicketResponse['currencies']
  onChange: (currency: string) => void
}

function CurrencySelector(props: CurrencySelectorProps) {
  const { label, currency, currencies, onChange } = props

  const currencyOptions = Object.entries(currencies).sort(([_, aName], [__, bName]) => aName.localeCompare(bName))
  const currencyName = currencyOptions.find(([cRef, _]) => cRef === currency)?.[1]

  return (
    <FormControl required>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={currency}
        onChange={(event) => onChange(event.target.value)}
        renderValue={() => <Chip key={currency} label={currencyName} size="small" />}
      >
        {currencyOptions.map(([currencyRef, currencyName]) => (
          <MenuItem key={currencyRef} value={currencyRef}>
            {currencyName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CurrencySelector
