import { TextField } from '@mui/material'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ParsedFilter } from '../../services/data/filter-parsing'
import { GridDataViewFilters } from '../../services/data/types/grid-data-view'
import FilterFormButtons from './filter-form-buttons'
import SelectFilterMenu from './select-filter-menu'

type StringFilterMenuProps = {
  datapointRef: string
  disableBackdrop?: boolean
  selectedFilter?: ParsedFilter
  anchorEl: HTMLElement | null
  isSelectedFilter: boolean
  onClose: () => void
  onChange: (filters: GridDataViewFilters) => void
  onDelete: () => void
}

export function StringFilterMenu(props: StringFilterMenuProps) {
  const { datapointRef, selectedFilter, anchorEl, disableBackdrop, isSelectedFilter, onChange, onClose, onDelete } =
    props

  const { t } = useTranslation('dataTable')

  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState('')

  useEffect(() => {
    const value = String(selectedFilter?.values[0] || '')
    setValue(value)
    inputRef.current?.focus()
  }, [selectedFilter, anchorEl])

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    onChange({
      [datapointRef]: {
        operator: 'contains',
        values: [value],
      },
    })
    onClose()
  }

  return (
    <SelectFilterMenu
      anchorEl={anchorEl}
      disableBackdrop={disableBackdrop}
      label={t('filter_for_values_containing')}
      onClose={onClose}
      sx={{ ml: -1.5 }}
    >
      <form onSubmit={handleSubmit}>
        <TextField
          inputRef={inputRef}
          autoFocus
          autoComplete="off"
          size="small"
          name="field1"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          onFocus={(event) => {
            setTimeout(() => event.target.select(), 0)
          }}
          sx={{ ml: 2.5, width: '88%' }}
        />
        <FilterFormButtons
          submitDisabled={!value}
          clearDisabled={!isSelectedFilter}
          onCancel={onClose}
          onClear={onDelete}
        />
      </form>
    </SelectFilterMenu>
  )
}
