import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PercentAdorment } from '../../modules/trade-ticket/components/profiles/shared/fields/adorments'
import { GridDataFilterOption, ParsedFilter } from '../../services/data/filter-parsing'
import { GridDataViewFilterOperator, GridDataViewFilters } from '../../services/data/types/grid-data-view'
import { defaultScale } from '../../utils/math'
import NumericField from '../fields/numeric-field'
import FilterFormButtons from './filter-form-buttons'
import SelectFilterMenu from './select-filter-menu'

type NumberFilterMenuProps = {
  anchorEl: HTMLElement | null
  filter: GridDataFilterOption
  selectedFilter?: ParsedFilter
  disableBackdrop?: boolean
  isSelectedFilter: boolean
  onChange: (filters: GridDataViewFilters) => void
  onClose: () => void
  onDelete: () => void
}

type NumberFilterForm = {
  field1: string | number
  field2: string | number
}

const initialValues = { field1: '', field2: '' }

export function NumberFilterMenu(props: NumberFilterMenuProps) {
  const { anchorEl, selectedFilter, filter, disableBackdrop, isSelectedFilter, onChange, onClose, onDelete } = props

  const { t } = useTranslation('dataTable')

  const [operator, setOperator] = useState<GridDataViewFilterOperator | ''>('')
  const [formValues, setFormValues] = useState<NumberFilterForm>(initialValues)

  const isPercent = filter.datapointType === 'Percent'

  useEffect(() => {
    const selectedValues = selectedFilter?.values || []
    const selectedOperator = selectedFilter?.operator || ''

    const multiplier = isPercent ? 100 : 1

    let value1 = ''
    let value2 = ''
    if (typeof selectedValues[0] === 'number') {
      value1 = String(selectedValues[0] * multiplier)
    }
    if (typeof selectedValues[1] === 'number') {
      value2 = String(selectedValues[1] * multiplier)
    }

    setOperator(selectedOperator)
    setFormValues({ field1: value1, field2: value2 })
  }, [selectedFilter, anchorEl])

  function handleOperatorChange(_: ChangeEvent<HTMLElement>, value: string) {
    setOperator(value as GridDataViewFilterOperator)
  }

  function handleFieldChange(name: string, value: string) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    if (!operator) {
      return
    }

    const datapointRef = filter.datapointRef

    const value1 = formValues.field1
    const value2 = operator === 'btwn' ? formValues.field2 : ''
    const values = [value1, value2]
      .filter((value) => value !== '')
      .map((value) => {
        if (isPercent) {
          return Number(value) / 100
        }
        return Number(value)
      })

    onChange({
      [datapointRef]: {
        operator,
        values,
      },
    })
    onClose()
  }

  function isApplyDisabled() {
    if (operator === 'btwn') {
      return formValues.field1 === '' || formValues.field2 === ''
    }

    return !operator || formValues.field1 === ''
  }

  return (
    <SelectFilterMenu anchorEl={anchorEl} onClose={onClose} disableBackdrop={disableBackdrop} sx={{ ml: -1.5 }}>
      <form onSubmit={handleSubmit}>
        <RadioGroup
          value={operator}
          onChange={handleOperatorChange}
          sx={{
            gap: 0.25,
            px: 3,
            '& .MuiSvgIcon-root': {
              fontSize: 16,
            },
          }}
        >
          <FormControlLabel
            value="lt"
            control={<Radio />}
            label={<Typography variant="body2">{t('filters.less_than')}</Typography>}
          />
          <FormControlLabel
            value="gt"
            control={<Radio />}
            label={<Typography variant="body2">{t('filters.more_than')}</Typography>}
          />
          <FormControlLabel
            value="eq"
            control={<Radio />}
            label={<Typography variant="body2">{t('filters.equal_to')}</Typography>}
          />
          <FormControlLabel
            value="btwn"
            control={<Radio />}
            label={<Typography variant="body2">{t('filters.between')}</Typography>}
          />
          {!!operator && (
            <Stack direction="row" gap={1} my={1}>
              <NumericField
                autoFocus
                size="small"
                name="field1"
                value={String(formValues.field1)}
                decimalScale={defaultScale}
                onValueChange={(value) => handleFieldChange('field1', value)}
                endAdornment={filter.datapointType === 'Percent' ? <PercentAdorment /> : null}
              />
              {operator === 'btwn' && (
                <NumericField
                  size="small"
                  name="field2"
                  value={String(formValues.field2)}
                  decimalScale={defaultScale}
                  onValueChange={(value) => handleFieldChange('field2', value)}
                  endAdornment={filter.datapointType === 'Percent' ? <PercentAdorment /> : null}
                />
              )}
            </Stack>
          )}
        </RadioGroup>
        <FilterFormButtons
          clearDisabled={!isSelectedFilter}
          submitDisabled={isApplyDisabled()}
          onCancel={onClose}
          onClear={onDelete}
        />
      </form>
    </SelectFilterMenu>
  )
}
