import { ArrowDownward, ArrowUpward, DeleteOutline, Edit } from '@mui/icons-material'
import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

type ClassificationValueMenuProps = {
  anchorEl: HTMLElement | null
  moveUpDisabled?: boolean
  moveDownDisabled?: boolean
  onEdit: MouseEventHandler
  onMoveUp: MouseEventHandler
  onMoveDown: MouseEventHandler
  onDelete: MouseEventHandler
  onClose?: MouseEventHandler
}

function ClassificationValueMenu(props: ClassificationValueMenuProps) {
  const { anchorEl, moveUpDisabled, moveDownDisabled, onEdit, onMoveUp, onMoveDown, onDelete, onClose } = props

  const { t } = useTranslation('dataEngine')

  return (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      transitionDuration={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
    >
      <MenuItem onClick={onEdit}>
        <ListItemIcon>
          <Edit sx={{ color: 'gray.300' }} />
        </ListItemIcon>
        <ListItemText>
          <Typography>{t('classification_values_table.edit')}</Typography>
        </ListItemText>
      </MenuItem>

      <MenuItem disabled={moveUpDisabled} onClick={onMoveUp}>
        <ListItemIcon>
          <ArrowUpward sx={{ color: 'gray.300' }} />
        </ListItemIcon>
        <ListItemText>
          <Typography>{t('classification_values_table.move_up')}</Typography>
        </ListItemText>
      </MenuItem>

      <MenuItem disabled={moveDownDisabled} onClick={onMoveDown}>
        <ListItemIcon>
          <ArrowDownward sx={{ color: 'gray.300' }} />
        </ListItemIcon>
        <ListItemText>
          <Typography>{t('classification_values_table.move_down')}</Typography>
        </ListItemText>
      </MenuItem>

      <MenuItem onClick={onDelete}>
        <ListItemIcon>
          <DeleteOutline sx={{ color: 'gray.300' }} />
        </ListItemIcon>
        <ListItemText>
          <Typography>{t('common:delete')}</Typography>
        </ListItemText>
      </MenuItem>
    </Menu>
  )
}

export default ClassificationValueMenu
