import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { InsertCounterpartySettlementPayload } from '../../../services/data/types/bank-accounts'

function useInsertCounterpartySettlementMutation() {
  const response = useMutation<AxiosResponse, AxiosError, MutationParams>({
    mutationFn: ({ bankAccountId, counterpartyId, payload }) => {
      return api.insertCounterpartySettlement(bankAccountId, counterpartyId, payload)
    },
  })

  return { ...response, error: parseError(response.error) }
}

type MutationParams = {
  bankAccountId: string
  counterpartyId: number
  payload: InsertCounterpartySettlementPayload
}

export default useInsertCounterpartySettlementMutation
