import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { GenerateDatatocResponse } from '../../../services/data/types/asset-static-data'

function useGenerateDatadocMutation() {
  const response = useMutation<AxiosResponse<GenerateDatatocResponse[]>, AxiosError, GenerateDatadocMutationParams>({
    mutationFn: ({ datasetRef, assetRef, datapointRef }) => {
      return api.generateDatadoc(datasetRef, [{ asset_ref: assetRef, datapoint_ref: datapointRef }])
    },
  })

  return { ...response, error: parseError(response.error) }
}

export type GenerateDatadocMutationParams = {
  datasetRef: string
  assetRef: string
  datapointRef: string
}

export default useGenerateDatadocMutation
