import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'

function useChangePasswordMutation() {
  return useMutation<AxiosResponse, AxiosError, MutationFnParams>({
    mutationFn: ({ currentPassword, newPassword }) => {
      return api.changePassword(currentPassword, newPassword)
    },
  })
}

type MutationFnParams = {
  currentPassword: string
  newPassword: string
}

export default useChangePasswordMutation
