import { Refresh } from '@mui/icons-material'
import { Box, IconButton, SxProps, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

type ReloadButtonProps = {
  loading: boolean
  sx?: SxProps
  onClick: () => void
}

export function ReloadButton(props: ReloadButtonProps) {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('common:reload_data')} enterDelay={500} arrow disableInteractive>
      <Box sx={props.sx}>
        <IconButton
          disabled={props.loading}
          onClick={props.onClick}
          sx={{ color: 'gray.300', padding: '4px', '&:hover': { background: 'none' } }}
        >
          <Refresh className={props.loading ? 'spin' : ''} />
        </IconButton>
      </Box>
    </Tooltip>
  )
}
