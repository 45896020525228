import { CheckCircleOutline, ErrorOutline, InfoOutlined, WarningAmber } from '@mui/icons-material'
import { Box, PopperProps, Tooltip, TooltipProps, lighten, useTheme } from '@mui/material'
import { MouseEventHandler, ReactElement, useEffect, useMemo } from 'react'
import useOpenStateDebounced from '../utils/hooks/use-open-state-debounced'
import TriangleIcon from './triangle-icon'

type AlertIconProps = {
  severity: AlertSeverity
  message: ReactElement | string
  tooltipFixed?: boolean
  tooltipPlacement?: TooltipProps['placement']
  tooltipOffset?: [number, number]
  iconSize?: 'regular'
  useInfoIcon?: boolean
  onTriangleClick?: MouseEventHandler<HTMLDivElement>
}

export type AlertSeverity = 'basic' | 'info' | 'warning' | 'error' | 'success'

// todo: this component is doing too many things, we should probably split it.

function AlertIcon(props: AlertIconProps) {
  const { severity, message, tooltipFixed, tooltipPlacement, tooltipOffset, iconSize, useInfoIcon, onTriangleClick } =
    props

  const theme = useTheme()

  const tooltip = useOpenStateDebounced()

  const isLowLevel = severity === 'basic' || severity === 'info'

  const severities = {
    basic: {
      baseColor: theme.palette.gray[300],
      backgroundColor: '#FFFFFF',
      textColor: '#000000',
      icon: TriangleIcon,
    },
    info: {
      baseColor: theme.palette.info.dark,
      backgroundColor: '#07202E',
      textColor: lighten(theme.palette.info.dark, 0.6),
      icon: InfoOutlined,
    },
    warning: {
      baseColor: theme.palette.warning.dark,
      backgroundColor: '#311E04',
      textColor: lighten(theme.palette.warning.dark, 0.6),
      icon: WarningAmber,
    },
    error: {
      baseColor: '#D32F2F',
      backgroundColor: '#2E0C09',
      textColor: lighten('#D32F2F', 0.6),
      icon: ErrorOutline,
    },
    success: {
      baseColor: theme.palette.success.light,
      backgroundColor: '#FFFFFF',
      textColor: '#000000',
      icon: CheckCircleOutline,
    },
  }

  const Icon = severities[severity].icon
  const iconFontSize = iconSize === 'regular' ? undefined : 'medium'

  const modifiers = useMemo(() => {
    const options: PopperProps['modifiers'] = [
      {
        // prevents body scroll (which allow us to disable it as well)
        // when tooltip is large and close to the edges of the screen
        name: 'preventOverflow',
        options: {
          altAxis: true,
          tether: false,
        },
      },
    ]

    if (tooltipOffset) {
      options.push({
        name: 'offset',
        options: {
          offset: tooltipOffset,
        },
      })
    }

    return options
  }, [tooltipOffset])

  useEffect(() => {
    // close when tooltip is unfixed
    if (!tooltipFixed && tooltip.isOpen) {
      tooltip.close()
    }
  }, [tooltipFixed])

  return (
    <Tooltip
      open={!!tooltipFixed || tooltip.isOpen}
      title={message}
      placement={tooltipPlacement}
      disableFocusListener
      onClose={tooltip.close}
      PopperProps={{
        modifiers,
      }}
      componentsProps={{
        tooltip: {
          sx: {
            color: severities[severity].textColor,
            backgroundColor: severities[severity].backgroundColor,
            border: `1px solid ${severities[severity].baseColor}`,
            alignItems: 'center',
            maxWidth: 'none',
            maxHeight: '80vh',
            overflowY: 'auto',
            padding: 1,
            whiteSpace: 'pre-wrap',
          },
        },
      }}
    >
      {!isLowLevel || (isLowLevel && useInfoIcon) ? (
        <Icon
          onMouseEnter={tooltip.open}
          onMouseLeave={tooltip.cancelOpenEvent}
          onTouchStart={tooltip.open}
          sx={{ color: severities[severity].baseColor, fontSize: iconFontSize }}
        />
      ) : (
        <Box
          onMouseEnter={tooltip.open}
          onMouseLeave={tooltip.cancelOpenEvent}
          onTouchStart={tooltip.open}
          onClick={onTriangleClick}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: 20,
            height: '100%',
          }}
        >
          <TriangleIcon
            sx={{
              color: tooltipFixed ? 'success.main' : severities[severity].baseColor,
              position: 'absolute',
              top: 0,
              right: 0,
              width: 15,
              height: 15,
            }}
          />
        </Box>
      )}
    </Tooltip>
  )
}

export default AlertIcon
