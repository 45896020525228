import ScheduleIcon from '@mui/icons-material/Schedule'
import { Button, Stack, Typography } from '@mui/material'
import { createLoginWindow, getLoginWindow } from '../../../../services/openfin/fin'

function SessionExpiredLayout() {
  async function handleLoginClick() {
    const window = await getLoginWindow()

    try {
      await window.restore()
      await window.focus()
    } catch (_) {
      createLoginWindow()
    }
  }

  return (
    <Stack
      sx={{
        gap: 2,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: 'gray.light',
        color: 'white',
      }}
    >
      <ScheduleIcon sx={{ fontSize: 80 }} />
      <Typography textAlign="center">
        <Typography fontSize={20} fontWeight="bold">
          Your session has expired
        </Typography>
        <Typography>Please log in to continue</Typography>
      </Typography>
      <Button variant="contained" onClick={handleLoginClick} sx={{ mt: 3 }}>
        Log in
      </Button>
    </Stack>
  )
}

export default SessionExpiredLayout
