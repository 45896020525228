import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { AddGroupPayload } from '../../../services/data/types/group'

function useUpdateGroupMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, UpdateGroupParams>({
    mutationFn: ({ groupRef, payload }) => api.updateGroup(payload, groupRef),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type UpdateGroupParams = {
  groupRef: string
  payload: AddGroupPayload
}

export default useUpdateGroupMutation
