import { ChevronRight } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ReloadButton } from '../../../components/buttons/reload-button'
import AppLayout from '../../../components/layouts/app-layout'
import PageHeaderContainer from '../../../components/page-header-container'
import { parseGridData } from '../../../services/data/grid-data-parsing'
import useOpenState from '../../../utils/hooks/use-open-state'
import useUserInfo from '../../auth/data/use-user-info'
import { useFilterOptions } from '../../portfolios/data/use-filter-options'
import useViewConfigState from '../../portfolios/data/use-view-config-state'
import parseLiquidityData from '../data/liquidity-parsing'
import useLiquidityMonitorQuery from '../data/use-liquidity-monitor-query'
import useLiquidityNavigatorQuery from '../data/use-liquidity-navigator-query'
import LiquidityMonitorNav from './liquidity-monitor-nav'
import LiquidityTable from './liquidity-table'

function LiquidityMonitor() {
  const { isGridsOnlyUser } = useUserInfo()

  const [searchParams, setSearchParams] = useSearchParams()
  const assetType = searchParams.get('assetType') || ''
  const assetRef = searchParams.get('assetRef') || ''

  const {
    viewConfigState,
    setSortBy,
    moveColumn,
    toggleHiddenColumn,
    hideAllColumns,
    unhideAllColumns,
    addFilters,
    deleteFilter,
    setFilters,
    resetState,
  } = useViewConfigState()

  const { filters, sortBy, columns } = viewConfigState

  const liquidityNavigatorResponse = useLiquidityNavigatorQuery()
  const liquidityMonitorResponse = useLiquidityMonitorQuery(assetRef, assetType, filters)

  const nav = useOpenState({ open: true })
  const [isLoading, setIsLoading] = useState(true)

  const liquidityNavigator = liquidityNavigatorResponse.data?.data
  const liquidityMonitor = liquidityMonitorResponse.data?.data
  const dateFilterDatapointRef = liquidityMonitor?.date_datapoint

  const parsedLiquidityData = parseLiquidityData(liquidityMonitor)
  const parsedGridData = parseGridData(parsedLiquidityData, columns, sortBy)
  const filterOptions = useFilterOptions(parsedGridData?.columnsOptions).filter((option) => {
    return option.datapointRef !== dateFilterDatapointRef
  })
  const columnsOptions = parsedGridData?.columnsOptions || []
  const shownColumnsCount = parsedGridData?.shownColumnsCount

  const headerTitle =
    liquidityNavigator?.asset_types.find((assetType) => {
      return assetType.asset_type_tag === liquidityMonitor?.asset_type
    })?.asset_type_name || ''

  useEffect(() => {
    const initialAssetType = liquidityNavigator?.asset_types[0]?.asset_type_tag

    if (initialAssetType && !assetType && !assetRef) {
      setSearchParams({ assetType: initialAssetType })
    }
  }, [liquidityNavigator])

  useEffect(() => {
    setIsLoading(liquidityMonitorResponse.isFetching)
  }, [liquidityMonitorResponse.isFetching])

  function handleNavClick(assetType: string) {
    resetState()
    setSearchParams({ assetType })
  }

  function handleToggleHiddenColumn(datapointRef: string) {
    if (parsedGridData) {
      toggleHiddenColumn(datapointRef, parsedGridData.originalHeadings)
    }
  }

  function handleMoveColumn(fromDatapointRef: string, toDatapointRef: string) {
    if (parsedGridData) {
      moveColumn(fromDatapointRef, toDatapointRef, parsedGridData.originalHeadings)
    }
  }

  function reloadData() {
    liquidityNavigatorResponse.refetch()
    liquidityMonitorResponse.refetch()
  }

  return (
    <AppLayout direction="row">
      <LiquidityMonitorNav
        open={nav.isOpen}
        selected={assetType}
        navigator={liquidityNavigator}
        onClick={handleNavClick}
        onCloseClick={nav.close}
      />
      <Stack flex="1" overflow="hidden">
        <PageHeaderContainer>
          <Stack direction="row" alignItems="center" gap={1}>
            {!nav.isOpen && (
              <IconButton onClick={nav.open} sx={{ p: 1 }}>
                <ChevronRight color="primary" />
              </IconButton>
            )}
            <Typography variant="h6" noWrap>
              {headerTitle}
            </Typography>
            <ReloadButton loading={isLoading} onClick={reloadData} />
          </Stack>
        </PageHeaderContainer>

        <Stack overflow="auto" height="100%" p={2}>
          <LiquidityTable
            data={parsedGridData}
            sortBy={sortBy}
            dateFilterDatapointRef={dateFilterDatapointRef}
            filters={filters}
            filterOptions={filterOptions}
            columnsOptions={columnsOptions}
            shownColumnsCount={shownColumnsCount}
            isGridsOnlyUser={isGridsOnlyUser}
            onSortChange={setSortBy}
            onToggleHiddenColumn={handleToggleHiddenColumn}
            onHideAllColumns={hideAllColumns}
            onUnhideAllColumns={unhideAllColumns}
            onMoveColumn={handleMoveColumn}
            onAddFilters={addFilters}
            onDeleteFilter={deleteFilter}
            onDeleteAllFilters={() => setFilters(null)}
          />
        </Stack>
      </Stack>
    </AppLayout>
  )
}

export default LiquidityMonitor
