import { RemoveCircleOutline } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import TableContainerHeader from '../../../components/table-container-header'
import { queryClient } from '../../../services/data/react-query'
import { Portfolio } from '../../../services/data/types/portfolio'
import useOpenState from '../../../utils/hooks/use-open-state'
import useRemovePortfolioMutation from '../data/use-remove-portfolio-mutation'
import AddPortfolioModal from './add-portfolio-modal'

type GroupPortfoliosTableProps = {
  currentPortfolios: Portfolio[]
  groupRef: string
}

function GroupPortfoliosSection(props: GroupPortfoliosTableProps) {
  const { currentPortfolios, groupRef } = props

  const { t } = useTranslation('settings')

  const [selectedPortfolio, setSelectedPortfolio] = useState<Portfolio>()

  const addPortfolioModal = useOpenState()
  const removePortfolioModal = useOpenState()

  const {
    mutate: removePortfolio,
    error: removePortfolioError,
    reset: resetRemovePortfolioStatus,
  } = useRemovePortfolioMutation()

  function handleClickOnPortfolioRemoveButton(portfolio: Portfolio) {
    setSelectedPortfolio(portfolio)
    removePortfolioModal.open()
  }

  function handleRemovePortfolio() {
    if (groupRef && selectedPortfolio) {
      const removePortfolioParams = {
        personalGroupRef: groupRef,
        selectedPortfolioRef: selectedPortfolio.portfolio_ref,
      }
      removePortfolio(removePortfolioParams, {
        onSuccess: () => {
          resetRemovePortfolioStatus()
          queryClient.invalidateQueries(['portfolio', 'group', groupRef, 'portfolios', 'current'])
          removePortfolioModal.close()
        },
      })
    }
  }

  function handlePortfolioRemoveClose() {
    resetRemovePortfolioStatus()
    removePortfolioModal.close()
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <TableContainerHeader
          title={t('user_details_page.group_portfolio_table.title')}
          action={
            <IconButton onClick={addPortfolioModal.open}>
              <AddIcon />
            </IconButton>
          }
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('user_details_page.group_portfolio_table.portfolio')}</TableCell>
              <TableCell>{t('user_details_page.group_portfolio_table.legal_entity')}</TableCell>
              <TableCell>{t('user_details_page.group_portfolio_table.base_currency')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPortfolios?.map((portfolio) => {
              return (
                <TableRow hover key={portfolio.portfolio_ref}>
                  <TableCell>{portfolio.portfolio_name}</TableCell>
                  <TableCell>{portfolio.legal_entity_name}</TableCell>
                  <TableCell>{portfolio.base_currency}</TableCell>
                  <TableCell className="action-cell" align="right">
                    <Tooltip title={t('remove')} arrow disableInteractive>
                      <IconButton onClick={() => handleClickOnPortfolioRemoveButton(portfolio)}>
                        <RemoveCircleOutline />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <AddPortfolioModal open={addPortfolioModal.isOpen} groupRef={groupRef} onClose={addPortfolioModal.close} />
      <ConfirmationModal
        error={removePortfolioError}
        title={t('group_tables.remove_portfolio_from_group')}
        confirmButtonText={t('common:remove')}
        open={removePortfolioModal.isOpen}
        onCloseButtonClick={handlePortfolioRemoveClose}
        onConfirmButtonClick={handleRemovePortfolio}
      >
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey="group_tables.are_you_sure_you_want_to_remove_the_permission"
            values={{ subject: selectedPortfolio?.portfolio_name }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </ConfirmationModal>
    </>
  )
}

export default GroupPortfoliosSection
