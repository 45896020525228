import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useCloseOutPositionQuery(
  portfolioRef: string | undefined,
  yRef: string | undefined,
  aggregations: string | null,
  datasetRef: string | null
) {
  const response = useQuery({
    queryKey: ['close-out-position', portfolioRef, yRef, aggregations, datasetRef],
    queryFn: () => {
      if (!portfolioRef || !yRef) {
        return
      }

      return api.getCloseOutPosition(portfolioRef, yRef, aggregations, datasetRef)
    },
    enabled: !!portfolioRef && !!yRef,
  })

  return { ...response, error: parseError(response.error as AxiosError) }
}

export default useCloseOutPositionQuery
