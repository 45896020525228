import { DateRange, FilterAlt, ViewColumn } from '@mui/icons-material'
import { Stack } from '@mui/system'
import { AgGridReact } from 'ag-grid-react'
import { MouseEvent, RefObject, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ColumnsMenu from '../../../components/data-table/columns-menu'
import DataTable from '../../../components/data-table/data-table'
import DateTimeFilterMenu, { getDateFilterLabel } from '../../../components/filters/date-time-filter-menu'
import FilterButton from '../../../components/filters/filter-button'
import FilterChips from '../../../components/filters/filter-chips'
import FilterMenu from '../../../components/filters/filter-menu'
import { FilterValue, GridDataFilterOption, parseFilters } from '../../../services/data/filter-parsing'
import { ParsedGridColumnOption, ParsedGridData } from '../../../services/data/grid-data-parsing'
import {
  GridDataViewFilterOperator,
  GridDataViewFilters,
  GridDataViewSortBy,
} from '../../../services/data/types/grid-data-view'
import { DeleteFilterPayload } from '../../portfolios/data/use-view-config-state'
import ContextMenu from './context-menu'

type LiquidityTableProps = {
  data: ParsedGridData | null
  sortBy: GridDataViewSortBy | null
  columnsOptions: ParsedGridColumnOption[]
  shownColumnsCount: number | undefined
  dateFilterDatapointRef: string | undefined
  filters: GridDataViewFilters | null
  filterOptions: GridDataFilterOption[]
  isGridsOnlyUser: boolean
  onSortChange: (sortBy: GridDataViewSortBy | null) => void
  onToggleHiddenColumn: (datapointRef: string) => void
  onHideAllColumns: () => void
  onUnhideAllColumns: () => void
  onAddFilters: (filters: GridDataViewFilters) => void
  onDeleteFilter: (payload: DeleteFilterPayload) => void
  onDeleteAllFilters: () => void
  onMoveColumn: (fromDatapointRef: string, toDatapointRef: string) => void
}

type ContextMenuState = {
  coordinates: { mouseX: number; mouseY: number }
}

function LiquidityTable(props: LiquidityTableProps) {
  const {
    data,
    sortBy,
    columnsOptions,
    shownColumnsCount,
    dateFilterDatapointRef,
    filters,
    filterOptions,
    isGridsOnlyUser,
    onToggleHiddenColumn,
    onHideAllColumns,
    onUnhideAllColumns,
    onAddFilters,
    onDeleteFilter,
    onDeleteAllFilters,
    onMoveColumn,
    onSortChange,
  } = props
  const { t } = useTranslation('dataTable')

  const [gridRef, setGridRef] = useState<RefObject<AgGridReact> | null>(null)
  const [contextMenu, setContextMenu] = useState<ContextMenuState | null>(null)

  const [hiddenColumnsMenuAnchorEl, setHiddenColumnsMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [dateFilterMenuAnchorEl, setDateFilterMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState<HTMLElement | null>(null)

  const selectedFilters = parseFilters(filters || {}, filterOptions)
  const dateSelectedFilter = selectedFilters.find((sf) => sf.datapointRef === dateFilterDatapointRef)
  const dateFilter = filters?.[dateFilterDatapointRef || ''] || { operator: 'today', values: [] }
  const dataFilterLabel = getDateFilterLabel(dateFilter?.operator || 'today', dateSelectedFilter?.filterValues || [])

  function handleHiddenColumnsMenuOpen(event: MouseEvent<HTMLButtonElement>) {
    setHiddenColumnsMenuAnchorEl(event.currentTarget)
  }

  function handleHiddenColumnsMenuClose() {
    setHiddenColumnsMenuAnchorEl(null)
  }

  function handleDateFilterMenuOpen(event: MouseEvent<HTMLButtonElement>) {
    setDateFilterMenuAnchorEl(event.currentTarget)
  }

  function handleDateFilterMenuClose() {
    setDateFilterMenuAnchorEl(null)
  }

  function toggleDateFilterMenu(event: MouseEvent<HTMLButtonElement>) {
    if (dateFilterMenuAnchorEl) {
      handleDateFilterMenuClose()
    } else {
      handleDateFilterMenuOpen(event)
    }
  }

  function handleFilterMenuOpen(event: MouseEvent<HTMLButtonElement>) {
    setFilterMenuAnchorEl(event.currentTarget)
  }

  function handleFilterMenuClose() {
    setFilterMenuAnchorEl(null)
  }

  function handleDateChange(operator: GridDataViewFilterOperator, values: FilterValue[]) {
    if (dateFilterDatapointRef) {
      onAddFilters({
        [dateFilterDatapointRef]: {
          operator,
          values,
        },
      })
    }
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between" pb={2} gap={1}>
        <Stack direction="row" flexWrap="wrap" alignItems="center" gap={1}>
          <FilterChips dark filters={selectedFilters} onDelete={onDeleteFilter} />
        </Stack>
        <Stack direction="row" gap={1}>
          <FilterButton
            label={t('hidden_columns')}
            count={shownColumnsCount}
            Icon={ViewColumn}
            onClick={handleHiddenColumnsMenuOpen}
          />
          <FilterButton label={dataFilterLabel} showArrow Icon={DateRange} onClick={toggleDateFilterMenu} />
          <FilterButton
            label={t('filter')}
            count={selectedFilters.length}
            Icon={FilterAlt}
            onClick={handleFilterMenuOpen}
          />
        </Stack>
      </Stack>
      <DataTable
        data={data}
        sortBy={sortBy}
        filterOptions={filterOptions}
        selectedFilters={selectedFilters}
        isGridsOnlyUser={isGridsOnlyUser}
        onMoveColumn={onMoveColumn}
        onSortChange={onSortChange}
        onHideColumn={onToggleHiddenColumn}
        onAddFilters={onAddFilters}
        onDeleteFilter={onDeleteFilter}
        onContextMenuOpen={(event) => {
          event.preventDefault()
          setContextMenu({
            coordinates: {
              mouseX: event.clientX,
              mouseY: event.clientY,
            },
          })
        }}
        onForwardGridRef={setGridRef}
      />
      <ContextMenu
        coordinates={contextMenu?.coordinates}
        onClose={() => setContextMenu(null)}
        onCopy={() => gridRef?.current?.api.copySelectedRangeToClipboard()}
      />
      <ColumnsMenu
        anchorEl={hiddenColumnsMenuAnchorEl}
        columns={columnsOptions}
        onClose={handleHiddenColumnsMenuClose}
        onHideColumn={onToggleHiddenColumn}
        onHideAll={onHideAllColumns}
        onUnhideAll={onUnhideAllColumns}
      />
      <DateTimeFilterMenu
        anchorEl={dateFilterMenuAnchorEl}
        selectedOperator={dateFilter?.operator}
        selectedValues={dateFilter?.values}
        onChange={handleDateChange}
        onClose={handleDateFilterMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mt: 1 }}
      />
      <FilterMenu
        hideActiveFilters
        anchorEl={filterMenuAnchorEl}
        filterOptions={filterOptions}
        selectedFilters={selectedFilters}
        onChange={onAddFilters}
        onDelete={onDeleteFilter}
        onDeleteAll={onDeleteAllFilters}
        onClose={handleFilterMenuClose}
      />
    </>
  )
}

export default LiquidityTable
