import { AddBox } from '@mui/icons-material'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

type NewOrderMenuButtonProps = {
  onNewTrade: () => void
  onNewCashflow: () => void
}

export function NewOrderMenuButton(props: NewOrderMenuButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = !!anchorEl

  const { t } = useTranslation('portfolio')

  function openMenu(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function closeMenu() {
    setAnchorEl(null)
  }

  function handleNewTrade() {
    props.onNewTrade()
    closeMenu()
  }

  function handleNewCashflow() {
    props.onNewCashflow()
    closeMenu()
  }

  return (
    <div>
      <Tooltip title={t('enter_trade_or_cashflow')} enterDelay={500} arrow disableInteractive>
        <IconButton
          id="new-order-button"
          aria-controls={open ? 'new-order-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={openMenu}
          color="primary"
          sx={{ color: 'gray.300', padding: '4px', margin: '4px' }}
        >
          <AddBox color="primary" />
        </IconButton>
      </Tooltip>
      <Menu
        id="new-order-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'new-order-button',
          sx: { minWidth: '160px' },
        }}
      >
        <MenuItem onClick={handleNewTrade}>{t('enter_trade')}</MenuItem>
        <MenuItem onClick={handleNewCashflow}>{t('enter_cashflow')}</MenuItem>
      </Menu>
    </div>
  )
}
