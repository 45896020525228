import { Add } from '@mui/icons-material'
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import TableContainerHeader from '../../../components/table-container-header'
import useOpenState from '../../../utils/hooks/use-open-state'
import useTenantsQuery from '../../auth/data/use-tenants-query'
import AddTenantModal from './add-tenant-modal'
import { useSettingsUpdateLoadingState } from './use-settings-set-loading'

function Tenants() {
  const { t } = useTranslation('settings')

  const addTenantModal = useOpenState()

  const tenantsResponse = useTenantsQuery()
  const isLoading = tenantsResponse.isLoading

  useSettingsUpdateLoadingState(isLoading)

  if (isLoading) {
    return null
  }

  return (
    <Box p={2}>
      <TableContainer component={Paper}>
        <TableContainerHeader
          title={t('tenants_page.tenants_page_title')}
          action={
            <Tooltip title={t('tenants_page.add_button_tooltip')} arrow disableInteractive>
              <IconButton onClick={addTenantModal.open}>
                <Add />
              </IconButton>
            </Tooltip>
          }
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('tenants_page.tenants_table.tenant_code')}</TableCell>
              <TableCell>{t('tenants_page.tenants_table.tenant_name')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tenantsResponse.data?.data?.map((tenant) => {
              return (
                <TableRow hover key={tenant.tenant_ref}>
                  <TableCell>{tenant.tenant_ref}</TableCell>
                  <TableCell>{tenant.tenant_name}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <AddTenantModal isOpen={addTenantModal.isOpen} onClose={addTenantModal.close} />
    </Box>
  )
}

export default Tenants
