import { Check } from '@mui/icons-material'
import { Button, ListItem, ListItemText, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import NestedMenu from '../../../components/nested-menu'
import DatapointOptionsResponse from '../../../services/data/types/datapoint-options'

type ActiveAliasMenuProps = {
  anchorEl: HTMLElement | null
  aliases: DatapointOptionsResponse['aliases']
  selectedAliasId: string | null
  activeAliasIds: string[]
  onUpdateActiveAlias: (activeAliasId: string | null) => void
  onClose?: () => void
}

function ActiveAliasMenu(props: ActiveAliasMenuProps) {
  const { anchorEl, aliases, selectedAliasId, activeAliasIds, onUpdateActiveAlias, onClose } = props
  const { t } = useTranslation('dataEngine')

  return (
    <NestedMenu
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          ml: -1.5,
          maxHeight: '60%',
        },
      }}
    >
      <ListItem sx={{ py: 0, pr: 1 }}>
        <ListItemText>
          <Typography variant="overline" color="gray.700">
            {t('datapoint_table.active_alias')}
          </Typography>
        </ListItemText>
        {selectedAliasId && (
          <Button variant="text" onClick={() => onUpdateActiveAlias(null)}>
            {t('common:remove')}
          </Button>
        )}
      </ListItem>
      {Object.entries(aliases)
        .sort((a, b) => a[1].localeCompare(b[1]))
        .map(([aliasId, aliasTag]) => {
          const selected = aliasId === selectedAliasId
          const diabled = activeAliasIds.includes(aliasId) && !selected

          return (
            <MenuItem
              key={aliasId}
              selected={selected}
              disabled={diabled}
              onClick={() => onUpdateActiveAlias(aliasId)}
              sx={{
                '&.Mui-selected': {
                  pointerEvents: 'none',
                  background: 'rgba(120, 220, 232, 0.08)',
                  '& .checkmark': {
                    display: 'initial',
                  },
                },
              }}
            >
              <ListItemText>{aliasTag}</ListItemText>
              <Check className="checkmark" color="primary" sx={{ mr: 1, display: 'none' }} />
            </MenuItem>
          )
        })}
    </NestedMenu>
  )
}

export default ActiveAliasMenu
