import { useParams } from 'react-router-dom'
import ModelDetails from '../../components/model-details'

function ModelDetailsView() {
  const { modelRef } = useParams()

  if (!modelRef) {
    return null
  }

  return <ModelDetails modelRef={modelRef} />
}

export default ModelDetailsView
