import { ParsedGridColumnOption } from './grid-data-parsing'
import { AssetCategory, SymbologyDisplayHeader, isCashSecurity } from './types/asset-static-data'
import { Classification } from './types/classifications'
import { DatapointType } from './types/datapoint'
import { GridDataViewFilterOperator, GridDataViewFilters } from './types/grid-data-view'

export type GridDataFilterOption = {
  columnName: string
  datapointRef: string
  datapointName: string
  datapointType: DatapointType
  assetCategory: AssetCategory | undefined
  values: {
    value: FilterValue
    title?: string | null
  }[]
}

export type ParsedFilter = {
  datapointRef: string
  filterName: string
  filterDataType: DatapointType
  operator: GridDataViewFilterOperator
  values: FilterValue[]
  filterValues: GridDataFilterOption['values']
}

// Dates are stored as string in a NaiveDate format
export type FilterValue = string | number | boolean

export function parseFilterOptions(
  columnOptions: ParsedGridColumnOption[],
  classifications: Classification[],
  assets: SymbologyDisplayHeader[]
): GridDataFilterOption[] {
  const filterOptions = columnOptions
    .filter((option) => {
      return (
        option.datapointType === 'Int' ||
        option.datapointType === 'Float' ||
        option.datapointType === 'Percent' ||
        option.datapointType === 'Boolean' ||
        option.datapointType === 'String' ||
        option.datapointType === 'DateTime' ||
        option.datapointType === 'NaiveDate' ||
        option.datapointType === 'Classification' ||
        option.datapointType === 'MultiSelect' ||
        option.datapointType === 'AssetRef'
      )
    })
    .map((option) => {
      let values: GridDataFilterOption['values'] = []

      if (option.datapointType === 'Boolean') {
        values = [
          { value: 'true', title: 'True' },
          { value: 'false', title: 'False' },
        ]
      }

      if (
        (option.datapointType === 'Classification' || option.datapointType === 'MultiSelect') &&
        option.classificationId
      ) {
        const classification = classifications.find((c) => c.classification_id === option.classificationId)
        if (classification) {
          values = classification.values.map((v) => ({ value: v.tag, title: v.desc }))
          values.sort((v1, v2) => {
            const a = String(v1.title || v1.value)
            const b = String(v2.title || v2.value)
            return a.localeCompare(b)
          })
        }
      }

      if (option.datapointType === 'AssetRef') {
        if (option.assetCategory === 'currency') {
          values = assets
            .filter((asset) => asset.asset_type === 'currency')
            .map((asset) => ({ value: asset.asset_ref, title: asset.display_as }))
        }

        if (option.assetCategory === 'index') {
          values = assets
            .filter((asset) => asset.asset_type === 'ticker_index')
            .map((asset) => ({ value: asset.asset_ref, title: asset.display_as }))
        }

        if (option.assetCategory === 'ref_rate') {
          values = assets
            .filter((asset) => asset.asset_type === 'ticker_refrate')
            .map((asset) => ({ value: asset.asset_ref, title: asset.display_as }))
        }

        if (option.assetCategory === 'security') {
          values = assets
            .filter((asset) => isCashSecurity(asset.asset_type))
            .map((asset) => ({ value: asset.asset_ref, title: asset.display_as }))
        }
      }

      return {
        columnName: option.columnName,
        datapointRef: option.datapointRef,
        datapointName: option.datapointName,
        datapointType: option.datapointType,
        assetCategory: option.assetCategory,
        values,
      } satisfies GridDataFilterOption
    })

  return filterOptions
}

export function parseFilters(filters: GridDataViewFilters, options: GridDataFilterOption[]): ParsedFilter[] {
  const parsedFilters: ParsedFilter[] = []

  Object.entries(filters).forEach(([datapointRef, filter]) => {
    const option = options.find((option) => option.datapointRef === datapointRef)

    if (option) {
      const filterName = option.columnName
      const filterDataType = option.datapointType

      let filterValues: ParsedFilter['filterValues'] = filter.values.map((value) => ({ value }))

      if (
        (option.datapointType === 'Classification' ||
          option.datapointType === 'MultiSelect' ||
          option.datapointType === 'AssetRef') &&
        option.values.length
      ) {
        filterValues = option.values.filter((value) => filter.values.includes(value.value))
      }

      if (option.datapointType === 'Boolean') {
        filterValues = option.values.filter((value) => filter.values.includes(value.value === 'true'))
      }

      parsedFilters.push({
        datapointRef,
        filterName,
        filterDataType,
        operator: filter.operator,
        values: filter.values,
        filterValues,
      })
    }
  })

  parsedFilters.sort((a, b) => a.filterName.localeCompare(b.filterName))

  return parsedFilters
}
