import { Table, TableCell, TableRow, Typography } from '@mui/material'
import { ReactNode } from 'react'

type DetailTableProps = {
  children: ReactNode
}

export function DetailTable({ children }: DetailTableProps) {
  return (
    <Table
      sx={{
        tableLayout: 'fixed',
        '& .MuiTableRow-root:last-of-type': {
          '& .MuiTableCell-root.detail-table-cell': { borderBottom: 'none' },
        },
        '& .MuiTableCell-root.detail-table-cell': { borderRadius: 0 },
      }}
    >
      {children}
    </Table>
  )
}

type DetailRowProps = {
  label: string
  children: ReactNode
}

export function DetailTableRow({ label, children }: DetailRowProps) {
  return (
    <TableRow sx={{ height: '48px' }}>
      <TableCell className="detail-table-cell" sx={{ borderBottom: 'solid 1px', borderColor: 'divider' }}>
        <Typography variant="caption" sx={{ color: 'gray.300', lineHeight: 2 }}>
          {label}
        </Typography>
      </TableCell>
      <TableCell className="detail-table-cell" sx={{ borderBottom: 'solid 1px', borderColor: 'divider' }}>
        {children}
      </TableCell>
    </TableRow>
  )
}
