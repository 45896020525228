import { Box, Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import useUpdateUserGroupStatusMutation from '../data/use-update-user-group-status-mutation'
import { GroupRoles } from './user-details'

type EditGroupModalProps = {
  open: boolean
  groupRef: string
  groupName: string
  userRole: GroupRoles | ''
  userRef: string
  roles: { role_name: string; role_ref: string }[]
  onClose: () => void
}

function EditGroupModal(props: EditGroupModalProps) {
  const { open, groupRef, userRef, groupName, userRole, roles, onClose } = props
  const { t } = useTranslation('settings')
  const {
    mutate: editGroup,
    error: updateUserGroupStatusError,
    reset: updateUserGroupStatusReset,
  } = useUpdateUserGroupStatusMutation()

  const [selectedRoleRef, setSelectedRoleRef] = useState('')

  const isGroupAdmin = selectedRoleRef === GroupRoles.admin

  function handleRoleChange(event: SelectChangeEvent) {
    setSelectedRoleRef(event.target.value)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    const editGroupParams = {
      groupRef,
      userRef,
      isGroupAdmin: isGroupAdmin,
    }
    editGroup(editGroupParams, {
      onSuccess: () => {
        queryClient.invalidateQueries(['tenant', 'current', 'groupswithmembers'])
        handleClose()
      },
    })
  }

  function handleClose() {
    updateUserGroupStatusReset()
    setSelectedRoleRef('')
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('groups.edit_group_membership')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={updateUserGroupStatusError} />
          <Box>
            <Typography variant="caption" color={'gray.700'}>
              {t('groups.group')}
            </Typography>
            <Typography variant="body1" sx={{ pt: 1, pb: 1.5 }}>
              {groupName}
            </Typography>
          </Box>
          <FormControl>
            <InputLabel required>{t('groups.role')}</InputLabel>
            <Select
              required
              displayEmpty
              label={t('groups.role')}
              value={selectedRoleRef || userRole}
              onChange={handleRoleChange}
            >
              {roles.map((role) => (
                <MenuItem key={role.role_name} value={role.role_ref}>
                  {role.role_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('common:save')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default EditGroupModal
