import { useFlexLayoutContext } from '../../../components/layouts/flex-layout-context'
import TradeTicketRoot from '../components/trade-ticket-root'

function TradeTicketFlexView() {
  const { closeActiveTab } = useFlexLayoutContext()

  function handleCloseView() {
    closeActiveTab()
  }

  return <TradeTicketRoot onCloseView={handleCloseView} />
}

export default TradeTicketFlexView
