import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { CounterpartyAccountHeader } from '../../../services/data/types/bank-accounts'

function useCounterpartyUnsettledDealsQuery(bankAccountId: string, counterparty: CounterpartyAccountHeader | null) {
  const response = useQuery({
    queryKey: ['bank-accounts', bankAccountId, 'counterparties', counterparty?.account_id, 'deals', 'unsettled'],
    queryFn: () => {
      if (!counterparty) {
        return null
      }

      return api.getCounterpartyUnsettleDeals(bankAccountId, counterparty.account_id)
    },
    // no cache so we don't flicker a stale deal when editing a settlement
    // specifically when a deal was fully settled and won't show up in the list anymore
    cacheTime: 0,
  })

  return { ...response, error: parseError(response.error as AxiosError) }
}

export default useCounterpartyUnsettledDealsQuery
