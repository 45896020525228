import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useCounterpartySettlementQuery(bankAccountId: string | undefined, txnRef: string | null) {
  const response = useQuery({
    queryKey: ['bank-accounts', bankAccountId, 'settlements', txnRef],
    queryFn: () => {
      if (!bankAccountId || !txnRef) {
        return null
      }
      return api.getCounterpartySettlement(bankAccountId, txnRef)
    },
  })

  return { ...response, error: parseError(response.error as AxiosError) }
}

export default useCounterpartySettlementQuery
