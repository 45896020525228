import { Divider, Stack } from '@mui/material'
import * as FlexLayout from 'flexlayout-react'
import 'flexlayout-react/style/dark.css'
import { ReactNode, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { MemoryRouter, useLocation } from 'react-router-dom'
import { useAuth } from '../../modules/auth/data/auth-context'
import useUserInfo from '../../modules/auth/data/use-user-info'
import ErrorBoundary from '../../modules/error/components/error-boundary'
import { appModelStorage, loginModel } from '../../services/flex-layout/models'
import NavBar, { NavItemName } from '../nav-bar'
import { FlexLayoutContext, useFlexLayoutContext } from './flex-layout-context'

const viewPaths: { [key in NavItemName]: string } = {
  [NavItemName.PortfolioGrid]: '/portfolios',
  [NavItemName.Transactions]: '/transactions',
  [NavItemName.DataEngine]: '/data-engine',
  [NavItemName.OrderBlotter]: '/order-blotter',
  [NavItemName.Compliance]: '/compliance',
  [NavItemName.Liquidity]: '/liquidity',
  [NavItemName.BankAccounts]: '/bank-accounts',
  [NavItemName.Account]: '/account',
  [NavItemName.Settings]: '/settings',
}

const viewNames: { [key in NavItemName]: string } = {
  [NavItemName.PortfolioGrid]: 'navigation.portfolio_grid',
  [NavItemName.Transactions]: 'navigation.transactions',
  [NavItemName.DataEngine]: 'navigation.data_engine',
  [NavItemName.OrderBlotter]: 'navigation.order_blotter',
  [NavItemName.Compliance]: 'navigation.compliance',
  [NavItemName.Liquidity]: 'navigation.liquidity',
  [NavItemName.BankAccounts]: 'navigation.bank_accounts',
  [NavItemName.Account]: 'menu.account',
  [NavItemName.Settings]: 'menu.settings',
}

function FlexLayoutLayout({ children }: { children: ReactNode }) {
  const { t } = useTranslation('common')

  const { logout } = useAuth()
  const { user, isLoading } = useUserInfo()

  const layoutRef = useRef<FlexLayout.Layout>(null)
  const appModel = useMemo(appModelStorage.getModel, [user])

  const isLoggedIn = !!user
  const model = isLoggedIn ? appModel : loginModel

  function handleNavigate(navItemName: NavItemName) {
    const path = viewPaths[navItemName]
    const name = t(viewNames[navItemName])
    openView(path, name)
  }

  function openView(path: string, name: string, splitTab: boolean = false) {
    if (!layoutRef.current) {
      return
    }

    const newTab: FlexLayout.IJsonTabNode = {
      type: 'tab',
      name,
      component: 'app',
      config: { path },
    }

    if (splitTab) {
      const maximizedTabset = model.getMaximizedTabset()
      if (maximizedTabset?.isMaximized) {
        model.doAction(FlexLayout.Actions.maximizeToggle(maximizedTabset.getId()))
      }

      model.doAction(FlexLayout.Actions.addNode(newTab, 'main', FlexLayout.DockLocation.RIGHT, -1))
      return
    }

    const activeTabsetId = model.getActiveTabset()?.getId()
    if (activeTabsetId) {
      layoutRef.current.addTabToTabSet(activeTabsetId, newTab)
      return
    }

    const firstTabId = (layoutRef.current as any).tabIds?.[0]
    if (firstTabId) {
      model.doAction(FlexLayout.Actions.selectTab(firstTabId))
    }

    layoutRef.current.addTabToActiveTabSet(newTab)
  }

  function closeActiveTab() {
    const tabId = getActiveTabId()
    if (tabId) {
      model.doAction(FlexLayout.Actions.deleteTab(tabId))
    }
  }

  function updateUrlPath(tabId: string, path: string) {
    updateTabConfig(tabId, { path })
  }

  function updateTabName(tabId: string, name: string) {
    const config = getTabConfig(tabId)
    if (!config.renamed) {
      model.doAction(FlexLayout.Actions.renameTab(tabId, name))
    }
  }

  function getActiveTabId(): string | undefined {
    return model.getActiveTabset()?.getSelectedNode()?.getId()
  }

  function getTabConfig(tabId: string): TabConfig {
    const node = model.getNodeById(tabId)
    const config = (node as any)?._attributes?.config || {}
    return config as TabConfig
  }

  function updateTabConfig(tabId: string, tabConfig: Partial<TabConfig>) {
    const config = { ...getTabConfig(tabId), ...tabConfig }
    model.doAction(FlexLayout.Actions.updateNodeAttributes(tabId, { config }))
  }

  function handleAction(action: FlexLayout.Action): FlexLayout.Action {
    if (action.type === 'FlexLayout_RenameTab') {
      const tabId = action.data.node
      if (tabId) {
        updateTabConfig(tabId, { renamed: true })
      }
    }
    return action
  }

  function handleModelChange(_changedModel: FlexLayout.Model) {
    if (isLoggedIn) {
      appModelStorage.setModel(appModel)
    }
  }

  function factory(node: FlexLayout.TabNode) {
    const component = node.getComponent()
    const flexLayoutConfig = node.getConfig()

    if (component === 'login') {
      return (
        <ErrorBoundary>
          <MemoryRouter initialEntries={['/login']}>{children}</MemoryRouter>
        </ErrorBoundary>
      )
    }

    if (component === 'app') {
      return (
        <ErrorBoundary>
          <MemoryRouter initialEntries={[flexLayoutConfig?.path || '/portfolios']}>
            <TrackUrlPath />
            {children}
          </MemoryRouter>
        </ErrorBoundary>
      )
    }
  }

  if (isLoading) {
    return null
  }

  return (
    <FlexLayoutContext.Provider value={{ openView, closeActiveTab, updateUrlPath, updateTabName, getActiveTabId }}>
      <Stack direction="row" sx={{ height: '100%', backgroundColor: 'background.default' }}>
        {isLoggedIn && (
          <>
            <NavBar onLogoutClick={logout} onNavigate={handleNavigate} />
            <Divider orientation="vertical" />
          </>
        )}
        <Stack
          sx={{
            position: 'relative',
            flexGrow: 1,
            height: 'calc(100vh - 32px)',
            margin: '16px',
          }}
        >
          <FlexLayout.Layout
            ref={layoutRef}
            model={model}
            factory={factory}
            onAction={handleAction}
            onModelChange={handleModelChange}
          />
        </Stack>
      </Stack>
    </FlexLayoutContext.Provider>
  )
}

export default FlexLayoutLayout

/// Tracks url path changes and sends updates through the FlexLayout context
/// so they are saved on the model. This allows users to reload the browser
/// and keep their navigation state on each tab.
function TrackUrlPath() {
  const location = useLocation()
  const { updateUrlPath, getActiveTabId } = useFlexLayoutContext()

  const tabId = useMemo(() => getActiveTabId(), [])
  const path = location.pathname + location.search

  useEffect(() => {
    if (tabId) {
      updateUrlPath(tabId, path)
    }
  }, [tabId, path])

  return null
}

/// Custom attributes stored per tab.
type TabConfig = {
  path?: string
  renamed?: true
}
