import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import usePortfoliosQuery from '../../portfolios/data/use-portfolios-query'
import { ComplianceRoot } from '../components/compliance-root'

function ComplianceFlexView() {
  const navigate = useNavigate()

  const { portfolioRef } = useParams()

  const portfoliosQuery = usePortfoliosQuery()
  const portfolios = portfoliosQuery.data?.data

  useEffect(() => {
    if (!portfolioRef && portfoliosQuery.isSuccess) {
      const portfolio = portfolios?.[0]
      if (portfolio) {
        navigate(`/compliance/${portfolio.portfolio_ref}`)
      }
    }
  }, [portfolioRef, portfoliosQuery])

  function handlePortfolioSelect(portfolioRef: string) {
    navigate(`/compliance/${portfolioRef}`)
  }

  return <ComplianceRoot portfolioRef={portfolioRef} onPortfolioSelect={handlePortfolioSelect} />
}

export default ComplianceFlexView
