import { useNavigate } from 'react-router-dom'
import Datasets from '../../components/datasets'

function DatasetsView() {
  const navigate = useNavigate()

  function handleDatasetClick(datasetRef: string) {
    navigate(`/openfin/views/data-engine/datasets/${datasetRef}`)
  }

  return <Datasets onDatasetClick={handleDatasetClick} />
}

export default DatasetsView
