import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { RequestAssetImportPayload } from '../../../services/data/types/asset-import'

function useRequestAssetImportMutation() {
  const response = useMutation({
    mutationFn: (payload: RequestAssetImportPayload) => api.requestAssetImport(payload),
  })

  return { ...response, error: parseError(response.error as AxiosError) }
}

export default useRequestAssetImportMutation
