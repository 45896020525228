import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import {
  BankRecordsUploadParams,
  RecordsUploadInspectResponse,
  RecordsUploadVerifyResponse,
} from '../../../services/data/types/reconciliation'

export function useBankRecordsUploadOptionsQuery(options: { enabled: boolean }) {
  return useQuery(['bank_records_upload', 'options'], api.bankRecordsUploadGetOptions, { enabled: options.enabled })
}

type InspectParams = {
  entityId: number
  accountId: number | null
  file: File
}

export function useBankRecordsUploadInspectFileMutation() {
  const response = useMutation<AxiosResponse<RecordsUploadInspectResponse>, AxiosError, InspectParams>({
    mutationFn: ({ entityId, accountId, file }) => {
      return api.bankRecordsUploadInspectFile(entityId, accountId, file)
    },
  })

  return { ...response, error: parseError(response.error) }
}

type CommitParams = {
  params: BankRecordsUploadParams
  file: File
}

export function useBankRecordsUploadCommitFileMutation() {
  const response = useMutation<AxiosResponse, AxiosError, CommitParams>({
    mutationFn: ({ params, file }) => api.bankRecordsUploadCommitFile(params, file),
  })

  return { ...response, error: parseError(response.error) }
}

type VerifyParams = {
  params: BankRecordsUploadParams
  file: File
}

export function useBankRecordsUploadVerifyFileMutation() {
  const response = useMutation<AxiosResponse<RecordsUploadVerifyResponse>, AxiosError, VerifyParams>({
    mutationFn: ({ params, file }) => api.bankRecordsUploadVerifyFile(params, file),
  })

  return { ...response, error: parseError(response.error) }
}
