import { Dialog } from '@mui/material'
import { ReactNode } from 'react'
import Alert from './alert'
import ModalActions from './modal-actions'
import ModalContent from './modal-content'
import ModalTitle from './modal-title'

type ConfirmationModalProps = {
  title: string
  confirmButtonText: string
  error?: string | null
  open: boolean
  isLoading?: boolean
  onCloseButtonClick: () => void
  onConfirmButtonClick: () => void
  children: ReactNode
}

function ConfirmationModal(props: ConfirmationModalProps) {
  const { confirmButtonText, title, error, open, isLoading, onCloseButtonClick, onConfirmButtonClick, children } = props

  return (
    <Dialog
      open={open}
      onClose={onCloseButtonClick}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
    >
      <ModalTitle title={title} onClose={onCloseButtonClick} />
      <ModalContent>
        <Alert severity="error" message={error} />
        {children}
      </ModalContent>
      <ModalActions
        confirmLabel={confirmButtonText}
        isLoading={isLoading}
        onConfirm={onConfirmButtonClick}
        onCancel={onCloseButtonClick}
      />
    </Dialog>
  )
}

export default ConfirmationModal
