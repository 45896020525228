import { Box, Chip, Dialog, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { BaseCurrency, currencies } from '../../../services/data/types/portfolio'

type NewGroupModalProps = {
  currentCurrency: BaseCurrency
  currentName: string
  open: boolean
  onCloseButtonClick: () => void
  onSaveButtonClick: () => void
}

function EditSandboxModal(props: NewGroupModalProps) {
  const { t } = useTranslation()

  function handleFormSubmit(event: any) {
    event.preventDefault()
    props.onSaveButtonClick()
  }

  return (
    <Dialog open={props.open} onClose={props.onCloseButtonClick}>
      <form onSubmit={handleFormSubmit}>
        <ModalTitle title={t('portfolio:edit_sandbox')} onClose={props.onCloseButtonClick} />
        <ModalContent>
          <Stack direction="row" gap={3}>
            <TextField
              name="name"
              label="Name"
              required
              placeholder={t('portfolio:name_of_the_sandbox')}
              defaultValue={props.currentName}
              sx={{ flex: 3 }}
            />
            <FormControl sx={{ flex: 1 }}>
              <InputLabel>{t('common:currency')}</InputLabel>
              <Select
                name="currency"
                label={t('common:currency')}
                required
                defaultValue={props.currentCurrency}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Chip key={selected} label={selected} size="small" />
                  </Box>
                )}
              >
                {currencies.map((currency) => (
                  <MenuItem value={currency} key={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </ModalContent>
        <ModalActions confirmLabel={t('common:save')} onCancel={props.onCloseButtonClick} />
      </form>
    </Dialog>
  )
}

export default EditSandboxModal
