import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { EditDatapointValue } from '../../../services/data/types/datapoint'

export function useEditAssetDatapointMutation() {
  const response = useMutation<AxiosResponse, AxiosError, EditAssetParams>({
    mutationFn: ({ assetRef, datapointRef, value, asOnDate }) => {
      return api.updateAssetDatapoint(assetRef, datapointRef, value, asOnDate)
    },
  })

  return { ...response, error: parseError(response.error) }
}

export type EditAssetParams = {
  assetRef: string
  datapointRef: string
  value: EditDatapointValue
  asOnDate?: Date
}
