import { useCallback, useState } from 'react'

type InitialState = {
  open: boolean
}

function useOpenState(state?: InitialState) {
  const [isOpen, setIsOpen] = useState(!!state?.open)

  const open = useCallback(() => setIsOpen(true), [])

  const close = useCallback(() => setIsOpen(false), [])

  const toggle = useCallback(() => setIsOpen((state) => !state), [])

  return { isOpen, open, close, toggle }
}

export default useOpenState
