import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { CloseOutPositionResponse } from '../../../services/data/types/close-out-position'
import { CloseOutSegmentPayload } from '../../../services/data/types/trade-ticket'

export function useCloseOutTransactionVerifyMutation() {
  const response = useMutation<AxiosResponse<CloseOutPositionResponse>, AxiosError, CloseOutParams>({
    mutationFn: ({ lei, segmentRef, body }) => {
      return api.closeOutTransactionVerify(lei, segmentRef, body)
    },
  })

  return { ...response, error: parseError(response.error) }
}

export function useCloseOutTransactionCommitMutation() {
  const response = useMutation<AxiosResponse<CloseOutPositionResponse>, AxiosError, CloseOutParams>({
    mutationFn: ({ lei, segmentRef, body }) => {
      return api.closeOutTransactionCommit(lei, segmentRef, body)
    },
  })

  return { ...response, error: parseError(response.error) }
}

export type CloseOutParams = {
  lei: string
  segmentRef: string
  body: CloseOutSegmentPayload
}
