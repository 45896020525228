import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useDeletePortfolioMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, DeletePortfolioParams>({
    mutationFn: ({ portfolioRef }) => api.deletePortfolio(portfolioRef),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type DeletePortfolioParams = {
  portfolioRef: string
}

export default useDeletePortfolioMutation
