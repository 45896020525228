import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

export function useBrokersQuery(lei?: string) {
  return useQuery(
    ['lei', lei, 'brokers'],
    () => {
      if (!lei) {
        return null
      }
      return api.getBrokers(lei)
    },
    {
      enabled: !!lei,
    }
  )
}
