import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useRemovePortfolioMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, RemovePortfolioParams>({
    mutationFn: ({ personalGroupRef, selectedPortfolioRef }) =>
      api.removePortfolio(personalGroupRef, selectedPortfolioRef),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type RemovePortfolioParams = {
  personalGroupRef: string
  selectedPortfolioRef: string
}
export default useRemovePortfolioMutation
