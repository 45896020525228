import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { ChangeEvent, ReactNode, SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AssetCategory, DataDocType } from '../../../services/data/types/asset-static-data'
import {
  AggregationType,
  Datapoint,
  DatapointType,
  Variety,
  aggregationOptions,
  newDatapointTypes,
  varietyOptions,
} from '../../../services/data/types/datapoint'
import DatapointOptionsResponse, { Calculation } from '../../../services/data/types/datapoint-options'
import useDatapointOptionsQuery from '../data/use-datapoint-options-query'
import useDatapointsQuery from '../data/use-datapoints-query'

type DatapointFormProps = {
  currentDatasetRef: string
  values: DatapointFormValues
  onChange: (name: string, value: string | boolean | DatapointFormValues['calculationInputs']) => void
  setFormValues: (values: DatapointFormValues) => void
}

export type DatapointFormValues = {
  datapointName: string
  variety: Variety | ''
  candidateAliasId: string
  dataType: DatapointType | ''
  assetCategory: AssetCategory | ''
  datadocType: DataDocType | ''
  classificationId: string
  parentClassId: string
  calculationId: number | ''
  aggregationType: AggregationType | ''
  weightDatapointRef: string
  customFunctionId: string
  aggregationOnlyAll: boolean
  calculationInputs: {
    [key: string]: string
  }
}

function DatapointForm(props: DatapointFormProps) {
  const { currentDatasetRef, values, onChange, setFormValues } = props

  const datapointsResponse = useDatapointsQuery(currentDatasetRef)
  const datapointOptionsResponse = useDatapointOptionsQuery(currentDatasetRef)

  const datapoints = datapointsResponse.data?.data || []
  const datapointOptions = datapointOptionsResponse.data?.data

  const isNotRequireDatatypeVarieties = ['calculated', 'internal', 'realtime'].includes(values.variety)

  const [selectedCalculation, setSelectedCalcualtion] = useState<Calculation | null>(null)

  useEffect(() => {
    if (!values || !datapointOptions?.calculations) {
      return
    }

    const currentCalculation = datapointOptions.calculations.find(
      (calculation) => calculation.calc_id === values.calculationId
    )

    if (!currentCalculation) {
      return
    }

    const outputDataType = currentCalculation.output_data_type
    const dataType = isNotRequireDatatypeVarieties && outputDataType ? outputDataType : ''

    setSelectedCalcualtion(currentCalculation)
    setFormValues({ ...values, dataType, calculationInputs: {} })
  }, [values.calculationId])

  useEffect(() => {
    if (values.dataType !== 'Classification') {
      setFormValues({ ...values, classificationId: '' })
    }

    if (values.dataType !== 'AssetRef') {
      setFormValues({ ...values, assetCategory: '' })
    }

    if (values.dataType !== 'DataDoc') {
      setFormValues({ ...values, datadocType: '' })
    }
  }, [values.dataType])

  useEffect(() => {
    if (values.aggregationType === 'wavg') {
      setFormValues({ ...values, customFunctionId: '' })
      return
    }

    if (values.aggregationType === 'custom') {
      setFormValues({ ...values, weightDatapointRef: '' })
      return
    }

    setFormValues({ ...values, weightDatapointRef: '', customFunctionId: '' })
  }, [values.aggregationType])

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) {
    const { name, value } = event.target
    onChange(name, value)
  }

  function handleVarietyChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) {
    handleChange(event)

    const value = event.target.value as Variety

    if (selectedCalculation && values.calculationId) {
      setSelectedCalcualtion(null)
      setFormValues({ ...values, dataType: '', calculationId: '', parentClassId: '', variety: value })
    }
  }

  function handleCalculationChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) {
    handleChange(event)
  }

  function handleCheckboxChange(event: SyntheticEvent, checked: boolean) {
    const { name } = event.target as HTMLInputElement
    onChange(name, checked)
  }

  function handleCalculationInputChange(event: SelectChangeEvent) {
    const { name: calcId, value: datapointRef } = event.target as HTMLInputElement
    const value = { ...values.calculationInputs, [calcId]: datapointRef }

    onChange('calculationInputs', value)
  }

  return (
    <Stack gap={6}>
      <NameFieldGroup
        datapointName={values.datapointName}
        candidateAliasId={values.candidateAliasId}
        aliases={datapointOptions?.aliases || {}}
        onChange={handleChange}
      />
      <VarietyFieldGroup
        variety={values.variety}
        calculationId={values.calculationId}
        parentClassId={values.parentClassId}
        calculationInputValues={values.calculationInputs}
        inuseClassifications={datapointOptions?.inuse_classifications || {}}
        calculations={datapointOptions?.calculations || []}
        datapoints={datapoints}
        onChange={handleVarietyChange}
        onCalculationChange={handleCalculationChange}
        onCalculationInputChange={handleCalculationInputChange}
      />
      <DataTypeFieldGroup
        variety={values.variety}
        dataType={values.dataType}
        assetCategory={values.assetCategory}
        assetCategories={datapointOptions?.asset_categories || []}
        datadocType={values.datadocType}
        datadocTypes={datapointOptions?.datadoc_types || []}
        classificationId={values.classificationId}
        classifications={datapointOptions?.available_classifications || {}}
        selectedCalculation={selectedCalculation}
        onChange={handleChange}
      />
      <AggregationFieldGroup
        aggregationType={values.aggregationType}
        weightDatapointRef={values.weightDatapointRef}
        customFunctionId={values.customFunctionId}
        aggregationOnlyAll={values.aggregationOnlyAll}
        weightDatapoints={datapointOptions?.weight_datapoints || {}}
        aggregationFunctions={datapointOptions?.agg_functions || {}}
        onChange={handleChange}
        onCheckboxChange={handleCheckboxChange}
      />
    </Stack>
  )
}

export default DatapointForm

type FieldGroupProps = {
  groupTitle: string
  groupNumber: number
  children: ReactNode
}

function FieldGroup(props: FieldGroupProps) {
  return (
    <Stack gap={3}>
      <Stack direction="row" gap={1} alignItems="center">
        <Stack
          width={24}
          height={24}
          alignItems="center"
          justifyContent="center"
          bgcolor="primary.main"
          borderRadius="50%"
        >
          <Typography variant="caption" color="gray.light">
            {props.groupNumber}
          </Typography>
        </Stack>
        <Typography variant="subtitle2">{props.groupTitle}</Typography>
      </Stack>
      {props.children}
    </Stack>
  )
}

type NameFieldGroupProps = {
  datapointName: string
  candidateAliasId: string
  aliases: DatapointOptionsResponse['aliases']
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => void
}

function NameFieldGroup(props: NameFieldGroupProps) {
  const { datapointName, candidateAliasId, aliases, onChange } = props

  const { t } = useTranslation('dataEngine')

  return (
    <FieldGroup groupNumber={1} groupTitle={t('new_datapoint_modal.name')}>
      <TextField
        required
        name="datapointName"
        label={t('new_datapoint_modal.datapoint_name')}
        value={datapointName}
        onChange={onChange}
      />
      <FormControl>
        <InputLabel>{t('new_datapoint_modal.candidate_alias')}</InputLabel>
        <Select
          defaultValue="-"
          name="candidateAliasId"
          label={t('new_datapoint_modal.candidate_alias')}
          value={candidateAliasId}
          onChange={onChange}
          MenuProps={{ sx: { maxHeight: 300 } }}
        >
          {Object.entries(aliases)
            .sort(([_, aliasA], [__, aliasB]) => aliasA.localeCompare(aliasB))
            .map(([aliasId, alias]) => (
              <MenuItem key={aliasId} value={aliasId}>
                {alias}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </FieldGroup>
  )
}

type VarietyFieldGroupProps = {
  variety: Variety | ''
  calculationId: number | ''
  parentClassId: string
  calculationInputValues: DatapointFormValues['calculationInputs']
  inuseClassifications: DatapointOptionsResponse['inuse_classifications']
  calculations: DatapointOptionsResponse['calculations']
  datapoints: Datapoint[]
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => void
  onCalculationChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => void
  onCalculationInputChange: (event: SelectChangeEvent) => void
}

function VarietyFieldGroup(props: VarietyFieldGroupProps) {
  const {
    variety,
    calculationId,
    parentClassId,
    calculationInputValues,
    inuseClassifications,
    calculations,
    datapoints,
    onChange,
    onCalculationChange,
    onCalculationInputChange,
  } = props

  const { t } = useTranslation('dataEngine')

  const showCalculationField = variety === 'calculated' || variety === 'internal' || variety === 'realtime'

  const calculationFieldLabels = {
    calculated: t('new_datapoint_modal.calculation'),
    internal: t('new_datapoint_modal.internal_feed'),
    realtime: t('new_datapoint_modal.realtime_feed'),
  }

  const filteredCalculations = calculations.filter((calc) => {
    if (variety === 'internal') {
      return calc.calc_type === 'internalfeed'
    }

    if (variety === 'realtime') {
      return calc.calc_type === 'realtimefeed'
    }

    return calc.calc_type === 'regular'
  })

  const calculationInputs = calculations.find((calculation) => calculation.calc_id === calculationId)?.calc_inputs || []

  return (
    <FieldGroup groupNumber={2} groupTitle={t('new_datapoint_modal.variety')}>
      <FormControl required>
        <InputLabel>{t('new_datapoint_modal.variety')}</InputLabel>
        <Select name="variety" label={t('new_datapoint_modal.variety')} value={variety} onChange={onChange}>
          {varietyOptions.map((variety) => (
            <MenuItem key={variety} value={variety} disabled={variety === 'class_static'}>
              {t(`datapoint_table.variety.${variety}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {showCalculationField && (
        <FormControl required>
          <InputLabel>{calculationFieldLabels[variety]}</InputLabel>
          <Select
            name="calculationId"
            label={calculationFieldLabels[variety]}
            value={calculationId.toString()}
            onChange={onCalculationChange}
            MenuProps={{ sx: { maxHeight: 300 } }}
          >
            {filteredCalculations.map((calculation) => (
              <MenuItem key={calculation.calc_id} value={calculation.calc_id}>
                {calculation.calc_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {variety === 'class_static' && (
        <FormControl required disabled>
          <InputLabel>{t('new_datapoint_modal.for_classification')}</InputLabel>
          <Select
            name="parentClassId"
            label={t('new_datapoint_modal.calculation')}
            value={parentClassId}
            onChange={onChange}
            MenuProps={{ sx: { maxHeight: 300 } }}
          >
            {Object.entries(inuseClassifications).map(([classificationId, classificationName]) => (
              <MenuItem key={classificationId} value={classificationId}>
                {classificationName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {calculationInputs.length > 0 && (
        <>
          <Typography variant="subtitle2">{t('new_datapoint_modal.select_inputs_for_calc')}</Typography>
          <Grid container rowSpacing={2} columnSpacing={2}>
            {calculationInputs.map((calcInput) => {
              return (
                <Grid key={calcInput.input_id} item xs={4}>
                  <FormControl required fullWidth>
                    <InputLabel>{calcInput.input_label}</InputLabel>
                    <Select
                      name={calcInput.input_id.toString()}
                      label={calcInput.input_label}
                      value={calculationInputValues[calcInput.input_id] || ''}
                      onChange={onCalculationInputChange}
                      MenuProps={{ sx: { maxHeight: 300 } }}
                    >
                      {datapoints.map((datapoint) => (
                        <MenuItem key={datapoint.datapoint_ref} value={datapoint.datapoint_ref}>
                          {datapoint.datapoint_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )
            })}
          </Grid>
        </>
      )}
    </FieldGroup>
  )
}

type DataTypeFieldGroupProps = {
  variety: Variety | ''
  dataType: DatapointType | ''
  assetCategory: AssetCategory | ''
  assetCategories: DatapointOptionsResponse['asset_categories']
  datadocType: DataDocType | ''
  datadocTypes: DatapointOptionsResponse['datadoc_types']
  classificationId: string
  classifications: DatapointOptionsResponse['available_classifications']
  selectedCalculation: Calculation | null
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => void
}

function DataTypeFieldGroup(props: DataTypeFieldGroupProps) {
  const {
    variety,
    dataType,
    assetCategory,
    assetCategories,
    datadocType,
    datadocTypes,
    classificationId,
    classifications,
    selectedCalculation,
    onChange,
  } = props

  const { t } = useTranslation('dataEngine')

  const isAssetRef = dataType == 'AssetRef'
  const isDataDoc = dataType == 'DataDoc'
  const isClassificationFieldVisible = dataType === 'Classification' || dataType === 'MultiSelect'
  const isNotRequireDatatypeVarieties = ['calculated', 'internal', 'realtime'].includes(variety)

  return (
    <FieldGroup groupNumber={3} groupTitle={t('new_datapoint_modal.data_type')}>
      {isNotRequireDatatypeVarieties && selectedCalculation?.output_data_type ? (
        <Alert
          severity="info"
          variant="filled"
          sx={{
            py: 1,
            px: 2,
            alignItems: 'center',
            background: 'rgba(255, 255, 255, 0.08)',
          }}
        >
          <Typography variant="caption">{t(`new_datapoint_modal.data_type_${variety}_info_alert`)}</Typography>
        </Alert>
      ) : (
        <FormControl required>
          <InputLabel>{t('new_datapoint_modal.data_type')}</InputLabel>
          <Select name="dataType" label={t('new_datapoint_modal.data_type')} value={dataType} onChange={onChange}>
            {newDatapointTypes.map((dataType) => (
              <MenuItem key={dataType} value={dataType}>
                {dataType === 'NaiveDate' ? t('common:date') : dataType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {isClassificationFieldVisible && (
        <FormControl required>
          <InputLabel>{t('new_datapoint_modal.using_classification')}</InputLabel>
          <Select
            name="classificationId"
            label={t('new_datapoint_modal.using_classification')}
            value={classificationId}
            onChange={onChange}
          >
            {Object.entries(classifications)
              .sort((a, b) => a[1].localeCompare(b[1]))
              .map(([id, classification]) => (
                <MenuItem key={id} value={id}>
                  {classification}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      {isAssetRef && (
        <FormControl required>
          <InputLabel>{t('new_datapoint_modal.asset_category')}</InputLabel>
          <Select
            name="assetCategory"
            label={t('new_datapoint_modal.asset_category')}
            value={assetCategory}
            onChange={onChange}
          >
            {assetCategories.map((assetCategory) => (
              <MenuItem key={assetCategory.value} value={assetCategory.value}>
                {assetCategory.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {isDataDoc && (
        <FormControl required>
          <InputLabel>{t('new_datapoint_modal.datadoc_type')}</InputLabel>
          <Select
            name="datadocType"
            label={t('new_datapoint_modal.datadoc_type')}
            value={datadocType}
            onChange={onChange}
          >
            {datadocTypes.map((datadocType) => (
              <MenuItem key={datadocType.value} value={datadocType.value}>
                {datadocType.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </FieldGroup>
  )
}

type AggregationFieldGroupProps = {
  aggregationType: AggregationType | ''
  weightDatapointRef: string
  customFunctionId: string
  aggregationOnlyAll: boolean
  weightDatapoints: DatapointOptionsResponse['weight_datapoints']
  aggregationFunctions: DatapointOptionsResponse['agg_functions']
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => void
  onCheckboxChange: (event: SyntheticEvent, checked: boolean) => void
}

function AggregationFieldGroup(props: AggregationFieldGroupProps) {
  const {
    aggregationType,
    weightDatapointRef,
    customFunctionId,
    weightDatapoints,
    aggregationFunctions,
    aggregationOnlyAll,
    onChange,
    onCheckboxChange,
  } = props

  const { t } = useTranslation('dataEngine')

  return (
    <FieldGroup groupNumber={4} groupTitle={t('new_datapoint_modal.aggregation')}>
      <FormControl>
        <InputLabel>{t('new_datapoint_modal.aggregation')}</InputLabel>
        <Select
          name="aggregationType"
          label={t('new_datapoint_modal.aggregation')}
          value={aggregationType}
          onChange={onChange}
        >
          {aggregationOptions.map((aggregationType) => (
            <MenuItem key={aggregationType} value={aggregationType}>
              {t(`datapoint_table.aggregation_type.${aggregationType}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {aggregationType === 'wavg' && (
        <FormControl required>
          <InputLabel>{t('new_datapoint_modal.wavg_based_on')}</InputLabel>
          <Select
            name="weightDatapointRef"
            label={t('new_datapoint_modal.wavg_based_on')}
            value={weightDatapointRef}
            MenuProps={{ sx: { maxHeight: 300 } }}
            onChange={onChange}
          >
            {Object.entries(weightDatapoints)
              .sort((a, b) => a[1].localeCompare(b[1]))
              .map(([datapointRef, datapointName]) => (
                <MenuItem key={datapointRef} value={datapointRef}>
                  {datapointName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      {aggregationType === 'custom' && (
        <FormControl required>
          <InputLabel>{t('new_datapoint_modal.custom_function')}</InputLabel>
          <Select
            name="customFunctionId"
            label={t('new_datapoint_modal.custom_function')}
            value={customFunctionId}
            MenuProps={{ sx: { maxHeight: 300 } }}
            onChange={onChange}
          >
            {Object.entries(aggregationFunctions).map(([functionId, functionName]) => (
              <MenuItem key={functionId} value={functionId}>
                {functionName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {aggregationType && (
        <FormControlLabel
          name="aggregationOnlyAll"
          label={t('new_datapoint_modal.requires_all_rows')}
          checked={aggregationOnlyAll}
          onChange={onCheckboxChange}
          control={<Checkbox />}
          sx={{ my: -1 }}
        />
      )}
    </FieldGroup>
  )
}
