import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useDatapointOptionsQuery(datasetRef: string) {
  return useQuery({
    queryKey: ['dataset', datasetRef, 'datapoint', 'options'],
    queryFn: () => api.getDatapointOptions(datasetRef),
    cacheTime: 0,
  })
}

export default useDatapointOptionsQuery
