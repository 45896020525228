import { FormControlLabel, PopoverOrigin, Radio, RadioGroup, SxProps, Typography } from '@mui/material'
import { Theme } from '@mui/system'
import { ChangeEvent } from 'react'
import SelectFilterMenu from './select-filter-menu'

type ValueFilterMenuProps = {
  options: ValueFilterOptions[]
  selectedValue?: string | number
  onChange: (value: string) => void
  onClose: () => void
  anchorEl: HTMLElement | null
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  disableBackdrop?: boolean
  sx?: SxProps<Theme>
}

export type ValueFilterOptions = {
  value: string | number
  title?: string | null | undefined
}

function ValueFilterMenu(props: ValueFilterMenuProps) {
  const { options, selectedValue, onChange, onClose, anchorEl, anchorOrigin, transformOrigin, disableBackdrop, sx } =
    props

  function handleChange(_: ChangeEvent, value: string) {
    onChange(value)
    onClose()
  }

  return (
    <SelectFilterMenu
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      disableBackdrop={disableBackdrop}
      sx={sx}
    >
      <RadioGroup
        value={selectedValue}
        onChange={handleChange}
        sx={{
          gap: 0.25,
          px: 3,
          '& .MuiSvgIcon-root': {
            fontSize: 16,
          },
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={<Typography variant="body2">{option.title || option.value}</Typography>}
          />
        ))}
      </RadioGroup>
    </SelectFilterMenu>
  )
}

export default ValueFilterMenu
