import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useDatapointsQuery(datasetRef: string) {
  return useQuery({
    queryKey: ['dataset', datasetRef, 'datapoints'],
    queryFn: () => api.getDatapoints(datasetRef),
  })
}

export default useDatapointsQuery
