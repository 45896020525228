import { Add, DeleteOutline } from '@mui/icons-material'
import {
  Chip,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { MouseEvent, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import TableContainerHeader from '../../../components/table-container-header'
import useOpenState from '../../../utils/hooks/use-open-state'
import useDeletePortfolioMutation from '../data/use-delete-portfolio-mutation'
import useRegularPortfoliosQuery from '../data/use-regular-portfolios-query'
import useSandboxPortfoliosQuery from '../data/use-sandbox-portfolios-query'
import CreatePortfolioModal from './create-portfolio-modal'
import { useSettingsUpdateLoadingState } from './use-settings-set-loading'

type PortfoliosProps = {
  onPortfolioClick: (portfolioRef: string) => void
}

function Portfolios(props: PortfoliosProps) {
  const { onPortfolioClick } = props
  const { t } = useTranslation('settings')

  const createPortfolioModal = useOpenState()
  const deletePortfolioModal = useOpenState()

  const [selectedPortfolioRef, setSelectedPortfolioRef] = useState<string>()

  const { mutate: deletePortfolio, error: deletePortfolioError } = useDeletePortfolioMutation()

  const portfoliosResponse = useRegularPortfoliosQuery()
  const portfolios = portfoliosResponse.data?.data
  const selectedPortfolio = portfolios?.find((portfolio) => portfolio.portfolio_ref === selectedPortfolioRef)

  const sandboxResponse = useSandboxPortfoliosQuery()
  const sandboxes = sandboxResponse.data?.data

  const isLoading = portfoliosResponse.isLoading || sandboxResponse.isLoading

  useSettingsUpdateLoadingState(isLoading)

  function handleDeletePortfolioModalOpen(event: MouseEvent<HTMLButtonElement>, portfolioRef: string) {
    event.stopPropagation()
    deletePortfolioModal.open()
    setSelectedPortfolioRef(portfolioRef)
  }

  async function handleDeletePortfolio() {
    if (selectedPortfolio!.portfolio_ref) {
      const portfolioRef = selectedPortfolio!.portfolio_ref
      deletePortfolio(
        { portfolioRef },
        {
          onSuccess: () => {
            portfoliosResponse.refetch()
            deletePortfolioModal.close()
          },
        }
      )
    }
  }

  if (isLoading) {
    return null
  }

  return (
    <>
      <Stack p={2} gap={2}>
        <TableContainer component={Paper}>
          <TableContainerHeader
            title={t('portfolios_list_table.title')}
            action={
              <Tooltip title={t('portfolios_list_table.add_portfolio')} arrow disableInteractive>
                <IconButton onClick={createPortfolioModal.open}>
                  <Add />
                </IconButton>
              </Tooltip>
            }
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('portfolios_list_table.portfolio')}</TableCell>
                <TableCell>{t('portfolios_list_table.legal_entity')}</TableCell>
                <TableCell>{t('portfolios_list_table.base_currency')}</TableCell>
                <TableCell>{t('portfolios_list_table.chart_of_account')}</TableCell>
                <TableCell>{t('portfolios_list_table.ledger_tag')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {portfolios?.map((portfolio) => {
                return (
                  <TableRow
                    hover
                    key={portfolio.portfolio_ref}
                    onClick={() => onPortfolioClick(portfolio.portfolio_ref)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>{portfolio.portfolio_name}</TableCell>
                    <TableCell>{portfolio.legal_entity_name}</TableCell>
                    <TableCell>
                      <Chip
                        label={portfolio.base_currency}
                        sx={{
                          color: 'inherit',
                          cursor: 'inherit',
                          '& .MuiChip-avatar': {
                            color: 'gray.light',
                            backgroundColor: 'gray.400',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>{portfolio.chart_name}</TableCell>
                    <TableCell>{portfolio.pot_tag}</TableCell>
                    <TableCell className="action-cell" align="right">
                      <Tooltip title={t('delete')} arrow disableInteractive>
                        <IconButton onClick={(event) => handleDeletePortfolioModalOpen(event, portfolio.portfolio_ref)}>
                          <DeleteOutline />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ width: '50%' }}>
          <TableContainerHeader
            title={t('sandboxes_list_table.title')}
            description={t('sandboxes_list_table.description')}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('sandboxes_list_table.sandbox_name')}</TableCell>
                <TableCell>{t('sandboxes_list_table.owner')}</TableCell>
                <TableCell>{t('sandboxes_list_table.base_currency')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sandboxes?.map((sandbox) => {
                return (
                  <TableRow key={sandbox.portfolio_ref}>
                    <TableCell>{sandbox.portfolio_name}</TableCell>
                    <TableCell>{sandbox.group_owner}</TableCell>
                    <TableCell>{sandbox.base_currency}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <CreatePortfolioModal open={createPortfolioModal.isOpen} onClose={createPortfolioModal.close} />
      <ConfirmationModal
        error={deletePortfolioError}
        title={t('settings:delete_portfolio')}
        confirmButtonText={t('common:delete')}
        open={deletePortfolioModal.isOpen}
        onCloseButtonClick={deletePortfolioModal.close}
        onConfirmButtonClick={handleDeletePortfolio}
      >
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey={'common:are_you_sure_you_want_to_delete'}
            values={{ subject: `${selectedPortfolio?.portfolio_name}` || '' }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </ConfirmationModal>
    </>
  )
}

export default Portfolios
