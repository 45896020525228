import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useSetViewAsDefaultMutation() {
  const response = useMutation<AxiosResponse, AxiosError, MutationFunctionParams>({
    mutationFn: ({ viewRef, assetType }) => api.setViewAsDefault(viewRef, assetType),
  })
  return { ...response, error: parseError(response.error) }
}

type MutationFunctionParams = {
  viewRef: string
  assetType: string | null
}

export default useSetViewAsDefaultMutation
