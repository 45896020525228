import { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import axios, { createCheckAuthTokenOnErrorInterceptor } from '../../../services/data/axios'
import { useAuth } from '../data/auth-context'
import useUserInfo from '../data/use-user-info'

function RequiresAuth() {
  const location = useLocation()
  const { logout } = useAuth()
  const { user, isLoading } = useUserInfo()

  useEffect(() => {
    const interceptor = createCheckAuthTokenOnErrorInterceptor({
      onUnauthorized: logout,
    })

    return () => axios.interceptors.response.eject(interceptor)
  }, [])

  if (isLoading) {
    return null
  }

  if (!isLoading && !user) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  if (!user?.tenant_ref) {
    return <Navigate to="/login/tenant" state={{ from: location }} />
  }

  return <Outlet />
}

export default RequiresAuth
