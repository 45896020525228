import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AppLayout from '../../../../components/layouts/app-layout'
import { ChannelAction, connectAuthChannel } from '../../../../services/openfin/fin'
import Account from '../../components/account'
import AccountHeader from '../../components/account-header'

function AccountView() {
  const { t } = useTranslation('account')

  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    document.title = t('account')
  }, [])

  async function handleSwitchTenantSuccess() {
    const client = await connectAuthChannel()
    client.dispatch(ChannelAction.switchTenant)
  }

  return (
    <AppLayout>
      <AccountHeader isLoading={isLoading} onSwitchTenantSuccess={handleSwitchTenantSuccess} />
      <Account onLoadingChange={setIsLoading} onSwitchTenantSuccess={handleSwitchTenantSuccess} />
    </AppLayout>
  )
}

export default AccountView
