import { GridDataFilterOption, parseFilterOptions } from '../../../services/data/filter-parsing'
import { ParsedGridColumnOption } from '../../../services/data/grid-data-parsing'
import { useAssetsSummaryQuery } from '../../data-engine/data/use-assets-summary-query'
import useClassificationsQuery from '../../data-engine/data/use-classifications-query'
import useSystemClassificationsQuery from '../../data-engine/data/use-system-classifications-query'

export function useFilterOptions(
  columnOptions: ParsedGridColumnOption[] = [],
  datasetRef?: string | null
): GridDataFilterOption[] {
  const systemClassifications = useSystemClassificationsQuery().data?.data || []
  const datasetClassfications = useClassificationsQuery(datasetRef).data?.data || []
  const all = [...systemClassifications, ...datasetClassfications]

  const assetsQuery = useAssetsSummaryQuery()
  const assets = assetsQuery.data?.data || []

  return parseFilterOptions(columnOptions, all, assets)
}
