import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import TenantLogin from '../../components/tenant-login'
import { useAuth } from '../../data/auth-context'
import useUserInfo from '../../data/use-user-info'

function TenantLoginFlexView() {
  const navigate = useNavigate()
  const { state } = useLocation()

  const { logout } = useAuth()
  const { user, isLoading } = useUserInfo()

  const fromLocation = (state as LocationState)?.from

  function handleSuccess() {
    navigate(fromLocation || '/portfolios')
  }

  async function handleBackToLogin() {
    await logout()
    navigate('/login')
  }

  async function handleNavigateToAccount() {
    navigate('/account-details')
  }

  if (!isLoading && !user) {
    return <Navigate to="/login" state={{ from: fromLocation }} />
  }

  return (
    <TenantLogin
      onSuccess={handleSuccess}
      onBackToLogin={handleBackToLogin}
      onNavigateToAccount={handleNavigateToAccount}
    />
  )
}

export default TenantLoginFlexView

type LocationState = {
  from?: string
}
