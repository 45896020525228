import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

export type DataEngineOutletContext = {
  showLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  isNavOpen: boolean
  onNavOpen: () => void
  breadcrumbs: { name: string; path: string }[]
}

export function useDataEngineOutletContext() {
  return useOutletContext<DataEngineOutletContext>()
}

export function useDataEngineUpdateLoadingState(isLoading: boolean) {
  const outletContext = useDataEngineOutletContext()

  useEffect(() => {
    outletContext.setIsLoading(isLoading)
  }, [isLoading])
}
