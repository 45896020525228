import { useNavigate } from 'react-router-dom'
import Datasets from '../../components/datasets'

function DatasetsFlexView() {
  const navigate = useNavigate()

  function handleDatasetClick(datasetRef: string) {
    navigate(`/data-engine/datasets/${datasetRef}`)
  }

  return <Datasets onDatasetClick={handleDatasetClick} />
}

export default DatasetsFlexView
