import { useNavigate } from 'react-router-dom'
import Users from '../../components/users'

function UsersView() {
  const navigate = useNavigate()

  return <Users onUserClick={(userRef) => navigate(`/openfin/views/settings/users/${userRef}`)} />
}

export default UsersView
