import { t } from 'i18next'
import { GridAlert } from '../../../services/data/types/grid-data'
import {
  MatchStatus,
  ReconGrid,
  ReconGridCell,
  ReconGridHeading,
  ReconGridResponse,
  ReconGridRow,
  ReconMatch,
  ReconRecord,
  ReconRecordLocal,
  ReconTxn,
  ReconTxnLocal,
  ReconUnreconciledBankTxn,
  reconRecordLocal,
  reconTxnLocal,
} from '../../../services/data/types/reconciliation'

export type SelectedTxns = {
  [extSegmentRef: string]: ReconTxn
}

export function processGrids(
  gridResponse: ReconGridResponse | null,
  selectedRecord: ReconRecord | null,
  selectedTxns: SelectedTxns,
  matches: Array<ReconMatch>,
  hideReconciled: boolean,
  isReconciling: boolean
): [ReconGrid | null, ReconGrid | null] {
  if (!gridResponse) {
    return [null, null]
  }

  const grid = window.structuredClone(gridResponse)

  let ledgerGrid: ReconGrid | null = {
    account_name: grid.account_name,
    currency: grid.currency,
    headings: ledgerHeadings(isReconciling),
    rows: [],
  }

  for (const gridTxn of grid.txns) {
    const txn = reconTxnLocal(gridTxn)
    const skipRow = hideReconciled && txn.is_reconciled
    if (skipRow) {
      continue
    }

    let txnExtSegmentRefs: string[] = [txn.ext_segment_ref]
    if (!txn.is_bank_txn) {
      txnExtSegmentRefs = txnExtSegmentRefs.concat(txn.bank_txns.map((bt) => bt.ext_segment_ref))
    }

    let totalMatched = 0
    let hasAnyMatch = false
    for (const match of matches) {
      for (const matchTxn of match.txns) {
        if (matchTxn.ext_segment_ref && txnExtSegmentRefs.includes(matchTxn.ext_segment_ref)) {
          hasAnyMatch = true
          totalMatched += matchTxn.amount
        }
      }
    }

    txn.quantity_reconciled += totalMatched
    txn.quantity_to_reconcile -= totalMatched

    if (hasAnyMatch) {
      if (txn.quantity_to_reconcile === 0) {
        txn.match_status = 'full_match'
      } else {
        txn.match_status = 'partial_match'
      }
    }

    ledgerGrid.rows.push({
      rowKey: txn.ext_segment_ref,
      mergeRow: false,
      rowSpan: 1,
      record: null,
      txn: txn,
      selected: !!selectedTxns[txn.ext_segment_ref],
      showSelect: txn.match_status === 'no_match' || txn.match_status === 'partial_match',
      arcfinaAmount: 0,
      bankBalance: 0,
      arcfinaBalance: 0,
      cells: ledgerCells(txn),
    })
  }

  let bankGrid: ReconGrid = {
    account_name: grid.account_name,
    currency: grid.currency,
    headings: bankHeadings(isReconciling),
    rows: [],
  }

  let bankBalance = 0
  let arcfinaBalance = 0

  for (const gridRecord of grid.records) {
    const record = reconRecordLocal(gridRecord)
    let recordAmountMatched = 0
    bankBalance += record.amount

    const skipRow = hideReconciled && record.is_reconciled
    const recordMatches = matches.filter((m) => m.record_id === record.record_id)

    // has reconciled txns
    for (const gridTxn of record.txns) {
      const txn = reconTxnLocal(gridTxn)
      const arcfinaAmount = txn.bank_txns.reduce((acc, bt) => acc + bt.amount, 0)
      arcfinaBalance += arcfinaAmount

      if (!skipRow) {
        const extSegRefs = txn.bank_txns.reduce((acc, bt) => `${acc}+${bt.ext_segment_ref}`, '')

        bankGrid.rows.push({
          rowKey: `record_${record.record_id}_${extSegRefs}`,
          mergeRow: false,
          rowSpan: 1,
          record: record,
          txn: txn,
          selected: selectedRecord?.record_id === record.record_id,
          showSelect: record.match_status === 'no_match' || record.match_status === 'partial_match',
          arcfinaAmount,
          bankBalance,
          arcfinaBalance,
          cells: [],
        })
      }
    }

    // no reconciled txns and no matches
    if (record.txns.length === 0 && recordMatches.length === 0) {
      bankGrid.rows.push({
        rowKey: `record_${record.record_id}_unmatched`,
        mergeRow: false,
        rowSpan: 1,
        record: record,
        txn: null,
        selected: selectedRecord?.record_id === record.record_id,
        showSelect: record.match_status === 'no_match' || record.match_status === 'partial_match',
        arcfinaAmount: 0,
        bankBalance,
        arcfinaBalance,
        cells: [],
      })
    }

    // has matches
    if (recordMatches.length > 0) {
      for (const match of recordMatches) {
        for (const matchTxn of match.txns) {
          const gridTxn = grid.txns.find((t) => {
            if (matchTxn.bank_txn_ref) {
              return t.bank_txns.some((bt) => bt.ext_segment_ref === matchTxn.ext_segment_ref)
            } else {
              return t.ext_segment_ref === matchTxn.ext_segment_ref
            }
          })
          const txn = gridTxn ? reconTxnLocal(gridTxn) : null

          const arcfinaAmount = matchTxn.amount
          arcfinaBalance += matchTxn.amount

          record.amount_reconciled += matchTxn.amount
          record.amount_to_reconcile -= matchTxn.amount
          recordAmountMatched += matchTxn.amount

          if (record.amount_reconciled === record.amount) {
            record.match_status = 'full_match'
          } else {
            record.match_status = 'partial_match'
          }

          bankGrid.rows.push({
            rowKey: `record_${record.record_id}_${txn?.ext_segment_ref || 'newbanktxn'}_match_${matchTxn.ext_segment_ref}`,
            mergeRow: false,
            rowSpan: 1,
            record: record,
            txn: txn,
            selected: selectedRecord?.record_id === record.record_id,
            // `record.status === 'no_match' ||` needs to be here in case the logic changes
            showSelect: record.match_status === 'partial_match',
            arcfinaAmount,
            bankBalance,
            arcfinaBalance,
            cells: [],
          })
        }
      }
    }
  }

  // merge bank rows and set cells
  let prevRow: ReconGridRow | null = null
  for (const row of bankGrid.rows) {
    if (row.record) {
      const mergeRow = row.record.record_id === prevRow?.record?.record_id

      row.mergeRow = mergeRow
      row.cells = bankCells(
        row.record,
        row.txn,
        mergeRow,
        row.arcfinaAmount,
        row.bankBalance,
        row.arcfinaBalance,
        isReconciling
      )

      if (mergeRow) {
        if (prevRow) {
          prevRow.rowSpan += 1
        }
      } else {
        prevRow = row
      }
    }
  }

  // load unreconciled bank txns into the bank grid
  for (const unreconBankTxn of grid.unreconciled_bank_txns) {
    const arcfinaAmount = unreconBankTxn.bank_amount
    arcfinaBalance += unreconBankTxn.bank_amount

    bankGrid.rows.push({
      rowKey: `unreconciled_${unreconBankTxn.bank_txn_ref}`,
      mergeRow: false,
      rowSpan: 1,
      record: null,
      txn: null,
      selected: false,
      showSelect: false,
      arcfinaAmount,
      bankBalance,
      arcfinaBalance,
      cells: unreconciledBankTxnCells(unreconBankTxn, arcfinaAmount, bankBalance, arcfinaBalance),
    })
  }

  return [bankGrid, ledgerGrid]
}

function bankCells(
  record: ReconRecordLocal,
  txn: ReconTxnLocal | null,
  mergeRow: boolean,
  arcfinaAmount: number,
  bankBalance: number,
  arcfinaBalance: number,
  isReconciling: boolean
): ReconGridCell[] {
  const showLedger = record.is_reconciled || isReconciling
  const recordDateMismatch = !mergeRow && txn && record.date !== txn.settlement_date

  return [
    {
      headingKey: 'action',
      value: 'None',
    },
    {
      headingKey: 'record_date',
      value: mergeRow ? 'None' : { NaiveDate: record.date },
      alert: recordDateMismatch
        ? {
            level: 'warning',
            message: t('bankAccounts:recon_table:record_date_mismatch', { date: txn.settlement_date }),
          }
        : undefined,
    },
    {
      headingKey: 'record_description',
      value: mergeRow ? 'None' : { String: record.description },
    },
    {
      headingKey: 'record_amount',
      value: mergeRow ? 'None' : { Float: record.amount },
    },
    {
      headingKey: 'record_amount_reconciled',
      value: mergeRow ? 'None' : { Float: record.amount_reconciled },
    },
    {
      headingKey: 'record_amount_to_reconcile',
      value: mergeRow ? 'None' : { Float: record.amount_to_reconcile },
    },
    {
      headingKey: 'reconciliation_status',
      value: mergeRow ? 'None' : { String: getReconStatusLabel(record.match_status, record.is_reconciled) },
      color: getReconStatusColor(record.match_status, record.is_reconciled),
      alert: getReconAlert(record.match_status),
    },
    {
      headingKey: 'match_status',
      value: mergeRow ? 'None' : { String: getMatchStatusLabel(record.match_status) },
      color: getMatchStatusColor(record.match_status),
      alert: getReconAlert(record.match_status),
    },
    {
      headingKey: 'bank_settlement_amount',
      value: showLedger && txn ? { Float: arcfinaAmount } : { String: '-' },
    },
    {
      headingKey: 'bank_balance',
      value: { Float: bankBalance },
    },
    {
      headingKey: 'arc_balance',
      value: showLedger && txn ? { Float: arcfinaBalance } : { String: '-' },
    },
    // ledger part
    {
      headingKey: 'txn_settlement_date',
      value: showLedger && txn ? { NaiveDate: txn.settlement_date } : { String: '-' },
    },
    {
      headingKey: 'txn_date',
      value: showLedger && txn ? { DateTime: txn.txn_datetime } : { String: '-' },
    },
    {
      headingKey: 'txn_ref',
      value: showLedger && txn ? { String: txn.txn_ref } : { String: '-' },
    },
    {
      headingKey: 'portfolio',
      value: showLedger && txn ? { String: txn.portfolio_name } : { String: '-' },
    },
    {
      headingKey: 'txn_description',
      value: showLedger && txn ? { String: txn.description } : { String: '-' },
    },
    {
      headingKey: 'txn_cp',
      value: showLedger && txn ? { String: txn.cparty_name || '-' } : { String: '-' },
    },
    {
      headingKey: 'txn_quantity',
      value: showLedger && txn ? { Float: txn.quantity } : { String: '-' },
    },
    {
      headingKey: 'txn_quantity_reconciled',
      value: showLedger && txn ? { Float: txn.quantity_reconciled } : { String: '-' },
    },
    {
      headingKey: 'txn_quantity_to_reconcile',
      value: showLedger && txn ? { Float: txn.quantity_to_reconcile } : { String: '-' },
    },
    {
      headingKey: 'txn_quantity_to_settle',
      value: showLedger && txn ? { Float: txn.quantity - (txn.quantity_settled || 0) } : { String: '-' },
    },
    {
      headingKey: 'settlement_percent',
      value:
        showLedger && typeof txn?.quantity_settled === 'number'
          ? { Percent: txn.quantity_settled / txn.quantity }
          : { String: '-' },
      color: getSettlementPercentColor(txn),
    },
  ]
}

function ledgerCells(txn: ReconTxnLocal): ReconGridCell[] {
  return [
    {
      headingKey: 'action',
      value: 'None',
    },
    {
      headingKey: 'txn_settlement_date',
      value: { NaiveDate: txn.settlement_date },
    },
    {
      headingKey: 'txn_date',
      value: { DateTime: txn.txn_datetime },
    },
    {
      headingKey: 'txn_ref',
      value: { String: txn.txn_ref },
    },
    {
      headingKey: 'portfolio',
      value: { String: txn.portfolio_name },
    },
    {
      headingKey: 'txn_description',
      value: { String: txn.description },
    },
    {
      headingKey: 'txn_cp',
      value: { String: txn.cparty_name || '-' },
    },
    {
      headingKey: 'txn_quantity',
      value: { Float: txn.quantity },
    },
    {
      headingKey: 'txn_quantity_reconciled',
      value: { Float: txn.quantity_reconciled },
    },
    {
      headingKey: 'txn_quantity_to_reconcile',
      value: { Float: txn.quantity_to_reconcile },
    },
    {
      headingKey: 'txn_quantity_to_settle',
      value: { Float: txn.quantity - (txn.quantity_settled || 0) },
    },
    {
      headingKey: 'settlement_percent',
      value:
        typeof txn.quantity_settled === 'number' ? { Percent: txn.quantity_settled / txn.quantity } : { String: '-' },
      color: getSettlementPercentColor(txn),
    },
    {
      headingKey: 'reconciliation_status',
      value: { String: getReconStatusLabel(txn.match_status, txn.is_reconciled) },
      color: getReconStatusColor(txn.match_status, txn.is_reconciled),
      alert: getReconAlert(txn.match_status),
    },
    {
      headingKey: 'match_status',
      value: { String: getMatchStatusLabel(txn.match_status) },
      color: getMatchStatusColor(txn.match_status),
      alert: getReconAlert(txn.match_status),
    },
  ]
}

function unreconciledBankTxnCells(
  txn: ReconUnreconciledBankTxn,
  arcfinaAmount: number,
  bankBalance: number,
  arcfinaBalance: number
): ReconGridCell[] {
  return [
    {
      headingKey: 'action',
      value: 'None',
    },
    {
      headingKey: 'record_date',
      value: { String: '-' },
    },
    {
      headingKey: 'record_description',
      value: { String: '-' },
    },
    {
      headingKey: 'record_amount',
      value: { String: '-' },
    },
    {
      headingKey: 'record_amount_reconciled',
      value: { String: '-' },
    },
    {
      headingKey: 'record_amount_to_reconcile',
      value: { String: '-' },
    },
    {
      headingKey: 'reconciliation_status',
      value: { String: '-' },
    },
    {
      headingKey: 'match_status',
      value: { String: '-' },
    },
    {
      headingKey: 'bank_settlement_amount',
      value: { Float: arcfinaAmount },
    },
    {
      headingKey: 'bank_balance',
      value: { Float: bankBalance },
    },
    {
      headingKey: 'arc_balance',
      value: { Float: arcfinaBalance },
    },
    // ledger part
    {
      headingKey: 'txn_settlement_date',
      value: { NaiveDate: txn.bank_datetime.slice(0, 10) },
    },
    {
      headingKey: 'txn_date',
      value: { DateTime: txn.txn_datetime },
    },
    {
      headingKey: 'txn_ref',
      value: { String: txn.txn_ref },
    },
    {
      headingKey: 'portfolio',
      value: { String: txn.portfolio_name },
    },
    {
      headingKey: 'txn_description',
      value: { String: txn.description },
    },
    {
      headingKey: 'txn_cp',
      value: { String: txn.cparty_name || '-' },
    },
    {
      headingKey: 'txn_quantity',
      value: { Float: txn.txn_amount },
    },
    {
      headingKey: 'txn_quantity_reconciled',
      value: { String: '-' },
    },
    {
      headingKey: 'txn_quantity_to_reconcile',
      value: { Float: txn.txn_amount },
    },
    {
      headingKey: 'txn_quantity_to_settle',
      value: { String: '-' },
    },
    {
      headingKey: 'settlement_percent',
      value: { String: '-' },
    },
  ]
}

function getReconStatusLabel(_status: MatchStatus, isReconciled: boolean): string {
  if (isReconciled) {
    return t('bankAccounts:recon_status.reconciled')
  }
  return t('bankAccounts:recon_status.not_reconciled')
}

function getMatchStatusLabel(status: MatchStatus): string {
  switch (status) {
    case 'no_match':
      return t('bankAccounts:recon_status.no_match')
    case 'partial_match':
      return t('bankAccounts:recon_status.partial_match')
    case 'full_match':
      return t('bankAccounts:recon_status.full_match')
    case 'reconciled':
      return t('bankAccounts:recon_status.reconciled')
    case 'reconciled_err':
      return t('bankAccounts:recon_status.reconciled')
  }
}

function getReconStatusColor(_status: MatchStatus, isReconciled: boolean): string {
  if (isReconciled) {
    return 'success.main'
  }
  return 'error.main'
}

function getMatchStatusColor(status: MatchStatus): string {
  switch (status) {
    case 'no_match':
      return 'error.main'
    case 'partial_match':
      return '#FFCA7B'
    case 'full_match':
      // same as info button bg
      return '#29B6F6'
    case 'reconciled':
      return 'success.main'
    case 'reconciled_err':
      return 'success.main'
  }
}

function getSettlementPercentColor(txn: ReconTxn | null): string {
  if (typeof txn?.quantity_settled !== 'number' || txn.quantity === 0) {
    return ''
  }
  const settlePercent = txn.quantity_settled / txn.quantity
  if (settlePercent === 1) {
    return 'success.main'
  }
  if (settlePercent !== 0) {
    return '#FFCA7B'
  }
  return 'error.main'
}

function getReconAlert(status: MatchStatus): GridAlert | undefined {
  if (status === 'reconciled_err') {
    return {
      level: 'breach',
      message: t('bankAccounts:recon_status:reconciled_err'),
    }
  }
}

//
// Headings definitions
//

export const defaultInitialWidth = 120
const actionWidth = 50
const dateWidth = 140
const amountWidth = 150
const descriptionWidth = 360
const statusWidth = 140

function bankHeadings(isReconciling: boolean): ReconGridHeading[] {
  return [
    {
      key: 'action',
      title: '',
      pinned: true,
      hide: !isReconciling,
      width: actionWidth,
    },
    {
      key: 'record_date',
      title: t('bankAccounts:recon_table.record_date'),
      initialWidth: defaultInitialWidth,
    },
    {
      key: 'record_description',
      title: t('bankAccounts:recon_table.record_description'),
      initialWidth: descriptionWidth,
    },
    {
      key: 'record_amount',
      title: t('bankAccounts:recon_table.record_amount'),
      initialWidth: amountWidth,
    },
    {
      key: 'bank_balance',
      title: t('bankAccounts:recon_table.bank_balance'),
      initialWidth: amountWidth,
    },
    // used when debugging is needed
    // {
    //   key: 'record_amount_reconciled',
    //   title: t('bankAccounts:recon_table.record_amount_reconciled'),
    //   initialWidth: amountWidth,
    // },
    // {
    //   key: 'record_amount_to_reconcile',
    //   title: t('bankAccounts:recon_table.record_amount_to_reconcile'),
    //   initialWidth: amountWidth,
    // },
    {
      key: 'reconciliation_status',
      title: t('bankAccounts:recon_table.reconciliation_status'),
      hide: isReconciling,
      initialWidth: statusWidth,
    },
    {
      key: 'match_status',
      title: t('bankAccounts:recon_table.match_status'),
      hide: !isReconciling,
      initialWidth: statusWidth,
    },
    {
      key: 'bank_settlement_amount',
      title: t('bankAccounts:recon_table.bank_settlement_amount'),
      initialWidth: amountWidth,
    },
    {
      key: 'arc_balance',
      title: t('bankAccounts:recon_table.arc_balance'),
      initialWidth: amountWidth,
    },
    {
      key: 'txn_settlement_date',
      title: t('bankAccounts:recon_table.txn_settlement_date'),
      initialWidth: defaultInitialWidth,
    },
    {
      key: 'txn_date',
      title: t('bankAccounts:recon_table.txn_date'),
      initialWidth: dateWidth,
    },
    {
      key: 'txn_ref',
      title: t('bankAccounts:recon_table.txn_ref'),
      initialWidth: defaultInitialWidth,
    },
    {
      key: 'portfolio',
      title: t('bankAccounts:recon_table.portfolio'),
      initialWidth: defaultInitialWidth,
    },
    {
      key: 'txn_description',
      title: t('bankAccounts:recon_table.txn_description'),
      initialWidth: descriptionWidth,
    },
    {
      key: 'txn_cp',
      title: t('bankAccounts:recon_table.txn_cp'),
      initialWidth: defaultInitialWidth,
    },
    {
      key: 'txn_quantity',
      title: t('bankAccounts:recon_table.txn_quantity'),
      initialWidth: amountWidth,
    },
    {
      key: 'txn_quantity_to_settle',
      title: t('bankAccounts:recon_table.txn_quantity_to_settle'),
      initialWidth: amountWidth,
    },
    {
      key: 'settlement_percent',
      title: t('bankAccounts:recon_table.settlement_percent'),
      initialWidth: defaultInitialWidth,
    },
    // used when debugging is needed
    // {
    //   key: 'txn_quantity_reconciled',
    //   title: t('bankAccounts:recon_table.txn_quantity_reconciled'),
    //   initialWidth: amountWidth
    // },
    // {
    //   key: 'txn_quantity_to_reconcile',
    //   title: t('bankAccounts:recon_table.txn_quantity_to_reconcile'),
    //   initialWidth: amountWidth
    // },
  ]
}

function ledgerHeadings(isReconciling: boolean): ReconGridHeading[] {
  return [
    {
      key: 'action',
      title: '',
      pinned: true,
      hide: !isReconciling,
      width: actionWidth,
    },
    {
      key: 'txn_settlement_date',
      title: t('bankAccounts:recon_table.txn_settlement_date'),
      initialWidth: defaultInitialWidth,
    },
    {
      key: 'txn_description',
      title: t('bankAccounts:recon_table.txn_description'),
      initialWidth: descriptionWidth,
    },
    {
      key: 'txn_quantity',
      title: t('bankAccounts:recon_table.txn_quantity'),
      initialWidth: amountWidth,
    },
    {
      key: 'txn_quantity_to_settle',
      title: t('bankAccounts:recon_table.txn_quantity_to_settle'),
      initialWidth: amountWidth,
    },
    {
      key: 'settlement_percent',
      title: t('bankAccounts:recon_table.settlement_percent'),
      initialWidth: defaultInitialWidth,
    },
    // used when debugging is needed
    // {
    //   key: 'txn_quantity_to_reconcile',
    //   title: t('bankAccounts:recon_table.txn_quantity_to_reconcile'),
    //   initialWidth: amountWidth,
    // },
    // {
    //   key: 'txn_quantity_reconciled',
    //   title: t('bankAccounts:recon_table.txn_quantity_reconciled'),
    //   initialWidth: amountWidth,
    // },
    // {
    //   key: 'reconciliation_status',
    //   title: t('bankAccounts:recon_table.reconciliation_status'),
    //   hide: isReconciling,
    //   initialWidth: statusWidth,
    // },
    // {
    //   key: 'match_status',
    //   title: t('bankAccounts:recon_table.match_status'),
    //   hide: !isReconciling,
    //   initialWidth: statusWidth,
    // },
    {
      key: 'txn_date',
      title: t('bankAccounts:recon_table.txn_date'),
      initialWidth: dateWidth,
    },
    {
      key: 'txn_ref',
      title: t('bankAccounts:recon_table.txn_ref'),
      initialWidth: defaultInitialWidth,
    },
    {
      key: 'portfolio',
      title: t('bankAccounts:recon_table.portfolio'),
      initialWidth: defaultInitialWidth,
    },
    {
      key: 'txn_cp',
      title: t('bankAccounts:recon_table.txn_cp'),
      initialWidth: defaultInitialWidth,
    },
  ]
}
