import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AlertIcon from '../../../components/alert-icon'
import { extractDatapointFormatAndValue, formatDatapointValue } from '../../../services/data/datapoint-formatting'
import { DatapointValue } from '../../../services/data/types/datapoint'
import { datetimeWithSeconds } from '../../../utils/dates'

type DatapointExplainerProps = {
  datapointName: string
  datasetName: string
  value: DatapointValue | undefined
  displayAs: string | undefined
  asOnDate: Date | undefined
  shadowedValue: DatapointValue | undefined
  shadowedDisplayAs: string | undefined
  modifiedAt: Date | undefined
  modifiedBy: string | undefined
  modifiedByDisplay: string | undefined
  hideAsOnDate?: boolean
}

function DatapointExplainer(props: DatapointExplainerProps) {
  const { t } = useTranslation('dataEngine')

  const [format, value] = extractDatapointFormatAndValue(props.value)
  const [shadowedFormat, shadowedValue] = extractDatapointFormatAndValue(props.shadowedValue)
  const formattedValue = props.displayAs || formatDatapointValue(format, value)
  const formattedShadowedValue = props.shadowedDisplayAs || formatDatapointValue(shadowedFormat, shadowedValue)

  const asOnDate = formatDatapointValue('NaiveDate', props.asOnDate)
  const modifiedAt = props.modifiedAt && datetimeWithSeconds(props.modifiedAt.toString())
  const modifiedBy = props.modifiedByDisplay || props.modifiedBy

  const showAsOnDate = !props.hideAsOnDate && !!asOnDate
  const showShadowValue = !!value && !!shadowedValue && value !== shadowedValue

  if (!showAsOnDate && !showShadowValue) {
    return null
  }

  return (
    <AlertIcon
      severity="info"
      message={
        <Stack direction="column">
          <Typography variant="subtitle1" sx={{ color: 'inherit', pb: 1 }}>
            {props.datapointName}
          </Typography>

          <Typography variant="inherit" sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap' }}>
            {t('datapoint_details_explainer.value', { value: formattedValue })}
          </Typography>

          {showAsOnDate && (
            <Typography variant="inherit" sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap' }}>
              {t('datapoint_details_explainer.as_on_date', { date: asOnDate })}
            </Typography>
          )}

          {showShadowValue && (
            <>
              <Typography variant="inherit" sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap', pb: 1 }}>
                {t('datapoint_details_explainer.shadowed_value', { value: formattedShadowedValue })}
              </Typography>

              <Typography variant="inherit" sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap' }}>
                {t('datapoint_details_explainer.modified', { username: modifiedBy, modifiedAt: modifiedAt })}
              </Typography>
            </>
          )}
        </Stack>
      }
    />
  )
}

export default DatapointExplainer
