import { Typography } from '@mui/material'
import { Box } from '@mui/system'

type FilterMenuLabelProps = {
  children: string
}

function FilterMenuLabel(props: FilterMenuLabelProps) {
  const { children } = props

  return (
    <Box sx={{ py: 0.5, px: 3 }}>
      <Typography variant="overline" color="gray.700">
        {children}
      </Typography>
    </Box>
  )
}

export default FilterMenuLabel
