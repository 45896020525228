import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useCurrentDatasetsQuery(groupRef?: string) {
  return useQuery(
    ['group', groupRef, 'datasets', 'current'],
    () => {
      if (groupRef) {
        return api.getCurrentDatasets(groupRef)
      }
    },
    {
      enabled: !!groupRef,
    }
  )
}

export default useCurrentDatasetsQuery
