import { useNavigate, useParams } from 'react-router-dom'
import GroupDetails from '../../components/group-details'

const GroupDetailsView = () => {
  const navigate = useNavigate()

  const { groupRef } = useParams()

  if (!groupRef) {
    return null
  }

  return <GroupDetails groupRef={groupRef} onBack={() => navigate('/openfin/views/settings/groups')} />
}

export default GroupDetailsView
