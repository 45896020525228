import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'
import { GridDataViewFilters } from '../../../services/data/types/grid-data-view'

export function useOrderBatchGridQuery(
  orderBatchRef: string | undefined,
  aggregations: string[],
  filters: GridDataViewFilters | null
) {
  return useQuery({
    queryKey: ['order-batch', orderBatchRef, 'grid', aggregations, filters],
    queryFn: () => {
      if (!orderBatchRef) {
        return null
      }
      return api.getOrderBatchGrid(orderBatchRef, aggregations, filters)
    },
    cacheTime: 0,
    keepPreviousData: true,
  })
}
