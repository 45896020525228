import { useNavigate } from 'react-router'
import OrderBlotterRoot from '../../components/order-blotter-root'

function OrderBlotterView() {
  const navigate = useNavigate()

  function handleOrderBatchChange(orderBatchRef: string) {
    navigate(`/order-blotter/${orderBatchRef}`)
  }

  return <OrderBlotterRoot onOrderBatchChange={handleOrderBatchChange} />
}

export default OrderBlotterView
