import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useBankAccountsNavigatorQuery() {
  return useQuery({
    queryKey: ['bank-accounts', 'navigator'],
    queryFn: api.getBankAccountsNavigator,
  })
}

export default useBankAccountsNavigatorQuery
