const tokenKey = 'auth_token'

const session = {
  saveToken: (token: string) => {
    localStorage.setItem(tokenKey, token)
  },
  getToken: (): string | null => {
    return localStorage.getItem(tokenKey)
  },
  clearSession: () => {
    localStorage.removeItem(tokenKey)
  },
}

export default session
