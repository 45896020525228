import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { openView } from '../../../../services/openfin/fin'
import TransactionsGrid from '../../components/transactions-grid'
import usePortfoliosQuery from '../../data/use-portfolios-query'

function TransactionsGridView() {
  const navigate = useNavigate()

  const { portfolioRef } = useParams()

  const portfoliosResponse = usePortfoliosQuery()
  const portfolios = portfoliosResponse.data?.data

  useEffect(() => {
    if (!portfolioRef && portfoliosResponse.isSuccess) {
      const portfolio = portfolios?.[0]
      if (portfolio) {
        navigate(`/openfin/views/transaltions/${portfolio.portfolio_ref}`)
      }
    }
  }, [portfolioRef, portfoliosResponse])

  function handlePortfolioSelect(portfolioRef: string) {
    navigate(`/openfin/views/transactions/${portfolioRef}`)
  }

  function handleCorrectTransaction(transactionRef: string) {
    if (portfolioRef) {
      openView({ viewPath: `openfin/views/trade-ticket/${portfolioRef}/transactions/${transactionRef}/correct` })
    }
  }

  function handleCloseOutTransaction(segmentRef: string) {
    if (portfolioRef) {
      openView({ viewPath: `openfin/views/trade-ticket/${portfolioRef}/transactions/segments/${segmentRef}/close` })
    }
  }

  function handleConfimTrade(dealRef: string) {
    if (portfolioRef) {
      openView({ viewPath: `openfin/views/trade-ticket/${portfolioRef}/transactions/deals/${dealRef}/confirm` })
    }
  }

  function handleNewTrade() {
    openView({ viewPath: `openfin/views/trade-ticket/${portfolioRef}` })
  }

  function handleNewCashflow() {
    openView({ viewPath: `openfin/views/trade-ticket/${portfolioRef}?cashflow` })
  }

  return (
    <TransactionsGrid
      portfolioRef={portfolioRef}
      onPortfolioSelect={handlePortfolioSelect}
      onCorrectTransaction={handleCorrectTransaction}
      onCloseOutTransaction={handleCloseOutTransaction}
      onConfirmTrade={handleConfimTrade}
      onNewTrade={handleNewTrade}
      onNewCashflow={handleNewCashflow}
    />
  )
}

export default TransactionsGridView
