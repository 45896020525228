import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useAvailableDatasetsQuery(groupRef?: string) {
  return useQuery(
    ['group', groupRef, 'datasets', 'available'],
    () => {
      if (groupRef) {
        return api.getAvailableDatasets(groupRef)
      }
    },
    {
      enabled: !!groupRef,
    }
  )
}

export default useAvailableDatasetsQuery
