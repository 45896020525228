import { Cancel, ChevronRight } from '@mui/icons-material'
import { Chip, Stack, Tooltip, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Fragment } from 'react'
import { ParsedGridColumnOption } from '../../../services/data/grid-data-parsing'

type GroupBySectionProps = {
  groups: ParsedGridColumnOption[]
  onToggleGroupLevelVisibility: (groupLevel: number) => void
  onRemoveGroup: (datapointRef: string) => void
}

function GroupBySection(props: GroupBySectionProps) {
  const { groups, onToggleGroupLevelVisibility, onRemoveGroup } = props

  if (groups.length === 0) {
    return null
  }

  return (
    <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', py: 2.5 }}>
      {groups.map((group, index: number) => (
        <Fragment key={group.datapointRef}>
          {index !== 0 && <ChevronRight sx={{ color: 'gray.300' }} />}
          <Chip
            variant="filled"
            size="small"
            onClick={() => onToggleGroupLevelVisibility(index + 1)}
            onDelete={() => onRemoveGroup(group.datapointRef)}
            deleteIcon={<Cancel style={{ color: grey[400] }} />}
            label={
              <Tooltip
                title={group.datapointName !== group.columnName ? group.datapointName : ''}
                arrow
                disableInteractive
              >
                <Typography fontSize={13}>{group.columnName}</Typography>
              </Tooltip>
            }
            sx={{ borderRadius: 'full', color: 'white', backgroundColor: 'gray.main' }}
          />
        </Fragment>
      ))}
    </Stack>
  )
}

export default GroupBySection
