import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { UpdatedGridCell } from '../../../services/data/types/grid-data'
import { UpdateWhatIfPayload } from '../../../services/data/types/what-if'

function useUpdateWhatIfMutation() {
  const response = useMutation<AxiosResponse<UpdatedGridCell[]>, AxiosError, MutationFnParams>({
    mutationFn: ({ portfolioRef, whatifRef, payload, asOfDate }) =>
      api.updateWhatIf(portfolioRef, whatifRef, payload, asOfDate),
  })

  return { ...response, error: parseError(response.error) }
}

type MutationFnParams = {
  portfolioRef: string
  whatifRef: string
  payload: UpdateWhatIfPayload
  asOfDate?: string | null
}

export default useUpdateWhatIfMutation
