import { Chip, Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { TradeTicketResponse } from '../../../services/data/types/trade-ticket'

type SelectCurrencyModalProps = {
  open: boolean
  currencyRef?: string
  currencies: TradeTicketResponse['currencies']
  onClose: () => void
  onSave: (currencyRef: string) => void
}

function SelectCurrencyModal(props: SelectCurrencyModalProps) {
  const { open, currencyRef, currencies, onClose, onSave } = props

  const { t } = useTranslation('tradeTicket')

  const [selectedCurrencyRef, setSelectedCurrencyRef] = useState('')

  const currencyOptions = Object.entries(currencies).sort(([_, aName], [__, bName]) => aName.localeCompare(bName))
  const currencyName = currencyOptions.find(([cRef, _]) => cRef === selectedCurrencyRef)?.[1]

  const canSave = !!selectedCurrencyRef

  useEffect(() => {
    if (open) {
      setSelectedCurrencyRef(currencyRef || '')
    } else {
      setSelectedCurrencyRef('')
    }
  }, [open, currencyRef])

  function handleCurrencyRefChange(event: SelectChangeEvent) {
    setSelectedCurrencyRef(event.target.value)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    onSave(selectedCurrencyRef)
  }

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('currency_modal.title')} onClose={onClose} />
        <ModalContent>
          <FormControl required>
            <InputLabel>{t('common:currency')}</InputLabel>
            <Select
              name="currency"
              label={t('common:currency')}
              value={selectedCurrencyRef}
              onChange={handleCurrencyRefChange}
              renderValue={() => <Chip key={selectedCurrencyRef} label={currencyName} size="small" />}
            >
              {currencyOptions.map(([currencyRef, currencyName]) => (
                <MenuItem key={currencyRef} value={currencyRef}>
                  {currencyName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalActions confirmDisabled={!canSave} confirmLabel={t('common:select')} onCancel={onClose} />
      </form>
    </Dialog>
  )
}

export default SelectCurrencyModal
