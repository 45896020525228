import { Close } from '@mui/icons-material'
import { DialogTitle, IconButton } from '@mui/material'

type ModalTitleProps = {
  title: string
  onClose: () => void
}

function ModalTitle(props: ModalTitleProps) {
  const { title, onClose } = props

  return (
    <DialogTitle
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {title}
      <IconButton onClick={onClose} size="small">
        <Close sx={{ color: 'gray.300' }} />
      </IconButton>
    </DialogTitle>
  )
}

export default ModalTitle
