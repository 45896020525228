import { Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { AgGridReact } from 'ag-grid-react'
import { MouseEvent, RefObject, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CheckboxButton } from '../../../components/buttons/checkbox-button'
import CollapsibleSideNavigation from '../../../components/collapsible-side-navigation'
import ConfirmationModal from '../../../components/confirmation-modal'
import ErrorSnackbar from '../../../components/error-snackbar'
import AppLayout from '../../../components/layouts/app-layout'
import ListItem from '../../../components/list-item'
import ListItemGroup from '../../../components/list-item-group'
import { ReconMatch, ReconRecord, ReconTxn } from '../../../services/data/types/reconciliation'
import useOpenState from '../../../utils/hooks/use-open-state'
import useUserInfo from '../../auth/data/use-user-info'
import usePortfoliosQuery from '../../portfolios/data/use-portfolios-query'
import { SelectedTxns, processGrids } from '../data/reconciliation-data'
import {
  useReconcileTransactionsMutation,
  useReconciliationGridQuery,
  useUnreconcileRecordMutation,
} from '../data/reconciliation-hooks'
import useBankAccountsNavigatorQuery from '../data/use-bank-accounts-navigator-query'
import useCancelTransactionMutation from '../data/use-cancel-transaction-mutation'
import BankAccountsGrid, { AgRowData } from './bank-accounts-grid'
import BankAccountsHeader from './bank-accounts-header'
import BankRecordsUploadModal from './bank-records-upload-modal'
import ContextMenu, { ContextMenuState } from './context-menu'
import EnterBankTransactionModal from './enter-bank-transaction-modal'
import EnterCounterpatySettlementModal from './enter-counterpary-settlement-modal'
import MatchModal from './match-modal'
import PaymentForRecordModal from './payment-for-record-modal'

type BankAccountsRootProps = {
  onBankAccountSelect: (bankAccountId: number, holderLei: string) => void
}

type Layout = 'bank' | 'ledger' | 'reconcile'

export function BankAccountsRoot(props: BankAccountsRootProps) {
  const { onBankAccountSelect } = props

  const { t } = useTranslation('bankAccounts')

  const { allowManualPayments } = useUserInfo()
  const { bankAccountId, holderLei } = useParams()

  const [gridRef, setGridRef] = useState<RefObject<AgGridReact> | null>(null)

  const nav = useOpenState({ open: true })
  const [layout, setLayout] = useState<Layout>('bank')
  const reconciling = layout === 'reconcile'
  const showBankGrid = reconciling || layout === 'bank'
  const showLedgerGrid = reconciling || layout === 'ledger'

  const cancelTransaction = useCancelTransactionMutation()
  const reconcileTransactions = useReconcileTransactionsMutation()
  const unreconcileRecord = useUnreconcileRecordMutation()

  const navigatorQuery = useBankAccountsNavigatorQuery()
  const navigator = navigatorQuery.data?.data || null
  const accounts = navigator?.accounts || []

  const portfoliosQuery = usePortfoliosQuery()
  let portfolios = portfoliosQuery.data?.data || []
  portfolios = portfolios.filter((p) => p.lei === holderLei)

  const gridQuery = useReconciliationGridQuery(bankAccountId)
  const grid = gridQuery.data?.data || null

  const uploadModal = useOpenState()
  const [contextMenu, setContextMenu] = useState<ContextMenuState | null>(null)

  const matchModal = useOpenState()
  const confirmReconcileModal = useOpenState()
  const confirmUnreconcileModal = useOpenState()
  const [hideReconciledRows, setHideReconciledRows] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState<ReconRecord | null>(null)
  const [selectedTxns, setSelectedTxns] = useState<SelectedTxns>({})
  const [matches, setMatches] = useState<Array<ReconMatch>>([])

  const counterpartySettlementModal = useOpenState()
  const bankTransactionModal = useOpenState()
  const [newBankPaymentForRecord, setNewBankPaymentForRecord] = useState<ReconRecord | null>(null)
  const [editBankTxnRef, setEditBankTxnRef] = useState<string | null>(null)
  const [editSettlementRef, setEditSettlementTxnRef] = useState<string | null>(null)

  useEffect(() => {
    if (matches.length === 0) {
      setMatches(grid?.matches || [])
    }
  }, [grid])

  const [bankGrid, ledgerGrid] = useMemo(
    () => processGrids(grid, selectedRecord, selectedTxns, matches, hideReconciledRows, reconciling),
    [grid, selectedRecord, selectedTxns, matches, hideReconciledRows, reconciling]
  )

  const canMatch = !!selectedRecord && !!Object.keys(selectedTxns).length
  const canReconcile = !!matches.length

  const isLoading = gridQuery.isFetching || cancelTransaction.isLoading

  useEffect(() => {
    if (!bankAccountId && navigatorQuery.isSuccess) {
      const account = accounts[0]
      if (account) {
        onBankAccountSelect(account.account_id, account.holder_lei)
      }
    }
  }, [bankAccountId, navigatorQuery])

  function reloadData() {
    setSelectedRecord(null)
    setSelectedTxns({})
    navigatorQuery.refetch()
    gridQuery.refetch()
  }

  function handleSelectGridRow(record: ReconRecord | null, txn: ReconTxn | null, checked: boolean) {
    if (record) {
      if (checked) {
        setSelectedRecord(record)
      } else {
        setSelectedRecord(null)
      }
    }

    if (txn) {
      if (checked) {
        setSelectedTxns((prev) => {
          return { ...prev, [txn.ext_segment_ref]: txn }
        })
      } else {
        setSelectedTxns((prev) => {
          const update = { ...prev }
          delete update[txn.ext_segment_ref]
          return update
        })
      }
    }
  }

  function handleContextMenuOpen(event: MouseEvent, cell: AgRowData) {
    event.preventDefault()

    const recordId = cell.record?.record_id || null
    const extSegmentRef = cell.txn?.ext_segment_ref || null
    const record = grid?.records.find((r) => r.record_id === recordId)

    const canUnmatchRecord = reconciling && !!recordId && matches.some((m) => m.record_id === recordId)
    const canUnmatchTxn =
      reconciling && !!extSegmentRef && matches.some((m) => m.txns.some((t) => t.ext_segment_ref === extSegmentRef))
    const canUnmatch = canUnmatchRecord || canUnmatchTxn
    const canUnreconcile = reconciling && !!record?.is_reconciled
    const canCreateBankPayment = reconciling && !canUnmatch && !!record && !record.is_reconciled

    // const canEdit = !recordId && !!cell.txn && !cell.txn.is_reconciled
    const canEdit = false
    const canCancel = canEdit

    const editTxn =
      cell.txn && canEdit
        ? {
            txnRef: cell.txn.txn_ref,
            isBankTxn: cell.txn.is_bank_txn,
          }
        : null

    setContextMenu({
      coordinates: {
        mouseX: event.clientX,
        mouseY: event.clientY,
      },
      editTxn,
      recordId,
      extSegmentRef,
      canEdit,
      canCancel,
      canUnmatch,
      canUnreconcile,
      canCreateBankPayment,
    })
  }

  function handleCancelTransaction() {
    if (bankAccountId && contextMenu?.editTxn) {
      const txnRef = contextMenu.editTxn.txnRef
      setContextMenu(null)

      cancelTransaction.mutate(
        { bankAccountId, txnRef },
        {
          onSuccess: () => gridQuery.refetch(),
        }
      )
    }
  }

  function handleEditTransaction() {
    if (contextMenu?.editTxn) {
      if (contextMenu.editTxn.isBankTxn) {
        setEditBankTxnRef(contextMenu.editTxn.txnRef)
        bankTransactionModal.open()
      } else {
        setEditSettlementTxnRef(contextMenu.editTxn.txnRef)
        counterpartySettlementModal.open()
      }
      setContextMenu(null)
    }
  }

  function handleUnmatchTxn() {
    const recordId = contextMenu?.recordId || null
    const extSegmentRef = contextMenu?.extSegmentRef || null
    setContextMenu(null)

    setMatches((prevMatches) => {
      return prevMatches
        .map((prevMatch) => {
          const foundSingleRecord = recordId && prevMatch.record_id == recordId
          const removeTxnFromAllRecords = !recordId

          // when create bank txn match
          if (!extSegmentRef && foundSingleRecord) {
            const newMatch = window.structuredClone(prevMatch)
            newMatch.txns = []
            return newMatch
          } else if (foundSingleRecord || removeTxnFromAllRecords) {
            const newMatch = window.structuredClone(prevMatch)
            newMatch.txns = newMatch.txns.filter((t) => t.ext_segment_ref !== extSegmentRef)
            return newMatch
          }

          return prevMatch
        })
        .filter((match) => {
          return match.txns.length > 0
        })
    })
  }

  function handleUnreconcileTxn() {
    const recordId = contextMenu?.recordId || null
    setContextMenu(null)

    if (!bankAccountId || !recordId) {
      return
    }

    unreconcileRecord.mutate(
      { bankAccountId, recordId },
      {
        onSuccess: () => {
          reloadData()
          confirmUnreconcileModal.close()
        },
      }
    )
  }

  function handleCreateBankTxnForRecord() {
    if (!contextMenu || !grid) {
      return
    }

    const recordId = contextMenu.recordId
    if (!recordId) {
      return
    }

    const record = grid.records.find((r) => r.record_id === recordId)
    if (!record) {
      return
    }

    if (record.amount_reconciled !== 0) {
      console.error('Impossible: record is partially reconciled')
      return
    }

    setNewBankPaymentForRecord(record)
    setContextMenu(null)
  }

  function handleCreatePaymentForRecord(match: ReconMatch) {
    if (!bankAccountId) {
      return
    }

    reconcileTransactions.mutate(
      {
        bankAccountId,
        payload: {
          matches: [match],
        },
      },
      {
        onSuccess: () => {
          reloadData()
          handleClosePaymentForRecordModal()
        },
      }
    )
  }

  function handleClosePaymentForRecordModal() {
    setNewBankPaymentForRecord(null)
    reconcileTransactions.reset()
  }

  function handleSaveMatch(newMatch: ReconMatch): void {
    matchModal.close()
    setSelectedRecord(null)
    setSelectedTxns({})

    setMatches((prevMatches) => {
      const matchesUpdate = window.structuredClone(prevMatches)
      const recordMatch = matchesUpdate.find((p) => p.record_id === newMatch.record_id)

      if (recordMatch) {
        // record match already exists
        for (const newTxnMatch of newMatch.txns) {
          const existingRecordTxn = recordMatch.txns.find((t) => t.ext_segment_ref === newTxnMatch.ext_segment_ref)

          if (existingRecordTxn) {
            // merge txn amount
            existingRecordTxn.amount += newTxnMatch.amount
          } else {
            // add new txn match
            recordMatch.txns.push(newTxnMatch)
          }
        }
      } else {
        // new record match
        matchesUpdate.push(newMatch)
      }

      return matchesUpdate
    })
  }

  function reconcileMatchedTxns() {
    if (!bankAccountId) {
      return
    }

    reconcileTransactions.mutate(
      {
        bankAccountId,
        payload: {
          matches: matches,
        },
      },
      {
        onSuccess: () => {
          confirmReconcileModal.close()
          setMatches([])
          reloadData()
        },
      }
    )
  }

  if (!bankAccountId) {
    if (navigatorQuery.isSuccess && accounts.length === 0) {
      return (
        <AppLayout direction="row">
          <Box sx={{ p: 2 }}>{t('no_bank_accounts')}</Box>
        </AppLayout>
      )
    }
    return null
  }

  return (
    <>
      <AppLayout direction="row">
        <CollapsibleSideNavigation title={t('navigation.title')} open={nav.isOpen} onClose={nav.close}>
          <ListItemGroup isDefaultOpen title={t('navigation.accounts')}>
            {accounts.map((account) => (
              <ListItem
                key={account.account_id}
                title={account.account_name}
                isSelected={String(account.account_id) === bankAccountId}
                onClick={() => {
                  setMatches([])
                  setSelectedRecord(null)
                  setSelectedTxns({})
                  onBankAccountSelect(account.account_id, account.holder_lei)
                }}
              />
            ))}
          </ListItemGroup>
        </CollapsibleSideNavigation>

        <Stack flex={1} overflow="hidden">
          <BankAccountsHeader
            title={bankGrid?.account_name || ''}
            currency={bankGrid?.currency || ''}
            showLoading={isLoading}
            isNavOpen={nav.isOpen}
            allowManualPayments={allowManualPayments}
            onOpenNavClick={nav.open}
            onNewCounterpartyTransfer={counterpartySettlementModal.open}
            onNewBankTransfer={bankTransactionModal.open}
            onUploadClick={uploadModal.open}
            onReloadData={reloadData}
          />

          <Stack sx={{ flexDirection: 'row', height: '100%', p: 2, gap: 1 }}>
            <Stack sx={{ flex: 1, gap: 2 }}>
              <Stack direction="row" sx={{ height: 35, gap: 2 }}>
                <ToggleButtonGroup
                  size="small"
                  value={layout}
                  exclusive
                  onChange={(_, newLayout) => {
                    if (newLayout) {
                      setLayout(newLayout)
                    }
                  }}
                >
                  <ToggleButton value="bank">{t('header.bank_records')}</ToggleButton>
                  <ToggleButton value="ledger">{t('header.ledger_txns')}</ToggleButton>
                  <ToggleButton value="reconcile">{t('header.reconcile')}</ToggleButton>
                </ToggleButtonGroup>

                {reconciling && (
                  <>
                    <Button
                      size="small"
                      color="info"
                      variant={canMatch ? 'contained' : 'outlined'}
                      disableElevation
                      disabled={!canMatch}
                      onClick={matchModal.open}
                    >
                      {t('header.match')}
                    </Button>
                    <Button
                      size="small"
                      variant={canReconcile ? 'contained' : 'outlined'}
                      disableElevation
                      disabled={!canReconcile}
                      onClick={confirmReconcileModal.open}
                    >
                      {t('header.reconcile') + (matches.length ? ` (${matches.length})` : '')}
                    </Button>
                  </>
                )}

                <CheckboxButton
                  checked={hideReconciledRows}
                  label={t('header.hide_reconciled')}
                  onChange={(checked) => {
                    setHideReconciledRows(checked)
                  }}
                  sx={{ ml: 'auto' }}
                />
              </Stack>

              <BankAccountsGrid
                grid={bankGrid}
                hide={!showBankGrid}
                onContextMenuOpen={handleContextMenuOpen}
                onForwardGridRef={setGridRef}
                onSelectRowChange={handleSelectGridRow}
              />
              <BankAccountsGrid
                grid={ledgerGrid}
                hide={!showLedgerGrid}
                onContextMenuOpen={handleContextMenuOpen}
                onForwardGridRef={setGridRef}
                onSelectRowChange={handleSelectGridRow}
              />
            </Stack>
          </Stack>
        </Stack>
      </AppLayout>

      <BankRecordsUploadModal
        open={uploadModal.isOpen}
        onSucess={() => {
          uploadModal.close()
          reloadData()
        }}
        onClose={uploadModal.close}
      />

      <EnterCounterpatySettlementModal
        open={counterpartySettlementModal.isOpen}
        bankAccountId={bankAccountId}
        txnRef={editSettlementRef}
        onSaved={() => {
          reloadData()
        }}
        onClose={() => {
          setEditSettlementTxnRef(null)
          counterpartySettlementModal.close()
        }}
      />
      <EnterBankTransactionModal
        open={bankTransactionModal.isOpen}
        bankAccountId={bankAccountId}
        txnRef={editBankTxnRef}
        portfolios={portfolios}
        onSaved={() => {
          reloadData()
        }}
        onClose={() => {
          setEditBankTxnRef(null)
          bankTransactionModal.close()
        }}
      />

      <MatchModal
        open={!!matchModal.isOpen}
        record={selectedRecord}
        transactions={selectedTxns}
        matches={matches}
        onSave={handleSaveMatch}
        onClose={matchModal.close}
      />
      <PaymentForRecordModal
        key={newBankPaymentForRecord?.record_id}
        open={!!newBankPaymentForRecord}
        record={newBankPaymentForRecord}
        portfolios={portfolios}
        error={reconcileTransactions.error}
        onCreate={handleCreatePaymentForRecord}
        onClose={handleClosePaymentForRecordModal}
      />

      <ConfirmationModal
        title={t('confirm_reconciliation_modal.title')}
        confirmButtonText={t('confirm_reconciliation_modal.button_label')}
        open={confirmReconcileModal.isOpen}
        error={reconcileTransactions.error}
        isLoading={reconcileTransactions.isLoading}
        onCloseButtonClick={confirmReconcileModal.close}
        onConfirmButtonClick={reconcileMatchedTxns}
      >
        <Typography variant="body1">{t('confirm_reconciliation_modal.message')}</Typography>
      </ConfirmationModal>
      <ConfirmationModal
        title={t('confirm_unreconciliation_modal.title')}
        confirmButtonText={t('confirm_unreconciliation_modal.button_label')}
        open={confirmUnreconcileModal.isOpen}
        error={unreconcileRecord.error}
        isLoading={unreconcileRecord.isLoading}
        onCloseButtonClick={confirmUnreconcileModal.close}
        onConfirmButtonClick={handleUnreconcileTxn}
      >
        <Typography variant="body1">{t('confirm_unreconciliation_modal.message')}</Typography>
      </ConfirmationModal>

      <ContextMenu
        state={contextMenu}
        onEdit={handleEditTransaction}
        onCancel={handleCancelTransaction}
        onUnmatch={handleUnmatchTxn}
        onUnreconcile={confirmUnreconcileModal.open}
        onCreateBankTxn={handleCreateBankTxnForRecord}
        onCopy={() => gridRef?.current?.api.copySelectedRangeToClipboard()}
        onClose={() => setContextMenu(null)}
      />

      <ErrorSnackbar open={gridQuery.isError} message={gridQuery.error} />
      <ErrorSnackbar
        open={cancelTransaction.isError}
        message={cancelTransaction.error}
        onClose={() => cancelTransaction.reset()}
      />
    </>
  )
}
