import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { CreatePlacementParams } from '../../../services/data/types/order'

export function useCreatePlacementMutation() {
  const response = useMutation<AxiosResponse, AxiosError, CreatePlacementParams>({
    mutationFn: (params) => api.createPlacement(params),
  })
  return { ...response, error: parseError(response.error) }
}
