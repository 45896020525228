import { Stack } from '@mui/system'
import { ReactNode } from 'react'

type PageHeaderContainerProps = {
  children: ReactNode
}

function PageHeaderContainer({ children }: PageHeaderContainerProps) {
  return (
    <Stack
      direction="row"
      sx={{
        px: 2,
        py: 0.5,
        gap: 2,
        minHeight: 56,
        alignItems: 'center',
        borderBottom: '1px solid',
        borderColor: 'divider',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </Stack>
  )
}

export default PageHeaderContainer
