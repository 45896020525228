import { RemoveCircleOutline } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import {
  Avatar,
  Chip,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import TableContainerHeader from '../../../components/table-container-header'
import { queryClient } from '../../../services/data/react-query'
import { Member } from '../../../services/data/types/group'
import useOpenState from '../../../utils/hooks/use-open-state'
import useRemoveUserFromGroupMutation from '../data/use-remove-user-from-group-mutation'
import AddUserToGroupModal from './add-user-to-group-modal'
import EditUserInGroupSectionModal from './edit-user-in-group-section-modal'

type GroupMembersSectionProps = {
  groupMembers: Member[]
  groupRef: string
  groupName: string
}

function GroupMembersSection(props: GroupMembersSectionProps) {
  const { groupMembers, groupRef, groupName } = props

  const addUserToGroupModal = useOpenState()
  const editUserRoleInGroupModal = useOpenState()
  const deleteUserFromGroupModal = useOpenState()

  const { t } = useTranslation('settings')

  const {
    mutate: removeUserFromGroup,
    error: removeUserFromGroupError,
    reset: resetRemoveUserFromGroupStatus,
  } = useRemoveUserFromGroupMutation()

  const [selectedMember, setSelectedMember] = useState<Member | null>(null)

  function handleEditClick(member: Member) {
    setSelectedMember(member)
    editUserRoleInGroupModal.open()
  }

  function handleDeleteClick(member: Member) {
    setSelectedMember(member)
    deleteUserFromGroupModal.open()
  }

  function handleRemoveGroupModalClose() {
    setSelectedMember(null)
    resetRemoveUserFromGroupStatus()
    deleteUserFromGroupModal.close()
  }

  function handleRemoveGroup() {
    if (selectedMember) {
      if (!!selectedMember.user_ref && !!groupRef) {
        const removeUserFromGroupParams = {
          groupRef,
          userRef: selectedMember.user_ref,
        }
        removeUserFromGroup(removeUserFromGroupParams, {
          onSuccess: () => {
            handleRemoveGroupModalClose()
            queryClient.invalidateQueries(['settings', 'groups', groupRef])
          },
        })
      }
    }
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <TableContainerHeader
          title={t('user_details_page.group_members_table.title')}
          action={
            <IconButton onClick={addUserToGroupModal.open}>
              <AddIcon />
            </IconButton>
          }
        />
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell>{t('user_details_page.group_members_table.group_name')}</TableCell>
              <TableCell>{t('user_details_page.group_members_table.role')}</TableCell>
              <TableCell sx={{ width: 64 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {groupMembers?.map((member) => {
              const memberStatus = member.is_group_admin ? t('admin') : t('user')
              return (
                <TableRow hover key={member.user_ref}>
                  <TableCell>
                    <Chip
                      avatar={<Avatar>{`${member.firstname[0]}${member.lastname[0]}`}</Avatar>}
                      label={`${member.firstname} ${member.lastname}`}
                      sx={{
                        color: 'inherit',
                        cursor: 'inherit',
                        '& .MuiChip-avatar': {
                          color: 'gray.light',
                          backgroundColor: 'gray.400',
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell>{memberStatus}</TableCell>
                  <TableCell className="action-cell" align="right">
                    <Stack direction="row" gap={1} justifyContent="flex-end">
                      <Tooltip title={t('edit')} arrow disableInteractive>
                        <IconButton onClick={() => handleEditClick(member)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('remove')} arrow disableInteractive>
                        <IconButton onClick={() => handleDeleteClick(member)}>
                          <RemoveCircleOutline />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <AddUserToGroupModal
        isOpen={addUserToGroupModal.isOpen}
        groupRef={groupRef}
        groupMembers={groupMembers}
        onClose={addUserToGroupModal.close}
      />
      <EditUserInGroupSectionModal
        isOpen={editUserRoleInGroupModal.isOpen}
        groupRef={groupRef}
        member={selectedMember || null}
        onClose={editUserRoleInGroupModal.close}
        setSelectedMember={setSelectedMember}
      />

      <ConfirmationModal
        error={removeUserFromGroupError}
        title={t('groups.remove_group_membership')}
        confirmButtonText={t('common:remove')}
        open={deleteUserFromGroupModal.isOpen}
        onCloseButtonClick={handleRemoveGroupModalClose}
        onConfirmButtonClick={handleRemoveGroup}
      >
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey="groups.are_you_sure_to_remove_group"
            values={{
              subject: `${selectedMember?.firstname} ${selectedMember?.lastname}`,
              target: groupName,
            }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </ConfirmationModal>
    </>
  )
}

export default GroupMembersSection
