import { AccountCircle } from '@mui/icons-material'
import { Box, Button, InputAdornment, Link, Stack, TextField } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import AuthLayout from '../../../components/layouts/auth-layout'
import useForgotPasswordMutation, { ForgotPasswordErrorCode } from '../../settings/data/use-forgot-password-mutation'
import AuthCard from '../components/auth-card'

type ForgotPasswordProps = {
  onSuccess: () => void
  onBack: () => void
}

function ForgotPassword({ onSuccess, onBack }: ForgotPasswordProps) {
  const { t } = useTranslation('auth')
  const { mutate: forgotPassword } = useForgotPasswordMutation()
  const [error, setError] = useState<string | null>(null)

  function clearError() {
    setError(null)
  }

  async function handleOnSubmit(event: any) {
    event.preventDefault()
    clearError()

    const username = event.target.elements.username.value

    if (!username) {
      setError(t('fill_username'))
      return
    }

    forgotPassword(
      { username },
      {
        onSuccess,
        onError: (error) => {
          const errorCode = error.response?.data?.error.code
          if (errorCode === ForgotPasswordErrorCode.USER_DOES_NOT_EXIST) {
            setError(t('invalid_username'))
          } else {
            setError(t('common:general_error_message'))
          }
        },
      }
    )
  }

  return (
    <AuthLayout>
      <Box maxWidth="560px" marginX="auto">
        <Box minHeight={70} marginBottom={2}>
          <Alert severity="error" message={error} onClose={clearError} />
        </Box>
        <AuthCard title={t('fogot_password_title')}>
          <form onSubmit={handleOnSubmit}>
            <Stack spacing={3}>
              <TextField
                required
                autoFocus
                id="username"
                name="username"
                placeholder={t('username')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
              <Stack direction="row-reverse" pt={4} justifyContent="space-between" alignItems="center">
                <Button type="submit" variant="contained">
                  {t('common:reset')}
                </Button>
                <Link
                  component="button"
                  onClick={onBack}
                  underline="hover"
                  sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px' }}
                >
                  {t('common:back')}
                </Link>
              </Stack>
            </Stack>
          </form>
        </AuthCard>
      </Box>
    </AuthLayout>
  )
}

export default ForgotPassword
