import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { StaticUploadParams } from '../../../services/data/types/asset-static-data'

export default function useStaticUploadVerifyFileMutation() {
  const response = useMutation<AxiosResponse, AxiosError, Params>({
    mutationFn: ({ params, file }) => api.staticUploadVerifyFile(params, file),
  })

  return { ...response, error: parseError(response.error) }
}

type Params = {
  params: StaticUploadParams
  file: File
}
