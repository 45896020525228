import { useParams } from 'react-router-dom'
import ClassificationDetails from '../../components/classification-details'

function ClassificationDetailsFlexView() {
  const { classificationId } = useParams()

  if (!classificationId) {
    return null
  }

  return <ClassificationDetails classificationId={classificationId} />
}

export default ClassificationDetailsFlexView
