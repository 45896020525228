import { Dialog, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { TradeTicketResponse } from '../../../services/data/types/trade-ticket'
import useRequestAssetImportMutation from '../data/use-request-asset-import-mutation'

type RequestAssetImportModalProps = {
  open: boolean
  identifiers: TradeTicketResponse['identifier_types']
  onSucess: (importPending: boolean) => void
  onClose: () => void
}

function RequestAssetImportModal(props: RequestAssetImportModalProps) {
  const { open, identifiers, onSucess, onClose } = props

  const { t } = useTranslation('dataEngine')

  const requestMutation = useRequestAssetImportMutation()

  const [identifier, setIdentifier] = useState('')
  const [securities, setSecurities] = useState('')

  const isFormValid = !!identifier && !!securities

  function handleClose() {
    onClose()
    setIdentifier('')
    setSecurities('')
    requestMutation.reset()
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (!isFormValid) {
      return
    }

    const identifiers = securities
      .split('\n')
      .map((s) => s.trim())
      .filter(Boolean)

    requestMutation.mutate(
      {
        identifier_datapoint_ref: identifier,
        identifiers,
      },
      {
        onSuccess: (data) => {
          onSucess(data.data.import_pending)
          handleClose()
        },
      }
    )
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('asset_imports.modal_title')} onClose={handleClose} />

        <ModalContent>
          <Alert severity="error" message={requestMutation.error} />

          <FormControl>
            <InputLabel>{t('asset_imports.identifier')}</InputLabel>
            <Select
              required
              name="identifier"
              label={t('asset_imports.identifier')}
              value={identifier}
              onChange={(event) => setIdentifier(event.target.value)}
            >
              {identifiers.map((identifier) => (
                <MenuItem key={identifier.identifier_type} value={identifier.identifier_type}>
                  {identifier.identifier_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            multiline
            rows={10}
            name="securities"
            label={t('asset_imports.securities')}
            value={securities}
            onChange={(event) => setSecurities(event.target.value)}
          />
        </ModalContent>

        <ModalActions
          confirmLabel={t('common:add')}
          confirmDisabled={!isFormValid}
          isLoading={requestMutation.isLoading}
          onCancel={handleClose}
        />
      </form>
    </Dialog>
  )
}

export default RequestAssetImportModal
