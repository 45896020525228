import { Dialog, FormControl, TextField } from '@mui/material'
import { FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { ParsedGridDataHeading } from '../../services/data/grid-data-parsing'
import ModalActions from '../modal-actions'
import ModalContent from '../modal-content'
import ModalTitle from '../modal-title'

type ColumnHeaderNameModalProps = {
  open: boolean
  heading: ParsedGridDataHeading
  onSave: (name: string) => void
  onClose: () => void
}

function ColumnHeaderNameModal(props: ColumnHeaderNameModalProps) {
  const { open, heading, onSave, onClose } = props

  const { t } = useTranslation('dataTable')

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const columnName = String(data.get('columnName') || '')

    onSave(columnName)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('header_modal.title')} onClose={onClose} />
        <ModalContent>
          <FormControl>
            <TextField
              autoFocus
              name="columnName"
              label={t('header_modal.column_name_label')}
              defaultValue={heading.title}
              placeholder={heading.meta.datapoint_name}
            />
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('common:save')} onCancel={onClose} />
      </form>
    </Dialog>
  )
}

export default ColumnHeaderNameModal
