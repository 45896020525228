import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { PortfolioPayload } from '../../../services/data/types/portfolio'

function useEditPortfolioMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, EditPortfolioError, CreatePortfolioParams>({
    mutationFn: ({ portfolioPayload, portfolioRef }) => api.editPortfolio(portfolioPayload, portfolioRef),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type CreatePortfolioParams = {
  portfolioPayload: PortfolioPayload
  portfolioRef: string
}

export type EditPortfolioError = AxiosError<
  | {
      error: {
        code: EditPortfolioErrorCode
        msg: string
      }
    }
  | undefined
>

export enum EditPortfolioErrorCode {
  INVALID_POT_TAG = '0x0002F101',
}

export default useEditPortfolioMutation
