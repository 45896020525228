import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useCompleteAssetImportMutation() {
  const response = useMutation({
    mutationFn: ({ importId }: Params) => api.completeAssetImport(importId),
  })

  return { ...response, error: parseError(response.error as AxiosError) }
}

type Params = {
  importId: number
}

export default useCompleteAssetImportMutation
