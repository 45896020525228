import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useClassificationValuesMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, MutationFunctionProps>({
    mutationFn: ({ classificationId, valueIds }) => api.reorderClassificationValues(classificationId, valueIds),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type MutationFunctionProps = {
  classificationId: string
  valueIds: number[]
}

export default useClassificationValuesMutation
