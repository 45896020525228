import { ChevronRight } from '@mui/icons-material'
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Link,
  Paper,
  Stack,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DetailTable, DetailTableRow } from '../../../components/detail-table'
import AppLayout from '../../../components/layouts/app-layout'
import PageHeaderContainer from '../../../components/page-header-container'
import TableContainerHeader from '../../../components/table-container-header'
import { formatDatapoint } from '../../../services/data/datapoint-formatting'
import useSecurityDetailsQuery from '../data/use-security-details-query'

type SecurityDetailsProps = {
  hidePageHeader?: boolean
  portfolioName?: string
  onNavigateToPortfolio: () => void
}

function SecurityDetails(props: SecurityDetailsProps) {
  const { hidePageHeader, portfolioName, onNavigateToPortfolio } = props

  const { t } = useTranslation('portfolio')

  const { assetRef } = useParams()
  const securityDetailsResponse = useSecurityDetailsQuery(assetRef!)
  const securityDetails = securityDetailsResponse.data?.data

  if (securityDetailsResponse.isLoading) {
    return (
      <Stack
        sx={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'gray.light',
        }}
      >
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <AppLayout>
      {!hidePageHeader && (
        <PageHeaderContainer>
          {portfolioName && (
            <Breadcrumbs separator={<ChevronRight />}>
              <Link
                variant="h6"
                underline="hover"
                color="gray.700"
                onClick={onNavigateToPortfolio}
                sx={{ cursor: 'pointer' }}
              >
                {portfolioName}
              </Link>
              <Typography variant="h6">{t('security_details.title')}</Typography>
            </Breadcrumbs>
          )}
        </PageHeaderContainer>
      )}
      <Box p={3} overflow="auto">
        <TableContainer component={Paper}>
          <TableContainerHeader title={t('security_details.title')} />
          <Typography pb={2} px={1.5}>
            {securityDetails?.asset_desc}
          </Typography>
          <DetailTable>
            <TableBody>
              {securityDetails?.fields.map((field: any) => {
                return (
                  <DetailTableRow label={field.datapoint_name} key={field.datapoint_ref}>
                    <Typography variant="body1" sx={{ lineHeight: 2 }}>
                      {formatDatapoint(field.value)}
                    </Typography>
                  </DetailTableRow>
                )
              })}
            </TableBody>
          </DetailTable>
        </TableContainer>
      </Box>
    </AppLayout>
  )
}

export default SecurityDetails
