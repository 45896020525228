import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useDeleteModelMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, DeleteModelParams>({
    mutationFn: ({ modelRef }) => api.deleteModel(modelRef),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type DeleteModelParams = {
  modelRef: string
}

export default useDeleteModelMutation
