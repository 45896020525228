import { ThemeProvider } from '@mui/material'
import { QueryClientProvider } from '@tanstack/react-query'
import { Analytics } from '@vercel/analytics/react'
import { ReactNode } from 'react'
import { AuthProvider } from './modules/auth/data/auth-context'
import ErrorBoundary from './modules/error/components/error-boundary'
import { queryClient } from './services/data/react-query'
import { muiTheme } from './styles/theme'

export default function AppProviders({ children }: { children: ReactNode }) {
  return (
    <>
      <ThemeProvider theme={muiTheme}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>{children}</AuthProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </ThemeProvider>
      <Analytics />
    </>
  )
}
