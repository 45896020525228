import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { queryClient } from '../../../../services/data/react-query'
import { ChannelEvent, connectAuthChannel, openView } from '../../../../services/openfin/fin'
import PortfolioGrid from '../../components/portfolio-grid'
import usePortfoliosQuery from '../../data/use-portfolios-query'

function PortfoliosGridView() {
  const { t } = useTranslation('portfolio')
  const navigate = useNavigate()

  const { portfolioRef } = useParams()

  const portfoliosResponse = usePortfoliosQuery()
  const portfolios = portfoliosResponse.data?.data

  useEffect(() => {
    document.title = t('navigation.title')
  }, [])

  useEffect(() => {
    connectAuthChannel().then((client) => {
      client.register(ChannelEvent.tenantSwitched, () => {
        queryClient.resetQueries(['portfolio'])
        navigate(`/openfin/views/portfolios/grid`)
      })
    })
  }, [])

  useEffect(() => {
    if (!portfolioRef && portfoliosResponse.isSuccess) {
      const portfolio = portfolios?.[0]
      if (portfolio) {
        navigate(`/openfin/views/portfolios/grid/${portfolio.portfolio_ref}`)
      }
    }
  }, [portfolioRef, portfoliosResponse])

  function handlePortfolioSelect(portfolioRef: string) {
    navigate(`/openfin/views/portfolios/grid/${portfolioRef}`)
  }

  function handleSecuritySelect(assetRef: string) {
    openView({ viewPath: `openfin/views/portfolios/${portfolioRef}/${assetRef}` })
  }

  function handleLiquiditySelect(assetRef: string) {
    openView({ viewPath: `openfin/views/liquidity?assetRef=${assetRef}` })
  }

  function handleNewTrade() {
    openView({ viewPath: `openfin/views/trade-ticket/${portfolioRef}` })
  }

  function handleNewCashflow() {
    openView({ viewPath: `openfin/views/trade-ticket/${portfolioRef}?cashflow` })
  }

  function handleClosePosition(yRef: string, aggregations: string[], datasetRef: string | null) {
    let url = `openfin/views/trade-ticket/${portfolioRef}/y-ref/${encodeURIComponent(yRef)}/close-out-position`
    const params = []

    if (aggregations.length) {
      params.push(`aggregations=${aggregations.join(',')}`)
    }

    if (datasetRef) {
      params.push(`datasetRef=${datasetRef}`)
    }

    if (params.length) {
      url += `?${params.join('&')}`
    }

    openView({ viewPath: url })
  }

  function handleOpenCompliance() {
    openView({ viewPath: `openfin/views/compliance/${portfolioRef}` })
  }

  return (
    <PortfolioGrid
      portfolioRef={portfolioRef}
      onPortfolioSelect={handlePortfolioSelect}
      onSecuritySelect={handleSecuritySelect}
      onLiquiditySelect={handleLiquiditySelect}
      onNewTrade={handleNewTrade}
      onNewCashflow={handleNewCashflow}
      onClosePosition={handleClosePosition}
      onOpenCompliance={handleOpenCompliance}
    />
  )
}

export default PortfoliosGridView
