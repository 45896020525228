import { Close, ContentCopy, PushPin } from '@mui/icons-material'
import { ClickAwayListener, IconButton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { CellExplainer } from '../../services/data/types/grid-data'
import { copyToClipboard } from '../../utils/clipboard'
import useOpenState from '../../utils/hooks/use-open-state'
import AlertIcon from '../alert-icon'

type DatapointExplainerProps = {
  explainer: CellExplainer
}

export function DatapointExplainer(props: DatapointExplainerProps) {
  const { explainer } = props

  const { t } = useTranslation()

  const tooltipFixed = useOpenState()

  return (
    <AlertIcon
      severity="info"
      tooltipFixed={tooltipFixed.isOpen}
      tooltipPlacement="left-start"
      tooltipOffset={[25, -15]}
      onTriangleClick={(event) => {
        // prevent other tooltips from closing when more than one is open
        event.stopPropagation()
        tooltipFixed.toggle()
      }}
      message={
        <ClickAwayListener onClickAway={tooltipFixed.close}>
          <Stack
            onContextMenu={(event) => {
              // allows right click on tooltip without interfering with the grid
              event.stopPropagation()
            }}
          >
            <Stack direction="row" justifyContent="center" pb={2} gap={4}>
              <Typography variant="subtitle1" color="inherit" mr="auto">
                {explainer.title}
              </Typography>

              <Stack direction="row" gap={0.5}>
                <IconButton
                  size="small"
                  color="inherit"
                  title={t('common:copy')}
                  onClick={async () => {
                    const content = explainer.title + '\n\n' + explainer.body
                    await copyToClipboard(content)
                  }}
                >
                  <Stack alignItems="center" justifyContent="center" width="20px" height="20px">
                    <ContentCopy sx={{ fontSize: '16px' }} />
                  </Stack>
                </IconButton>
                <IconButton
                  size="small"
                  color="inherit"
                  title={t('common:pin')}
                  onClick={(event) => {
                    // prevent other tooltips from closing when more than one is open
                    event.stopPropagation()
                    tooltipFixed.toggle()
                  }}
                >
                  <Stack alignItems="center" justifyContent="center" width="20px" height="20px">
                    {tooltipFixed.isOpen ? <Close sx={{ fontSize: '16px' }} /> : <PushPin sx={{ fontSize: '16px' }} />}
                  </Stack>
                </IconButton>
              </Stack>
            </Stack>

            <Typography variant="inherit" sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap' }}>
              {explainer.body}
            </Typography>
          </Stack>
        </ClickAwayListener>
      }
    />
  )
}
