import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { CorrectCustodianTransactionPayload } from '../../../services/data/types/bank-accounts'

function useCorrectCustodianTransactionMutation() {
  const response = useMutation<AxiosResponse, AxiosError, MutationParams>({
    mutationFn: ({ bankAccountId, txnRef, payload }) => {
      return api.correctCustodianTransaction(bankAccountId, txnRef, payload)
    },
  })

  return { ...response, error: parseError(response.error) }
}

type MutationParams = {
  bankAccountId: string
  txnRef: string
  payload: CorrectCustodianTransactionPayload
}

export default useCorrectCustodianTransactionMutation
