import { Add, DeleteOutline, Edit, MoreVert } from '@mui/icons-material'
import {
  Box,
  Chip,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { MouseEvent, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AlertIcon from '../../../components/alert-icon'
import ErrorSnackbar from '../../../components/error-snackbar'
import TableContainerHeader from '../../../components/table-container-header'
import { queryClient } from '../../../services/data/react-query'
import { Classification } from '../../../services/data/types/classifications'
import { Datapoint } from '../../../services/data/types/datapoint'
import { DatapointAlias } from '../../../services/data/types/datapoint-options'
import { Model } from '../../../services/data/types/models'
import useOpenState from '../../../utils/hooks/use-open-state'
import useClassificationsQuery from '../data/use-classifications-query'
import useDatapointOptionsQuery from '../data/use-datapoint-options-query'
import useDatapointsQuery from '../data/use-datapoints-query'
import useDatasetQuery from '../data/use-dataset-query'
import useModelsQuery from '../data/use-models-query'
import useUpdateActiveAliastMutation from '../data/use-update-active-alias-mutation'
import ActiveAliasMenu from './active-alias-menu'
import AddClassificationModal from './add-classification-modal'
import AddDatapointMenu from './add-datapoint-menu'
import AddModelModal from './add-model-modal'
import DataEngineShell from './data-engine-shell'
import DatapointMenu from './datapoint-menu'
import DeleteClassificationModal from './delete-classification-modal'
import DeleteDatapointModal from './delete-datapoint-modal'
import DeleteModelModal from './delete-model-modal'
import EditClassificationModal from './edit-classification-modal'
import EditDatapointModal from './edit-datapoint-modal'
import QuickSearchField from './quick-search-field'
import RemoveDatapointModal from './remove-datapoint-modal'
import { useDataEngineUpdateLoadingState } from './use-data-engine-outlet-context'

type DatasetDetailsProps = {
  datasetRef: string
  onClassificationClick: (classificationId: string | number) => void
  onModelClick: (modelRef: string) => void
}

function DatasetDetails(props: DatasetDetailsProps) {
  const { datasetRef, onClassificationClick, onModelClick } = props

  const datapointsQuery = useDatapointsQuery(datasetRef)
  const optionsQuery = useDatapointOptionsQuery(datasetRef)
  const classificationsQuery = useClassificationsQuery(datasetRef)
  const modelsQuery = useModelsQuery(datasetRef)

  const datapoints = datapointsQuery.data?.data || []
  const aliases = optionsQuery.data?.data.aliases || {}
  const classifications = classificationsQuery.data?.data || []
  const models = modelsQuery.data?.data || []

  const isLoading =
    datapointsQuery.isLoading || optionsQuery.isLoading || classificationsQuery.isLoading || modelsQuery.isLoading
  const isFetching = datapointsQuery.isFetching || classificationsQuery.isFetching || modelsQuery.isFetching

  useDataEngineUpdateLoadingState(isFetching)

  if (isLoading) {
    return <DataEngineShell />
  }

  return (
    <DataEngineShell>
      <Stack p={2} gap={2}>
        <DatapointsSection datasetRef={datasetRef} datapoints={datapoints} aliases={aliases} />
        <ClassificationsSection
          datasetRef={datasetRef}
          datapoints={datapoints}
          classifications={classifications}
          onClassificationClick={onClassificationClick}
        />
        <ModelsSection
          onModelClick={onModelClick}
          classifications={classifications}
          datasetRef={datasetRef}
          models={models}
        />
      </Stack>
    </DataEngineShell>
  )
}

export default DatasetDetails

type DatapointsSectionProps = {
  datasetRef: string
  datapoints: Datapoint[]
  aliases: {
    [key: string]: DatapointAlias
  }
}

function DatapointsSection(props: DatapointsSectionProps) {
  const { t } = useTranslation('dataEngine')
  const { datasetRef, datapoints, aliases } = props

  const editDatapointModal = useOpenState()
  const deleteDatapointModal = useOpenState()
  const removeDatapointModal = useOpenState()

  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null)
  const [activeAliasMenuAnchorElement, setActiveAliasMenuAnchorElement] = useState<HTMLElement | null>(null)
  const [addDatapointAnchorElement, setAddDatapointAnchorElement] = useState<HTMLElement | null>(null)
  const [activeDatapoint, setActiveDatapoint] = useState<Datapoint | null>(null)
  const [quickSearch, setQuickSearch] = useState('')

  const updateActiveAlias = useUpdateActiveAliastMutation()

  const activeDatapointRef = activeDatapoint?.datapoint_ref || ''
  const activeDatapointName = activeDatapoint?.datapoint_name || ''
  const activeDatapointDatasetRef = activeDatapoint?.primary_dataset_ref
  const activeDatapointActiveAliasTag = activeDatapoint?.active_alias_tag

  const filteredDatapoints = datapoints.filter((datapoint) =>
    datapoint.datapoint_name.toLocaleLowerCase().includes(quickSearch.toLocaleLowerCase())
  )

  const activeAliasIds = Object.entries(aliases)
    .filter(([_, aliasTag]) => {
      return datapoints.find((datapoint) => datapoint.active_alias_tag === aliasTag)
    })
    .map(([aliasId]) => aliasId)

  const activeDatapointSelectedAliasId =
    Object.entries(aliases).find(([_, aliasTag]) => {
      return aliasTag === activeDatapointActiveAliasTag
    })?.[0] || ''

  function handleMenuOpen(event: MouseEvent<HTMLElement>, datapoint: Datapoint) {
    event.stopPropagation()
    setMenuAnchorElement(event.currentTarget)
    setActiveDatapoint(datapoint)
  }

  function handleMenuClose() {
    setMenuAnchorElement(null)
    setActiveDatapoint(null)
    setActiveAliasMenuAnchorElement(null)
  }

  function handleEditModalClose() {
    editDatapointModal.close()
    handleMenuClose()
  }

  function handleDeleteModalClose() {
    deleteDatapointModal.close()
    handleMenuClose()
  }

  function handleRemoveModalClose() {
    removeDatapointModal.close()
    handleMenuClose()
  }

  function handleActiveAliasMenuOpen(event: MouseEvent<HTMLElement>) {
    setActiveAliasMenuAnchorElement(event.currentTarget)
  }

  function handleActiveAliasMenuClose() {
    setActiveAliasMenuAnchorElement(null)
  }

  function handleAddDatapointMenuOpen(event: MouseEvent<HTMLElement>) {
    setAddDatapointAnchorElement(event.currentTarget)
  }

  function handleUpdateActiveAlias(activeAliasId: string | null) {
    if (!activeDatapointRef) {
      return
    }

    const params = {
      datasetRef,
      datapointRef: activeDatapointRef,
      activeAliasId: activeAliasId ? Number(activeAliasId) : null,
    }

    updateActiveAlias.mutate(params, {
      onSuccess: () => {
        handleMenuClose()
        queryClient.invalidateQueries(['dataset', datasetRef, 'datapoints'])
      },
    })
  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <TableContainerHeader
          title={t('datapoint_table.title')}
          action={
            <Tooltip title={t('datapoint_table.add_datapoint')} arrow disableInteractive>
              <IconButton onClick={handleAddDatapointMenuOpen}>
                <Add />
              </IconButton>
            </Tooltip>
          }
        />
        <QuickSearchField autoFocus onValueChange={setQuickSearch} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('datapoint_table.headers.datapoint_name')}</TableCell>
              <TableCell>{t('datapoint_table.headers.variety')}</TableCell>
              <TableCell>{t('datapoint_table.headers.data_type')}</TableCell>
              <TableCell>{t('datapoint_table.headers.aggregation')}</TableCell>
              <TableCell>{t('datapoint_table.headers.source')}</TableCell>
              <TableCell>{t('datapoint_table.headers.active_alias')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDatapoints?.map((datapoint) => {
              const isMenuActive = datapoint.datapoint_ref === activeDatapoint?.datapoint_ref

              return (
                <TableRow hover key={datapoint.datapoint_ref} className={isMenuActive ? 'active' : ''}>
                  <TableCell>{datapoint.datapoint_name}</TableCell>
                  <VarietyCell datapoint={datapoint} />
                  <DataTypeCell datapoint={datapoint} />
                  <AggregationCell datapoint={datapoint} />
                  <SourceCell
                    datasetRef={datapoint.primary_dataset_ref}
                    datasetName={datapoint.primary_dataset_name}
                    currentDatasetRef={datasetRef}
                  />
                  <AliasCell alias={datapoint.active_alias_tag} />
                  <TableCell className="action-cell" align="right">
                    <IconButton onClick={(event) => handleMenuOpen(event, datapoint)}>
                      <MoreVert />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <DatapointMenu
        anchorEl={menuAnchorElement}
        aliasMenuActive={!!activeAliasMenuAnchorElement}
        showEdit={activeDatapointDatasetRef === datasetRef}
        showDelete={activeDatapointDatasetRef === datasetRef}
        showRemove={activeDatapointDatasetRef !== datasetRef}
        onActiveAliasClick={handleActiveAliasMenuOpen}
        onEdit={editDatapointModal.open}
        onDelete={deleteDatapointModal.open}
        onRemove={removeDatapointModal.open}
        onClose={handleMenuClose}
      />
      <ActiveAliasMenu
        selectedAliasId={activeDatapointSelectedAliasId}
        anchorEl={activeAliasMenuAnchorElement}
        aliases={aliases}
        activeAliasIds={activeAliasIds}
        onUpdateActiveAlias={handleUpdateActiveAlias}
        onClose={handleActiveAliasMenuClose}
      />
      <DeleteDatapointModal
        open={deleteDatapointModal.isOpen}
        datasetRef={datasetRef}
        datapointRef={activeDatapointRef}
        datapointName={activeDatapointName}
        onClose={handleDeleteModalClose}
      />
      <RemoveDatapointModal
        open={removeDatapointModal.isOpen}
        datasetRef={datasetRef}
        datapointRef={activeDatapointRef}
        datapointName={activeDatapointName}
        onClose={handleRemoveModalClose}
      />
      <AddDatapointMenu
        currentDatasetRef={datasetRef}
        anchorEl={addDatapointAnchorElement}
        onClose={() => setAddDatapointAnchorElement(null)}
      />
      <EditDatapointModal
        currentDatasetRef={datasetRef}
        currentDatapointRef={activeDatapointRef}
        open={editDatapointModal.isOpen}
        onClose={handleEditModalClose}
      />

      <ErrorSnackbar
        open={!!updateActiveAlias.error}
        message={updateActiveAlias.error}
        onClose={updateActiveAlias.reset}
      />
    </Box>
  )
}

type ClassificationsSectionProps = {
  classifications: Classification[]
  datasetRef: string
  datapoints: Datapoint[]
  onClassificationClick: (classificationId: string | number) => void
}

function ClassificationsSection(props: ClassificationsSectionProps) {
  const { t } = useTranslation('dataEngine')
  const { classifications, datasetRef, datapoints, onClassificationClick } = props

  const addClassificationModal = useOpenState()
  const editClassificationModal = useOpenState()
  const deleteClassificationModal = useOpenState()

  const [activeClassification, setActiveClassification] = useState<Classification | null>(null)

  function handleEditModalOpen(event: MouseEvent<HTMLElement>, classification: Classification) {
    event.stopPropagation()
    setActiveClassification(classification)
    editClassificationModal.open()
  }

  function handleEditModalClose() {
    setActiveClassification(null)
    editClassificationModal.close()
  }

  function handleDeleteModalOpen(event: MouseEvent<HTMLElement>, classification: Classification) {
    event.stopPropagation()
    setActiveClassification(classification)
    deleteClassificationModal.open()
  }

  function handleDeleteModalClose() {
    setActiveClassification(null)
    deleteClassificationModal.close()
  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <TableContainerHeader
          title={t('classifications_table.title')}
          action={
            <Tooltip title={t('classifications_table.add')} arrow disableInteractive>
              <IconButton onClick={addClassificationModal.open}>
                <Add />
              </IconButton>
            </Tooltip>
          }
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('classifications_table.headers.classification')}</TableCell>
              <TableCell>{t('classifications_table.headers.type')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {classifications?.map((classification) => {
              const isMenuActive = false

              return (
                <TableRow
                  key={classification.classification_id}
                  className={isMenuActive ? 'active' : ''}
                  hover
                  onClick={() => onClassificationClick(classification.classification_id)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{classification.classification_name}</TableCell>
                  <TableCell>
                    {t(`classifications_table.classification_type.${classification.classification_type}`)}
                  </TableCell>
                  <TableCell className="action-cell" align="right">
                    <Tooltip title={t('classifications_table.edit')} arrow disableInteractive>
                      <IconButton onClick={(event) => handleEditModalOpen(event, classification)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('classifications_table.delete')} arrow disableInteractive>
                      <IconButton onClick={(event) => handleDeleteModalOpen(event, classification)}>
                        <DeleteOutline />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <AddClassificationModal
        open={addClassificationModal.isOpen}
        datasetRef={datasetRef}
        datapoints={datapoints}
        onClose={addClassificationModal.close}
      />
      <EditClassificationModal
        open={editClassificationModal.isOpen}
        classificationId={activeClassification?.classification_id}
        datasetRef={datasetRef}
        datapoints={datapoints}
        onClose={handleEditModalClose}
      />
      <DeleteClassificationModal
        open={deleteClassificationModal.isOpen}
        datasetRef={datasetRef}
        classificationId={activeClassification?.classification_id}
        classificationName={activeClassification?.classification_name}
        onClose={handleDeleteModalClose}
      />
    </Box>
  )
}

type ModelsSectionProps = {
  models: Model[]
  datasetRef: string
  classifications: Classification[]
  onModelClick: (mdoelRef: string) => void
}

function ModelsSection(props: ModelsSectionProps) {
  const { models, datasetRef, classifications, onModelClick } = props

  const { t } = useTranslation('dataEngine')

  const [selectedModel, setSelectedModel] = useState<Model | null>(null)

  const deleteModelModal = useOpenState()
  const addModelModal = useOpenState()

  const datasetResponse = useDatasetQuery(datasetRef)
  const datasetName = datasetResponse.data?.data.dataset_name || ''

  function handleDeleteOpen(model: Model, event: MouseEvent<HTMLElement>) {
    event.stopPropagation()
    setSelectedModel(model)
    deleteModelModal.open()
  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <TableContainerHeader
          title={t('models')}
          action={
            <Tooltip title={t('models_table.add_model')} arrow disableInteractive>
              <IconButton onClick={addModelModal.open}>
                <Add />
              </IconButton>
            </Tooltip>
          }
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('models_table.model')}</TableCell>
              <TableCell>{t('models_table.basis')}</TableCell>
              <TableCell>{t('models_table.constituents')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {models.map((model) => {
              const basis = model.classification_name
                ? `${model.classification_name} (${datasetName})`
                : t('models_table.asset_based')

              return (
                <TableRow
                  key={model.model_ref}
                  hover
                  onClick={() => onModelClick(model.model_ref)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell sx={{ width: '33%' }}>{model.model_name}</TableCell>
                  <TableCell>{basis}</TableCell>
                  <TableCell>{model.nos_constituents}</TableCell>
                  <TableCell className="action-cell" align="right">
                    <Tooltip title={t('models_table.delete_model')} arrow disableInteractive>
                      <IconButton onClick={(event) => handleDeleteOpen(model, event)}>
                        <DeleteOutline />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteModelModal
        datasetRef={datasetRef}
        isOpen={deleteModelModal.isOpen}
        model={selectedModel}
        onClose={deleteModelModal.close}
      />
      <AddModelModal
        datasetRef={datasetRef}
        datasetName={datasetName}
        isOpen={addModelModal.isOpen}
        onClose={addModelModal.close}
        classifications={classifications}
      />
    </Box>
  )
}

type VarietyCellProps = {
  datapoint: Datapoint
}

function VarietyCell(props: VarietyCellProps) {
  const { variety, datapoint_name, calc_name, calc_input_map, classi_static_name } = props.datapoint

  const { t } = useTranslation('dataEngine')

  return (
    <TableCell>
      <Stack direction="row" alignItems="center" gap={1}>
        {t(`datapoint_table.variety.${variety}`)}
        {variety === 'calculated' && (
          <DatapointInfoAlert title={t('datapoint_table.calculation', { name: calc_name })}>
            {!!calc_input_map?.length && (
              <Typography variant="body2" color="inherit">
                {t(`datapoint_table.inputs`)}:
              </Typography>
            )}
            {calc_input_map?.map((calc) => (
              <Stack key={calc.input_label} direction="row" gap={2}>
                <Typography variant="body2" color="inherit">
                  {calc.input_label}
                </Typography>
                <Typography variant="body2" color="inherit">
                  {calc.datapoint_name}
                </Typography>
              </Stack>
            ))}
          </DatapointInfoAlert>
        )}
        {variety === 'class_static' && (
          <DatapointInfoAlert
            title={t(`datapoint_table.static_field_for_classification`, {
              name: classi_static_name,
            })}
          />
        )}
      </Stack>
    </TableCell>
  )
}

type DataTypeCellProps = {
  datapoint: Datapoint
}

function DataTypeCell(props: DataTypeCellProps) {
  const { t } = useTranslation('dataEngine')

  const { asset_category, datadoc_type, data_type, classi_values } = props.datapoint
  const isInfoAlerVisible = data_type === 'Classification' || data_type === 'MultiSelect'

  let data_type_display: string = data_type

  if (data_type === 'AssetRef' && !!asset_category) {
    data_type_display = t(`datapoint_table.asset_category.${asset_category}`, { defaultValue: asset_category })
  } else if (data_type === 'NaiveDate') {
    data_type_display = t('common:date')
  }

  if (data_type === 'DataDoc' && !!datadoc_type) {
    data_type_display = t(`datapoint_table.datadoc_type.${datadoc_type}`, { defaultValue: datadoc_type })
  }

  return (
    <TableCell>
      <Stack direction="row" alignItems="center" gap={1}>
        {data_type_display}
        {isInfoAlerVisible && (
          <DatapointInfoAlert title={t('datapoint_table.classification_values')}>
            {classi_values?.map((value) => (
              <Typography key={value} variant="body2" color="inherit">
                {value}
              </Typography>
            ))}
          </DatapointInfoAlert>
        )}
      </Stack>
    </TableCell>
  )
}

type AggregationCellProps = {
  datapoint: Datapoint
}

function AggregationCell(props: AggregationCellProps) {
  const { t } = useTranslation('dataEngine')

  const { aggregation_type, weight_datapoint_name } = props.datapoint
  const isInfoAlerVisible = aggregation_type === 'wavg'

  return (
    <TableCell>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {t(`datapoint_table.aggregation_type.${aggregation_type || 'none'}`, {
          defaultValue: aggregation_type,
        })}
        {isInfoAlerVisible && (
          <DatapointInfoAlert
            title={t(`datapoint_table.wavg_based_on_datapoint`, {
              name: weight_datapoint_name,
            })}
          />
        )}
      </Stack>
    </TableCell>
  )
}

type SourceCellProps = {
  datasetRef: string
  datasetName: string
  currentDatasetRef?: string
}

function SourceCell(props: SourceCellProps) {
  const { datasetRef, datasetName, currentDatasetRef } = props
  const { t } = useTranslation('dataEngine')

  const isThisDataset = datasetRef === currentDatasetRef

  return (
    <TableCell>
      {isThisDataset ? (
        <Chip
          size="small"
          label={t('datapoint_table.this_dataset')}
          sx={{
            color: 'inherit',
            '& .MuiChip-label': {
              px: 1.3,
            },
          }}
        />
      ) : (
        datasetName
      )}
    </TableCell>
  )
}

type AliasCellProps = {
  alias: string | null
}

function AliasCell({ alias }: AliasCellProps) {
  return (
    <TableCell>
      {alias ? (
        <Chip
          variant="outlined"
          size="small"
          label={alias}
          sx={{
            color: 'inherit',
            '& .MuiChip-label': {
              px: 1.3,
            },
          }}
        />
      ) : (
        <Typography color="inherit">-</Typography>
      )}
    </TableCell>
  )
}

type DatapointInfoAlertProps = {
  title: string
  children?: ReactNode
}

function DatapointInfoAlert(props: DatapointInfoAlertProps) {
  const { title, children } = props

  return (
    <AlertIcon
      severity="info"
      tooltipPlacement="right"
      useInfoIcon
      message={
        <Stack p={1} gap={1}>
          <Typography variant="subtitle1" fontWeight="500" color="inherit">
            {title}
          </Typography>
          {children}
        </Stack>
      }
    />
  )
}

function swapListItems(list: string[], indexA: number, indexB: number) {
  const newList = [...list]

  const itemToSwap = newList[indexA]!
  newList[indexA] = newList[indexB]!
  newList[indexB] = itemToSwap

  return newList
}
