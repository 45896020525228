import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import { queryClient } from '../../../services/data/react-query'
import useDeleteDatasetMutation from '../data/use-delete-dataset-mutation'

type DeleteDatasetModalProps = {
  datasetRef: string
  open: boolean
  onClose: () => void
}

function DeleteDatasetModal(props: DeleteDatasetModalProps) {
  const { datasetRef, open, onClose } = props
  const { t } = useTranslation('dataEngine')

  const { mutate: deleteDataset, error: deleteDatasetError, reset: deleteDatasetReset } = useDeleteDatasetMutation()

  function handleClose() {
    deleteDatasetReset()
    onClose()
  }

  function handleDelete() {
    deleteDataset(
      { datasetRef },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['datasets'])
          handleClose()
        },
      }
    )
  }

  return (
    <ConfirmationModal
      error={deleteDatasetError}
      title={t('delete_dataset')}
      confirmButtonText={t('common:delete')}
      open={open}
      onCloseButtonClick={handleClose}
      onConfirmButtonClick={handleDelete}
    >
      <Typography variant="body1">{t('delete_dataset_confirmation_text')}</Typography>
    </ConfirmationModal>
  )
}

export default DeleteDatasetModal
