import { Dialog } from '@mui/material'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import useGroupDetailsQuery from '../data/use-group-details-query'
import useGroupsWithMembers from '../data/use-groups-with-members'
import useUpdateGroupMutation from '../data/use-update-group-mutation'
import CreateUpdateGroupForm from './create-update-group-form'

type UpdateGroupModalProps = {
  open: boolean
  onClose: () => void
  groupRef: string
}

function UpdateGroupModal(props: UpdateGroupModalProps) {
  const { open, onClose, groupRef } = props

  const { t } = useTranslation('settings')

  const groupsResponse = useGroupsWithMembers()
  const groupTypes = groupsResponse.data?.data.group_types || {}

  const groupDetailsResponse = useGroupDetailsQuery(groupRef)
  const groupDetails = groupDetailsResponse.data?.data

  const {
    mutate: updateGroup,
    error: updateGroupError,
    reset: updateGroupReset,
    isLoading: updateGroupIsLoading,
  } = useUpdateGroupMutation()

  const initialFormValues = {
    groupName: groupDetails?.group_name || '',
    groupType: groupDetails?.group_type || '',
  }

  const [formValues, setFormValues] = useState(initialFormValues)

  function handleChange(name: string, value: string) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleClose() {
    onClose()
    updateGroupReset()
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    const updateGroupParams = {
      groupRef,
      payload: {
        group_name: formValues.groupName,
        group_type: formValues.groupType,
      },
    }

    updateGroup(updateGroupParams, {
      onSuccess: () => {
        queryClient.invalidateQueries(['tenant', 'current', 'groupswithmembers'])
        queryClient.invalidateQueries(['settings', 'groups', groupRef])
        handleClose()
      },
    })
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle onClose={handleClose} title={t('create_groups_modal.create_title')} />
        <ModalContent>
          <CreateUpdateGroupForm
            groupTypes={groupTypes}
            errorMessage={updateGroupError}
            onChange={handleChange}
            values={formValues}
          />
        </ModalContent>
        <ModalActions isLoading={updateGroupIsLoading} confirmLabel={t('common:add')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default UpdateGroupModal
