import { RemoveCircleOutline } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import {
  Chip,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import TableContainerHeader from '../../../components/table-container-header'
import { queryClient } from '../../../services/data/react-query'
import { UserDetailsDataset } from '../../../services/data/types/dataset'
import { GroupWithMembers } from '../../../services/data/types/group'
import useOpenState from '../../../utils/hooks/use-open-state'
import useRemoveDatasetMutation from '../data/use-remove-dataset-mutation'
import AddDatasetModal from './add-dataset-modal'
import EditDatasetModal from './edit-dataset-modal'

type GroupDatasetsSectionProps = {
  groupRef: string
  currentDatasets: UserDetailsDataset[]
  groupDetails: GroupWithMembers | undefined
}

function GroupDatasetsSection(props: GroupDatasetsSectionProps) {
  const { groupRef, currentDatasets, groupDetails } = props

  const { t } = useTranslation('settings')

  const addDatasetModal = useOpenState()
  const editDatasetModal = useOpenState()
  const removeDatasetModal = useOpenState()

  const { mutate: removeDataset, error: removeDatasetError, reset: resetRemoveDataset } = useRemoveDatasetMutation()

  const [selectedDataset, setSelectedDataset] = useState<UserDetailsDataset>()

  function handleEditClick(dataset: UserDetailsDataset) {
    setSelectedDataset(dataset)
    editDatasetModal.open()
  }

  function handleDeleteClick(dataset: UserDetailsDataset) {
    setSelectedDataset(dataset)
    removeDatasetModal.open()
  }

  function handleRemoveDatasetModalClose() {
    resetRemoveDataset()
    removeDatasetModal.close()
  }

  function handleRemoveDataset() {
    if (groupRef && selectedDataset?.dataset_ref) {
      const removeDatasetParams = {
        groupRef: groupRef,
        datasetRef: selectedDataset?.dataset_ref,
      }
      removeDataset(removeDatasetParams, {
        onSuccess: () => {
          queryClient.invalidateQueries(['group', groupRef, 'datasets', 'current'])
          handleRemoveDatasetModalClose()
        },
      })
    }
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <TableContainerHeader
          title={t('user_details_page.group_datasets_table.title')}
          action={
            <IconButton onClick={addDatasetModal.open}>
              <AddIcon />
            </IconButton>
          }
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('user_details_page.group_datasets_table.dataset')}</TableCell>
              <TableCell>{t('user_details_page.group_datasets_table.owner')}</TableCell>
              <TableCell>{t('user_details_page.group_datasets_table.expiry')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentDatasets?.map((dataset) => (
              <TableRow hover key={dataset.dataset_ref}>
                <TableCell>{dataset.dataset_name}</TableCell>
                <TableCell>
                  {dataset.owner_name === groupDetails?.group_name ? (
                    <Chip label={groupDetails?.group_name} />
                  ) : (
                    dataset.owner_name
                  )}
                </TableCell>
                <TableCell>{dataset.expiry || '-'}</TableCell>
                <TableCell className="action-cell" align="right">
                  <Stack direction="row" gap={1} justifyContent="flex-end">
                    <Tooltip title={t('edit_dataset')} arrow disableInteractive>
                      <IconButton onClick={() => handleEditClick(dataset)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('remove')} arrow disableInteractive>
                      <IconButton onClick={() => handleDeleteClick(dataset)}>
                        <RemoveCircleOutline />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddDatasetModal open={addDatasetModal.isOpen} groupRef={groupRef} onClose={addDatasetModal.close} />
      <EditDatasetModal
        open={editDatasetModal.isOpen}
        groupRef={groupRef}
        dataset={selectedDataset}
        onClose={editDatasetModal.close}
      />
      <ConfirmationModal
        error={removeDatasetError}
        title={t('group_tables.remove_dataset_modal_title')}
        confirmButtonText={t('common:remove')}
        open={removeDatasetModal.isOpen}
        onCloseButtonClick={removeDatasetModal.close}
        onConfirmButtonClick={handleRemoveDataset}
      >
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey="group_tables.are_you_sure_you_want_to_remove_dataset"
            values={{ subject: `${selectedDataset?.dataset_name}` }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </ConfirmationModal>
    </>
  )
}

export default GroupDatasetsSection
