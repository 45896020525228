import { Button, Dialog, Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormEvent, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import NumericField from '../../../components/fields/numeric-field'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import { ModalDetailRow } from '../../../components/modal-detail-row'
import ModalTitle from '../../../components/modal-title'
import { Broker } from '../../../services/data/types/broker'
import { CreatePlacementParams, Order } from '../../../services/data/types/order'
import { defaultScale } from '../../../utils/math'
import { useCreatePlacementMutation } from '../data/use-create-placement-mutation'

type OrderPlacementModalProps = {
  open: boolean
  order: Order | null
  brokers: Pick<Broker, 'account_id' | 'account_name'>[]
  editBrokerId?: number
  onCreated: () => void
  onClose: () => void
}

export function OrderPlacementModal(props: OrderPlacementModalProps) {
  const { open, order, brokers, editBrokerId, onCreated, onClose } = props

  const { t } = useTranslation('orderBlotter')

  const {
    mutate: createPlacement,
    error: createPlacementError,
    reset: createPlacementReset,
    isLoading: createPlacementIsLoading,
  } = useCreatePlacementMutation()

  const [brokerId, setBrokerId] = useState('')
  const [quantity, setQuantity] = useState('')

  const isEditing = !!editBrokerId
  const selectedBrokerId = editBrokerId || brokerId

  const hasBrokerId = !!selectedBrokerId
  const hasValidQuantity = !!quantity && !Number.isNaN(Number(quantity))
  const canSubmit = hasBrokerId && hasValidQuantity

  const brokerOptions = [...brokers]
  if (editBrokerId && !brokers.find((b) => b.account_id === editBrokerId)) {
    brokerOptions.push({ account_id: editBrokerId, account_name: t('no_access') })
  }

  const title = isEditing ? t('adjust_placement') : t('new_placement')
  const quantityLabel = isEditing ? t('adjust_quantity') : t('place_quantity')
  const confirmLabel = isEditing ? t('common:save') : t('place_order')

  function handleBrokerSelect(event: SelectChangeEvent) {
    setBrokerId(event.target.value)
  }

  function setUnplacedQuantity() {
    setQuantity(String(order?.amount_unplaced ?? ''))
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    handleCreatePlacement()
  }

  function handleCreatePlacement() {
    if (order && canSubmit) {
      const params: CreatePlacementParams = {
        orderRef: order.order_ref,
        brokerAccountId: Number(selectedBrokerId),
        amount: Number(quantity),
      }

      createPlacement(params, {
        onSuccess: () => {
          onCreated()
          handleClose()
        },
      })
    }
  }

  function handleClose() {
    createPlacementReset()
    setBrokerId('')
    setQuantity('')
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={title} onClose={handleClose} />

        <ModalContent>
          <Alert severity="error" message={createPlacementError} />

          <ModalDetailRow label={t('order_ref')} value={order?.order_ref} />
          <ModalDetailRow label={t('security')} value={order?.asset_description} />
          <ModalDetailRow label={t('quantity_unplaced')} value={order?.amount_unplaced} />

          <Divider />

          <FormControl required>
            <InputLabel>{t('counterparty')}</InputLabel>
            <Select
              required
              name="broker"
              label={t('counterparty')}
              value={String(selectedBrokerId)}
              disabled={isEditing}
              onChange={handleBrokerSelect}
            >
              {brokerOptions.map((broker) => (
                <MenuItem key={broker.account_id} value={broker.account_id}>
                  {broker.account_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <NumericField
              required
              name="quantity"
              label={quantityLabel}
              value={quantity}
              decimalScale={defaultScale}
              onValueChange={setQuantity}
            />
            <Button
              type="button"
              size="small"
              variant="outlined"
              onClick={setUnplacedQuantity}
              sx={{ mt: 1, alignSelf: 'self-start' }}
            >
              <Trans t={t} i18nKey="unplaced_label" values={{ quantity: order?.amount_unplaced ?? '-' }} />
            </Button>
          </FormControl>
        </ModalContent>

        <ModalActions
          confirmLabel={confirmLabel}
          confirmDisabled={!canSubmit}
          isLoading={createPlacementIsLoading}
          onCancel={handleClose}
        />
      </form>
    </Dialog>
  )
}
