import { format } from 'date-fns'

export type NaiveDate = string

export const dateFormat = 'yyyy-MM-dd'
export const datePlaceholder = 'YYYY-MM-DD'

export const datetimeFormat = 'yyyy-MM-dd HH:mm'
export const datetimeWithSecondsFormat = 'yyyy-MM-dd HH:mm:ss'
export const datetimePlaceholder = 'YYYY-MM-DD HH:MM'

export function formatDate(timestamp: string, formatStr: string) {
  return format(new Date(timestamp), formatStr)
}

export function formatNaiveDate(timestamp: Date | string) {
  const date = typeof timestamp === 'string' ? new Date(timestamp) : timestamp
  return format(date, dateFormat)
}

export function formatDatetime(timestamp: string) {
  return format(new Date(timestamp), datetimeFormat)
}

export function datetimeWithSeconds(timestamp: string) {
  return format(new Date(timestamp), datetimeWithSecondsFormat)
}

/**
 * Parses a NaiveDate string into a midnight Date in the local timezone.
 *
 * Commonly used to pass NaiveDates into a DatePicker value.
 *
 * Expected value format: 'YYYY-MM-DD'.
 */
export function parseNaiveDate(value: string | null | undefined): Date | null {
  if (typeof value !== 'string') {
    return null
  }

  // We can't simply use `new Date(value)` as it would be X hours off
  // based on the user's timezone, and we want a 00:00:00 time.
  //
  // Eg.: User is in Europe/London BST (British Summer Time)
  //   new Date(value)               - Mon Apr 01 2024 01:00:00 GMT+0100 (British Summer Time)
  //   new Date(`${value}T00:00:00`) - Mon Apr 01 2024 00:00:00 GMT+0100 (British Summer Time)
  return new Date(`${value}T00:00:00`)
}
