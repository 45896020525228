import { TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FocusEventHandler, KeyboardEvent } from 'react'
import { dateFormat, datePlaceholder } from '../../../../../../utils/dates'

type DateSelectorProps = {
  label: string
  value: Date | null
  required?: boolean
  disabled?: boolean
  fixedLabel?: true
  onBlur?: FocusEventHandler
  onEnterDown?: () => void
  onChange?: (date: Date | null) => void
}

function DateSelector(props: DateSelectorProps) {
  const { label, value, required, disabled, fixedLabel, onBlur, onEnterDown, onChange } = props

  function handleOnKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && onEnterDown) {
      event.preventDefault()
      onEnterDown()
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        inputFormat={dateFormat}
        value={value}
        disabled={disabled}
        ignoreInvalidInputs
        onChange={(date, inputValue) => {
          // trigger change only when date was selected or the typed date is valid (yyyy/mm/dd)
          if (!inputValue || inputValue.length === 10) {
            onChange?.(date)
          }
        }}
        InputProps={{
          onBlur: onBlur,
          onKeyDown: handleOnKeyDown,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            autoComplete="off"
            error={false}
            InputLabelProps={{
              shrink: fixedLabel,
            }}
            inputProps={{
              ...params.inputProps,
              placeholder: datePlaceholder,
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}

export default DateSelector
