import { DatapointType } from './datapoint'

export type PortfolioHeader = {
  portfolio_ref: string
  portfolio_name: string
  lei: string
}

export type Portfolio = {
  portfolio_ref: string
  portfolio_name: string
  lei: string
  legal_entity_name: string
  base_currency: string
}

export type RegularPortfolio = {
  portfolio_ref: string
  portfolio_name: string
  lei: string
  legal_entity_name: string
  base_currency: string
  chart_ref: string
  chart_name: string
  pot_tag: string
}

export type SandboxPortfolio = {
  portfolio_ref: string
  portfolio_name: string
  group_owner: string
  base_currency: string
}

export type PortfolioPayload = {
  portfolio_name: string
  lei: string
  currency: string
  chart_ref: string
  pot_tag: string
}

export type PortfolioOptions = {
  legal_entities: [{ lei: string; legal_name: string }]
  nominal_charts: [{ chart_ref: string; chart_name: string }]
}

export enum BaseCurrency {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
}

export const currencies = Object.values(BaseCurrency)

export type PortfolioUploadOptions = {
  principal_columns: {
    tag: string
    display_as: string
  }[]
  static_fields: {
    datapoint_ref: string
    datapoint_name: string
    datapoint_type: DatapointType
    classification_id: number | null
    active_alias?: string
  }[]
  contract_terms: {
    term_tag: string
    name: string
    data_type: DatapointType
  }[]
}

export type PortfolioUploadParams = {
  portfolioRef: string
  columns: string[]
  uploadingTransactions: boolean
}

export type ExportPortfolioParams = {
  portfolioRef: string
  portfolioName: string
  viewRef: string | null
  viewName: string | undefined
  exportTransactions: boolean
  asOfDate: string | null
}

export type ExportPortfolioData = {
  blob: Blob
  filename: string
}
