import { useLocation, useNavigate } from 'react-router-dom'
import DataEngine, { DataEngineModule } from '../../components/data-engine'

const dataEngineModulePath: { [key in DataEngineModule]: string } = {
  [DataEngineModule.Datasets]: '/data-engine/datasets',
  [DataEngineModule.Assets]: '/data-engine/assets',
  [DataEngineModule.Classifications]: '/data-engine/classifications',
  [DataEngineModule.Models]: '/data-engine/models',
}

function DataEnginePage() {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const defaultModule = DataEngineModule.Datasets
  const selectedModule: DataEngineModule =
    (Object.entries(dataEngineModulePath).find(([_, path]) => {
      return pathname.startsWith(path)
    })?.[0] as DataEngineModule) || defaultModule

  function handleModuleClick(module: DataEngineModule, param?: string | number) {
    const url = param ? `${dataEngineModulePath[module]}/${param}` : dataEngineModulePath[module]
    navigate(url)
  }

  return (
    <DataEngine selectedModule={selectedModule} modulePaths={dataEngineModulePath} onModuleClick={handleModuleClick} />
  )
}

export default DataEnginePage
