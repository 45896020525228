import { Logout } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Footer from '../../../components/footer'
import AppLayout from '../../../components/layouts/app-layout'
import Account from '../../account/components/account'

type AccountDetailsProps = {
  onLogout: () => void
  onSwitchTenantSuccess: () => void
}

function AccountDetails(props: AccountDetailsProps) {
  const { onLogout, onSwitchTenantSuccess } = props

  const { t } = useTranslation('common')

  return (
    <AppLayout bgcolor="background.default">
      <Stack direction="row" bgcolor="gray.main" alignItems="center" justifyContent="space-between" py={1.5} px={2}>
        <Typography variant="h6">{t('account:account_details')}</Typography>
        <Button variant="contained" size="small" startIcon={<Logout />} onClick={onLogout}>
          {t('auth:logout')}
        </Button>
      </Stack>
      <Account onSwitchTenantSuccess={onSwitchTenantSuccess} />
      <Footer />
    </AppLayout>
  )
}

export default AccountDetails
