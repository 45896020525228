import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'

type CreateGroupModalProps = {
  portfolios: any[]
  open: boolean
  onCloseButtonClick: () => void
  onCreateButtonClick: () => void
}

function CreateGroupModal(props: CreateGroupModalProps) {
  const { t } = useTranslation()
  const [groupName, setGroupName] = useState('')
  const [portfolios, setPortfolios] = useState<string[]>([])

  useEffect(() => {
    if (props.open) {
      setGroupName('')
      setPortfolios([])
    }
  }, [props.open])

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    props.onCreateButtonClick()
  }

  function handleGroupNameChange(event: ChangeEvent<HTMLTextAreaElement>) {
    const value = event.target.value
    setGroupName(value)
  }

  function handlePortfoliosChange(event: SelectChangeEvent<string[]>) {
    const value = event.target.value
    setPortfolios(typeof value === 'string' ? value.split(',') : value)
  }

  function getPortfolioNameByRef(portfolioRef: string) {
    const portfolio = props.portfolios.find((portfolio) => portfolio.portfolio_ref === portfolioRef)
    return portfolio.portfolio_name
  }

  return (
    <Dialog open={props.open} onClose={props.onCloseButtonClick}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('portfolio:create_portfolio_group')} onClose={props.onCloseButtonClick} />
        <ModalContent>
          <TextField
            required
            placeholder={t('portfolio:name_of_the_group')}
            value={groupName}
            onChange={handleGroupNameChange}
          />
          <FormControl>
            <InputLabel>{t('common:portfolio')}</InputLabel>
            <Select
              required
              multiple
              label={t('common:portfolio')}
              value={portfolios}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={getPortfolioNameByRef(value)} />
                  ))}
                </Box>
              )}
              onChange={handlePortfoliosChange}
            >
              {props.portfolios.map((portfolio) => (
                <MenuItem key={portfolio.portfolio_ref} value={portfolio.portfolio_ref}>
                  <Checkbox checked={portfolios.includes(portfolio.portfolio_ref)} />
                  {portfolio.portfolio_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('common:create')} onCancel={props.onCloseButtonClick} />
      </form>
    </Dialog>
  )
}

export default CreateGroupModal
