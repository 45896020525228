import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useUpdateActiveAliastMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, MutationFnParams>({
    mutationFn: ({ datasetRef, datapointRef, activeAliasId }) =>
      api.updateDatapointActiveAlias(datasetRef, datapointRef, activeAliasId),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type MutationFnParams = {
  datasetRef: string
  datapointRef: string
  activeAliasId: number | null
}

export default useUpdateActiveAliastMutation
