import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useClassificationQuery(classificationId: string | number | undefined, options: { enabled: boolean }) {
  const enabled = !!classificationId && !!options.enabled

  return useQuery({
    queryKey: ['classification', classificationId],
    queryFn: () => {
      if (!enabled) {
        return
      }

      return api.getClassification(classificationId)
    },
    cacheTime: 0,
    enabled,
  })
}

export default useClassificationQuery
