import { Box, Stack } from '@mui/material'
import { ReactNode } from 'react'
import Logo from '../../assets/logo'
import Footer from '../footer'

function AuthLayout({ children }: { children: ReactNode }) {
  return (
    <Stack height="100%" bgcolor="background.default">
      <Stack sx={{ alignItems: 'center', p: 6, pb: 0 }}>
        <a href="/">
          <Logo width="275" />
        </a>
      </Stack>
      <Box flex={1} pb={6} bgcolor="background.default">
        {children}
      </Box>
      <Footer />
    </Stack>
  )
}

export default AuthLayout
