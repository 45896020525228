import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { StaticUploadInspection } from '../../../services/data/types/asset-static-data'

export default function useStaticUploadInspectFileMutation() {
  const response = useMutation<AxiosResponse<StaticUploadInspection>, AxiosError, Params>({
    mutationFn: ({ file }) => api.staticUploadInspectFile(file),
  })

  return { ...response, error: parseError(response.error) }
}

type Params = {
  file: File
}
