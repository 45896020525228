import { Upload } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { ChangeEvent, FormEvent, ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { BankRecordsUploadParams, RecordsUploadInspectResponse } from '../../../services/data/types/reconciliation'
import {
  useBankRecordsUploadCommitFileMutation,
  useBankRecordsUploadInspectFileMutation,
  useBankRecordsUploadOptionsQuery,
  useBankRecordsUploadVerifyFileMutation,
} from '../data/bank-records-upload-hooks'

type BankRecordsUploadModalProps = {
  open: boolean
  onSucess: () => void
  onClose: () => void
}

type Columns = Array<string | null>

function BankRecordsUploadModal(props: BankRecordsUploadModalProps) {
  const { open, onSucess, onClose } = props

  const { t } = useTranslation('bankAccounts')

  const [file, setFile] = useState<File>()
  const [entityId, setEntityId] = useState('')
  const [accountId, setAccountId] = useState('')
  const [columns, setColumns] = useState<Columns>([])

  const optionsQuery = useBankRecordsUploadOptionsQuery({ enabled: open })
  const options = optionsQuery.data?.data
  const entities = options?.related_entities || []
  const entity = entities.find((entity) => String(entity.entity_id) === entityId)
  const entityHasConfig = !!entity?.has_config
  const accounts = entity?.accounts || []

  const inspectMutation = useBankRecordsUploadInspectFileMutation()
  const verifyMutation = useBankRecordsUploadVerifyFileMutation()
  const commitMutation = useBankRecordsUploadCommitFileMutation()

  const inspection = inspectMutation.data?.data
  const verifySuccess = !!verifyMutation.data?.data.valid
  const fileErrors: string | null =
    inspectMutation.error ||
    verifyMutation.error ||
    verifyMutation.data?.data.errors ||
    commitMutation.error ||
    commitMutation.data?.data.errors

  const entitySelected = !!entityId
  const accountSelected = !!accountId
  const showInspection = !!inspection
  const canUpload = entitySelected && accountSelected
  const canSubmit = !!file && !!options && !!inspection
  const canVerify = canSubmit && inspectMutation.isSuccess
  const canCommit = canSubmit && verifySuccess

  useEffect(() => {
    reinspectFile()
  }, [entityId, accountId])

  function reinspectFile() {
    if (file) {
      handleInspect(file)
    }
  }

  function handleFileSelected(file: File) {
    setFile(file)
    handleInspect(file)
  }

  function handleFileRemoved() {
    setFile(undefined)
    inspectMutation.reset()
    verifyMutation.reset()
    commitMutation.reset()
  }

  function resetFileSelection() {
    setFile(undefined)
    setColumns([])
    inspectMutation.reset()
    verifyMutation.reset()
    commitMutation.reset()
  }

  function setColumnTagAtIndex(index: number, columnTag: string) {
    const newColumns = [...columns]
    newColumns[index] = columnTag
    setColumns(newColumns)
  }

  function handleInspect(file: File) {
    verifyMutation.reset()
    commitMutation.reset()

    if (!entityId) {
      return
    }

    inspectMutation.mutate(
      {
        entityId: Number(entityId),
        accountId: accountId === 'all' ? null : Number(accountId),
        file,
      },
      {
        onSuccess: (data) => {
          let newColumns: typeof columns = []

          for (let i = 0; i < data.data.headings.length; i++) {
            const column = data.data.columns.find((c) => c.index === i)
            newColumns[i] = columns[i] || column?.tag || null
          }

          setColumns(newColumns)
        },
      }
    )
  }

  function handleVerify() {
    if (!canSubmit) {
      return
    }

    commitMutation.reset()

    const params: BankRecordsUploadParams = {
      headerRow: inspection.header_row,
      columns: columns.map((c) => c || null),
      accountId: accountId === 'all' ? null : Number(accountId),
    }
    verifyMutation.mutate({ params, file })
  }

  function handleCommit() {
    if (!canSubmit) {
      return
    }

    verifyMutation.reset()

    const params: BankRecordsUploadParams = {
      headerRow: inspection.header_row,
      columns: columns.map((c) => c || null),
      accountId: accountId === 'all' ? null : Number(accountId),
    }
    commitMutation.mutate(
      { params, file },
      {
        onSuccess: () => {
          onSucess()
          handleClose()
        },
      }
    )
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    handleCommit()
  }

  function handleClose() {
    onClose()
    setEntityId('')
    setAccountId('')
    resetFileSelection()
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
      PaperProps={{
        sx: {
          position: 'fixed',
          top: '5%',
          maxHeight: '90%',
          my: 0,
        },
      }}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('records_upload_modal.title')} onClose={handleClose} />
        <ModalContent>
          <Stack direction="row" gap={2}>
            <FormControl required sx={{ width: '100%', maxWidth: '250px' }}>
              <InputLabel>{t('records_upload_modal.entity')}</InputLabel>
              <Select
                label={t('records_upload_modal.entity')}
                value={entityId}
                onChange={(event) => {
                  // apparently target.value can be a number sometimes
                  setEntityId(String(event.target.value))
                  setAccountId('')
                  resetFileSelection()
                }}
              >
                {entities.map((entity) => (
                  <MenuItem key={entity.entity_id} value={entity.entity_id}>
                    {entity.entity_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl required disabled={!entitySelected} sx={{ width: '100%', maxWidth: '250px' }}>
              <InputLabel>{t('records_upload_modal.account')}</InputLabel>
              <Select
                label={t('records_upload_modal.account')}
                value={accountId}
                onChange={(event) => {
                  setAccountId(String(event.target.value))
                  resetFileSelection()
                }}
              >
                <MenuItem value="all" disabled={!entityHasConfig}>
                  {entityHasConfig
                    ? t('records_upload_modal.all_accounts')
                    : t('records_upload_modal.all_accounts_not_configured')}
                </MenuItem>

                {!!accounts.length && <Divider />}

                {accounts.map((account) => {
                  const accountName = account.account_name
                  return (
                    <MenuItem key={account.account_id} value={account.account_id} disabled={!account.has_config}>
                      {account.has_config
                        ? accountName
                        : t('records_upload_modal.account_not_configured', { accountName })}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>

          {canUpload && (
            <FileInput
              file={file}
              isUploading={inspectMutation.isLoading}
              onSelect={handleFileSelected}
              onRemove={handleFileRemoved}
            />
          )}

          {showInspection && <Divider />}

          <InspectionTable inspection={inspection} columns={columns} setColumnTagAtIndex={setColumnTagAtIndex} />

          {verifySuccess && (
            <Alert icon={false} variant="outlined">
              <AlertTitle color="rgba(194, 228, 195, 1.00)">{t('common:upload_modal.file_valid')}</AlertTitle>
              {t('common:upload_modal.verify_success_message')}
            </Alert>
          )}

          <FileErrors error={fileErrors} />
        </ModalContent>

        <Divider />

        <DialogActions>
          <Button onClick={handleClose} sx={{ mr: 'auto' }}>
            {t('common:cancel')}
          </Button>
          <Button variant="outlined" color="primary" disabled={!canVerify} onClick={handleVerify}>
            {verifyMutation.isLoading && <CircularProgress size={24} color="inherit" sx={{ position: 'absolute' }} />}
            <Typography color="inherit" variant="inherit" sx={{ opacity: verifyMutation.isLoading ? 0 : 1 }}>
              {t('common:upload_modal.verify')}
            </Typography>
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={!canCommit}>
            {commitMutation.isLoading && <CircularProgress size={24} color="inherit" sx={{ position: 'absolute' }} />}
            <Typography color="inherit" variant="inherit" sx={{ opacity: commitMutation.isLoading ? 0 : 1 }}>
              {t('common:upload_modal.commit')}
            </Typography>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default BankRecordsUploadModal

type FileInputProps = {
  file: File | undefined
  isUploading: boolean
  onSelect: (file: File) => void
  onRemove: () => void
}

function FileInput(props: FileInputProps) {
  const { file, isUploading, onSelect, onRemove } = props

  const { t } = useTranslation('bankAccounts')

  const input = useRef<HTMLInputElement>(null)

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target.files
    const file = files?.[0]

    if (file) {
      onSelect(file)
    }

    input.current!.value = ''
  }

  function handleRemove() {
    input.current!.value = ''
    onRemove()
  }

  return (
    <Box
      sx={{
        height: '192px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        border: 'dashed',
        borderWidth: '1px',
        borderColor: 'gray.300',
        borderRadius: '4px',
        lineHeight: 1,
        marginRight: '16px',
        paddingLeft: '12px',
        overflow: 'hidden',
        transition: 'background 150ms',
        '&:hover': {
          borderColor: 'primary.main',
          backgroundColor: 'primary.light',
        },
      }}
    >
      <input
        ref={input}
        type="file"
        accept="text/csv"
        onChange={handleFileChange}
        style={{
          margin: 0,
          opacity: '0',
          fontSize: '0px',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0px',
          left: '0px',
          cursor: 'pointer',
        }}
      />
      <Stack alignItems="center" gap={1}>
        {isUploading && (
          <>
            <CircularProgress size="20px" />
            <Typography>{t('common:upload_modal.upload_in_progress')}</Typography>
          </>
        )}

        {!isUploading && (
          <>
            <Upload sx={{ color: 'gray.300' }} />
            <Typography>{t('common:upload_modal.select_file_message')}</Typography>
          </>
        )}

        {file && <Chip label={file.name} onDelete={handleRemove} />}

        {!file && !isUploading && <Typography color="gray.300">{t('records_upload_modal.file_types')}</Typography>}
      </Stack>
    </Box>
  )
}

type InspectionTableProps = {
  inspection: RecordsUploadInspectResponse | undefined
  columns: Columns
  setColumnTagAtIndex: (index: number, columnTag: string) => void
}

function InspectionTable(props: InspectionTableProps) {
  const { inspection, columns, setColumnTagAtIndex } = props

  const { t } = useTranslation('bankAccounts')

  if (!inspection) {
    return null
  }

  if (!inspection.rows.length) {
    return <FileErrors error={t('records_upload_modal.empty_rows')} />
  }

  return (
    <TableContainer>
      <UploadTable>
        <TableBody>
          <TableRow>
            {inspection.headings.map((_heading, index) => {
              return (
                <TableCell key={index}>
                  <Select
                    value={columns[index] || ''}
                    onChange={(event) => {
                      setColumnTagAtIndex(index, String(event.target.value))
                    }}
                    sx={{ width: '100%', minWidth: '150px' }}
                  >
                    <MenuItem value="">---</MenuItem>
                    {inspection.columns.map((field) => (
                      <MenuItem key={field.tag} value={field.tag}>
                        {field.name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              )
            })}
          </TableRow>

          <TableRow>
            {inspection.headings.map((heading, index) => (
              <TableCell key={index}>{heading}</TableCell>
            ))}
          </TableRow>

          {inspection.rows.map((row, index) => (
            <TableRow key={index}>
              {row.map((cell, cIndex) => (
                <TableCell key={cIndex}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </UploadTable>
    </TableContainer>
  )
}

type UploadTableProps = {
  children: ReactNode
}

function UploadTable(props: UploadTableProps) {
  return (
    <Table
      size="small"
      sx={{
        '& .MuiTableRow-root .MuiTableCell-root': {
          color: 'white',
          borderRadius: 0,
          borderBottom: '1px solid',
          borderRight: '1px solid',
          borderColor: 'divider',
        },
        '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
          borderLeft: '1px solid',
          borderColor: 'divider',
        },
        '& .MuiTableRow-root:first-of-type .MuiTableCell-root': {
          borderLeft: 'none',
          borderRight: 'none',
          borderBottom: 'none',
          pb: 2,
        },
        '& .MuiTableRow-root:nth-of-type(2) .MuiTableCell-root': {
          backgroundColor: 'gray.50',
          borderTop: '1px solid',
          borderColor: 'divider',
          p: 2,
        },
        '& .MuiTableRow-root:nth-of-type(2) .MuiTableCell-root:first-of-type': {
          borderTopLeftRadius: '4px',
        },
        '& .MuiTableRow-root:nth-of-type(2) .MuiTableCell-root:last-of-type': {
          borderTopRightRadius: '4px',
        },
        '& .MuiTableRow-root:last-of-type .MuiTableCell-root:first-of-type': {
          borderBottomLeftRadius: '4px',
        },
        '& .MuiTableRow-root:last-of-type .MuiTableCell-root:last-of-type': {
          borderBottomRightRadius: '4px',
        },
      }}
    >
      {props.children}
    </Table>
  )
}

type FileErrorsProps = {
  error: string | null
}

function FileErrors(props: FileErrorsProps) {
  const { error } = props

  const { t } = useTranslation()

  if (!error) {
    return null
  }

  return (
    <Alert
      icon={false}
      variant="outlined"
      severity="error"
      sx={{
        borderColor: 'rgba(244, 67, 54, 1)',
        '& .MuiAlert-message': { width: '100%' },
      }}
    >
      <AlertTitle color="rgba(251, 180, 175, 1.00)">{t('common:upload_modal.file_errors')}</AlertTitle>
      <TextField
        value={props.error}
        InputProps={{ readOnly: true }}
        multiline
        sx={{
          width: '100%',
          '& fieldset': { border: '0 !important' },
          '& textarea': { color: 'rgba(251, 180, 175, 1.00)' },
          '& .MuiInputBase-root': { px: 0 },
        }}
      />
    </Alert>
  )
}
