import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useAssetStaticQuery(assetType: string, datasetRef: string | null, options: { enabled: boolean }) {
  return useQuery({
    queryKey: ['assetType', assetType, 'static', datasetRef],
    queryFn: () => {
      if (assetType) {
        return api.getAssetStatic(assetType, datasetRef)
      }
    },
    keepPreviousData: true,
    enabled: !!assetType && options.enabled,
  })
}

export default useAssetStaticQuery
