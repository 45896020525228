import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useTradeTicketQuery(options: { enabled: boolean }) {
  return useQuery({
    queryKey: ['tradeticket'],
    queryFn: api.getTradeTicket,
    cacheTime: 0,
    enabled: options.enabled,
  })
}

export default useTradeTicketQuery
