import { ManageAccounts, SupervisorAccount } from '@mui/icons-material'
import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useOpenState from '../../../utils/hooks/use-open-state'
import SwitchTenantModal from './switch-tenant-modal'

type TenantMenuProps = {
  anchorEl: HTMLElement | null
  onClose: () => void
  onSwitchTenantSuccess: () => void
}

function TenantMenu(props: TenantMenuProps) {
  const { anchorEl, onClose, onSwitchTenantSuccess } = props

  const { t } = useTranslation('account')

  const switchTenantModal = useOpenState()

  function handleSwitchTenantModalOpen() {
    switchTenantModal.open()
    onClose()
  }

  return (
    <>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: { mt: 1 },
        }}
      >
        <MenuItem onClick={handleSwitchTenantModalOpen}>
          <ListItemIcon>
            <SupervisorAccount sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{t('switch_tenant')}</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <ManageAccounts sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{t('manage_tenant')}</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
      <SwitchTenantModal
        open={switchTenantModal.isOpen}
        onClose={switchTenantModal.close}
        onSuccess={onSwitchTenantSuccess}
      />
    </>
  )
}

export default TenantMenu
