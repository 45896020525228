import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useDatapointDetailsQuery(datapointRef: string, options: { enabled: boolean }) {
  const enabled = !!datapointRef && !!options.enabled

  return useQuery({
    queryKey: ['datapoint', datapointRef],
    queryFn: () => {
      if (!enabled) {
        return
      }

      return api.getDatapoint(datapointRef)
    },
    cacheTime: 0,
    enabled,
  })
}

export default useDatapointDetailsQuery
