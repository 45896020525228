import { useParams } from 'react-router-dom'
import DatapointDetails from '../../components/datapoint-details'

function DatapointDetailsView() {
  const { assetRef, datapointRef, datasetRef } = useParams()

  if (!assetRef || !datapointRef || !datasetRef) {
    return null
  }

  return <DatapointDetails assetRef={assetRef} datapointRef={datapointRef} datasetRef={datasetRef} />
}

export default DatapointDetailsView
