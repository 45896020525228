import { RestartAlt, Send, Settings } from '@mui/icons-material'
import { ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Switch, Tooltip, Typography } from '@mui/material'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../../config'

type ModellerMenuProps = {
  anchorEl: HTMLElement | null
  modellerOn: boolean
  onModellerSwitch?: (checked: boolean) => void
  onModellingOptionsClick?: () => void
  onModellerResetClick?: () => void
  onModellerSendToTradingClick?: () => void
  onClose: () => void
}

function ModellerMenu(props: ModellerMenuProps) {
  const {
    anchorEl,
    modellerOn,
    onModellerSwitch,
    onModellingOptionsClick,
    onModellerResetClick,
    onModellerSendToTradingClick,
    onClose,
  } = props

  const { t } = useTranslation('dataTable')

  function handleModellerSwitch(_: ChangeEvent, checked: boolean) {
    onModellerSwitch?.(checked)
  }

  return (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
      PaperProps={{
        sx: {
          mt: 0.5,
          width: 330,
        },
      }}
    >
      <ListItem sx={{ py: 0, pl: 3, pr: 1 }}>
        <ListItemText>
          <Typography variant="overline" color="gray.700">
            {t('modeller')}
          </Typography>
        </ListItemText>
        <Tooltip title={!config.enableUnreadyFeatures ? t('modeller_message') : ''} arrow disableInteractive>
          <div>
            <Switch
              size="small"
              disabled={!config.enableUnreadyFeatures}
              checked={modellerOn}
              onChange={handleModellerSwitch}
            />
          </div>
        </Tooltip>
      </ListItem>

      <MenuItem disabled={!modellerOn} onClick={onModellerResetClick}>
        <ListItemIcon>
          <RestartAlt sx={{ color: 'gray.300' }} />
        </ListItemIcon>
        <ListItemText>
          <Typography>{t('common:reset')}</Typography>
        </ListItemText>
      </MenuItem>

      <MenuItem disabled={!modellerOn} onClick={onModellingOptionsClick}>
        <ListItemIcon>
          <Settings sx={{ color: 'gray.300' }} />
        </ListItemIcon>
        <ListItemText>
          <Typography>{t('common:options')}</Typography>
        </ListItemText>
      </MenuItem>

      <MenuItem disabled={!modellerOn} onClick={onModellerSendToTradingClick}>
        <ListItemIcon>
          <Send sx={{ color: 'gray.300' }} />
        </ListItemIcon>
        <ListItemText>
          <Typography>{t('send_to_trading')}</Typography>
        </ListItemText>
      </MenuItem>
    </Menu>
  )
}

export default ModellerMenu
