import { Dialog } from '@mui/material'
import { FormEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import { ClassificationPayload } from '../../../services/data/types/classifications'
import { Datapoint } from '../../../services/data/types/datapoint'
import useClassificationQuery from '../data/use-classification-query'
import useEditClassificationMutation from '../data/use-edit-classification-mutation'
import ClassificationForm, { ClassificationFormValues } from './classification-form'

type EditClassificationModalProps = {
  open: boolean
  classificationId?: string | number
  datasetRef: string
  datapoints: Datapoint[]
  onClose: () => void
}

function EditClassificationModal(props: EditClassificationModalProps) {
  const { open, classificationId, datasetRef, datapoints, onClose } = props
  const { t } = useTranslation('dataEngine')

  const {
    error: editClassificationError,
    mutate: editClassification,
    reset: editClassificationReset,
  } = useEditClassificationMutation()

  const classificationResponse = useClassificationQuery(classificationId, { enabled: open })
  const classification = classificationResponse.data?.data

  const initialFormValues = useMemo<ClassificationFormValues>(() => {
    return {
      name: classification?.classification_name || '',
      type: classification?.classification_type || 'fixed',
      bucketRef: classification?.bucket_datapoint_ref || '',
    }
  }, [classification])

  const [formValues, setFormValues] = useState(initialFormValues)

  useEffect(() => {
    setFormValues(initialFormValues)
  }, [initialFormValues])

  function handleChange(name: string, value: string) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (!classificationId) {
      return
    }

    const bucketRef = formValues.bucketRef === '' ? null : formValues.bucketRef

    const payload: ClassificationPayload = {
      name: formValues.name,
      classification_type: formValues.type,
      bucket_datapoint_ref: formValues.type === 'auto' ? bucketRef : null,
    }

    editClassification(
      { classificationId, payload },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['classification', classificationId])
          queryClient.invalidateQueries(['dataset', datasetRef, 'classifications'])
          handleClose()
        },
      }
    )
  }

  function handleClose() {
    onClose()
    editClassificationReset()
    setFormValues(initialFormValues)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('classifications_table.edit')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={editClassificationError} />
          <ClassificationForm datapoints={datapoints} values={formValues} onChange={handleChange} />
        </ModalContent>
        <ModalActions confirmLabel={t('common:save')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default EditClassificationModal
