import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useClassificationsQuery(datasetRef: string | null | undefined) {
  return useQuery({
    queryKey: ['dataset', datasetRef, 'classifications'],
    queryFn: () => {
      if (!datasetRef) {
        return
      }
      return api.getClassifications(datasetRef)
    },
    enabled: !!datasetRef,
  })
}

export default useClassificationsQuery
