import { Cancel } from '@mui/icons-material'
import {
  Avatar,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'
import { ChangeEvent, SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '../../../services/data/types/group'
import useUserInfo from '../../auth/data/use-user-info'
import useGroupsQuery from '../data/use-groups-query'

type DatasetFormProps = {
  type: 'new' | 'edit'
  values: DatasetFormValues
  onChange: (name: string, value: string | string[] | boolean) => void
}

type DatasetFormValues = {
  owner: string
  datasetName: string
  published: boolean
  sharedWith: string[]
}

function DatasetForm(props: DatasetFormProps) {
  const { type, values, onChange } = props
  const { t } = useTranslation('dataEngine')

  const userInfoResponse = useUserInfo()
  const isSuperUser = userInfoResponse.user?.is_superuser

  const groupsResponse = useGroupsQuery()
  const groups = groupsResponse.data?.data
  const sharedWithGroups = groups?.filter((group) => group.group_ref !== values.owner) || []

  const isOwnerVisible = type === 'new'

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) {
    const { name, value } = event.target
    onChange(name, value)
  }

  function handleCheckboxChange(event: SyntheticEvent, checked: boolean) {
    const { name } = event.target as HTMLInputElement
    onChange(name, checked)
  }

  return (
    <>
      {isOwnerVisible && (
        <FormControl>
          <InputLabel required>{t('owner')}</InputLabel>
          <Select required name="owner" label={t('owner')} value={values.owner} onChange={handleChange}>
            {groups?.map((group) => (
              <MenuItem key={group.group_ref} value={group.group_ref}>
                {group.group_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <TextField
        required
        name="datasetName"
        label={t('dataset_name')}
        value={values.datasetName}
        onChange={handleChange}
      />
      <SharedWithField value={values.sharedWith} groups={sharedWithGroups} onChange={onChange} />
      {isSuperUser && (
        <FormControlLabel
          name="published"
          label={t('published')}
          checked={values.published}
          onChange={handleCheckboxChange}
          control={<Checkbox />}
          sx={{ ml: 0.5 }}
        />
      )}
    </>
  )
}

export default DatasetForm

type SharedWithFieldProps = {
  value: string[]
  groups: Group[]
  onChange: (name: string, value: string[]) => void
}

function SharedWithField(props: SharedWithFieldProps) {
  const { value, groups, onChange } = props
  const { t } = useTranslation('dataEngine')

  const selectedValues = groups.filter((group) => value.includes(group.group_ref))

  function handleChange(event: SelectChangeEvent<string[]>) {
    const { name, value } = event.target
    const newValue = typeof value === 'string' ? value.split(',') : value
    onChange(name, newValue)
  }

  function handleRemoveGroup(groupRef: string) {
    const newValues = value.filter((ref) => ref !== groupRef)
    onChange('sharedWith', newValues)
  }

  return (
    <FormControl>
      <InputLabel>{t('shared_with')}</InputLabel>
      <Select
        multiple
        name="sharedWith"
        label={t('shared_with')}
        value={value}
        onChange={handleChange}
        renderValue={() => {
          return (
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {selectedValues?.map((group) => (
                <Chip
                  key={group.group_ref}
                  avatar={<Avatar>{group.group_name[0]}</Avatar>}
                  label={group.group_name}
                  onDelete={() => handleRemoveGroup(group.group_ref)}
                  onMouseDown={(event) => event.stopPropagation()}
                  deleteIcon={<Cancel />}
                  sx={{
                    color: 'inherit',
                    cursor: 'inherit',
                    '& .MuiChip-avatar': {
                      color: 'gray.light',
                      backgroundColor: 'gray.400',
                    },
                    '& .MuiChip-deleteIcon': {
                      color: 'gray.300',
                    },
                  }}
                />
              ))}
            </Stack>
          )
        }}
        MenuProps={{
          sx: {
            '& .MuiMenuItem-root:not(:last-child)': {
              mb: 0.5,
            },
          },
        }}
      >
        {groups.map((group) => (
          <MenuItem key={group.group_ref} value={group.group_ref}>
            {group.group_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
