import { SnackbarCloseReason, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import ConfirmationModal from '../../../components/confirmation-modal'
import ErrorSnackbar from '../../../components/error-snackbar'
import AppLayout from '../../../components/layouts/app-layout'
import { ParsedGridData, getPanelColumns, parseGridData } from '../../../services/data/grid-data-parsing'
import { GridDataViewCreateBody, GridDataViewUpdateBody } from '../../../services/data/types/grid-data-view'
import { ExportPortfolioParams } from '../../../services/data/types/portfolio'
import { TxnStatus } from '../../../services/data/types/transaction'
import { downloadBlobFile } from '../../../utils/download'
import useOpenState from '../../../utils/hooks/use-open-state'
import useUserInfo from '../../auth/data/use-user-info'
import useRegularPortfolioDetailsQuery from '../../settings/data/use-regular-portfolio-details-query'
import useCancelTransactionMutation from '../data/use-cancel-transaction-mutation'
import useExportPortfolioMutation from '../data/use-export-portfolio-mutation'
import { useFilterOptions } from '../data/use-filter-options'
import useTransactionGridOptionsQuery from '../data/use-transaction-grid-options-query'
import useTransactionsQuery from '../data/use-transactions-query'
import useUpdateGridDataViewMutation from '../data/use-update-grid-data-view-mutation'
import useViewConfigState, { initColumnOrder } from '../data/use-view-config-state'
import PortfolioGridNav from './portfolio-grid-nav'
import PortfolioTable from './portfolio-table'
import PortfolioUploadModal from './portfolio-upload-modal'
import TransactionsGridHeader from './transactions-grid-header'
import { ViewEditParams } from './view-edit-modal'
import ViewOptionsMenu from './view-options-menu'
import { ViewSaveAsParams } from './view-save-as-modal'

type TransactionsGridProps = {
  portfolioRef?: string
  onPortfolioSelect: (portfolioRef: string) => void
  onCorrectTransaction: (txnRef: string) => void
  onCloseOutTransaction: (segmentRef: string) => void
  onConfirmTrade: (dealRef: string) => void
  onNewTrade: () => void
  onNewCashflow: () => void
  onTabNameUpdate?: (name: string | undefined) => void
}

export type SelectedTransaction = {
  txnRef: string | undefined
  txnStatus: TxnStatus | undefined
}

function TransactionsGrid(props: TransactionsGridProps) {
  const {
    portfolioRef,
    onPortfolioSelect,
    onCorrectTransaction,
    onCloseOutTransaction,
    onConfirmTrade,
    onNewTrade,
    onNewCashflow,
    onTabNameUpdate,
  } = props

  const { t } = useTranslation('portfolio')
  const [, setSearchParams] = useSearchParams()

  const { user, isGridsOnlyUser } = useUserInfo()

  const nav = useOpenState({ open: true })
  const confirmCancelModal = useOpenState()
  const uploadModal = useOpenState()
  const viewsMenu = useOpenState()

  const updateView = useUpdateGridDataViewMutation()
  const exportPortfolio = useExportPortfolioMutation()
  const cancelTransaction = useCancelTransactionMutation()

  // When tableKey changes, the PortfolioTable component is recreated.
  // This is needed so AgGrid doesn't animate when the whole grid data changes,
  // making changing between portfolios or views instant, but other interations still animate.
  const [tableKey, setTableKey] = useState('')
  const [header, setHeader] = useState({ title: '', currency: '' })

  const [selectedTransaction, setSelectedTransaction] = useState<SelectedTransaction | null>(null)
  const [parsedGridData, setParsedGridData] = useState<ParsedGridData | null>(null)

  const columnsOptions = parsedGridData?.columnsOptions || []
  const shownColumnsCount = parsedGridData?.shownColumnsCount

  const gridOptionsResponse = useTransactionGridOptionsQuery(portfolioRef)
  const gridOptions = gridOptionsResponse.data?.data

  const portfolioQuery = useRegularPortfolioDetailsQuery(portfolioRef)
  const portfolio = portfolioQuery.data?.data

  const {
    viewConfigState,
    setAggregations,
    hideAllColumns,
    unhideAllColumns,
    toggleHiddenColumn,
    setFilters,
    addFilters,
    deleteFilter,
    setSortBy,
    resetState,
    setView,
    setDataset,
    moveColumn,
    moveColumnToPanel,
    removeColumnFromPanel,
    setColumnWidth,
    renameColumn,
    isStateReady,
  } = useViewConfigState(gridOptions)

  const { datasetRef, viewRef, aggregations, filters, sortBy, columns, isViewDirty } = viewConfigState

  // panelColumns could be on the parsedGridData, but this is needed because
  // it makes sure there is a single grid request on a page reload
  const panelColumns = getPanelColumns(columns) || []

  const transactionsQuery = useTransactionsQuery(portfolioRef, datasetRef, aggregations, filters, panelColumns, {
    enabled: isStateReady,
  })

  const data = transactionsQuery.data?.data || null

  const currentView = gridOptions?.views?.find((view) => view.view_ref === viewRef)
  const currentViewName = currentView?.view_name
  const filterOptions = useFilterOptions(parsedGridData?.columnsOptions, datasetRef)

  let cancelLabel = t('cancel_transaction')
  let confirmCancelMessage = t('confirm_cancel_transaction_message')
  const txnStatus = selectedTransaction?.txnStatus

  if (txnStatus === 'approved') {
    cancelLabel = t('cancel_approval')
    confirmCancelMessage = t('confirm_cancel_approval_message')
  } else if (txnStatus === 'confirmed') {
    cancelLabel = t('cancel_confirmation')
    confirmCancelMessage = t('confirm_cancel_confirmation_message')
  } else if (txnStatus === 'closed' || txnStatus === 'close_out') {
    cancelLabel = t('cancel_close_out')
    confirmCancelMessage = t('confirm_cancel_close_out_message')
  }

  useEffect(() => {
    if (!transactionsQuery.isFetching && !transactionsQuery.isError) {
      const title = [data?.portfolio_name, currentViewName].filter(Boolean).join(' / ')
      const currency = data?.base_currency || ''

      setHeader({ title, currency })
      onTabNameUpdate?.(currentViewName)
      setTableKey(`portfolio-${portfolioRef}_view-${viewRef}`)
      setParsedGridData(parseGridData(data, columns, sortBy))
    }
  }, [data, columns, sortBy, currentViewName, transactionsQuery.isFetching, transactionsQuery.isError])

  function handlePortfolioClick(portfolioref: string) {
    resetState()
    onPortfolioSelect(portfolioref)
  }

  function handleToggleHiddenColumn(datapointRef: string) {
    toggleHiddenColumn(datapointRef, parsedGridData?.originalHeadings!)
  }

  function handleMoveColumn(fromDatapointRef: string, toDatapointRef: string) {
    moveColumn(fromDatapointRef, toDatapointRef, parsedGridData?.originalHeadings!)
  }

  function handleMoveColumnToPanel(datapointRef: string) {
    moveColumnToPanel(datapointRef, parsedGridData?.originalHeadings!)
  }

  function handleRemoveColumnFromPanel(datapointRef: string) {
    removeColumnFromPanel(datapointRef, parsedGridData?.originalHeadings!)
  }

  function handleSetColumnWidth(datapointRef: string, width: number) {
    setColumnWidth(datapointRef, width, parsedGridData?.originalHeadings!)
  }

  function handleRenameColumn(datapointRef: string, name: string) {
    renameColumn(datapointRef, name, parsedGridData?.originalHeadings!)
  }

  function handleViewOptionsOpen() {
    viewsMenu.open()
  }

  function createEditViewBody(params: ViewEditParams): GridDataViewUpdateBody | undefined {
    if (!gridOptions || !parsedGridData) {
      return
    }

    const body: GridDataViewUpdateBody = {
      view_name: params.viewName,
      owner_group_ref: params.ownerGroupRef,
    }

    return body
  }

  function createSaveViewBody(): GridDataViewUpdateBody | undefined {
    if (!parsedGridData) {
      return
    }

    const body: GridDataViewUpdateBody = {
      view_options: {
        aggregation: aggregations,
        sort_by: sortBy,
        filters,
        columns: initColumnOrder(columns, parsedGridData?.originalHeadings || []),
        panel_mode: null,
        asset_types: null,
        as_of_date: null,
      },
    }

    return body
  }

  function createSaveAsViewBody(params: ViewSaveAsParams): GridDataViewCreateBody | undefined {
    if (!data?.dataset) {
      return
    }

    const body: GridDataViewCreateBody = {
      view_name: params.name,
      dataset_ref: datasetRef || data?.dataset,
      view_type: 'transactions_grid',
      view_options: {
        aggregation: aggregations,
        sort_by: sortBy,
        filters,
        columns: initColumnOrder(columns, parsedGridData?.originalHeadings || []),
        panel_mode: null,
        asset_types: null,
        as_of_date: null,
      },
    }

    return body
  }

  function handleViewSaveAsSuccess(data: any) {
    setSearchParams({ viewRef: data.view_ref })
    gridOptionsResponse.refetch()
    viewsMenu.close()
  }

  async function handleViewDeleteSuccess(deletedViewRef: string) {
    if (deletedViewRef === viewRef) {
      viewsMenu.close()

      // await needed so we don't use the deleted view as fallback for the grid request
      await gridOptionsResponse.refetch()
      setSearchParams({})
      resetState()
      return
    }

    await gridOptionsResponse.refetch()
  }

  function handleCancelTransaction() {
    const txnRef = selectedTransaction?.txnRef
    if (portfolio && txnRef) {
      cancelTransaction.mutate(
        {
          lei: portfolio.lei,
          txnRef,
        },
        {
          onSuccess: () => {
            transactionsQuery.refetch()
            confirmCancelModal.close()
            setSelectedTransaction(null)
          },
        }
      )
    }
  }

  function handleCancelTransactionModalClose() {
    cancelTransaction.reset()
    confirmCancelModal.close()
    setSelectedTransaction(null)
  }

  function handleExportTransactions() {
    if (!portfolioRef || !data) {
      return
    }

    const params: ExportPortfolioParams = {
      portfolioRef,
      portfolioName: data.portfolio_name,
      viewRef,
      viewName: currentView?.view_name,
      exportTransactions: true,
      asOfDate: null,
    }

    exportPortfolio.mutate(params, {
      onSuccess: (response) => {
        downloadBlobFile(response.data.blob, response.data.filename)
      },
    })
  }

  function handleAlertClose(_event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
    if (reason === 'clickaway') {
      return
    }
    updateView.reset()
  }

  function handlePortfolioUploadSuccess() {
    reloadData()
    uploadModal.close()
  }

  function reloadData() {
    gridOptionsResponse.refetch()
    transactionsQuery.refetch()
  }

  return (
    <>
      <AppLayout direction="row">
        <PortfolioGridNav
          open={nav.isOpen}
          selectedPortfolioRef={portfolioRef}
          navTitle={t('common:navigation.transactions')}
          onCloseClick={nav.close}
          onPortfolioSelect={handlePortfolioClick}
        />
        <Stack flex={1} overflow="hidden">
          <TransactionsGridHeader
            portfolioTitle={header.title}
            currency={header.currency}
            showLoading={transactionsQuery.isFetching}
            isNavOpen={nav.isOpen}
            isViewDirty={isViewDirty}
            isGridsOnlyUser={isGridsOnlyUser}
            onOpenNavClick={nav.open}
            onNewCashflowClick={onNewCashflow}
            onNewTradeClick={onNewTrade}
            onViewOptionsClick={handleViewOptionsOpen}
            onUploadClick={uploadModal.open}
            onExportTransactions={handleExportTransactions}
            onReloadData={reloadData}
          />
          <Stack sx={{ height: '100%', px: 2, pb: 2, overflow: 'hidden' }}>
            <PortfolioTable
              key={tableKey}
              hideModeller
              hideLockCellButton
              data={parsedGridData}
              aggregations={aggregations}
              filters={filters}
              filterOptions={filterOptions}
              columnsOptions={columnsOptions}
              shownColumnsCount={shownColumnsCount}
              sortBy={sortBy}
              canOnlyApprove={gridOptions?.can_only_approve}
              isGridsOnlyUser={isGridsOnlyUser}
              cancelLabel={cancelLabel}
              onAggregationsChange={setAggregations}
              onSortChange={setSortBy}
              onAddFilters={addFilters}
              onDeleteFilter={deleteFilter}
              onDeleteAllFilters={() => setFilters(null)}
              onToggleHiddenColumn={handleToggleHiddenColumn}
              onHideAllColumns={hideAllColumns}
              onUnhideAllColumns={unhideAllColumns}
              onMoveColumn={handleMoveColumn}
              onMoveColumnToPanel={handleMoveColumnToPanel}
              onRemoveColumnFromPanel={handleRemoveColumnFromPanel}
              onSetColumnWidth={handleSetColumnWidth}
              onRenameColumn={handleRenameColumn}
              onCorrectTransaction={onCorrectTransaction}
              onCloseOutTransaction={onCloseOutTransaction}
              onConfirmTrade={onConfirmTrade}
              onTransactionSelect={setSelectedTransaction}
              onCancelTransaction={confirmCancelModal.open}
            />
          </Stack>
        </Stack>
      </AppLayout>

      <ViewOptionsMenu
        open={viewsMenu.isOpen}
        views={gridOptions?.views}
        defaultViewRef={gridOptions?.default_view_ref}
        datasets={gridOptions?.datasets}
        isViewDirty={isViewDirty}
        userGroupRef={user?.group_ref}
        currentView={currentView}
        portfolioRef={portfolioRef}
        onViewSelect={setView}
        onDatasetSelect={setDataset}
        createSaveViewBody={createSaveViewBody}
        createSaveAsViewBody={createSaveAsViewBody}
        createEditViewBody={createEditViewBody}
        onViewSaveAsSuccess={handleViewSaveAsSuccess}
        onViewDeleteSuccess={handleViewDeleteSuccess}
        onClose={viewsMenu.close}
      />

      <ConfirmationModal
        open={confirmCancelModal.isOpen}
        error={cancelTransaction.error}
        isLoading={cancelTransaction.isLoading}
        onConfirmButtonClick={handleCancelTransaction}
        onCloseButtonClick={handleCancelTransactionModalClose}
        title={cancelLabel}
        confirmButtonText={cancelLabel}
      >
        <Typography variant="body1">{confirmCancelMessage}</Typography>
      </ConfirmationModal>
      <PortfolioUploadModal
        open={uploadModal.isOpen}
        portfolioRef={portfolioRef}
        onClose={uploadModal.close}
        onSucess={handlePortfolioUploadSuccess}
      />

      <ErrorSnackbar open={transactionsQuery.isError} message={transactionsQuery.error} />
      <ErrorSnackbar open={!!updateView.error} message={updateView.error} onClose={handleAlertClose} />
      <ErrorSnackbar open={!!exportPortfolio.error} message={exportPortfolio.error} onClose={exportPortfolio.reset} />
    </>
  )
}

export default TransactionsGrid
