import { AxiosError } from 'axios'
import { i18n } from '../../i18n'

function parseError(error: AxiosError<ServerError | any> | null): string | null {
  const status = error?.response?.status

  if (!error || !status) {
    return null
  }

  if (status >= 500) {
    return i18n.t('common:general_error_message')
  }

  if (!error.response?.data?.error?.msg) {
    return error.response?.statusText || i18n.t('common:general_error_message')
  }

  const message = error.response.data.error.msg
  const code = error.response.data.error.code

  return `${message} (${code})`
}

export type ServerError = {
  error: {
    code: string
    msg: string
  }
}

export default parseError
