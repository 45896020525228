import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'

function NoMoreOptionsAlert() {
  const { t } = useTranslation('common')
  return (
    <Alert
      severity="info"
      variant="filled"
      sx={{
        color: 'gray.700',
        background: 'rgba(255, 255, 255, 0.08)',
      }}
    >
      {t('no_options_left')}
    </Alert>
  )
}

export default NoMoreOptionsAlert
