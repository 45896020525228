import { Button } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'

type FilterFormButtonsProps = {
  submitDisabled?: boolean
  clearDisabled: boolean
  onCancel: () => void
  onClear?: () => void
}

function FilterFormButtons(props: FilterFormButtonsProps) {
  const { submitDisabled, clearDisabled, onCancel, onClear } = props

  const { t } = useTranslation()

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      gap={1}
      sx={{
        px: 3,
        py: 1,
        '& .MuiButton-root': {
          fontSize: 13,
        },
      }}
    >
      {onClear && (
        <Button variant="text" onClick={onClear} disabled={clearDisabled} sx={{ mr: 'auto' }}>
          {t('common:clear')}
        </Button>
      )}
      <Button variant="text" onClick={onCancel}>
        {t('common:cancel')}
      </Button>
      <Button type="submit" variant="contained" disabled={submitDisabled}>
        {t('common:apply')}
      </Button>
    </Stack>
  )
}

export default FilterFormButtons
