import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useAssetImportsQuery() {
  return useQuery({
    queryKey: ['assets', 'imports'],
    queryFn: api.getAssetImports,
  })
}

export default useAssetImportsQuery
