import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useAvailablePermissionsQuery(groupRef: string) {
  return useQuery(['group', groupRef, 'permissions', 'available'], () => {
    return api.getAvailablePermissions(groupRef)
  })
}

export default useAvailablePermissionsQuery
