import { ListItemText, SxProps } from '@mui/material'

type MenuItemLabelProps = {
  label: string
  sx?: SxProps
}

export function MenuItemLabel(props: MenuItemLabelProps) {
  return (
    <ListItemText
      primary={props.label}
      primaryTypographyProps={{ variant: 'overline', color: 'gray.700' }}
      sx={{ px: 3, ...props.sx }}
    />
  )
}
