import { AttachMoney, Cancel, ContentCopy, Edit } from '@mui/icons-material'
import {
  ClickAwayListener,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverPosition,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

export type ContextMenuState = {
  coordinates: { mouseX: number; mouseY: number }
  editTxn: {
    txnRef: string
    isBankTxn: boolean
  } | null
  recordId: number | null
  extSegmentRef: string | null
  canEdit: boolean
  canCancel: boolean
  canUnmatch: boolean
  canUnreconcile: boolean
  canCreateBankPayment: boolean
}

type ContextMenuProps = {
  state: ContextMenuState | null
  onEdit: () => void
  onCancel: () => void
  onUnmatch: () => void
  onUnreconcile: () => void
  onCreateBankTxn: () => void
  onCopy: () => void
  onClose: () => void
}

function ContextMenu(props: ContextMenuProps) {
  const { state, onEdit, onCancel, onUnmatch, onUnreconcile, onCreateBankTxn, onCopy, onClose } = props

  const { t } = useTranslation('bankAccounts')

  const isOpen = !!state

  const coordinates = state?.coordinates
  const anchorPosition: PopoverPosition | undefined = !!coordinates
    ? { top: coordinates.mouseY, left: coordinates.mouseX }
    : undefined

  const canEdit = !!state?.canEdit
  const canCancel = !!state?.canCancel
  const canUnmatch = !!state?.canUnmatch
  const canUnreconcile = !!state?.canUnreconcile
  const canCreateBankPayment = !!state?.canCreateBankPayment
  const showDivider = canEdit || canCancel || canUnmatch || canUnreconcile || canCreateBankPayment

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Menu
        open={isOpen}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        disableScrollLock
        transitionDuration={{ exit: 0 }}
        sx={{ position: 'static' }}
        PaperProps={{
          sx: { zIndex: 5 },
        }}
      >
        {canCreateBankPayment && (
          <MenuItem onClick={onCreateBankTxn}>
            <ListItemIcon>
              <AttachMoney sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('context_menu.create_bank_txn')}</Typography>
            </ListItemText>
          </MenuItem>
        )}

        {canUnmatch && (
          <MenuItem onClick={onUnmatch}>
            <ListItemIcon>
              <Cancel sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('context_menu.unmatch')}</Typography>
            </ListItemText>
          </MenuItem>
        )}

        {canUnreconcile && (
          <MenuItem onClick={onUnreconcile}>
            <ListItemIcon>
              <Cancel sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('context_menu.unreconcile')}</Typography>
            </ListItemText>
          </MenuItem>
        )}

        {canEdit && (
          <MenuItem onClick={onEdit}>
            <ListItemIcon>
              <Edit sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('context_menu.edit')}</Typography>
            </ListItemText>
          </MenuItem>
        )}

        {canCancel && (
          <MenuItem onClick={onCancel}>
            <ListItemIcon>
              <Cancel sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('context_menu.cancel')}</Typography>
            </ListItemText>
          </MenuItem>
        )}

        {showDivider && <Divider />}

        <MenuItem onClick={onCopy}>
          <ListItemIcon>
            <ContentCopy sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography fontSize="14px">{t('common:copy')}</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </ClickAwayListener>
  )
}

export default ContextMenu
