import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Alert as MuiAlert, SxProps } from '@mui/material'

type AlertProps = {
  severity: 'error' | 'success' | 'warning'
  message?: string | null
  sx?: SxProps
  onClose?: () => void
}

function Alert(props: AlertProps) {
  if (!props.message) {
    return null
  }

  return (
    <MuiAlert
      variant="filled"
      action={props.onClose && <Close onClose={props.onClose} />}
      severity={props.severity}
      sx={{ color: 'white', ...props.sx }}
    >
      {props.message}
    </MuiAlert>
  )
}

type CloseButtonProps = {
  onClose: () => void
}

function Close(props: CloseButtonProps) {
  return (
    <IconButton onClick={props.onClose} sx={{ p: '5px', color: 'inherit' }}>
      <CloseIcon sx={{ maxWidth: '20px', maxHeight: '20px' }} />
    </IconButton>
  )
}

export default Alert
