import { SvgIcon } from '@mui/material'
import { forwardRef } from 'react'

const TriangleIcon = forwardRef((props: any, ref: any) => {
  return (
    <SvgIcon viewBox="-7 0 15 15" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path d="M7.99976 8V0H-0.000244141L7.99976 8Z" />
    </SvgIcon>
  )
})

export default TriangleIcon
