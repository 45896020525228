import { useNavigate } from 'react-router-dom'
import Groups from '../../components/groups'

const GroupsView = () => {
  const navigate = useNavigate()

  return <Groups onGroupClick={(groupRef) => navigate(`/openfin/views/settings/groups/${groupRef}`)} />
}

export default GroupsView
