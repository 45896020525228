import { useNavigate } from 'react-router-dom'
import { ChannelAction, connectAuthChannel, getCurrentWindow } from '../../../../services/openfin/fin'
import TenantLogin from '../../components/tenant-login'
import { useAuth } from '../../data/auth-context'

function TenantLoginView() {
  const navigate = useNavigate()
  const { logout } = useAuth()

  async function handleSuccess() {
    const client = await connectAuthChannel()
    client.dispatch(ChannelAction.login)

    const window = await getCurrentWindow()
    window.close()
  }

  async function handleBackToLogin() {
    await logout()
    navigate('/openfin/views/login')
  }

  async function handleNavigateToAccount() {
    navigate('/openfin/views/account-details')
  }

  return (
    <TenantLogin
      onSuccess={handleSuccess}
      onBackToLogin={handleBackToLogin}
      onNavigateToAccount={handleNavigateToAccount}
    />
  )
}

export default TenantLoginView
