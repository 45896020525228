import UploadIcon from '@mui/icons-material/Upload'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TableContainerHeader from '../../../components/table-container-header'
import { formatDatetime } from '../../../utils/dates'
import useOpenState from '../../../utils/hooks/use-open-state'
import useModelDetailsQuery from '../data/use-model-details-query'
import DataEngineShell from './data-engine-shell'
import UploadModel from './upload-model'
import { useDataEngineUpdateLoadingState } from './use-data-engine-outlet-context'

type ModelDetailsProps = {
  modelRef: string
}

function ModelDetails(props: ModelDetailsProps) {
  const { modelRef } = props

  const { t } = useTranslation('dataEngine')

  const [selectedVersion, setSelectedVersion] = useState<string | null>(null)
  const uploadModelModal = useOpenState()

  const modelResponse = useModelDetailsQuery(modelRef, selectedVersion)
  const modelDetails = modelResponse?.data?.data

  useDataEngineUpdateLoadingState(modelResponse.isFetching)

  function handleVersionChange(event: SelectChangeEvent) {
    setSelectedVersion(event.target.value)
  }

  if (!modelDetails || modelResponse.isLoading) {
    return <DataEngineShell />
  }

  return (
    <DataEngineShell>
      <Stack p={2} gap={2}>
        <Paper
          component={Stack}
          direction="row"
          overflow="auto"
          elevation={0}
          p={3}
          sx={{ backgroundColor: 'gray.main', alignItems: 'center' }}
        >
          <Detail label={t('model_values_table.model_name')} value={modelDetails.model_name} />
          <Detail label={t('model_values_table.source_dataset')} value={modelDetails.dataset_name} />
          <FormControl sx={{ width: '100%', maxWidth: 200 }}>
            <InputLabel>{t('model_values_table.as_at_date')}</InputLabel>
            <Select
              label={t('model_values_table.as_at_date')}
              value={selectedVersion || modelDetails.version || ''}
              onChange={handleVersionChange}
            >
              {modelDetails.versions.map((version, index) => (
                <MenuItem key={index} value={version}>
                  {formatDatetime(version)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <UploadButton
            disabled={!modelDetails.can_upload}
            uploadButtonText={t('common:upload')}
            onConfirm={uploadModelModal.open}
          />
        </Paper>
        <TableContainer component={Paper}>
          <TableContainerHeader title={t('model_values_table.title')} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '50%' }}>{t('model_values_table.label')}</TableCell>
                <TableCell sx={{ width: '50%' }}>{t('model_values_table.value')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modelDetails?.constituents?.map((constituent) => {
                return (
                  <TableRow key={constituent.value}>
                    <TableCell>{constituent.name}</TableCell>
                    <TableCell>{constituent.value}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <UploadModel
          name={modelDetails.model_name}
          datasetName={modelDetails.dataset_name}
          classificationName={modelDetails.classification_name}
          modelRef={modelDetails.model_ref}
          isOpen={uploadModelModal.isOpen}
          onClose={uploadModelModal.close}
          setSelectedVersion={setSelectedVersion}
        />
      </Stack>
    </DataEngineShell>
  )
}

type DetailProps = {
  label?: string
  value?: string | null
  node?: ReactNode
}

function Detail(props: DetailProps) {
  const { label, value, node } = props

  return (
    <Stack width="100%" maxWidth={200} gap={0.5} px={1.5}>
      <Typography variant="caption" color="gray.700">
        {label}
      </Typography>
      {node ? <>{node}</> : <Typography variant="body1">{value || '-'}</Typography>}
    </Stack>
  )
}

type UploadButtonProps = {
  uploadButtonText: string
  isLoading?: boolean
  disabled?: boolean
  onConfirm: () => void
}

function UploadButton(props: UploadButtonProps) {
  const { uploadButtonText, isLoading, disabled, onConfirm } = props

  return (
    <Button
      variant="outlined"
      color="primary"
      type="submit"
      disabled={disabled || isLoading}
      onClick={onConfirm}
      sx={{ marginInlineStart: 'auto', overflow: 'hidden' }}
    >
      {isLoading && <CircularProgress size={24} color={'inherit'} sx={{ position: 'absolute' }} />}
      <UploadIcon sx={{ marginRight: '8px' }} />
      <Typography color="inherit" variant="inherit" sx={{ opacity: isLoading ? 0 : 1 }}>
        {uploadButtonText}
      </Typography>
    </Button>
  )
}

export default ModelDetails
