import { Search, Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Button,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ParsedGridColumnOption } from '../../services/data/grid-data-parsing'

type ColumnsMenuProps = {
  anchorEl: HTMLElement | null
  columns: ParsedGridColumnOption[]
  onClose: () => void
  onHideColumn: (datapointRef: string) => void
  onHideAll: () => void
  onUnhideAll: () => void
}

function ColumnsMenu(props: ColumnsMenuProps) {
  const { anchorEl, columns, onClose, onHideColumn, onHideAll, onUnhideAll } = props

  const { t } = useTranslation('dataTable')

  const [searchValue, setSearchValue] = useState('')

  const searchInputRef = useRef<HTMLInputElement>(null)
  const listRef = useRef<HTMLDivElement>(null)

  const filteredColumns = columns.filter((option) => {
    return (
      option.datapointName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      option.columnName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    )
  })

  function handleSearchChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchValue(event.target.value)
  }

  function handleClose() {
    onClose()
    setSearchValue('')
  }

  function handleSearchNavigation(event: KeyboardEvent<HTMLDivElement>) {
    event.stopPropagation()
    if (event.key === 'ArrowUp') {
      const allListItems = listRef.current?.querySelectorAll('li')
      const lastListItem = allListItems && allListItems[allListItems.length - 1]

      if (lastListItem) {
        handleFocus(event, lastListItem as HTMLElement)
        return
      }
    }

    if (event.key === 'ArrowDown') {
      const firstListItem = listRef.current?.querySelector('li')

      if (firstListItem) {
        handleFocus(event, firstListItem as HTMLElement)
        return
      }
    }

    if (event.key === 'Escape') {
      handleClose()
    }
  }

  function handleListItemNavigation(event: KeyboardEvent<HTMLElement>) {
    if (event.key === 'ArrowUp') {
      const firstListItem = listRef.current?.querySelector('li')

      if (event.currentTarget === firstListItem) {
        handleFocus(event, searchInputRef.current as HTMLElement)
        return
      }
    }

    if (event.key === 'ArrowDown') {
      const allListItems = listRef.current?.querySelectorAll('li')
      const lastListItem = allListItems && allListItems[allListItems.length - 1]

      if (event.currentTarget === lastListItem) {
        handleFocus(event, searchInputRef.current as HTMLElement)
        return
      }
    }
  }

  function handleFocus(event: KeyboardEvent<HTMLElement>, element: HTMLElement) {
    event.preventDefault()
    event.stopPropagation()
    element.focus()
  }

  return (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      className="context-menu"
      ref={listRef}
      sx={{ marginTop: '5px', maxHeight: '60%' }}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          fontSize: '13px',
          px: 3,
          pr: 2,
          mb: 0.5,
        }}
      >
        <Typography sx={{ textTransform: 'uppercase', color: 'gray.300', fontSize: '12px', mr: 'auto' }}>
          {t('hidden_columns')}
        </Typography>
        <Button variant="text" onClick={onHideAll}>
          {t('hide_all')}
        </Button>
        {false && (
          <Button variant="text" onClick={onUnhideAll}>
            {t('unhide_all')}
          </Button>
        )}
      </Stack>

      <TextField
        size="small"
        label={t('search_columns')}
        autoFocus
        inputRef={searchInputRef}
        onKeyDown={(event) => handleSearchNavigation(event)}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: <InputAdornment position="end">{<Search />}</InputAdornment>,
        }}
        sx={{ mb: 1, mx: 2.5 }}
      />

      {filteredColumns.map((column) => {
        const showTooltip = column.columnName !== column.datapointName

        return (
          <Tooltip key={column.datapointRef} title={showTooltip && column.datapointName} arrow disableInteractive>
            <MenuItem
              onClick={() => onHideColumn(column.datapointRef)}
              tabIndex={0}
              onKeyDown={(event) => handleListItemNavigation(event)}
            >
              <ListItemText>
                <Typography
                  sx={{
                    color: column.isHidden ? 'gray.200' : 'white',
                    pr: 2,
                    lineHeight: 1,
                  }}
                >
                  {column.columnName}
                </Typography>
              </ListItemText>
              <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                {column.isHidden ? (
                  <VisibilityOff sx={{ color: 'gray.200' }} />
                ) : (
                  <Visibility sx={{ color: 'gray.700' }} />
                )}
              </ListItemIcon>
            </MenuItem>
          </Tooltip>
        )
      })}
    </Menu>
  )
}

export default ColumnsMenu
