import { Percent } from '@mui/icons-material'
import { InputAdornment } from '@mui/material'

export function CurrencyAdorment(props: { symbol: string }) {
  return <InputAdornment position="end">{props.symbol}</InputAdornment>
}

export function PercentAdorment() {
  return (
    <InputAdornment position="end">
      <Percent fontSize="inherit" sx={{ color: 'gray.300' }} />
    </InputAdornment>
  )
}
