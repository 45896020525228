import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useCancelTransactionMutation() {
  const response = useMutation<AxiosResponse, AxiosError, { lei: string; txnRef: string }>({
    mutationFn: ({ lei, txnRef }) => api.cancelTransaction(lei, txnRef),
  })

  return { ...response, error: parseError(response.error) }
}

export default useCancelTransactionMutation
