import { ChevronRight, ImportExport } from '@mui/icons-material'
import { Chip, IconButton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { ReloadButton } from '../../../components/buttons/reload-button'
import PageHeaderContainer from '../../../components/page-header-container'
import config from '../../../config'

type OrderBlotterHeaderProps = {
  title: string
  currency?: string
  isNavOpen: boolean
  showLoading: boolean
  onNavOpen: () => void
  onReloadData: () => void
}

export function OrderBlotterHeader(props: OrderBlotterHeaderProps) {
  const { title, currency, isNavOpen, showLoading, onNavOpen, onReloadData } = props

  return (
    <PageHeaderContainer>
      <Stack flex="1" direction="row" alignItems="center" gap={1}>
        {!isNavOpen && (
          <IconButton onClick={onNavOpen}>
            <ChevronRight color="primary" />
          </IconButton>
        )}

        {title && (
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
        )}
        {currency && <Chip label={<Typography color="gray.700">{currency}</Typography>} />}
        <ReloadButton loading={showLoading} onClick={onReloadData} />

        {config.enableUnreadyFeatures && (
          <IconButton
            onClick={() => alert('Behind unready flag, not implemented yet.')}
            sx={{ color: 'gray.300', padding: '4px', margin: '4px', ml: 'auto' }}
          >
            <ImportExport />
          </IconButton>
        )}
      </Stack>
    </PageHeaderContainer>
  )
}
