import { ListItem as MuiListItem, SxProps, Theme, Typography } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import { ReactNode } from 'react'

type ListItemProps = {
  disabled?: boolean
  title: string
  isSelected: boolean
  secondaryAction?: ReactNode
  sx?: SxProps<Theme>
  onClick?: () => void
}

function ListItem(props: ListItemProps) {
  const { disabled, title, isSelected, secondaryAction, sx, onClick } = props

  return (
    <>
      <MuiListItem disablePadding secondaryAction={secondaryAction} sx={sx}>
        <ListItemButton
          disabled={disabled}
          selected={isSelected}
          onClick={onClick}
          sx={{
            pl: 2,
            '&.Mui-selected': {
              background: 'rgba(120, 220, 232, 0.08)',
              borderLeft: '2px solid #78DCE8',
              color: 'white',
              pl: 1.75,
              '&:hover': { backgroundColor: 'rgba(120, 220, 232, 0.2)' },
            },
          }}
        >
          <Typography variant="body2" sx={{ marginRight: 'auto', color: isSelected ? 'white' : 'gray.700' }}>
            {title}
          </Typography>
        </ListItemButton>
      </MuiListItem>
    </>
  )
}

export default ListItem
