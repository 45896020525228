import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Button, Stack, SxProps, Typography } from '@mui/material'
import { Theme } from '@mui/system'

type SelectButtonProps = {
  disabled?: boolean
  label: string
  selected: boolean
  onClick: () => void
  sx?: SxProps<Theme>
}

function SelectButton(props: SelectButtonProps) {
  const { disabled, label, selected, onClick, sx } = props
  const backgroundColor = selected ? 'rgba(255, 255, 255, 0.08)' : 'transparent'
  const borderColor = selected ? 'primary.main' : 'rgba(255, 255, 255, 0.23)'

  return (
    <Button
      disabled={disabled}
      disableFocusRipple
      variant="outlined"
      onClick={onClick}
      sx={{
        flex: 1,
        px: 2,
        py: 1.5,
        textTransform: 'capitalize',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor,
        borderColor,
        ...sx,
      }}
    >
      <Typography
        height={24}
        component={Stack}
        justifyContent="center"
        variant="subtitle2"
        sx={{ opacity: disabled ? '0.38' : 1 }}
      >
        {label}
      </Typography>
      {selected && <CheckCircleIcon />}
    </Button>
  )
}

export default SelectButton
