import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'

type EditGroupModalProps = {
  currentName: string
  portfolios: any[]
  currentPortfolios: any[]
  open: boolean
  onCloseButtonClick: () => void
  onSaveButtonClick: () => void
}

function EditGroupModal(props: EditGroupModalProps) {
  const { t } = useTranslation()
  const [groupName, setGroupName] = useState('')
  const [portfolios, setPortfolios] = useState<any[]>([])

  useEffect(() => {
    if (props.open) {
      setGroupName(props.currentName)
      setPortfolios(props.currentPortfolios.map((portfolio) => portfolio.portfolio_ref))
    }
  }, [props.open, props.currentName, props.currentPortfolios])

  function handleSubmit(event: any) {
    event.preventDefault()
    props.onSaveButtonClick()
  }

  function handleGroupNameChange(event: ChangeEvent<HTMLTextAreaElement>) {
    const value = event.target.value
    setGroupName(value)
  }

  function handlePortfoliosChange(event: SelectChangeEvent<typeof portfolios>) {
    const value = event.target.value
    setPortfolios(typeof value === 'string' ? value.split(',') : value)
  }

  function getPortfolioNameByRef(portfolioRef: string) {
    const portfolio = props.portfolios.find((portfolio) => portfolio.portfolio_ref === portfolioRef)
    return portfolio.portfolio_name
  }

  return (
    <Dialog open={props.open} onClose={props.onCloseButtonClick}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('portfolio:edit_portfolio_group')} onClose={props.onCloseButtonClick} />
        <ModalContent>
          <TextField
            name="name"
            required
            placeholder={t('portfolio:name_of_the_group')}
            value={groupName}
            onChange={handleGroupNameChange}
          />
          <FormControl>
            <InputLabel>{t('common:portfolio')}</InputLabel>
            <Select
              name="portfolios"
              label={t('common:portfolio')}
              required
              multiple
              value={portfolios}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={getPortfolioNameByRef(value)} />
                  ))}
                </Box>
              )}
              onChange={handlePortfoliosChange}
            >
              {props.portfolios.map((portfolio) => (
                <MenuItem key={portfolio.portfolio_ref} value={portfolio.portfolio_ref}>
                  <Checkbox checked={portfolios.indexOf(portfolio.portfolio_ref) > -1} />
                  {portfolio.portfolio_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('common:save')} onCancel={props.onCloseButtonClick} />
      </form>
    </Dialog>
  )
}

export default EditGroupModal
