import { useMutation } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useSetCurrentTenantMutation() {
  return useMutation({
    mutationFn: api.setCurrentTenat,
  })
}

export default useSetCurrentTenantMutation
