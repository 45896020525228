import { DeleteOutline } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../components/fields/numeric-field'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import SelectButton from '../../../components/select-button'
import { ParsedGridData } from '../../../services/data/grid-data-parsing'
import { ScenarioAnalysis, SliceBy, TimeSeries, UamPanelMode } from '../../../services/data/types/grid-panels'
import { newLocalKey } from '../../../utils/id'
import { Nullable } from '../../../utils/types'

enum PanelModeOption {
  Off = 'off',
  SliceBy = 'slice_by',
  TimeSeries = 'time_series',
  ScenarioAnalysis = 'scenario_analysis',
}

enum IntervalOption {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

const initialPanelMode: PanelModeOption = PanelModeOption.Off
const initialSliceBy: SliceBy['slice_by'] = ''
const initialTimeSeries: Nullable<TimeSeries['time_series']> = {
  period_unit: 'day',
  period_length: null,
  use_calendar_periods: false,
  number_of_periods: null,
}
const initalScenarioAnalysis: ScenarioAnalysisFormValues = []

type ScenarioAnalysisFormValues = {
  key: string
  name: string
  fx_shock: string
  price_shock: string
  yield_shock: string
}[]

type MultipanelSettingsModalProps = {
  open: boolean
  parsedGridData: ParsedGridData | null
  panelModeData: UamPanelMode | null
  onSave: (panelMode: UamPanelMode | null) => void
  onClose: () => void
}

function MultipanelSettingsModal(props: MultipanelSettingsModalProps) {
  const { parsedGridData, panelModeData, open, onSave, onClose } = props

  const { t } = useTranslation('portfolio')

  const [panelMode, setPanelMode] = useState<PanelModeOption>(initialPanelMode)
  const [sliceByConfig, setSliceByConfig] = useState<SliceBy['slice_by']>(initialSliceBy)
  const [timeSeriesConfig, setTimeSeriesConfig] = useState<Nullable<TimeSeries['time_series']>>(initialTimeSeries)
  const [scenarioAnalysisConfig, setScenarioAnalysis] = useState<ScenarioAnalysisFormValues>(initalScenarioAnalysis)

  const isSliceBy = panelMode === PanelModeOption.SliceBy
  const isTimeSeries = panelMode === PanelModeOption.TimeSeries
  const isScenarioAnalysis = panelMode === PanelModeOption.ScenarioAnalysis

  useEffect(() => {
    if (open) {
      if (panelModeData) {
        if ('slice_by' in panelModeData) {
          setPanelMode(PanelModeOption.SliceBy)
          setSliceByConfig(panelModeData.slice_by)
        } else if ('time_series' in panelModeData) {
          setPanelMode(PanelModeOption.TimeSeries)
          setTimeSeriesConfig(panelModeData.time_series)
        } else if ('scenario_analysis' in panelModeData) {
          setPanelMode(PanelModeOption.ScenarioAnalysis)
          setScenarioAnalysis(
            panelModeData.scenario_analysis.map((s) => ({
              key: newLocalKey(),
              name: s.name || '',
              fx_shock: String(s.fx_shock || ''),
              price_shock: String(s.price_shock || ''),
              yield_shock: String(s.yield_shock || ''),
            }))
          )
        } else {
          setPanelMode(PanelModeOption.Off)
        }
      } else {
        setPanelMode(initialPanelMode)
        setSliceByConfig(initialSliceBy)
        setTimeSeriesConfig(initialTimeSeries)
        setScenarioAnalysis(initalScenarioAnalysis)
      }
    }
  }, [open, panelModeData])

  function handleModeClick(mode: PanelModeOption) {
    setPanelMode(mode)
  }

  function handleSliceByConfigChange(value: string) {
    setSliceByConfig(value)
  }

  function handleTimeSeriesConfigChange(name: keyof TimeSeries['time_series'], value: string | boolean | null) {
    setTimeSeriesConfig({ ...timeSeriesConfig, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (panelMode === PanelModeOption.Off) {
      onSave(null)
    }

    if (panelMode === PanelModeOption.SliceBy) {
      onSave({
        slice_by: sliceByConfig,
      })
    }

    if (panelMode === PanelModeOption.TimeSeries) {
      onSave({
        time_series: {
          period_unit: timeSeriesConfig.period_unit ?? 'day',
          period_length: Number(timeSeriesConfig.period_length) || 1,
          use_calendar_periods: !!timeSeriesConfig.use_calendar_periods,
          number_of_periods: Number(timeSeriesConfig.number_of_periods) || 1,
        },
      })
    }

    if (panelMode === PanelModeOption.ScenarioAnalysis) {
      onSave({
        scenario_analysis: scenarioAnalysisConfig.map((s) => {
          return {
            name: s.name || null,
            fx_shock: s.fx_shock ? Number(s.fx_shock) : null,
            price_shock: s.price_shock ? Number(s.price_shock) : null,
            yield_shock: s.yield_shock ? Number(s.yield_shock) : null,
          } satisfies ScenarioAnalysis['scenario_analysis'][number]
        }),
      })
    }

    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          position: 'fixed',
          top: '10%',
          margin: 0,
          width: '750px',
          maxWidth: '750px',
          maxHeight: '85%',
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('multipanel.title')} onClose={onClose} />
        <ModalContent>
          <Stack gap={4}>
            <Alert
              severity="info"
              variant="filled"
              sx={{
                p: 2,
                alignItems: 'flex-start',
                fontSize: 12,
                color: 'gray.700',
                background: 'rgba(255, 255, 255, 0.08)',
              }}
            >
              <AlertTitle color="inherit">{t('multipanel.off_state_info.title')}</AlertTitle>
              {t('multipanel.off_state_info.description')}
            </Alert>
            <Box>
              <Typography variant="subtitle2" mb={1.5}>
                {t('multipanel.panel_mode')}
              </Typography>
              <Stack direction="row" gap={2}>
                <SelectButton
                  label={t('multipanel.off')}
                  selected={panelMode === PanelModeOption.Off}
                  onClick={() => handleModeClick(PanelModeOption.Off)}
                  sx={{ maxWidth: '100px' }}
                />
                <SelectButton
                  label={t('multipanel.slicer')}
                  selected={panelMode === PanelModeOption.SliceBy}
                  onClick={() => handleModeClick(PanelModeOption.SliceBy)}
                />
                <SelectButton
                  label={t('multipanel.time_series')}
                  selected={panelMode === PanelModeOption.TimeSeries}
                  onClick={() => handleModeClick(PanelModeOption.TimeSeries)}
                />
                <SelectButton
                  label={t('multipanel.scenario_analysis')}
                  selected={panelMode === PanelModeOption.ScenarioAnalysis}
                  onClick={() => handleModeClick(PanelModeOption.ScenarioAnalysis)}
                />
              </Stack>
            </Box>
            {isSliceBy && (
              <SliceByForm data={parsedGridData} value={sliceByConfig} onChange={handleSliceByConfigChange} />
            )}
            {isTimeSeries && <TimeSeriesForm values={timeSeriesConfig} onChange={handleTimeSeriesConfigChange} />}
            {isScenarioAnalysis && (
              <ScenarioAnalysisForm values={scenarioAnalysisConfig} onChange={setScenarioAnalysis} />
            )}
          </Stack>
        </ModalContent>
        <ModalActions confirmLabel={t('common:save')} onCancel={onClose} />
      </form>
    </Dialog>
  )
}

export default MultipanelSettingsModal

type SliceByFormProps = {
  data: ParsedGridData | null
  value: string | null
  onChange: (value: string) => void
}

function SliceByForm(props: SliceByFormProps) {
  const { data, value, onChange } = props

  const { t } = useTranslation('portfolio')

  const items =
    data?.originalHeadings
      .filter((heading) => !!heading.can_slice_by)
      .sort((a, b) => a.datapoint_name.localeCompare(b.datapoint_name)) || []

  function handleChange(event: SelectChangeEvent) {
    onChange(event.target.value)
  }

  return (
    <Box>
      <Typography variant="subtitle2" mb={1.5}>
        {t('multipanel.configuration')}
      </Typography>
      <FormControl required fullWidth>
        <InputLabel>{t('multipanel.slice_by')}</InputLabel>
        <Select name="datapoint_ref" label={t('multipanel.slice_by')} value={value ?? ''} onChange={handleChange}>
          {items.map((item) => (
            <MenuItem key={item.datapoint_ref} value={item.datapoint_ref}>
              {item.datapoint_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

type TimeSeriesFormProps = {
  values: Nullable<TimeSeries['time_series']>
  onChange: (name: keyof TimeSeries['time_series'], value: string | boolean | null) => void
}

function TimeSeriesForm(props: TimeSeriesFormProps) {
  const { values, onChange } = props

  const { t } = useTranslation('portfolio')

  function handlePeriodUnitChange(event: SelectChangeEvent) {
    onChange('period_unit', event.target.value)
  }

  function handlePeriodLengthChange(value: string) {
    onChange('period_length', value)
  }

  function handleNumberOfPeriodsChange(value: string) {
    onChange('number_of_periods', value)
  }

  function handleUseCalendarPeriodsChange(checked: boolean) {
    onChange('use_calendar_periods', checked)
  }

  return (
    <Box>
      <Typography variant="subtitle2" mb={1.5}>
        {t('multipanel.configuration')}
      </Typography>
      <Stack gap={2}>
        <Stack direction="row" gap={2}>
          <FormControl fullWidth required>
            <InputLabel>{t('multipanel.period_unit')}</InputLabel>
            <Select
              name="interval"
              label={t('multipanel.period_unit')}
              value={values.period_unit ?? ''}
              onChange={handlePeriodUnitChange}
            >
              <MenuItem value={IntervalOption.Day}>{t('multipanel.day')}</MenuItem>
              <MenuItem value={IntervalOption.Week}>{t('multipanel.week')}</MenuItem>
              <MenuItem value={IntervalOption.Month}>{t('multipanel.month')}</MenuItem>
              <MenuItem value={IntervalOption.Year}>{t('multipanel.year')}</MenuItem>
            </Select>
          </FormControl>
          <NumericField
            required
            name="period_length"
            label={t('multipanel.period_length')}
            value={String(values.period_length ?? '')}
            decimalScale={0}
            onValueChange={handlePeriodLengthChange}
          />
          <NumericField
            required
            name="number_of_periods"
            label={t('multipanel.number_of_periods')}
            value={String(values.number_of_periods ?? '')}
            decimalScale={0}
            onValueChange={handleNumberOfPeriodsChange}
          />
        </Stack>
        <Stack alignItems="start">
          <FormControlLabel
            name="aggregationOnlyAll"
            label={t('multipanel.use_calendar_periods')}
            checked={!!values.use_calendar_periods}
            onChange={(_, checked) => handleUseCalendarPeriodsChange(checked)}
            control={<Checkbox />}
          />
        </Stack>
      </Stack>
    </Box>
  )
}

type ScenarioAnalysisFormProps = {
  values: ScenarioAnalysisFormValues
  onChange: (value: ScenarioAnalysisFormValues) => void
}

function ScenarioAnalysisForm(props: ScenarioAnalysisFormProps) {
  const { values, onChange } = props

  const { t } = useTranslation('portfolio')

  function handleAddScenario() {
    onChange(
      values.concat([
        {
          key: newLocalKey(),
          name: '',
          fx_shock: '',
          price_shock: '',
          yield_shock: '',
        },
      ])
    )
  }

  function handleFieldChangeAt(field: keyof ScenarioAnalysisFormValues[number], index: number, value: string) {
    onChange(
      values.map((prev, idx) => {
        if (idx === index) {
          return { ...prev, [field]: value }
        } else {
          return prev
        }
      })
    )
  }

  function handleRemoveFieldAt(index: number) {
    onChange(values.filter((_, idx) => idx !== index))
  }

  return (
    <Box>
      <Typography variant="subtitle2" mb={1.5}>
        {t('multipanel.configuration')}
      </Typography>
      <Stack gap={2}>
        <Stack alignItems="start">
          <Button onClick={handleAddScenario}>{t('multipanel.add_panel')}</Button>
        </Stack>

        {values.map((value, index) => (
          <Stack key={index} direction="row" alignItems="center" gap={2}>
            <NumericField
              name="fx_shock"
              label={t('multipanel.fx_shock')}
              fixedLabel
              value={String(value.fx_shock ?? '')}
              decimalScale={0}
              onValueChange={(value) => handleFieldChangeAt('fx_shock', index, value)}
            />
            <NumericField
              name="price_shock"
              label={t('multipanel.price_shock')}
              fixedLabel
              value={String(value.price_shock ?? '')}
              decimalScale={0}
              onValueChange={(value) => handleFieldChangeAt('price_shock', index, value)}
            />
            <NumericField
              name="yield_shock"
              label={t('multipanel.yield_shock')}
              fixedLabel
              value={String(value.yield_shock ?? '')}
              decimalScale={0}
              onValueChange={(value) => handleFieldChangeAt('yield_shock', index, value)}
            />
            <TextField
              name="name"
              label={t('multipanel.panel_name')}
              value={value.name}
              autoComplete="off"
              onChange={(event) => handleFieldChangeAt('name', index, event.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={{ width: '100%' }}
            />
            <div>
              <IconButton size="small" onClick={() => handleRemoveFieldAt(index)}>
                <DeleteOutline color="error" />
              </IconButton>
            </div>
          </Stack>
        ))}
      </Stack>
    </Box>
  )
}
