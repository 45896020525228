import { Lock, LockOpen } from '@mui/icons-material'
import { InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { useState } from 'react'

type PasswordFieldProps = {
  required: boolean
  name: string
  placeholder: string
  autoComplete: string
  autoFocus?: boolean
  value?: TextFieldProps['value']
  onChange?: TextFieldProps['onChange']
}

function PasswordField(props: PasswordFieldProps) {
  const [isOpen, setOpen] = useState(false)

  function onToggle() {
    setOpen(!isOpen)
  }

  return (
    <TextField
      required={props.required}
      name={props.name}
      placeholder={props.placeholder}
      autoComplete={props.autoComplete}
      autoFocus={props.autoFocus}
      value={props.value}
      onChange={props.onChange}
      variant="outlined"
      type={isOpen ? 'text' : 'password'}
      InputProps={{
        startAdornment: (
          <InputAdornment onClick={onToggle} position="start" sx={{ cursor: 'pointer' }}>
            {isOpen ? <LockOpen /> : <Lock />}
          </InputAdornment>
        ),
      }}
    />
  )
}

export default PasswordField
