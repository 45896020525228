import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useFlexLayoutContext } from '../../../../components/layouts/flex-layout-context'
import AssetStaticDetails from '../../components/asset-static-details'

function AssetStaticDetailsFlexView() {
  const { t } = useTranslation('dataEngine')

  const { assetTag } = useParams()
  const { openView } = useFlexLayoutContext()

  function handleDatapointDetailsOpen(assetRef: string, datapointRef: string, datasetRef: string) {
    const path = `/data-engine/datasets/${datasetRef}/datapoints/${datapointRef}/assets/${assetRef}`
    const name = t('datapoint_details.title')
    openView(path, name, true)
  }

  if (!assetTag) {
    return null
  }

  return <AssetStaticDetails assetTag={assetTag} onDatapointDetailsOpen={handleDatapointDetailsOpen} />
}

export default AssetStaticDetailsFlexView
