import { i18n } from '../../i18n'
import { extractDatapointFormatAndValue } from './datapoint-formatting'
import { AssetCategory, DataDocType } from './types/asset-static-data'
import { PortfolioComplianceResponse } from './types/compliance'
import { DatapointType } from './types/datapoint'
import {
  BaseGridData,
  GridAlert,
  GridResponseAggregate,
  GridResponseCell,
  GridResponseHeading,
  GridResponseRow,
  UpdatedGridCell,
} from './types/grid-data'
import { GridDataViewColumn, GridDataViewSortBy } from './types/grid-data-view'

export type ParsedGridData = {
  originalHeadings: GridResponseHeading[]
  visibleHeadings: ParsedGridDataHeading[]
  panelHeadings: ParsedGridDataPanelHeading[] | null
  modellerHeadings: ParsedGridDataModellerHeading[] | null
  rows: ParsedGridDataRow[]
  shownColumnsCount: number
  columnsOptions: ParsedGridColumnOption[]
  aggregatePaths: { groupLevel: number; aggregatePath: string }[]
}

export type ParsedGridDataPanelHeading = {
  key: string
  title: string | null
  colSpan: number
  leftDivider: boolean
}

export type ParsedGridDataModellerHeading = {
  key: string
  title: string | null
  colSpan: number
  panelType: PanelType | null
  leftDivider: boolean
}

export type ParsedGridDataHeading = {
  key: string
  title: string | null
  leftDivider: boolean
  groupable: boolean
  width: number | undefined
  panelType: PanelType | null
  panelIndex: number | null
  canMoveLeft: boolean
  canMoveRight: boolean
  canMoveToPanel: boolean
  canRemoveFromPanel: boolean
  datadoc_type?: DataDocType
  meta: GridResponseHeading
}

export type ParsedGridDataRow = {
  key: string
  rowRef: string
  rowDescription: string
  assetRef: string | null
  txnRef: string | undefined
  dealRef: string | undefined
  canConfirmTrade: boolean
  canCorrectTransaction: boolean
  canCancelTransaction: boolean
  canCloseOut: boolean
  aggregatePath: string
  aggregateLevel: number | null
  cells: ParsedGridDataCell[]
  type: 'aggregate' | 'data'
  meta: GridResponseRow | GridResponseAggregate
}

export type ParsedGridDataCell = {
  leftDivider: boolean
  value: GridResponseCell['datapoint_value'] | undefined
  displayAs: string | null | undefined
  key: string
  headingKey: string
  datapointRef: string
  rowRef: string
  panelIndex: number | null
  panelType: PanelType | null
  datapointType: DatapointType
  datadoc_type: DataDocType | undefined
  canLock: boolean
  alert: GridAlert | null
  meta: GridResponseCell | null
}

export type PanelType = 'change' | 'new' | 'current'

export type ParsedGridColumnOption = {
  columnName: string
  datapointRef: string
  datapointName: string
  datapointType: DatapointType
  classificationId?: number
  assetCategory: AssetCategory | undefined
  isHidden: boolean
}

export function parseGridData(
  data: BaseGridData | null,
  columns?: GridDataViewColumn[] | null,
  sortBy?: GridDataViewSortBy | null,
  compliance?: PortfolioComplianceResponse | null
): ParsedGridData | null {
  if (!data) {
    return null
  }

  const columnsState = new ViewColumnsState(columns)

  sortHeadings(data, columns)
  sortRows(data, sortBy)

  const parsedHeadings = parseGridDataHeadings(data, columnsState)
  const visibleHeadings = getVisibleHeadings(parsedHeadings, columnsState)
  const panelHeadings = parseGridDataPanelHeadings(data, visibleHeadings)
  const modellerHeadings = parseGridDataModellerHeadings(data, visibleHeadings)

  const [shownColumnsCount, columnsOptions] = parseGridColumnsOptions(data, columnsState)
  const rows = parseGridDataRows(data, parsedHeadings, compliance)

  const aggregatePaths = data.aggregates.map((aggregate) => ({
    groupLevel: getAggregatePathGroupLevel(aggregate.aggregate_path),
    aggregatePath: aggregate.aggregate_path,
  }))

  return {
    originalHeadings: data.headings,
    visibleHeadings,
    panelHeadings,
    modellerHeadings,
    rows,
    shownColumnsCount,
    columnsOptions,
    aggregatePaths,
  }
}

export function parseGridDataPanelHeadings(data: BaseGridData, visibleHeadings: ParsedGridDataHeading[]) {
  if (data.panels.length <= 1 && !data.panels[0]?.panel_title) {
    return null
  }

  const maingridHeadingsLength = visibleHeadings.filter((heading) => heading.meta.position === 'maingrid').length

  const mainGridPanel = {
    key: 'panel_maingrid',
    title: null,
    colSpan: maingridHeadingsLength,
    leftDivider: false,
  }

  const panelHeadings = data.panels.map((panel) => {
    const numberOfPanelHeadings = data.headings.filter((heading) => heading.position === 'panel').length
    let colSpan = numberOfPanelHeadings

    if (panel.modeller_on) {
      const numberOfchangeHeadings = data.headings.filter((heading) => heading.position === 'change').length
      const numberOfNewHeadings = numberOfPanelHeadings

      colSpan = numberOfPanelHeadings + numberOfchangeHeadings + numberOfNewHeadings
    }

    return {
      key: `panel_${panel.panel_index}`,
      title: panel.panel_title,
      colSpan,
      leftDivider: true,
    }
  })

  return [mainGridPanel, ...panelHeadings].filter((heading) => heading.colSpan)
}

export function parseGridDataModellerHeadings(data: BaseGridData, visibleHeadings: ParsedGridDataHeading[]) {
  if (data.panels.every((panel) => !panel.modeller_on)) {
    return null
  }

  const maingridHeadingsLength = visibleHeadings.filter((heading) => heading.meta.position === 'maingrid').length

  const mainGridHeader = {
    key: 'modeller_maingrid',
    title: null,
    colSpan: maingridHeadingsLength,
    panelType: null,
    leftDivider: false,
  }

  const modellerHeadings = data.panels.map((panel) => {
    const panelHeadings = data.headings.filter((heading) => heading.position === 'panel')

    if (panel.modeller_on) {
      const changeHeadings = data.headings.filter((heading) => heading.position === 'change')

      return [
        {
          key: `modeller_current_panel_${panel.panel_index}`,
          title: i18n.t('portfolio:modeller_current'),
          colSpan: panelHeadings.length,
          panelType: 'current' as const,
          leftDivider: true,
        },
        {
          key: `modeller_change_panel_${panel.panel_index}`,
          title: i18n.t('portfolio:modeller_change'),
          colSpan: changeHeadings.length,
          panelType: 'change' as const,
          leftDivider: false,
        },
        {
          key: `modeller_new_panel_${panel.panel_index}`,
          title: i18n.t('portfolio:modeller_new'),
          colSpan: panelHeadings.length,
          panelType: 'new' as const,
          leftDivider: false,
        },
      ]
    }

    return [
      {
        key: `modeller_current_panel_${panel.panel_index}`,
        title: '',
        colSpan: panelHeadings.length,
        panelType: 'current' as const,
        leftDivider: true,
      },
    ]
  })

  return [mainGridHeader, ...modellerHeadings.flat()].filter((heading) => heading.colSpan)
}

export function parseGridDataHeadings(data: BaseGridData, columnsState: ViewColumnsState): ParsedGridDataHeading[] {
  const panelHeadings = data.headings.filter((heading) => heading.position === 'panel')
  const changeHeadings = data.headings.filter((heading) => heading.position === 'change')

  const panelHeadingsWithChangeHeadings = data.panels
    .map((panel) => {
      const panelIndexedHeadings = panelHeadings.map((heading, index) => ({
        ...heading,
        panelIndex: panel.panel_index,
        leftDivider: false,
        panelType: 'current' as const,
        canMoveLeft: index > 0,
        canMoveRight: index < panelHeadings.length - 1,
        canMoveToPanel: false,
        canRemoveFromPanel: true,
      }))

      if (panelIndexedHeadings[0]) {
        panelIndexedHeadings[0].leftDivider = true
      }

      if (panel.modeller_on) {
        const panelIndexedChangeHeadings = changeHeadings.map((heading) => ({
          ...heading,
          panelIndex: panel.panel_index,
          leftDivider: false,
          panelType: 'change' as const,
          canMoveLeft: false,
          canMoveRight: false,
          canMoveToPanel: false,
          canRemoveFromPanel: false,
        }))

        const panelIndexedNewHeadings = panelIndexedHeadings.map((heading) => ({
          ...heading,
          panelType: 'new' as const,
        }))

        if (panelIndexedNewHeadings[0]) {
          panelIndexedNewHeadings[0].leftDivider = false
        }

        return [...panelIndexedHeadings, ...panelIndexedChangeHeadings, ...panelIndexedNewHeadings]
      }

      return panelIndexedHeadings
    })
    .flat()

  const mainHeadings = data.headings.filter((heading) => heading.position === 'maingrid')
  const mainGridHeadings = mainHeadings.map((heading, index) => {
    const column = columnsState.getColumn(heading.datapoint_ref)

    const parsedHeading: ParsedGridDataHeading = {
      key: `heading_${heading.datapoint_ref}_panel_null_null`,
      title: column?.name || heading.datapoint_name,
      leftDivider: false,
      groupable: !!heading.can_aggregate_by,
      width: column?.width || undefined,
      panelType: null,
      panelIndex: null,
      canMoveLeft: index > 0,
      canMoveRight: index < mainHeadings.length - 1,
      canMoveToPanel: true,
      canRemoveFromPanel: false,
      datadoc_type: heading.datadoc_type,
      meta: heading,
    }
    return parsedHeading
  })

  const panelGridHeadings = panelHeadingsWithChangeHeadings.map((heading) => {
    const originalHeading = data.headings.find((origHeading) => origHeading.datapoint_ref === heading.datapoint_ref)!
    const column = columnsState.getColumn(heading.datapoint_ref)

    const parsedHeading: ParsedGridDataHeading = {
      key: `heading_${heading.datapoint_ref}_panel_${heading.panelIndex}_${heading.panelType}`,
      title: column?.name || heading.datapoint_name,
      leftDivider: heading.leftDivider,
      groupable: false,
      width: column?.width || undefined,
      panelType: heading.panelType,
      panelIndex: heading.panelIndex,
      canMoveLeft: heading.canMoveLeft,
      canMoveRight: heading.canMoveRight,
      canMoveToPanel: heading.canMoveToPanel,
      canRemoveFromPanel: heading.canRemoveFromPanel,
      meta: originalHeading,
    }
    return parsedHeading
  })

  return [...mainGridHeadings, ...panelGridHeadings]
}

export function parseGridDataRows(
  data: BaseGridData,
  headings: ParsedGridDataHeading[],
  compliance?: PortfolioComplianceResponse | null
): ParsedGridDataRow[] {
  const dataRows = data.rows.map((row) => {
    const parsedRow: ParsedGridDataRow = {
      key: `row_${row.row_ref}`,
      rowRef: row.row_ref,
      rowDescription: row.row_description,
      assetRef: row.asset_ref,
      txnRef: row.txn_ref,
      dealRef: row.deal_ref,
      canConfirmTrade: !!row.txn_can_confirm,
      canCorrectTransaction: !!row.txn_can_correct,
      canCancelTransaction: !!row.txn_can_cancel,
      canCloseOut: !!row.can_close_out,
      aggregatePath: row.aggregate_group,
      aggregateLevel: null,
      cells: parseGridDataCells(row, headings, compliance),
      type: 'data' as const,
      meta: row,
    }
    return parsedRow
  })

  const aggregateRows = data.aggregates.map<ParsedGridDataRow>((aggregateRow) => {
    const groupLevel = aggregateRow.aggregate_path.split('').filter((ch) => ch === '/').length

    const parsedRow: ParsedGridDataRow = {
      key: `aggregate_${aggregateRow.aggregate_path}`,
      rowRef: aggregateRow.aggregate_path,
      rowDescription: '',
      assetRef: null,
      txnRef: undefined,
      dealRef: undefined,
      canConfirmTrade: false,
      canCancelTransaction: false,
      canCorrectTransaction: false,
      canCloseOut: false,
      aggregatePath: aggregateRow.aggregate_path,
      aggregateLevel: groupLevel,
      cells: parseGridDataCells(aggregateRow, headings, compliance),
      type: 'aggregate' as const,
      meta: aggregateRow,
    }
    return parsedRow
  })

  const rows = [...dataRows, ...aggregateRows]

  rows.sort((rowA, rowB) => {
    const sortStringA = generateSortingString(rowA)
    const sortStringB = generateSortingString(rowB)

    // total row always to the bottom
    if (rowA.aggregatePath === '/') {
      return 1
    }
    if (rowB.aggregatePath === '/') {
      return -1
    }

    if (sortStringA > sortStringB) {
      return 1
    }
    if (sortStringA < sortStringB) {
      return -1
    }
    return 0
  })

  return rows
}

function generateSortingString(row: ParsedGridDataRow): string {
  let path = row.aggregatePath.replaceAll('/null/', '/zzzz/').toLowerCase()
  if (row.type === 'data') {
    path = `${path}data/`
  }
  return path
}

function sortHeadings(data: BaseGridData, columns?: GridDataViewColumn[] | null) {
  if (!columns) {
    return
  }

  data.headings.sort((a, b) => {
    const aIndex = columns.findIndex((column) => column.datapoint_ref === a.datapoint_ref)
    const bIndex = columns.findIndex((column) => column.datapoint_ref === b.datapoint_ref)

    if (aIndex === -1) {
      return 1
    }

    if (bIndex === -1) {
      return -1
    }

    if (aIndex > bIndex) {
      return 1
    }

    return -1
  })
}

export function sortRows(data: BaseGridData, sortBy?: GridDataViewSortBy | null) {
  if (!sortBy) {
    return
  }

  data.rows.sort((rowA, rowB) => {
    const cellA = rowA.cells.find((cell) => cell.datapoint_ref === sortBy.column)
    const cellB = rowB.cells.find((cell) => cell.datapoint_ref === sortBy.column)

    const datapointA = extractDatapointFormatAndValue(cellA?.datapoint_value)
    const datapointB = extractDatapointFormatAndValue(cellB?.datapoint_value)

    const valueTypeA = datapointA[0] || ''
    const valueTypeB = datapointB[0] || ''
    const valueA = cellA?.display_as || datapointA[1] || ''
    const valueB = cellB?.display_as || datapointB[1] || ''

    const direction = sortBy.direction === 'asc' ? 1 : -1

    if (valueTypeA === 'Error') {
      return 1
    }
    if (valueTypeB === 'Error') {
      return -1
    }

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      if (valueA > valueB) {
        return 1 * direction
      }
      if (valueA < valueB) {
        return -1 * direction
      }
      return 0
    }

    if (!valueA) {
      return 1
    }
    if (!valueB) {
      return -1
    }

    return String(valueA).localeCompare(String(valueB)) * direction
  })
}

function parseGridDataCells(
  row: GridResponseRow | GridResponseAggregate,
  headings: ParsedGridDataHeading[],
  compliance?: PortfolioComplianceResponse | null
): ParsedGridDataCell[] {
  const rowRef = 'row_ref' in row ? row.row_ref : row.aggregate_path

  // todo: replace with row_ref once that gets updated on the backend
  const cellsCompliance =
    'aggregate_path' in row
      ? compliance?.aggregations[row.aggregate_path]
      : row.asset_ref
        ? compliance?.rows[row.asset_ref]
        : undefined

  const cells = row.cells.map<ParsedGridDataCell | undefined>((cell) => {
    const heading = headings.find((heading) => {
      const isHeadingNew = heading.panelType === 'new'
      const isDataPointRefMatch = heading.meta.datapoint_ref === cell.datapoint_ref
      const isNewMatch = cell.is_panel_new === isHeadingNew || (!cell.is_panel_new && !heading.panelType)

      return isDataPointRefMatch && isNewMatch
    })

    if (!heading) {
      return
    }

    const cellPanelIndex = heading.panelType ? cell.panel_index : null
    const complianceAlert = cellsCompliance?.[cell.datapoint_ref] || null
    const incompleteCellAlert = cell.is_incomplete
      ? ({
          level: 'warning',
          message: i18n.t('portfolio:incomplete_cell_message'),
        } satisfies GridAlert)
      : null

    const parsedCell: ParsedGridDataCell = {
      key: `cell_${cell.datapoint_ref}_panel_${cellPanelIndex}_new_${cell.is_panel_new}`,
      headingKey: `heading_${heading.meta.datapoint_ref}_panel_${cellPanelIndex}_${heading.panelType}`,
      leftDivider: heading.leftDivider,
      value: cell.datapoint_value,
      datapointRef: heading.meta.datapoint_ref,
      rowRef,
      displayAs: cell.display_as,
      panelIndex: cellPanelIndex === undefined ? null : cellPanelIndex,
      panelType: heading.panelType,
      datapointType: heading.meta.datapoint_type,
      datadoc_type: heading.datadoc_type,
      canLock: !!cell.can_lock,
      alert: incompleteCellAlert || complianceAlert || null,
      meta: cell,
    }
    return parsedCell
  })

  const sortedCells = headings.map((heading, index) => {
    const leftDivider = heading.panelType === 'new' ? false : heading.leftDivider

    let emptyCell: ParsedGridDataCell = {
      key: `cell_${heading.meta.datapoint_ref}_panel_${heading.panelIndex}_empty_${
        heading.panelType ? heading.panelType : heading.meta.position
      }`,
      headingKey: `heading_${heading.meta.datapoint_ref}_panel_${heading.panelIndex}_${heading.panelType}`,
      leftDivider,
      datapointRef: heading.meta.datapoint_ref,
      rowRef,
      panelIndex: heading.panelIndex,
      panelType: heading.panelType,
      datapointType: heading.meta.datapoint_type,
      canLock: false,
      alert: null,
      meta: null,
      value: undefined,
      displayAs: undefined,
      datadoc_type: undefined,
    }

    if ('aggregate_path' in row && index === 0) {
      emptyCell = {
        key: `total_path`,
        headingKey: `heading_${heading.meta.datapoint_ref}_panel_${heading.panelIndex}_${heading.panelType}`,
        leftDivider,
        value: { String: row.aggregate_path_display || '' },
        datapointRef: heading.meta.datapoint_ref,
        rowRef,
        panelIndex: heading.panelIndex,
        panelType: heading.panelType,
        datapointType: heading.meta.datapoint_type,
        canLock: false,
        alert: null,
        meta: null,
        displayAs: undefined,
        datadoc_type: undefined,
      }
    }

    if (heading.meta.position === 'maingrid') {
      return cells.find((cell) => cell?.meta?.datapoint_ref === heading.meta.datapoint_ref) || emptyCell
    }

    if (heading.panelType === 'current') {
      return (
        cells.find((cell) => {
          const isDataPointRefMatch = cell?.meta?.datapoint_ref === heading.meta.datapoint_ref
          const isPanelIndexMatch = cell?.meta?.panel_index === heading.panelIndex
          const isNotNew = cell?.meta?.is_panel_new === false

          return isDataPointRefMatch && isPanelIndexMatch && isNotNew
        }) || emptyCell
      )
    }

    if (heading.panelType === 'change') {
      return (
        cells.find((cell) => {
          const isDataPointRefMatch = cell?.meta?.datapoint_ref === heading.meta.datapoint_ref
          const isPanelIndexMatch = cell?.meta?.panel_index === heading.panelIndex

          return isDataPointRefMatch && isPanelIndexMatch
        }) || emptyCell
      )
    }

    if (heading.panelType === 'new') {
      return (
        cells.find((cell) => {
          const isDataPointRefMatch = cell?.meta?.datapoint_ref === heading.meta.datapoint_ref
          const isPanelIndexMatch = cell?.meta?.panel_index === heading.panelIndex
          const isNew = cell?.meta?.is_panel_new === true

          return isDataPointRefMatch && isPanelIndexMatch && isNew
        }) || emptyCell
      )
    }

    return emptyCell
  })

  return sortedCells
}

export function mergeUpdatedCells(data: ParsedGridData | null, updatedCells: UpdatedGridCell[]): ParsedGridData | null {
  if (!data) {
    return null
  }

  const rows = data.rows.map((row) => {
    const cells = row.cells.map((cell) => {
      const updatedCell = updatedCells.find((updatedCell) => {
        const isSameRow = row.rowRef === updatedCell.y_ref
        const isSameColum = cell.datapointRef === updatedCell.datapoint_ref
        const panelIsNew = cell.panelType === 'new'
        const isSamePanel = cell.panelIndex === updatedCell.panel_index && panelIsNew === updatedCell.panel_is_new

        return isSameRow && isSameColum && isSamePanel
      })

      if (updatedCell) {
        return {
          ...cell,
          value: updatedCell.datapoint_value,
        }
      }

      return cell
    })

    return {
      ...row,
      cells,
    }
  })

  return {
    ...data,
    rows,
  }
}

/// Gets the details about the columns shown on the columns menu.
function parseGridColumnsOptions(
  data: BaseGridData,
  columnsState: ViewColumnsState
): [number, ParsedGridColumnOption[]] {
  let shownColumnsCount = 0

  const columnsOptions = data.headings
    .filter((heading) => heading.position === 'maingrid' || heading.position === 'panel')
    .map((heading) => {
      const column = columnsState.getColumn(heading.datapoint_ref)
      const isHidden = !columnsState.isColumnVisible(heading.datapoint_ref)

      if (!isHidden) {
        shownColumnsCount += 1
      }

      const option: ParsedGridColumnOption = {
        columnName: column?.name || heading.datapoint_name,
        datapointRef: heading.datapoint_ref,
        datapointName: heading.datapoint_name,
        datapointType: heading.datapoint_type,
        classificationId: heading.classification_id,
        assetCategory: heading.asset_category,
        isHidden,
      }
      return option
    })
    .sort((a, b) => a.columnName.localeCompare(b.columnName))

  return [shownColumnsCount, columnsOptions] as const
}

export function getPanelColumns(columnOrder: GridDataViewColumn[] | null) {
  if (!columnOrder) {
    return []
  }

  return columnOrder.filter((column) => column.position === 'p').map((column) => column.datapoint_ref)
}

function getVisibleHeadings(headings: ParsedGridDataHeading[], columnsState: ViewColumnsState) {
  // show all headings when view doesn't have columns (just a shortcut to speed things up)
  if (columnsState.isEmpty) {
    return headings
  }

  return headings.filter((heading) => {
    return heading.meta.position === 'change' || columnsState.isColumnVisible(heading.meta.datapoint_ref)
  })
}

export class ViewColumnsState {
  private state: { [datapointRef: string]: GridDataViewColumn }
  readonly isEmpty: boolean

  constructor(viewColumns: GridDataViewColumn[] | null | undefined) {
    this.state = {}
    this.isEmpty = true

    if (viewColumns) {
      for (const column of viewColumns) {
        this.state[column.datapoint_ref] = column
        this.isEmpty = false
      }
    }
  }

  getColumn(datapointRef: string): GridDataViewColumn | undefined {
    return this.state[datapointRef]
  }

  isColumnVisible(datapointRef: string): boolean {
    // show all columns when view doesn't have columns
    if (this.isEmpty) {
      return true
    }

    // do not show when column is not on view
    const column = this.getColumn(datapointRef)
    if (!column) {
      return false
    }

    // show columns that are not hidden
    return column.position !== 'h'
  }
}

/// Returns the group level of an aggregate path.
/// Paths are expected to have a leading and trailing slash.
/// Eg.: '/a/' => 1 and '/a/b/' => 2
export function getAggregatePathGroupLevel(aggregatePath: string): number {
  return aggregatePath.split('/').length - 2
}
