import { createContext, ReactNode, useContext } from 'react'
import api from '../../../services/data/api'
import { queryClient } from '../../../services/data/react-query'
import { UserSelf } from '../../../services/data/types/auth'
import { appModelStorage } from '../../../services/flex-layout/models'
import session from '../../../services/session'
import useUserInfo from './use-user-info'

interface AuthContextType {
  user: UserSelf | null
  loginRequest: (username: string, password: string) => Promise<LoginRequestResult>
  loginConfirmation: (username: string, password: string, mfaCode: string) => Promise<LoginConfirmationResult>
  logout: () => Promise<void>
  refreshUser: () => Promise<void>
}

export type LoginRequestResult = {
  mfa_required: boolean
  tenant_ref: string | null
}

export type LoginConfirmationResult = {
  tenant_ref: string | null
}

const AuthContext = createContext<AuthContextType>(null!)

export function AuthProvider({ children }: { children: ReactNode }) {
  const { user, refreshUser } = useUserInfo()

  async function loginRequest(username: string, password: string): Promise<LoginRequestResult> {
    const response = await api.loginRequest(username, password)

    if (response.status !== 200) {
      throw new Error(String(response.data))
    }

    if (response.data.token) {
      session.saveToken(response.data.token)
      refreshUser()
    }

    return {
      mfa_required: response.data.mfa_required,
      tenant_ref: response.data.tenant_ref,
    }
  }

  async function loginConfirmation(
    username: string,
    password: string,
    mfaCode: string
  ): Promise<LoginConfirmationResult> {
    const response = await api.loginConfirmation(username, password, mfaCode)

    if (response.status !== 200) {
      throw new Error(String(response.data))
    }

    session.saveToken(response.data.token)
    refreshUser()

    return {
      tenant_ref: response.data.tenant_ref,
    }
  }

  async function logout() {
    appModelStorage.clear()
    queryClient.clear()
    session.clearSession()
    refreshUser()
  }

  return (
    <AuthContext.Provider value={{ user, loginRequest, loginConfirmation, logout, refreshUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
