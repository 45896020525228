import { ChevronLeft } from '@mui/icons-material'
import { IconButton, List, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { ReactNode } from 'react'

type CollapsibleSideNavigationProps = {
  open: boolean
  title: string
  children: ReactNode
  onClose: () => void
}

function CollapsibleSideNavigation(props: CollapsibleSideNavigationProps) {
  const { open, title, children, onClose } = props

  return (
    <Stack
      sx={{
        minWidth: 212,
        backgroundColor: 'gray.main',
        display: open ? 'flex' : 'none',
      }}
    >
      <Stack
        direction="row"
        sx={{
          pl: 2,
          pr: 1,
          minHeight: 56,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="subtitle1">{title}</Typography>
        <IconButton onClick={onClose}>
          <ChevronLeft color="primary" />
        </IconButton>
      </Stack>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        sx={{ width: '100%', maxWidth: 360, overflow: 'auto' }}
      >
        {children}
      </List>
    </Stack>
  )
}

export default CollapsibleSideNavigation
