import { useNavigate } from 'react-router-dom'
import Groups from '../../components/groups'

function GroupsPage() {
  const navigate = useNavigate()

  return <Groups onGroupClick={(groupRef) => navigate(`/settings/groups/${groupRef}`)} />
}

export default GroupsPage
