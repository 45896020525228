import { Dialog } from '@mui/material'
import { FormEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import { BaseUser } from '../../../services/data/types/user'
import UserForm from '../../settings/components/user-form'
import useEditAccoutMutation, { EditAccountCode } from '../data/use-edit-account-mutation'

type EditAccountModalProps = {
  user: BaseUser
  open: boolean
  onClose: () => void
}

const initialFormErrors = {
  general: '',
  username: '',
}

function EditAccountModal(props: EditAccountModalProps) {
  const { user, open, onClose } = props
  const { t } = useTranslation('account')

  const { mutate: editAccount } = useEditAccoutMutation()

  const initialFormValues = useMemo(() => {
    return {
      username: user.username,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      timezone: user.timezone,
    }
  }, [user])

  const [formValues, setFormValues] = useState(initialFormValues)
  const [formErrors, setFormErrors] = useState(initialFormErrors)

  const isFormDirty = Object.entries(formValues).some(([key, value]) => {
    const fieldKey = key as keyof typeof initialFormValues
    return initialFormValues[fieldKey] !== value
  })

  function handleChange(name: string, value: string) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    editAccount(formValues, {
      onSuccess: () => {
        queryClient.invalidateQueries(['user', user.user_ref])
        queryClient.invalidateQueries(['tenant', 'users'])
        queryClient.invalidateQueries(['userInfo'])
        handleClose()
      },
      onError: (error) => {
        const errorCode = error.response?.data?.error.code

        if (errorCode === EditAccountCode.USERNAME_TAKEN) {
          setFormErrors({ ...formErrors, username: 'account:username_taken' })
        } else {
          setFormErrors({ ...formErrors, general: 'common:general_error_message' })
        }
      },
    })
  }

  function handleClose() {
    setFormValues(initialFormValues)
    setFormErrors(initialFormErrors)
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('edit_user_details')} onClose={handleClose} />
        <ModalContent>
          <UserForm type="edit" values={formValues} errors={formErrors} onChange={handleChange} />
        </ModalContent>
        <ModalActions confirmLabel={t('common:save')} confirmDisabled={!isFormDirty} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default EditAccountModal
