import { StackProps } from '@mui/material'
import { Stack } from '@mui/system'
import { ReactNode } from 'react'

type AppLayoutProps = {
  direction?: StackProps['direction']
  bgcolor?: string
  children: ReactNode
}

function AppLayout(props: AppLayoutProps) {
  const { direction, bgcolor, children } = props

  return (
    <Stack
      className="app-layout"
      direction={direction}
      flex="1"
      height="100%"
      overflow="hidden"
      color="white"
      bgcolor={bgcolor || 'gray.light'}
    >
      {children}
    </Stack>
  )
}

export default AppLayout
