import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useChangeTenantAdminStatusMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, ChangeTenantAdminStatusParams>({
    mutationFn: ({ userRef, body }) => api.changeUserTenantAdminStatus(userRef, body),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type ChangeTenantAdminStatusParams = {
  userRef: string
  body: { is_tenant_admin: boolean }
}

export default useChangeTenantAdminStatusMutation
