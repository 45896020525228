import { AccountCircle, Pin } from '@mui/icons-material'
import { Box, Button, CircularProgress, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Alert from '../../../components/alert'
import PasswordField from '../../../components/fields/password-field'
import AuthLayout from '../../../components/layouts/auth-layout'
import { useAuth } from '../data/auth-context'
import AuthCard from './auth-card'

type LoginProps = {
  onSuccess: (tenantRef: string | null) => void
  onForgotPassword: () => void
}

function Login(props: LoginProps) {
  const { onSuccess, onForgotPassword } = props

  const { t } = useTranslation('auth')

  const { state } = useLocation() as LoginUseLocationType
  const { loginRequest, loginConfirmation } = useAuth()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [mfaRequired, setMfaRequired] = useState(false)
  const [mfaCode, setMfaCode] = useState('')

  const [message, setMessage] = useState<string | null>(state?.message || null)
  const [warning, setWarning] = useState<string | null>(state?.warning || null)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  function clearMessage() {
    setMessage(null)
  }

  function clearWarning() {
    setWarning(null)
  }

  function clearError() {
    setError(null)
  }

  function clearAlerts() {
    clearMessage()
    clearWarning()
    clearError()
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    if (isLoading) {
      return
    }

    clearAlerts()

    if (!username.trim() || !password.trim()) {
      setError(t('fill_all_fields'))
      return
    }

    if (mfaRequired) {
      if (!mfaCode.trim()) {
        setError(t('fill_all_fields'))
        return
      }

      setIsLoading(true)
      loginConfirmation(username, password, mfaCode)
        .then((response) => {
          onSuccess(response.tenant_ref)
        })
        .catch(() => {
          setError(t('invalid_mfa_code'))
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(true)
      loginRequest(username, password)
        .then((response) => {
          if (response.mfa_required) {
            setMfaRequired(true)
          } else {
            onSuccess(response.tenant_ref)
          }
        })
        .catch(() => {
          setError(t('invalid_credentials'))
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <AuthLayout>
      <Box maxWidth="560px" marginX="auto">
        <Box minHeight={70} marginBottom={2}>
          <Alert severity="success" message={message} onClose={clearMessage} />
          <Alert severity="warning" message={warning} onClose={clearWarning} />
          <Alert severity="error" message={error} onClose={clearError} />
        </Box>

        <AuthCard title={t('login_title')}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {!mfaRequired && (
                <>
                  <TextField
                    required
                    autoFocus
                    name="username"
                    placeholder={t('username')}
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <PasswordField
                    required
                    name="password"
                    placeholder={t('password')}
                    autoComplete="current-password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </>
              )}

              {mfaRequired && (
                <>
                  <TextField
                    required
                    autoFocus
                    name="mfa_code"
                    placeholder={t('mfa_code_placeholder')}
                    helperText={t('mfa_code_helper')}
                    autoComplete="off"
                    value={mfaCode}
                    onChange={(event) => setMfaCode(event.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Pin />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      '.MuiFormHelperText-root': {
                        fontSize: '14px',
                      },
                    }}
                  />
                </>
              )}

              <Stack direction="row-reverse" alignItems="center" gap={2}>
                <Button data-testid="submit-button" type="submit" variant="contained" disabled={isLoading}>
                  {isLoading && <CircularProgress size={24} color="inherit" sx={{ position: 'absolute' }} />}
                  <Typography color="inherit" variant="inherit" sx={{ opacity: isLoading ? 0 : 1 }}>
                    {t('login')}
                  </Typography>
                </Button>

                {mfaRequired && <Button onClick={() => setMfaRequired(false)}>{t('common:cancel')}</Button>}

                <Link
                  component="button"
                  onClick={onForgotPassword}
                  underline="hover"
                  sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', mr: 'auto' }}
                >
                  {t('forgot_password')}
                </Link>
              </Stack>
            </Stack>
          </form>
        </AuthCard>
      </Box>
    </AuthLayout>
  )
}

export default Login

export type LoginUseLocationType = {
  state: {
    message?: string
    warning?: string
    from?: string
  }
}
