import { CalendarToday } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AlertIcon from '../../../components/alert-icon'
import FilterButton from '../../../components/filters/filter-button'
import AppLayout from '../../../components/layouts/app-layout'
import TableContainerHeader from '../../../components/table-container-header'
import { formatDatapoint } from '../../../services/data/datapoint-formatting'
import { ComplianceResult } from '../../../services/data/types/compliance'
import useOpenState from '../../../utils/hooks/use-open-state'
import { newId } from '../../../utils/id'
import AsOfDateMenu from '../../portfolios/components/as-of-date-menu'
import PortfolioGridNav from '../../portfolios/components/portfolio-grid-nav'
import usePortfolioCompliaceQuery from '../data/use-portfolio-compliance-query'
import ComplianceHeader from './compliance-header'
import { getRuleStatusSeverity } from './compliance-indicator'

type ComplianceRootProps = {
  portfolioRef?: string
  onPortfolioSelect: (portfolioRef: string) => void
}

export function ComplianceRoot(props: ComplianceRootProps) {
  const { portfolioRef, onPortfolioSelect } = props

  const { t } = useTranslation('compliance')

  const nav = useOpenState({ open: true })
  const onlyAlerts = useOpenState({ open: true })

  const [asOfDate, setAsOfDate] = useState<string | null>(null)
  const [asOfDateMenuEl, setAsOfDateMenuEl] = useState<HTMLElement | null>(null)

  const complianceQuery = usePortfolioCompliaceQuery(portfolioRef, asOfDate)
  const compliance = complianceQuery.data?.data || null

  function reloadData() {
    complianceQuery.refetch()
  }

  return (
    <AppLayout direction="row">
      <PortfolioGridNav
        open={nav.isOpen}
        selectedPortfolioRef={portfolioRef}
        navTitle={t('common:navigation.compliance')}
        onCloseClick={nav.close}
        onPortfolioSelect={onPortfolioSelect}
      />

      <Stack flex={1} overflow="hidden">
        <ComplianceHeader
          portfolioTitle={compliance?.portfolio_name || ''}
          currency={compliance?.base_currency || ''}
          showLoading={complianceQuery.isFetching}
          isNavOpen={nav.isOpen}
          onOpenNavClick={nav.open}
          onReloadData={reloadData}
        />

        <Stack sx={{ height: '100%', overflow: 'hidden' }}>
          <Stack direction="row" alignItems="center" gap={1} p={2}>
            <FilterButton
              label={asOfDate ? asOfDate : t('portfolio:current')}
              isActive={!!asOfDate}
              Icon={CalendarToday}
              showArrow={true}
              onClick={(event) => setAsOfDateMenuEl(event.currentTarget)}
            />
            <OnlyAlertsButton checked={onlyAlerts.isOpen} onClick={onlyAlerts.toggle} />
          </Stack>

          <ComplianceResults compliance={compliance} showOnlyAlerts={onlyAlerts.isOpen} />
        </Stack>
      </Stack>

      {setAsOfDate && (
        <AsOfDateMenu
          anchorEl={asOfDateMenuEl}
          currentAsOfDate={asOfDate}
          onSetAsOfDate={setAsOfDate}
          onClose={() => setAsOfDateMenuEl(null)}
        />
      )}
    </AppLayout>
  )
}

type ComplianceResultProps = {
  compliance: ComplianceResult | null
  showOnlyAlerts: boolean
}

function ComplianceResults(props: ComplianceResultProps) {
  const { compliance, showOnlyAlerts } = props

  const { t } = useTranslation('compliance')

  if (!compliance) {
    return null
  }

  return (
    <Box
      sx={{
        p: 2,
        pt: 0,
        overflowX: 'auto',
        '& *': {
          mb: 4,
        },
        '& *:last-of-type': {
          mb: 0,
        },
      }}
    >
      {!compliance.rulesets.length && <TableContainer component={Paper}>{t('messages.not_configured')}</TableContainer>}
      {!!compliance.rulesets.length &&
        compliance.rulesets.map((ruleset) => {
          let failed = 0
          const rules = ruleset.rules.filter((rule) => {
            failed += rule.rule_status !== 'pass' ? 1 : 0
            return !showOnlyAlerts || rule.rule_status !== 'pass'
          })
          const total = ruleset.rules.length
          const description = failed === 0 ? t('no_rules_alerts') : t('rules_have_alerts', { failed, total })

          return (
            <TableContainer key={ruleset.ruleset_ref} component={Paper}>
              <TableContainerHeader title={ruleset.ruleset_name} description={description} />
              {!!rules.length && (
                <Table
                  sx={{
                    '.MuiTableBody-root .MuiTableRow-root.rule-row .MuiTableCell-root': {
                      backgroundColor: 'gray.50',
                      borderBottom: '1px solid',
                      borderBottomColor: 'var(--af-border-color-solid)',
                      borderRadius: 0,
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('table.rule')}</TableCell>
                      <TableCell>{t('table.current_value')}</TableCell>
                      <TableCell>{t('table.reason')}</TableCell>
                      <TableCell sx={{ width: '100px' }}>{t('table.outcome')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rules.map((rule) => {
                      return [
                        <TableRow key={rule.rule_id} hover className="rule-row">
                          <TableCell colSpan={3}>{rule.rule_name}</TableCell>
                          <TableCell sx={{ whiteSpace: 'nowrap', verticalAlign: 'center' }}>
                            <Stack justifyContent="center">
                              <AlertIcon
                                useInfoIcon
                                iconSize="regular"
                                severity={getRuleStatusSeverity(rule.rule_status)}
                                message=""
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>,
                        ...rule.results.map((result) => {
                          return (
                            <TableRow
                              key={`${rule.rule_id}-${result.row_ref}-${newId()}`}
                              hover
                              sx={{ '.MuiTableCell-root': { fontSize: '14px' } }}
                            >
                              <TableCell sx={{ pl: 4 }}>{result.description}</TableCell>
                              <TableCell>{result.value_display || formatDatapoint(result.value)}</TableCell>
                              <TableCell sx={{ whiteSpace: 'nowrap' }}>{result.reason}</TableCell>
                              <TableCell>
                                <AlertIcon
                                  useInfoIcon
                                  iconSize="regular"
                                  severity={getRuleStatusSeverity(result.rule_status)}
                                  message={result.alert}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        }),
                      ]
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          )
        })}
    </Box>
  )
}

type OnlyAlertsButtonProps = {
  checked: boolean
  disabled?: boolean
  onClick: (checked: boolean) => void
}

function OnlyAlertsButton(props: OnlyAlertsButtonProps) {
  const { checked, disabled, onClick } = props

  const { t } = useTranslation('compliance')

  return (
    <Button
      variant="outlined"
      disabled={disabled}
      onClick={() => onClick(!checked)}
      sx={{
        px: 1.5,
        height: '32px',
        color: 'white',
        backgroundColor: 'gray.main',
        borderColor: 'gray.main',
        '&:hover': { backgroundColor: 'gray.main' },
      }}
    >
      <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
        <Checkbox size="small" value={checked} checked={checked} disabled={disabled} disableRipple sx={{ p: 0 }} />
        <Typography color="inherit" textTransform="none" fontSize="12px">
          {t('show_only_alers')}
        </Typography>
      </Stack>
    </Button>
  )
}
