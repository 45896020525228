import { useNavigate } from 'react-router-dom'
import { ChannelAction, connectAuthChannel, getCurrentWindow } from '../../../../services/openfin/fin'
import Login from '../../components/login'

function LoginView() {
  const navigate = useNavigate()

  async function handleSuccess(tenantRef: string | null) {
    if (tenantRef) {
      const client = await connectAuthChannel()
      client.dispatch(ChannelAction.login)

      const window = await getCurrentWindow()
      window.close()
    } else {
      navigate('/openfin/views/login/tenant')
    }
  }

  function handleForgotPassword() {
    navigate('/openfin/views/forgot-password')
  }

  return <Login onSuccess={handleSuccess} onForgotPassword={handleForgotPassword} />
}

export default LoginView
