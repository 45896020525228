import account from './account.json'
import auth from './auth.json'
import bankAccounts from './bank-accounts.json'
import common from './common.json'
import compliance from './compliance.json'
import dataEngine from './data-engine.json'
import dataTable from './data-table.json'
import orderBlotter from './order-blotter.json'
import portfolio from './portfolio.json'
import settings from './settings.json'
import tradeTicket from './trade-ticket.json'

export default {
  account,
  auth,
  bankAccounts,
  common,
  compliance,
  dataEngine,
  dataTable,
  orderBlotter,
  portfolio,
  settings,
  tradeTicket,
}
