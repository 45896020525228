import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useSecurityDetailsQuery(assetRef?: string) {
  return useQuery({
    queryKey: ['asset', assetRef],
    queryFn: () => {
      if (assetRef) {
        return api.getSecurityDetails(assetRef)
      }
    },
    enabled: !!assetRef,
  })
}

export default useSecurityDetailsQuery
