import { Add, MoreVert } from '@mui/icons-material'
import {
  Chip,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { isBefore } from 'date-fns'
import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorSnackbar from '../../../components/error-snackbar'
import TableContainerHeader from '../../../components/table-container-header'
import { queryClient } from '../../../services/data/react-query'
import useOpenState from '../../../utils/hooks/use-open-state'
import useClassificationQuery from '../data/use-classification-query'
import useClassificationValuesMutation from '../data/use-reorder-classification-values-mutation'
import AddClassificationValueModal from './add-classification-value-modal'
import ClassificationValueMenu from './classification-value-menu'
import DataEngineShell from './data-engine-shell'
import DeleteClassificationValueModal from './delete-classification-value-modal'
import EditClassificationValueModal from './edit-classification-value-modal'
import { useDataEngineUpdateLoadingState } from './use-data-engine-outlet-context'

type ClassificationDetailsProps = {
  classificationId: string
}

function ClassificationDetails(props: ClassificationDetailsProps) {
  const { classificationId } = props
  const { t } = useTranslation('dataEngine')

  const classificationResponse = useClassificationQuery(classificationId, { enabled: true })

  const {
    error: reorderClassificationValuesError,
    mutate: reorderClassificationValues,
    reset: reorderClassificationValuesReset,
  } = useClassificationValuesMutation()

  const [classificationValueMenuAnchorEl, setClassificationValuesMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [activeClassificationValueId, setActiveClassificationValueId] = useState<number | null>(null)

  const addClassificationValueModal = useOpenState()
  const editClassificationValueModal = useOpenState()
  const deleteClassificationValueModal = useOpenState()

  const classification = classificationResponse.data?.data
  const isBucketed = !!classification?.bucket_datapoint_ref

  const filteredClassificationValues =
    classification?.values.filter((value) => {
      if (value.expired) {
        const today = new Date()
        return !isBefore(new Date(value.expired), today)
      }
      return true
    }) || []

  const classificationValueIds = filteredClassificationValues.map((value) => value.value_id)
  const activeClassificationValueIndex = classificationValueIds.indexOf(activeClassificationValueId || NaN)

  const activeClassificationValue = filteredClassificationValues.find((value) => {
    return value.value_id === activeClassificationValueId
  })

  const isLoading = (!classification && classificationResponse.isFetching) || classificationResponse.isLoading

  useDataEngineUpdateLoadingState(isLoading)

  function handleClassificationValueMenuOpen(event: MouseEvent<HTMLElement>, classificationValueId: number) {
    event.stopPropagation()
    setClassificationValuesMenuAnchorEl(event.currentTarget)
    setActiveClassificationValueId(classificationValueId)
  }

  function handleClassificationValueMenuClose() {
    setClassificationValuesMenuAnchorEl(null)
    setActiveClassificationValueId(null)
  }

  function handleEditClassificationValueModalOpen() {
    setClassificationValuesMenuAnchorEl(null)
    editClassificationValueModal.open()
  }

  function handleEditClassificationValueModalClose() {
    setActiveClassificationValueId(null)
    editClassificationValueModal.close()
  }

  function handleDeleteClassificationValueModalOpen() {
    setClassificationValuesMenuAnchorEl(null)
    deleteClassificationValueModal.open()
  }

  function handleDeleteClassificationValueModalClose() {
    setActiveClassificationValueId(null)
    deleteClassificationValueModal.close()
  }

  function handleMoveUp() {
    reorderClassificationValueList('up')
  }

  function handleMoveDown() {
    reorderClassificationValueList('down')
  }

  function reorderClassificationValueList(direction: 'up' | 'down') {
    if (!activeClassificationValueId) {
      return
    }

    const indexMovements = {
      up: -1,
      down: 1,
    }

    const currentIndex = classificationValueIds.indexOf(activeClassificationValueId)
    const newIndex = currentIndex + indexMovements[direction]
    const updatedList = swapListItems(classificationValueIds, currentIndex, newIndex)

    reorderClassificationValues(
      { classificationId, valueIds: updatedList },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['classification', classificationId])
        },
      }
    )
  }

  if (isLoading) {
    return <DataEngineShell />
  }

  return (
    <DataEngineShell>
      <Stack p={2} gap={2}>
        <Paper
          component={Stack}
          direction="row"
          overflow="auto"
          elevation={0}
          p={3}
          sx={{ backgroundColor: 'gray.main' }}
        >
          <Detail
            label={t('classification_values_table.classification_name')}
            value={classification?.classification_name}
          />
          <Detail
            label={t('classification_values_table.classification_type')}
            value={t(`classifications_table.classification_type.${classification?.classification_type}`)}
          />
          <Detail label={t('classification_values_table.bucketed_on')} value={classification?.bucket_datapoint_name} />
        </Paper>
        <TableContainer component={Paper}>
          <TableContainerHeader
            title={t('classification_values_table.title')}
            action={
              <Tooltip title={t('classification_values_table.add')} arrow disableInteractive>
                <IconButton onClick={addClassificationValueModal.open}>
                  <Add />
                </IconButton>
              </Tooltip>
            }
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('classification_values_table.headers.tag')}</TableCell>
                <TableCell>{t('classification_values_table.headers.description')}</TableCell>
                <TableCell>{t('classification_values_table.headers.expiry')}</TableCell>
                <TableCell>{t('classification_values_table.headers.bucket_start_value')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredClassificationValues.map((classificationValue) => {
                const classificationValueId = classificationValue.value_id
                const bucketStartValue = isBucketed ? classificationValue.bucket_start : null
                const isMenuActive = classificationValueId === activeClassificationValueId

                return (
                  <TableRow
                    key={classificationValue.value_id}
                    className={isMenuActive ? 'active' : ''}
                    hover
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>
                      <Chip size="small" label={classificationValue.tag} sx={{ color: 'inherit' }} />
                    </TableCell>
                    <TableCell>{classificationValue.desc}</TableCell>
                    <TableCell>{classificationValue.expired || '-'}</TableCell>
                    <TableCell>{bucketStartValue || '-'}</TableCell>
                    <TableCell className="action-cell" align="right">
                      <IconButton onClick={(event) => handleClassificationValueMenuOpen(event, classificationValueId)}>
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <ClassificationValueMenu
          anchorEl={classificationValueMenuAnchorEl}
          moveUpDisabled={activeClassificationValueIndex === 0}
          moveDownDisabled={activeClassificationValueIndex === classificationValueIds.length - 1}
          onEdit={handleEditClassificationValueModalOpen}
          onMoveUp={handleMoveUp}
          onMoveDown={handleMoveDown}
          onDelete={handleDeleteClassificationValueModalOpen}
          onClose={handleClassificationValueMenuClose}
        />
        <AddClassificationValueModal
          open={addClassificationValueModal.isOpen}
          classificationId={classificationId}
          isBucketed={isBucketed}
          onClose={addClassificationValueModal.close}
        />
        <EditClassificationValueModal
          open={editClassificationValueModal.isOpen}
          classificationValue={activeClassificationValue}
          classificationId={classificationId}
          isBucketed={isBucketed}
          onClose={handleEditClassificationValueModalClose}
        />
        <DeleteClassificationValueModal
          open={deleteClassificationValueModal.isOpen}
          classificationId={classificationId}
          classificationValueId={activeClassificationValueId}
          classificationValueTag={activeClassificationValue?.tag}
          onClose={handleDeleteClassificationValueModalClose}
        />

        <ErrorSnackbar
          open={!!reorderClassificationValuesError}
          message={reorderClassificationValuesError}
          onClose={reorderClassificationValuesReset}
        />
      </Stack>
    </DataEngineShell>
  )
}

export default ClassificationDetails

type DetailProps = {
  label: string
  value?: string | null
}

function Detail(props: DetailProps) {
  const { label, value } = props

  return (
    <Stack width="100%" maxWidth={200} gap={0.5} px={1.5}>
      <Typography variant="caption" color="gray.700">
        {label}
      </Typography>
      <Typography variant="body1">{value || '-'}</Typography>
    </Stack>
  )
}

function swapListItems(list: number[], indexA: number, indexB: number) {
  const newList = [...list]

  const itemToSwap = newList[indexA]!
  newList[indexA] = newList[indexB]!
  newList[indexB] = itemToSwap

  return newList
}
