import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'

function useForgotPasswordMutation() {
  return useMutation<AxiosResponse, ForgotPasswordError, ForgotPasswordParams>({
    mutationFn: ({ username }) => api.forgotPassword(username),
  })
}

type ForgotPasswordParams = {
  username: string
}

type ForgotPasswordError = AxiosError<
  | {
      error: {
        code: ForgotPasswordErrorCode
      }
    }
  | undefined
>

export enum ForgotPasswordErrorCode {
  USER_DOES_NOT_EXIST = '0x00010000',
}

export default useForgotPasswordMutation
