import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useAvailablePortfoliosQuery(groupRef: string) {
  return useQuery(['portfolio', 'group', groupRef, 'portfolios', 'available'], () => {
    return api.getAvailablePortfolios(groupRef)
  })
}

export default useAvailablePortfoliosQuery
