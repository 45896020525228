import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { GridDataViewFilters } from '../../../services/data/types/grid-data-view'

function useTransactionsQuery(
  portfolioRef: string | null | undefined,
  datasetRef: string | null,
  aggregations: string[],
  filters: GridDataViewFilters | null,
  panelColumns: string[] | null,
  options: { enabled: boolean },
  asOfDate?: string | null
) {
  const response = useQuery(
    ['transactions', portfolioRef, aggregations, datasetRef, filters, panelColumns],
    () => {
      if (!portfolioRef) {
        return null
      }

      return api
        .getTransactions(
          portfolioRef,
          datasetRef,
          {
            aggregation: aggregations,
            filters,
            panel_columns: panelColumns,
          },
          asOfDate
        )
        .then((response) => {
          return {
            ...response,
            data: response.data,
          }
        })
    },
    {
      enabled: !!portfolioRef && !!datasetRef && options.enabled,
      cacheTime: 0,
      keepPreviousData: true,
    }
  )

  return { ...response, error: parseError(response.error as AxiosError) }
}

export default useTransactionsQuery
