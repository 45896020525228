import { Box, Dialog, FormControl, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { isFuture, isToday, startOfToday } from 'date-fns'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import { UserDetailsDataset } from '../../../services/data/types/dataset'
import { dateFormat, datePlaceholder, formatNaiveDate, parseNaiveDate } from '../../../utils/dates'
import useUpdateDatasetMutation from '../data/use-edit-dataset-mutation'

type EditDatasetModalProps = {
  open: boolean
  groupRef: string
  dataset: UserDetailsDataset | undefined
  onClose: () => void
}

function EditDatasetModal(props: EditDatasetModalProps) {
  const { open, groupRef, dataset, onClose } = props
  const { t } = useTranslation('settings')

  const [expiryDate, setExpiryDate] = useState<Date | null>(null)
  const [isSaveDisabled, setIsSaveDisapbled] = useState(false)

  const updateDataset = useUpdateDatasetMutation()

  useEffect(() => {
    setExpiryDate(parseNaiveDate(dataset?.expiry))
  }, [dataset])

  function handleExpiryChange(date: Date | null) {
    if (!date || isToday(date) || isFuture(date)) {
      setIsSaveDisapbled(false)
    } else {
      setIsSaveDisapbled(true)
    }
    setExpiryDate(date)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (!dataset) {
      return
    }

    updateDataset.mutate(
      {
        groupRef,
        datasetRef: dataset.dataset_ref,
        expiry: expiryDate && formatNaiveDate(expiryDate),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['group', groupRef, 'datasets', 'current'])
          handleClose()
        },
      }
    )
  }

  function handleClose() {
    updateDataset.reset()
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('datasets.edit_dataset')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={updateDataset.error} />
          <Box>
            <Typography variant="caption" color={'gray.700'}>
              {t('datasets.dataset')}
            </Typography>
            <Typography variant="body1" sx={{ pt: 1, pb: 1.5 }}>
              {dataset?.dataset_name}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption" color={'gray.700'}>
              {t('datasets.owner')}
            </Typography>
            <Typography variant="body1" sx={{ pt: 1, pb: 1.5 }}>
              {dataset?.owner_name}
            </Typography>
          </Box>
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t('datasets.expiry')}
                inputFormat={dateFormat}
                value={expiryDate}
                minDate={startOfToday()}
                disablePast
                onChange={(date) => handleExpiryChange(date)}
                renderInput={({ required, ...params }) => (
                  <TextField
                    {...params}
                    autoComplete="off"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: datePlaceholder,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('common:save')} confirmDisabled={isSaveDisabled} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default EditDatasetModal
