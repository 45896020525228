import { AssetCategory, DataDocType } from '../../../services/data/types/asset-static-data'
import { DatapointPayload, DatapointType, Variety } from '../../../services/data/types/datapoint'
import { DatapointFormValues } from '../components/datapoint-form'

export function parseDatapointFormToPayload(values: DatapointFormValues): DatapointPayload {
  const {
    datapointName,
    variety,
    candidateAliasId,
    dataType,
    assetCategory,
    datadocType,
    classificationId,
    calculationId,
    parentClassId,
    aggregationType,
    weightDatapointRef,
    customFunctionId,
    aggregationOnlyAll,
    calculationInputs,
  } = values

  return {
    datapoint_name: datapointName,
    candidate_alias_id: candidateAliasId === '' ? null : Number(candidateAliasId),
    variety: variety as Variety,
    calc_id: calculationId === '' ? null : Number(calculationId),
    calc_inputs: calculationInputs,
    data_type: dataType as DatapointType,
    asset_category: assetCategory === '' ? null : (assetCategory as AssetCategory),
    datadoc_type: datadocType === '' ? null : (datadocType as DataDocType),
    classification_id: classificationId === '' ? null : Number(classificationId),
    parent_class_id: parentClassId === '' ? null : Number(parentClassId),
    aggregation: aggregationType
      ? {
          agg_type: aggregationType,
          onlyall: aggregationOnlyAll,
          weight_dpref: weightDatapointRef || null,
          custom_fn_id: customFunctionId === '' ? null : Number(customFunctionId),
        }
      : null,
  }
}
