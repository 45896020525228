import { Box, Button, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Alert from '../../../../components/alert'
import PasswordField from '../../../../components/fields/password-field'
import AuthLayout from '../../../../components/layouts/auth-layout'
import config from '../../../../config'
import session from '../../../../services/session'
import AuthCard from '../../components/auth-card'
import useResetPasswordMutation from '../../data/use-reset-password-mutation'

function ResetPasswordPage() {
  const { token } = useParams()
  const { t } = useTranslation('auth')

  const [error, setError] = useState<string | null>(null)
  const resetPassword = useResetPasswordMutation()

  useEffect(() => {
    if (!token) {
      setError(t('missing_token'))
    }
  }, [token, setError])

  async function handleOnSubmit(event: any) {
    event.preventDefault()

    setError(null)
    session.clearSession()

    const newPassword = event.target.elements.newPassword.value
    const newPasswordConfirmation = event.target.elements.newPasswordConfirmation.value

    if (!token) {
      setError(t('missing_token'))
      return
    }

    if (!newPassword || !newPasswordConfirmation) {
      setError(t('please_fill_all_fields'))
      return
    }

    if (newPassword !== newPasswordConfirmation) {
      setError(t('password_should_match'))
      return
    }

    resetPassword.mutate(
      { token, newPassword },
      {
        onSuccess: () => {
          location.href = config.baseUrl
        },
        onError: () => {
          setError(t('changing_password_error'))
        },
      }
    )
  }

  return (
    <AuthLayout>
      <Box maxWidth="560px" marginX="auto">
        <Box minHeight={70} marginBottom={2}>
          <Alert severity="error" message={error} />
        </Box>

        <AuthCard title="Reset your password">
          <form onSubmit={handleOnSubmit}>
            <Stack spacing={3}>
              <PasswordField
                required
                autoFocus
                name="newPassword"
                placeholder="New password"
                autoComplete="new-password"
              />

              <PasswordField
                required
                name="newPasswordConfirmation"
                placeholder="Repeat password"
                autoComplete="new-password"
              />

              <Stack direction="row" justifyContent="end" alignItems="center">
                <Button type="submit" variant="contained">
                  {t('account:save')}
                </Button>
              </Stack>
            </Stack>
          </form>
        </AuthCard>
      </Box>
    </AuthLayout>
  )
}

export default ResetPasswordPage
