import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'

function useResetPasswordMutation() {
  return useMutation<AxiosResponse, AxiosError, ResetPasswordParams>({
    mutationFn: ({ token, newPassword }) => api.resetPassword(token, newPassword),
  })
}

type ResetPasswordParams = {
  token: string
  newPassword: string
}

export default useResetPasswordMutation
