import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'
import { ViewType } from '../../../services/data/types/view'

function useGetViewsQuery(
  viewType: ViewType,
  { includeSharedWith, includeViewOptions }: { includeSharedWith?: boolean; includeViewOptions?: boolean }
) {
  return useQuery({
    queryKey: ['views', viewType],
    queryFn: () => {
      if (!viewType) {
        return null
      }

      return api.getViews(viewType, { includeSharedWith, includeViewOptions })
    },
    keepPreviousData: true,
  })
}

export default useGetViewsQuery
