import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { CreateManualFillParams } from '../../../services/data/types/order'

export function useCreateManualFillMutation() {
  const response = useMutation<AxiosResponse, AxiosError, CreateManualFillParams>({
    mutationFn: (params) => api.createManualFill(params),
  })
  return { ...response, error: parseError(response.error) }
}
