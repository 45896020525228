import TradeTicketRoot from '../components/trade-ticket-root'

function TradeTicketPage() {
  function handleCloseView() {
    window.close()
  }

  return <TradeTicketRoot onCloseView={handleCloseView} />
}

export default TradeTicketPage
