import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import { GroupType, GroupTypes } from '../../../services/data/types/group'

type CreateUpdateGroupFormProps = {
  values: CreateUpdateGroupFormValues
  onChange: (name: string, value: string) => void
  errorMessage: string | null
  groupTypes: GroupTypes
}

type CreateUpdateGroupFormValues = {
  groupName: string
  groupType: string
}

function CreateUpdateGroupForm(props: CreateUpdateGroupFormProps) {
  const { values, onChange, errorMessage, groupTypes } = props

  const { t } = useTranslation('settings')

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target
    onChange(name, value)
  }

  function handleSelectChange(event: SelectChangeEvent) {
    const { name, value } = event.target
    onChange(name, value)
  }

  return (
    <>
      <Alert severity="error" message={errorMessage} />
      <Stack direction="row" gap={2}>
        <TextField
          required
          name="groupName"
          label={t('create_groups_modal.group_name_input_label')}
          value={values.groupName}
          onChange={handleChange}
          sx={{ flex: 1 }}
        />
        <FormControl sx={{ flex: 1 }}>
          <InputLabel>{t('create_groups_modal.select_group_field_label')}</InputLabel>
          <Select
            name="groupType"
            label={t('create_groups_modal.select_group_field_label')}
            value={values.groupType}
            onChange={handleSelectChange}
          >
            {Object.entries(groupTypes).map(([groupTypeId, _groupType]) => (
              <MenuItem key={groupTypeId} value={groupTypeId}>
                {groupTypes[groupTypeId as GroupType] || groupTypeId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </>
  )
}

export default CreateUpdateGroupForm
