import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useClassificationValues(classificationId: number | null | undefined) {
  const response = useQuery({
    queryKey: ['classification', classificationId, 'values'],
    queryFn: () => {
      if (!classificationId) {
        return
      }

      return api.getClassificationValues(classificationId)
    },
    cacheTime: 0,
    enabled: !!classificationId,
  })

  return { ...response, error: parseError(response.error as AxiosError) }
}

export default useClassificationValues
