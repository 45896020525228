import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useBankAccountsCounterpartiesQuery() {
  const response = useQuery({
    queryKey: ['bank-accounts', 'counterparties'],
    queryFn: api.getBankAccountsCounterparties,
  })

  return { ...response, error: parseError(response.error as AxiosError) }
}

export default useBankAccountsCounterpartiesQuery
