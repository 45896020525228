import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useDatasetsWithDatapointsQuery() {
  return useQuery({
    queryKey: ['datasets', 'withdatapoints'],
    queryFn: api.getDatasetsWithDatapoints,
    cacheTime: 0,
  })
}

export default useDatasetsWithDatapointsQuery
