import { FormControlLabel, PopoverOrigin, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { Box, Stack, SxProps, Theme } from '@mui/system'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { i18n } from '../../i18n'
import { FilterValue, ParsedFilter } from '../../services/data/filter-parsing'
import { DatapointType } from '../../services/data/types/datapoint'
import { GridDataViewFilterOperator } from '../../services/data/types/grid-data-view'
import { dateFormat, datePlaceholder, formatNaiveDate, parseNaiveDate } from '../../utils/dates'
import NumericField from '../fields/numeric-field'
import FilterFormButtons from './filter-form-buttons'
import SelectFilterMenu from './select-filter-menu'

export const dateValueOperators = ['eq', 'gt', 'lt', 'btwn']

type DateTimeFilterMenuProps = {
  selectedOperator?: GridDataViewFilterOperator
  selectedValues?: FilterValue[]
  anchorEl: HTMLElement | null
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  disableBackdrop?: boolean
  isSelectedFilter?: boolean
  onChange: (operator: GridDataViewFilterOperator, values: FilterValue[]) => void
  onClose: () => void
  onDelete?: () => void
  sx?: SxProps<Theme>
}

function DateTimeFilterMenu(props: DateTimeFilterMenuProps) {
  const {
    selectedOperator,
    selectedValues,
    anchorEl,
    anchorOrigin,
    transformOrigin,
    disableBackdrop,
    isSelectedFilter,
    onChange,
    onClose,
    onDelete,
    sx,
  } = props

  const { t } = useTranslation('dataTable')

  const [operator, setOperator] = useState<GridDataViewFilterOperator | ''>('')
  const [days, setDays] = useState('')
  const [dateValues, setDateValues] = useState<{ date1: Date | null; date2: Date | null }>({ date1: null, date2: null })

  const isDatePickersVisible = dateValueOperators.includes(operator)

  useEffect(() => {
    const currentOperator = selectedOperator || ''
    const currentValues = selectedValues || []

    const value1 = currentValues[0] || ''
    const value2 = currentValues[1] || ''

    setOperator(currentOperator)

    if (dateValueOperators.includes(currentOperator)) {
      setDateValues({
        date1: typeof value1 === 'string' ? parseNaiveDate(value1) : null,
        date2: typeof value2 === 'string' ? parseNaiveDate(value2) : null,
      })
    } else {
      setDays(String(value1))
    }
  }, [selectedOperator, selectedValues, anchorEl])

  function handleOperatorChange(_: ChangeEvent<HTMLElement>, value: string) {
    setOperator(value as GridDataViewFilterOperator)
  }

  function handleDaysChange(value: string) {
    setDays(value)
  }

  function handleDateChange(name: string, value: Date | null) {
    const isDateValid = value && !!value.getTime()

    if (isDateValid) {
      setDateValues({ ...dateValues, [name]: value })
    }
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (!operator) {
      return
    }

    let values: (string | number)[] = []

    if (operator === 'lastxdays' || operator === 'nextxdaysinc' || operator === 'nextxdaysexc') {
      values = [Number(days)]
    }

    if (dateValueOperators.includes(operator)) {
      const date1 = dateValues.date1
      const date2 = operator === 'btwn' ? dateValues.date2 : ''

      if (date1 && date2) {
        values = [formatNaiveDate(date1), formatNaiveDate(date2)]
      } else if (date1) {
        values = [formatNaiveDate(date1)]
      } else {
        return
      }
    }

    onChange(operator, values)
    onClose()
  }

  function isApplyDisabled() {
    if (operator === 'lastxdays' || operator === 'nextxdaysinc' || operator === 'nextxdaysexc') {
      return days === ''
    }

    if (!dateValueOperators.includes(operator)) {
      return !operator
    }

    if (operator === 'btwn') {
      return !dateValues.date1 || !dateValues.date2
    }

    return !dateValues.date1
  }

  return (
    <SelectFilterMenu
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      disableBackdrop={disableBackdrop}
      sx={sx}
    >
      <form onSubmit={handleSubmit}>
        <RadioGroup
          value={operator}
          onChange={handleOperatorChange}
          sx={{
            gap: 0.25,
            px: 3,
            '& .MuiSvgIcon-root': {
              fontSize: 16,
            },
          }}
        >
          <FormControlLabel
            value="today"
            control={<Radio />}
            label={<Typography variant="body2">{t('filters.today')}</Typography>}
          />
          <FormControlLabel
            value="yesterday"
            control={<Radio />}
            label={<Typography variant="body2">{t('filters.yesterday')}</Typography>}
          />
          <FormControlLabel
            value="thismonth"
            control={<Radio />}
            label={<Typography variant="body2">{t('filters.this_month')}</Typography>}
          />
          <FormControlLabel
            value="lastmonth"
            control={<Radio />}
            label={<Typography variant="body2">{t('filters.last_month')}</Typography>}
          />
          <FormControlLabel
            value="lastxdays"
            control={<Radio />}
            label={<Typography variant="body2">{t('filters.last_x_days', { days: 'x' })}</Typography>}
          />
          <FormControlLabel
            value="nextxdaysinc"
            control={<Radio />}
            label={<Typography variant="body2">{t('filters.next_x_days_inc', { days: 'x' })}</Typography>}
          />
          <FormControlLabel
            value="nextxdaysexc"
            control={<Radio />}
            label={<Typography variant="body2">{t('filters.next_x_days_exc', { days: 'x' })}</Typography>}
          />
          <FormControlLabel
            value="lt"
            control={<Radio />}
            label={<Typography variant="body2">{t('filters.less_than')}</Typography>}
          />
          <FormControlLabel
            value="gt"
            control={<Radio />}
            label={<Typography variant="body2">{t('filters.more_than')}</Typography>}
          />
          <FormControlLabel
            value="eq"
            control={<Radio />}
            label={<Typography variant="body2">{t('filters.equal_to')}</Typography>}
          />
          <FormControlLabel
            value="btwn"
            control={<Radio />}
            label={<Typography variant="body2">{t('filters.between')}</Typography>}
          />
          {isDatePickersVisible && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack direction="column" gap={1} my={1}>
                <DatePicker
                  value={dateValues.date1}
                  inputFormat={dateFormat}
                  onChange={(value: Date | null) => handleDateChange('date1', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      error={false}
                      autoComplete="off"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: datePlaceholder,
                      }}
                    />
                  )}
                />
                {operator === 'btwn' && (
                  <DatePicker
                    value={dateValues.date2}
                    inputFormat={dateFormat}
                    onChange={(value: Date | null) => handleDateChange('date2', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        error={false}
                        autoComplete="off"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: datePlaceholder,
                        }}
                      />
                    )}
                  />
                )}
              </Stack>
            </LocalizationProvider>
          )}
          {(operator === 'lastxdays' || operator === 'nextxdaysinc' || operator === 'nextxdaysexc') && (
            <Box my={1}>
              <NumericField size="small" name="days" value={days} decimalScale={0} onValueChange={handleDaysChange} />
            </Box>
          )}
        </RadioGroup>
        <FilterFormButtons
          submitDisabled={isApplyDisabled()}
          clearDisabled={!isSelectedFilter}
          onCancel={onClose}
          onClear={onDelete}
        />
      </form>
    </SelectFilterMenu>
  )
}

export default DateTimeFilterMenu

export function getDateFilterLabel(operator: GridDataViewFilterOperator, values: ParsedFilter['filterValues']) {
  const formattedValue = getFormattedFilterValues(values, operator, 'DateTime')

  const fixedDateOperators: GridDataViewFilterOperator[] = [
    'today',
    'yesterday',
    'thismonth',
    'lastmonth',
    'lastxdays',
    'nextxdaysinc',
    'nextxdaysexc',
  ]

  if (fixedDateOperators.includes(operator)) {
    return formattedValue
  }

  return `${operator.toUpperCase()}: ${formattedValue}`
}

export function getFormattedFilterValues(
  filterValues: ParsedFilter['filterValues'],
  operator: GridDataViewFilterOperator,
  dataType: DatapointType
) {
  const formattedValue = filterValues
    .map((value) => {
      if (dataType === 'Percent') {
        return `${+value.value * 100}%`
      }
      return value.title || value.value
    })
    .join('; ')

  if (dataType === 'DateTime' || dataType === 'NaiveDate') {
    if (dateValueOperators.includes(operator)) {
      const formattedDateValues = filterValues.map((value) => {
        if (typeof value.value !== 'string') {
          throw new Error(`Invalid date value: ${value.value}`)
        }
        // Date values should be a NaiveDate, so no need to format.
        return value.value
      })
      return formattedDateValues.join('; ')
    }

    const dateTimeValues = {
      today: i18n.t('dataTable:filters.today'),
      yesterday: i18n.t('dataTable:filters.yesterday'),
      thismonth: i18n.t('dataTable:filters.this_month'),
      lastmonth: i18n.t('dataTable:filters.last_month'),
      lastxdays: i18n.t('dataTable:filters.last_x_days', { days: formattedValue }),
      nextxdaysinc: i18n.t('dataTable:filters.next_x_days_inc', { days: formattedValue }),
      nextxdaysexc: i18n.t('dataTable:filters.next_x_days_exc', { days: formattedValue }),
    }

    const key = operator as keyof typeof dateTimeValues
    return dateTimeValues[key]
  }

  return formattedValue
}
