type LogoProps = {
  width?: string
}

function Logo({ width = '200' }: LogoProps) {
  return (
    <svg width={width} viewBox="0 0 322.97 125.34" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M64.75 41.93h-8.46L40.37 83.34h8.64l2.74-7.46h13.58l-2.7-7.35h-8.19l6.12-16.62 6.13 16.62 2.69 7.35 2.74 7.46h8.64L64.77 41.93Zm41.05 26.39c4.78-2.04 7.63-6.35 7.63-12.29 0-8.45-5.83-14.04-14.62-14.04H84.3v41.35h7.98V69.66h6.18l8.5 13.68h8.5l-9.67-15.02ZM98 62.73h-5.71V49.34H98c4.36 0 7.34 1.92 7.34 6.7s-2.97 6.7-7.34 6.7Zm40.59 13.63c-7.34 0-13.46-5.24-13.46-13.69s6.06-13.68 13.39-13.68c4.02 0 8.04 1.57 10.72 5.41l5.82-4.02c-4.36-6.99-11.12-8.97-16.77-8.97-11.59 0-21.31 8.39-21.31 21.26s9.67 21.26 21.31 21.26c5.65 0 12.41-1.98 16.77-8.97l-5.82-4.02c-2.68 3.84-6.53 5.41-10.66 5.41Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M242.24 83.35h8.64l2.74-7.46 2.69-7.35 6.13-16.62 6.12 16.62h-8.19l-2.7 7.35h13.58l2.74 7.46h8.64l-15.92-41.41h-8.46l-15.99 41.41Zm-59.21-34.08v-7.28h-23.24v41.35h7.98V65.99h14.21v-6.81h-14.21v-9.9h15.26Zm6.21-7.28v41.35h7.98V41.99h-7.98Zm41.44 0v28.25h-.29L211.4 41.99h-7.98v41.35h7.98V55.1h.29l18.99 28.24h7.98V41.99h-7.98Z"
        style={{
          fill: '#9e6fff',
        }}
      />
    </svg>
  )
}

export default Logo
