import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { CorrectTransactionPayload } from '../../../services/data/types/trade-ticket'

export function useCorrectTransactionMutation() {
  const response = useMutation<AxiosResponse, AxiosError, CorrectTransactionParams>({
    mutationFn: ({ transactionRef, payload }) => {
      return api.correctTransaction(transactionRef, payload)
    },
  })

  return { ...response, error: parseError(response.error) }
}

export type CorrectTransactionParams = {
  transactionRef: string
  payload: CorrectTransactionPayload
}
