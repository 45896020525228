import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { ExportPortfolioData, ExportPortfolioParams } from '../../../services/data/types/portfolio'

function useExportPortfolioMutation() {
  const response = useMutation<AxiosResponse<ExportPortfolioData>, AxiosError, ExportPortfolioParams>({
    mutationFn: api.exportPortfolio,
  })

  return { ...response, error: parseError(response.error) }
}

export default useExportPortfolioMutation
