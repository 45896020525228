import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function usePortfolioGridOptionsQuery(portfolioRef?: string | null) {
  return useQuery(['portfolio', portfolioRef, 'options'], () => {
    if (!portfolioRef) {
      return null
    }

    return api.getPortfolioGridOptions(portfolioRef)
  })
}

export default usePortfolioGridOptionsQuery
