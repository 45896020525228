import { Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import NoMoreOptionsAlert from '../../../components/no-more-options-alert'
import { queryClient } from '../../../services/data/react-query'
import Permission from '../../../services/data/types/permission'
import useAddPermissionMutation from '../data/use-add-permission-mutation'
import useAvailablePermissionsQuery from '../data/use-available-permissions-query'
import useChangeTenantAdminStatusMutation from '../data/use-change-tenant-admin-status-mutation'

type AddPermissionModalProps = {
  open: boolean
  groupRef: string
  userRef: string
  isTenantAdmin: boolean
  adminPermission: Permission
  onClose: () => void
}

function AddPermissionModal(props: AddPermissionModalProps) {
  const { open, groupRef, userRef, isTenantAdmin, adminPermission, onClose } = props
  const { t } = useTranslation('settings')

  const {
    mutate: changeTenantAdminStatus,
    error: changeTenantAdminStatusError,
    reset: changeTenantAdminStatusReset,
  } = useChangeTenantAdminStatusMutation()
  const {
    mutate: addPermission,
    error: addPermissionToUserError,
    reset: addPermissionToUserReset,
  } = useAddPermissionMutation()

  const error = addPermissionToUserError || changeTenantAdminStatusError

  const [selectedPermissionTag, setSelectedPermissionTag] = useState('')

  const availablePermissionsResponse = useAvailablePermissionsQuery(groupRef)
  const availablePermissions = availablePermissionsResponse.data?.data || []
  const allAvailablePermissions = isTenantAdmin ? availablePermissions : availablePermissions.concat(adminPermission)
  const hasOptions = allAvailablePermissions.length > 0

  useEffect(() => {
    if (open) {
      availablePermissionsResponse.refetch()
    }
  }, [open])

  function handleSelectChange(event: SelectChangeEvent) {
    setSelectedPermissionTag(event.target.value)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (selectedPermissionTag === 'admin') {
      const adminStatusParams = {
        userRef,
        body: { is_tenant_admin: true },
      }
      changeTenantAdminStatus(adminStatusParams, {
        onSuccess: () => {
          queryClient.invalidateQueries(['tenant', 'current', 'user', userRef])
          handleClose()
        },
      })
      return
    }

    const addPermissionParams = {
      groupRef,
      permissionTag: selectedPermissionTag,
    }
    addPermission(addPermissionParams, {
      onSuccess: () => {
        queryClient.invalidateQueries(['permissions', 'current', groupRef])
        handleClose()
      },
    })
  }

  function handleClose() {
    changeTenantAdminStatusReset()
    addPermissionToUserReset()
    setSelectedPermissionTag('')
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('permission.add_permission')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={error} />
          {!hasOptions && <NoMoreOptionsAlert />}
          <FormControl disabled={!hasOptions}>
            <InputLabel required>{t('permission.permission')}</InputLabel>
            <Select
              required
              displayEmpty
              label={t('permission.permission')}
              value={selectedPermissionTag}
              onChange={handleSelectChange}
            >
              {allAvailablePermissions.map((permission) => (
                <MenuItem key={permission.name} value={permission.permission_tag}>
                  {permission.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('common:add')} confirmDisabled={!hasOptions} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default AddPermissionModal
