import { Dialog } from '@mui/material'
import { FormEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import { Dataset } from '../../../services/data/types/dataset'
import useEditDatasetMutation from '../data/use-edit-dataset-mutation'
import DatasetForm from './dataset-form'

type EditDatasetModalProps = {
  dataset?: Dataset
  open: boolean
  onClose: () => void
}

function EditDatasetModal(props: EditDatasetModalProps) {
  const { dataset, open, onClose } = props
  const { t } = useTranslation('dataEngine')

  const { mutate: editDataset, error: editDatasetError, reset: editDatasetReset } = useEditDatasetMutation()

  const initialFormValues = useMemo(() => {
    const sharedWith = dataset?.shared_to
      .map((group) => group.group_ref)
      .filter((groupRef) => groupRef !== dataset.owner_group_ref)

    return {
      owner: dataset?.owner_group_ref || '',
      datasetName: dataset?.dataset_name || '',
      published: dataset?.published || false,
      sharedWith: sharedWith || [],
    }
  }, [dataset])

  const [formValues, setFormValues] = useState(initialFormValues)

  useEffect(() => {
    setFormValues(initialFormValues)
  }, [initialFormValues])

  function handleChange(name: string, value: string | string[] | boolean) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (!dataset?.dataset_ref) {
      return
    }

    const editDatasetProps = {
      datasetRef: dataset?.dataset_ref,
      body: {
        dataset_name: formValues.datasetName,
        is_published: formValues.published,
      },
    }

    editDataset(editDatasetProps, {
      onSuccess: () => {
        queryClient.invalidateQueries(['datasets'])
        onClose()
      },
    })
  }

  function handleClose() {
    editDatasetReset()
    onClose()
    setFormValues(initialFormValues)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('edit_dataset')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={editDatasetError} />
          <DatasetForm type="edit" values={formValues} onChange={handleChange} />
        </ModalContent>
        <ModalActions confirmLabel={t('common:save')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default EditDatasetModal
