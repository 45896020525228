import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useRegularPortfolioDetailsQuery(portfolioRef: string | undefined) {
  return useQuery(
    ['portfolio', 'details', portfolioRef],
    () => {
      if (!portfolioRef) {
        return
      }

      return api.getRegularPortfolioDetails(portfolioRef)
    },
    {
      enabled: !!portfolioRef,
    }
  )
}

export default useRegularPortfolioDetailsQuery
