import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useSystemClassificationsQuery() {
  return useQuery({
    queryKey: ['classifications/system'],
    queryFn: api.getSystemClassifications,
  })
}

export default useSystemClassificationsQuery
