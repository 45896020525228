import { Dialog } from '@mui/material'
import { FormEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import { parseDatapointFormToPayload } from '../data/datapoint-parsing'
import useDatapointDetailsQuery from '../data/use-datapoint-details-query'
import useEditDatapointMutation from '../data/use-edit-datapoint-mutation'
import DatapointForm, { DatapointFormValues } from './datapoint-form'

type EditDatapointModalProps = {
  open: boolean
  currentDatasetRef: string
  currentDatapointRef: string
  onClose: () => void
}

function EditDatapointModal(props: EditDatapointModalProps) {
  const { open, currentDatasetRef, currentDatapointRef, onClose } = props
  const { t } = useTranslation('dataEngine')

  const { mutate: editDatapoint, error: editDatapointError, reset: editDatapointReset } = useEditDatapointMutation()

  const datapointDetailsResponse = useDatapointDetailsQuery(currentDatapointRef, { enabled: open })
  const datapoint = datapointDetailsResponse.data?.data

  const initialFormValues = useMemo<DatapointFormValues>(() => {
    return {
      datapointName: datapoint?.datapoint_name || '',
      variety: datapoint?.variety || '',
      candidateAliasId: datapoint?.candidate_alias_id?.toString() || '',
      dataType: datapoint?.data_type || '',
      assetCategory: datapoint?.asset_category || '',
      datadocType: datapoint?.datadoc_type || '',
      classificationId: datapoint?.classification_id?.toString() || '',
      parentClassId: datapoint?.parent_class_id?.toString() || '',
      calculationId: datapoint?.calc_id || '',
      aggregationType: datapoint?.aggregation?.agg_type || '',
      weightDatapointRef: datapoint?.aggregation?.weight_dpref || '',
      customFunctionId: datapoint?.aggregation?.custom_fn_id?.toString() || '',
      aggregationOnlyAll: datapoint?.aggregation?.onlyall || false,
      calculationInputs: datapoint?.calc_inputs || {},
    } satisfies DatapointFormValues
  }, [datapoint])

  const [formValues, setFormValues] = useState(initialFormValues)

  useEffect(() => {
    setFormValues(initialFormValues)
  }, [initialFormValues])

  function handleChange(name: string, value: string | boolean | DatapointFormValues['calculationInputs']) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    const editDatapointParams = {
      datapointRef: currentDatapointRef,
      body: parseDatapointFormToPayload(formValues),
    }

    editDatapoint(editDatapointParams, {
      onSuccess: () => {
        queryClient.invalidateQueries(['datapoint', currentDatapointRef])
        queryClient.invalidateQueries(['dataset', currentDatasetRef, 'datapoints'])
        handleClose()
      },
    })
  }

  function handleClose() {
    editDatapointReset()
    onClose()
    setFormValues(initialFormValues)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('datapoint_table.edit_datapoint')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={editDatapointError} />
          <DatapointForm
            currentDatasetRef={currentDatasetRef}
            values={formValues}
            onChange={handleChange}
            setFormValues={setFormValues}
          />
        </ModalContent>
        <ModalActions confirmLabel={t('common:save')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default EditDatapointModal
