import { useNavigate } from 'react-router-dom'
import PortfolioDetails from '../../components/portfolio-details'

function PortfolioDetailsFlexView() {
  const navigate = useNavigate()

  return <PortfolioDetails onBack={() => navigate('/settings/portfolios')} />
}

export default PortfolioDetailsFlexView
