import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useShareViewMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, MutationFunctionParams>({
    mutationFn: ({ viewRef, body }) => api.shareView(viewRef, body),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type MutationFunctionParams = {
  viewRef: string
  body: ShareViewBody
}

export type ShareViewBody = {
  groups: string[]
}

export default useShareViewMutation
