import { Menu, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useOpenState from '../../../utils/hooks/use-open-state'
import AddExistingDatapointModal from './add-existing-datapoint-modal'
import AddNewDatapointModal from './add-new-datapoint-modal'

type AddDatapointMenuProps = {
  currentDatasetRef: string
  anchorEl: HTMLElement | null
  onClose: () => void
}

function AddDatapointMenu(props: AddDatapointMenuProps) {
  const { currentDatasetRef, anchorEl, onClose } = props
  const { t } = useTranslation('dataEngine')

  const addExistingModal = useOpenState()
  const addNewModal = useOpenState()

  function handleAddExistionModalOpen() {
    addExistingModal.open()
    onClose()
  }

  function handleAddNewModalOpen() {
    addNewModal.open()
    onClose()
  }

  return (
    <>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleAddNewModalOpen}>
          <Typography variant="body2">{t('datapoint_table.add_new_datapoint')}</Typography>
        </MenuItem>
        <MenuItem onClick={handleAddExistionModalOpen}>
          <Typography variant="body2">{t('datapoint_table.add_existing_datapoint')}</Typography>
        </MenuItem>
      </Menu>
      <AddExistingDatapointModal
        open={addExistingModal.isOpen}
        currentDatasetRef={currentDatasetRef}
        onClose={addExistingModal.close}
      />
      <AddNewDatapointModal
        open={addNewModal.isOpen}
        currentDatasetRef={currentDatasetRef}
        onClose={addNewModal.close}
      />
    </>
  )
}

export default AddDatapointMenu
