import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useUploadModelMutation() {
  const response = useMutation<AxiosResponse, AxiosError, UploadFileMutationParams>({
    mutationFn: ({ modelRef, date, file }) => api.uploadModel(modelRef, date, file),
  })

  return { ...response, error: parseError(response.error) }
}

type UploadFileMutationParams = {
  modelRef: string
  date: string
  file: File
}

export default useUploadModelMutation
