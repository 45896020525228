import { useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import { ModelDetails } from '../../../services/data/types/models'

function useModelDetailsQuery(modelRef: string, version: string | null) {
  return useQuery<AxiosResponse<ModelDetails>, AxiosError>({
    queryKey: ['models', modelRef, 'model', version],
    queryFn: () => api.getModel(modelRef, version),
    keepPreviousData: true,
  })
}

export default useModelDetailsQuery
