import { Box, Stack } from '@mui/system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../../components/fields/numeric-field'
import { TradeDirectionField } from '../../../../components/fields/trade-direction-field'
import { PricingPayload } from '../../../../services/data/types/pricing'
import { Leg, TradeDirection, TradeTicketResponse } from '../../../../services/data/types/trade-ticket'
import { formatNaiveDate } from '../../../../utils/dates'
import { defaultScale, priceScale } from '../../../../utils/math'
import { CurrencyAdorment } from './shared/fields/adorments'
import BooleanSelector from './shared/fields/boolean-selector'
import CurrencySelector from './shared/fields/currency-selector'
import DateSelector from './shared/fields/date-selector'
import { ProfileOperator } from './shared/profile-operator'
import { ProfileRow } from './shared/profile-row'

type FxSpotProfileProps = {
  leg: Leg
  currencies: TradeTicketResponse['currencies']
  currencySymbol: string
  isTradeConfirmation: boolean
  onUpdateLeg: (leg: Leg) => void
  onUpdatePricing: (payload: PricingPayload) => void
  onTradeDirectionChange: (tradeDirection: TradeDirection) => void
}

export function FxSpotProfile(props: FxSpotProfileProps) {
  const { leg, currencies, currencySymbol, isTradeConfirmation, onUpdateLeg, onUpdatePricing, onTradeDirectionChange } =
    props

  const { t } = useTranslation('tradeTicket')

  useEffect(() => {
    priceConsideration()
  }, [leg.settlementDate?.getTime(), leg.quoteCurrency, leg.isGiveUp])

  function priceConsideration() {
    const payload = createPricingPayload()

    if (!payload || !leg.notional || !leg.spotRate) {
      return
    }

    payload.quantity = Number(leg.notional)
    payload.price = Number(leg.spotRate)

    onUpdatePricing(payload)
  }

  function priceNotional() {
    const payload = createPricingPayload()

    if (!payload || !leg.spotRate || !leg.value) {
      return
    }

    payload.price = Number(leg.spotRate)
    payload.consideration = Number(leg.value)

    onUpdatePricing(payload)
  }

  function createPricingPayload() {
    if (!leg.tradeAssetType || !leg.settlementDate) {
      return
    }

    const payload: PricingPayload = {
      asset_type: leg.tradeAssetType.asset_type_tag,
      quantity_type: 'notional',
      quantity: undefined,
      price: undefined,
      consideration: undefined,
      static_data: {},
      proposed_terms: {
        settlement_date: { NaiveDate: formatNaiveDate(leg.settlementDate) },
        is_give_up: { Boolean: leg.isGiveUp },
      },
    }

    return payload
  }

  return (
    <Stack gap={3}>
      <ProfileRow>
        <TradeDirectionField
          value={leg.tradeDirection}
          disabled={isTradeConfirmation}
          onChange={onTradeDirectionChange}
          sx={{ maxWidth: '150px', mr: 2 }}
        />

        <NumericField
          required
          fixedLabel
          name="notional"
          label={t('common:notional')}
          value={leg.notional}
          decimalScale={defaultScale}
          onValueChange={(notional) => onUpdateLeg({ ...leg, notional })}
          onBlur={priceConsideration}
          onEnterDown={priceConsideration}
          endAdornment={<CurrencyAdorment symbol={currencySymbol} />}
        />
        <ProfileOperator operator="x" />
        <NumericField
          required
          fixedLabel
          name="spot_rate"
          label={t('common:spot_rate')}
          value={leg.spotRate}
          decimalScale={priceScale}
          onValueChange={(spotRate) => onUpdateLeg({ ...leg, spotRate })}
          onBlur={priceConsideration}
          onEnterDown={priceConsideration}
        />
        <ProfileOperator />
        <CurrencySelector
          label={t('common:quote_currency')}
          currency={leg.quoteCurrency}
          currencies={currencies}
          onChange={(quoteCurrency) => onUpdateLeg({ ...leg, quoteCurrency })}
        />
        <ProfileOperator operator="=" />
        <NumericField
          required
          fixedLabel
          name="value"
          label={t('common:consideration')}
          value={leg.value}
          decimalScale={defaultScale}
          onValueChange={(value) => onUpdateLeg({ ...leg, value })}
          onBlur={priceNotional}
          onEnterDown={priceNotional}
          endAdornment={<CurrencyAdorment symbol={currencySymbol} />}
        />
      </ProfileRow>

      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <DateSelector
          required
          label={t('common:settlement_date')}
          value={leg.settlementDate}
          onChange={(settlementDate) => onUpdateLeg({ ...leg, settlementDate })}
          onBlur={priceConsideration}
          onEnterDown={priceConsideration}
        />
        <ProfileOperator />
        <BooleanSelector
          label={t('common:is_give_up')}
          value={leg.isGiveUp}
          onChange={(isGiveUp) => onUpdateLeg({ ...leg, isGiveUp })}
        />
        <ProfileOperator />
        <Box />
        <ProfileOperator />
        <Box />
      </ProfileRow>
    </Stack>
  )
}
