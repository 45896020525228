import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { GridDataViewCreateBody } from '../../../services/data/types/grid-data-view'

function useCreateGridDataViewMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, MutationFunctionParams>({
    mutationFn: ({ body }) => api.createGridDataView(body),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type MutationFunctionParams = {
  body: GridDataViewCreateBody
}

export default useCreateGridDataViewMutation
