import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useSecurityMasterNavigatorQuery() {
  return useQuery({
    queryKey: ['static', 'navigator'],
    queryFn: api.getSecurityMasterNavigator,
    cacheTime: 0,
  })
}

export default useSecurityMasterNavigatorQuery
