import { Add, AdminPanelSettings } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  Link,
  List,
  ListItem,
  Menu,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { MouseEvent, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import TableContainerHeader from '../../../components/table-container-header'
import useOpenState from '../../../utils/hooks/use-open-state'
import useGroupsWithMembers from '../data/use-groups-with-members'
import CreateGroupModal from './create-group-modal'
import { useSettingsUpdateLoadingState } from './use-settings-set-loading'

type GroupsProps = {
  onGroupClick: (groupRef: string) => void
}

function Groups({ onGroupClick }: GroupsProps) {
  const { t } = useTranslation('settings')

  const createGroupModal = useOpenState()

  const groupsResponse = useGroupsWithMembers()
  const groups = groupsResponse.data?.data.groups || []
  const groupTypes = groupsResponse.data?.data.group_types || {}

  const isLoading = groupsResponse.isLoading

  useSettingsUpdateLoadingState(isLoading)

  if (isLoading) {
    return null
  }

  return (
    <>
      <Box p={2}>
        <TableContainer component={Paper}>
          <TableContainerHeader
            title={t('group_list_table.title')}
            action={
              <Tooltip title={t('group_list_table.add_group')} arrow disableInteractive>
                <IconButton onClick={createGroupModal.open}>
                  <Add />
                </IconButton>
              </Tooltip>
            }
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('group_list_table.group')}</TableCell>
                <TableCell>{t('group_list_table.group_type')}</TableCell>
                <TableCell>{t('group_list_table.members')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups?.map((group) => {
                return (
                  <TableRow
                    hover
                    onClick={() => onGroupClick(group.group_ref)}
                    key={group.group_ref}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>{group.group_name}</TableCell>
                    <TableCell sx={{ width: '30%' }}>{groupTypes[group.group_type] || group.group_type}</TableCell>
                    <TableCell sx={{ width: '45%' }}>
                      <GroupMembers groupMembers={group.members} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CreateGroupModal open={createGroupModal.isOpen} onClose={createGroupModal.close} />
    </>
  )
}

export default Groups

type GroupMembersProps = {
  groupMembers: { firstname: string; is_group_admin: boolean; lastname: string; user_ref: string }[]
}

function GroupMembers({ groupMembers }: GroupMembersProps) {
  const { t } = useTranslation('settings')
  const anchorElement = useRef<HTMLAnchorElement>(null)
  const remainingMembersMenu = useOpenState()
  const totalMembers = groupMembers.length

  function handleUserClickOnLinkMenu(event: MouseEvent) {
    event.stopPropagation()
    remainingMembersMenu.open()
  }

  return (
    <Stack direction="row" spacing={1}>
      {!totalMembers ? (
        <Typography color="gray.700">-</Typography>
      ) : (
        groupMembers.map((member, index) => {
          return index < 2 && <MemberChip key={member.user_ref} member={member} />
        })
      )}
      {totalMembers > 2 && (
        <Link ref={anchorElement} onClick={handleUserClickOnLinkMenu} sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
          {t('more', { count: totalMembers - 2 })}
        </Link>
      )}
      <Menu
        open={remainingMembersMenu.isOpen}
        anchorEl={anchorElement.current}
        onClose={remainingMembersMenu.close}
        onClick={(event) => event.stopPropagation()}
        sx={{ '& .MuiPaper-root': { minWidth: 'unset' } }}
      >
        <List sx={{ p: 0 }}>
          {totalMembers > 2 &&
            groupMembers.map(
              (member, index) =>
                index >= 2 && (
                  <ListItem key={member.user_ref}>
                    <MemberChip member={member} />
                  </ListItem>
                )
            )}
        </List>
      </Menu>
    </Stack>
  )
}

type MemberChipProps = {
  member: { firstname: string; is_group_admin: boolean; lastname: string; user_ref: string }
}

function MemberChip({ member }: MemberChipProps) {
  const { t } = useTranslation('settings')

  const firstName = member.firstname[0] || ''
  const lastname = member.lastname[0] || ''
  const initials = firstName.toUpperCase() + lastname.toUpperCase()

  return (
    <Chip
      avatar={<Avatar>{initials}</Avatar>}
      label={
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography fontSize="inherit">{`${member.firstname} ${member.lastname}`}</Typography>
          {member.is_group_admin && (
            <Tooltip title={t('group_list_table.admin_icon_tooltip')} arrow disableInteractive>
              <AdminPanelSettings sx={{ width: '18px', height: '18px' }} color="primary" />
            </Tooltip>
          )}
        </Stack>
      }
      sx={{
        color: 'inherit',
        cursor: 'inherit',
        '& .MuiChip-avatar': {
          color: 'gray.light',
          backgroundColor: 'gray.400',
        },
      }}
    />
  )
}
