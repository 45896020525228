import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { GridDataViewColumn, GridDataViewFilters } from '../../../services/data/types/grid-data-view'
import { UamPanelMode } from '../../../services/data/types/grid-panels'

function usePortfolioQuery(
  portfolioRef: string | null | undefined,
  datasetRef: string | null,
  whatifRef: string | null,
  aggregations: string[],
  panelMode: UamPanelMode | null,
  filters: GridDataViewFilters | null,
  panelColumns: string[] | null,
  columns: GridDataViewColumn[] | null,
  asOfDate: string | null,
  options: { enabled: boolean }
) {
  const response = useQuery({
    queryKey: [
      'portfolio',
      portfolioRef,
      whatifRef,
      aggregations,
      datasetRef,
      panelMode,
      filters,
      panelColumns,
      asOfDate,
    ],
    queryFn: () => {
      if (!portfolioRef) {
        return null
      }

      // visible columns should not trigger a refetch
      const visibleColumns = columns?.filter((column) => column.position === 'm').map((column) => column.datapoint_ref)

      return api
        .getPortfolio(
          portfolioRef,
          datasetRef,
          {
            aggregation: aggregations,
            whatifset: whatifRef,
            panel_mode: panelMode,
            filters,
            panel_columns: panelColumns,
            visible_columns: visibleColumns,
          },
          asOfDate
        )
        .then((response) => {
          return {
            ...response,
            data: response.data,
          }
        })
    },
    enabled: !!portfolioRef && !!datasetRef && options.enabled,
    cacheTime: 0,
    keepPreviousData: true,
  })

  return { ...response, error: parseError(response.error as AxiosError) }
}

export default usePortfolioQuery
