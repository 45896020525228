import { createContext, useContext } from 'react'

type FlexLayoutContextType = {
  openView: (path: string, name: string, splitTab?: boolean) => void
  closeActiveTab: () => void
  updateUrlPath: (tabId: string, path: string) => void
  updateTabName: (tabId: string, name: string) => void
  getActiveTabId: () => string | undefined
}

export const FlexLayoutContext = createContext<FlexLayoutContextType>(null!)

export function useFlexLayoutContext() {
  return useContext(FlexLayoutContext)
}
