import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useAddUserToGroupMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, AddUserToGroupParams>({
    mutationFn: ({ groupRef, userRef, isGroupAdmin }) => api.addUserToGroup(groupRef, userRef, isGroupAdmin),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type AddUserToGroupParams = {
  groupRef: string
  userRef: string
  isGroupAdmin: boolean
}

export default useAddUserToGroupMutation
