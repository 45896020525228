import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import { EditUserBody } from '../../../services/data/types/user'

function useEditUserMutation() {
  return useMutation<AxiosResponse, EditUserError, MutationFunctionProps>({
    mutationFn: ({ userRef, body }) => api.editUser(userRef, body),
  })
}

type MutationFunctionProps = {
  userRef: string
  body: EditUserBody
}

export type EditUserError = AxiosError<
  | {
      error: {
        code: EditUserErrorCode
      }
    }
  | undefined
>

export enum EditUserErrorCode {
  USERNAME_TAKEN = '0x0001000C',
}

export default useEditUserMutation
