import { DatapointValueType } from '../../../services/data/types/datapoint'
import { PricingPayload } from '../../../services/data/types/pricing'
import { Leg, TradeDirection, TradeTicketResponse } from '../../../services/data/types/trade-ticket'
import { CashflowProfile } from './profiles/cashflow-profile'
import { CloseBondBySpreadProfile } from './profiles/close-bond-by-spread-profile'
import { CloseFxForwardProfile } from './profiles/close-fx-forward-profile'
import { CloseNdfProfile } from './profiles/close-ndf-profile'
import { CloseRepoProfile } from './profiles/close-repo-profile'
import { CloseTrsProfile } from './profiles/close-trs-profile'
import { FxForwardProfile } from './profiles/fx-forward-profile'
import { FxSpotProfile } from './profiles/fx-spot-profile'
import { IndexFutureProfile } from './profiles/index-future-profile'
import { NdfProfile } from './profiles/ndf-profile'
import { OpenBondBySpreadProfile } from './profiles/open-bond-by-spread-profile'
import { StandardBondProfile } from './profiles/standard-bond-profile'
import { StandardProfile } from './profiles/standard-profile'
import { StandardRepoProfile } from './profiles/standard-repo-profile'
import { TrsProfile } from './profiles/trs-profile'

type SecurityLegProfilesProps = {
  leg: Leg
  currencies: TradeTicketResponse['currencies']
  cashflowTypes: TradeTicketResponse['cashflow_types']
  currencySymbol: string
  tradeTicket: TradeTicketResponse | null
  contractSize: DatapointValueType
  parValue: DatapointValueType
  isTradeConfirmation: boolean
  isCloseOutPosition: boolean
  isCloseOutTransaction: boolean
  isStandardProfile: boolean
  isStandardBondProfile: boolean
  isOpenBondBySpreadProfile: boolean
  isCloseBondBySpreadProfile: boolean
  isIndexFutureProfile: boolean
  isStandardRepoProfile: boolean
  isFxSpotProfile: boolean
  isFxForwardProfile: boolean
  isNdfProfile: boolean
  isTrsProfile: boolean
  isCashflowProfile: boolean
  isCloseRepoProfile: boolean
  isCloseFxForwardProfile: boolean
  isCloseNdfProfile: boolean
  isCloseTrsProfile: boolean
  onUpdateLeg: (leg: Leg) => void
  onUpdatePricing: (payload: PricingPayload) => void
  onVerifyCloseOut: () => void
  onTradeDirectionChange: (tradeDirection: TradeDirection) => void
}

export function SecurityLegProfiles(props: SecurityLegProfilesProps) {
  const {
    leg,
    currencies,
    cashflowTypes,
    currencySymbol,
    tradeTicket,
    contractSize,
    parValue,
    isTradeConfirmation,
    isCloseOutPosition,
    isCloseOutTransaction,
    isStandardProfile,
    isStandardBondProfile,
    isOpenBondBySpreadProfile,
    isCloseBondBySpreadProfile,
    isIndexFutureProfile,
    isStandardRepoProfile,
    isFxSpotProfile,
    isFxForwardProfile,
    isNdfProfile,
    isTrsProfile,
    isCashflowProfile,
    isCloseRepoProfile,
    isCloseFxForwardProfile,
    isCloseNdfProfile,
    isCloseTrsProfile,
    onUpdateLeg,
    onUpdatePricing,
    onVerifyCloseOut,
    onTradeDirectionChange,
  } = props

  return (
    <>
      {isStandardProfile && (
        <StandardProfile
          leg={leg}
          currencySymbol={currencySymbol}
          isTradeConfirmation={isTradeConfirmation}
          isCloseOutPosition={isCloseOutPosition}
          onUpdateLeg={onUpdateLeg}
          onUpdatePricing={onUpdatePricing}
          onTradeDirectionChange={onTradeDirectionChange}
        />
      )}

      {isStandardBondProfile && (
        <StandardBondProfile
          leg={leg}
          currencySymbol={currencySymbol}
          parValue={parValue}
          isTradeConfirmation={isTradeConfirmation}
          onUpdateLeg={onUpdateLeg}
          onUpdatePricing={onUpdatePricing}
          onTradeDirectionChange={onTradeDirectionChange}
        />
      )}

      {isOpenBondBySpreadProfile && (
        <OpenBondBySpreadProfile
          leg={leg}
          currencySymbol={currencySymbol}
          parValue={parValue}
          isTradeConfirmation={isTradeConfirmation}
          onUpdateLeg={onUpdateLeg}
          onUpdatePricing={onUpdatePricing}
          onTradeDirectionChange={onTradeDirectionChange}
        />
      )}

      {isCloseBondBySpreadProfile && (
        <CloseBondBySpreadProfile
          leg={leg}
          parValue={parValue}
          isTradeConfirmation={isTradeConfirmation}
          onUpdateLeg={onUpdateLeg}
          onUpdatePricing={onUpdatePricing}
          onTradeDirectionChange={onTradeDirectionChange}
        />
      )}

      {isIndexFutureProfile && (
        <IndexFutureProfile
          leg={leg}
          currencySymbol={currencySymbol}
          contractSize={contractSize}
          isTradeConfirmation={isTradeConfirmation}
          onUpdateLeg={onUpdateLeg}
          onUpdatePricing={onUpdatePricing}
          onTradeDirectionChange={onTradeDirectionChange}
        />
      )}

      {isStandardRepoProfile && (
        <StandardRepoProfile
          leg={leg}
          currencySymbol={currencySymbol}
          isTradeConfirmation={isTradeConfirmation}
          onUpdateLeg={onUpdateLeg}
          onUpdatePricing={onUpdatePricing}
          onTradeDirectionChange={onTradeDirectionChange}
        />
      )}

      {isFxSpotProfile && (
        <FxSpotProfile
          leg={leg}
          currencies={currencies}
          currencySymbol={currencySymbol}
          isTradeConfirmation={isTradeConfirmation}
          onUpdateLeg={onUpdateLeg}
          onUpdatePricing={onUpdatePricing}
          onTradeDirectionChange={onTradeDirectionChange}
        />
      )}

      {isFxForwardProfile && (
        <FxForwardProfile
          leg={leg}
          currencySymbol={currencySymbol}
          isTradeConfirmation={isTradeConfirmation}
          onUpdateLeg={onUpdateLeg}
          onUpdatePricing={onUpdatePricing}
          onTradeDirectionChange={onTradeDirectionChange}
        />
      )}

      {isNdfProfile && (
        <NdfProfile
          leg={leg}
          currencySymbol={currencySymbol}
          isTradeConfirmation={isTradeConfirmation}
          onUpdateLeg={onUpdateLeg}
          onUpdatePricing={onUpdatePricing}
          onTradeDirectionChange={onTradeDirectionChange}
        />
      )}

      {isTrsProfile && (
        <TrsProfile
          leg={leg}
          currencySymbol={currencySymbol}
          isTradeConfirmation={isTradeConfirmation}
          onUpdateLeg={onUpdateLeg}
          onUpdatePricing={onUpdatePricing}
          onTradeDirectionChange={onTradeDirectionChange}
        />
      )}

      {isCashflowProfile && (
        <CashflowProfile
          leg={leg}
          currencySymbol={currencySymbol}
          cashflowTypes={cashflowTypes}
          tradeTicket={tradeTicket}
          onUpdateLeg={onUpdateLeg}
          onTradeDirectionChange={onTradeDirectionChange}
        />
      )}

      {isCloseRepoProfile && (
        <CloseRepoProfile
          leg={leg}
          currencySymbol={currencySymbol}
          onUpdateLeg={onUpdateLeg}
          onVerifyCloseOut={onVerifyCloseOut}
        />
      )}

      {isCloseFxForwardProfile && <CloseFxForwardProfile leg={leg} currencySymbol={currencySymbol} />}

      {isCloseNdfProfile && (
        <CloseNdfProfile
          leg={leg}
          currencySymbol={currencySymbol}
          onUpdateLeg={onUpdateLeg}
          onVerifyCloseOut={onVerifyCloseOut}
        />
      )}

      {isCloseTrsProfile && (
        <CloseTrsProfile
          leg={leg}
          currencySymbol={currencySymbol}
          onUpdateLeg={onUpdateLeg}
          onVerifyCloseOut={onVerifyCloseOut}
        />
      )}
    </>
  )
}
