import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

type SetLoadingType = {
  setIsLoading: (isLoading: boolean) => void
}

export function useSettingsSetLoading() {
  return useOutletContext<SetLoadingType>()
}

export function useSettingsUpdateLoadingState(isLoading: boolean) {
  const outletContext = useSettingsSetLoading()

  useEffect(() => {
    outletContext.setIsLoading(isLoading)
  }, [isLoading])
}
