/** Default scale used for Float and Percent data types */
export const defaultScale = 6

/** Scale used for Pricing inputs */
export const priceScale = 9

export function roundDecimals(n: number, scale: number = defaultScale) {
  return Number(n.toFixed(scale))
}

export function isSafeNumber(number: number) {
  const isBiggerOrEqualThanMinSafeInt = number >= Number.MIN_SAFE_INTEGER
  const isSmallerOrEqualThanMaxSafeInt = number <= Number.MAX_SAFE_INTEGER

  return isBiggerOrEqualThanMinSafeInt && isSmallerOrEqualThanMaxSafeInt
}
