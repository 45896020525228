import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AlertIcon, { AlertSeverity } from '../../../components/alert-icon'
import { ComplianceStatus, PortfolioComplianceResponse, RuleStatus } from '../../../services/data/types/compliance'

type ComplianceIndicatorProps = {
  compliance: PortfolioComplianceResponse | null
  isLoading: boolean
  onClick: () => void
}

export function ComplianceIndicator(props: ComplianceIndicatorProps) {
  const { compliance, isLoading, onClick } = props

  const { t } = useTranslation('compliance')

  if (isLoading || !compliance || compliance.status === 'not_configured') {
    return null
  }

  return (
    <IconButton onClick={onClick} sx={{ padding: '4px', '&:hover': { background: 'none' } }}>
      <AlertIcon
        useInfoIcon
        iconSize="regular"
        severity={getRuleStatusSeverity(compliance.status)}
        message={t(getMessageKey(compliance.status))}
      />
    </IconButton>
  )
}

export function getRuleStatusSeverity(ruleStatus: RuleStatus): AlertSeverity {
  const severities: Record<RuleStatus, AlertSeverity> = {
    pass: 'success',
    note_required: 'warning',
    warning: 'warning',
    breach: 'error',
    hard_stop: 'error',
    data_error: 'error',
  }
  const severity = severities[ruleStatus]

  if (!severity) {
    console.error(`invalid RuleStatus ${ruleStatus}`)
    return 'info'
  }
  return severity
}

function getMessageKey(status: ComplianceStatus): string {
  const keys: Record<ComplianceStatus, string> = {
    not_configured: 'messages.not_configured',
    pass: 'messages.pass',
    note_required: 'messages.note_required',
    warning: 'messages.warning',
    breach: 'messages.breach',
    hard_stop: 'messages.hard_stop',
  }
  const key = keys[status]

  if (!key) {
    console.error(`invalid ComplianceStatus: ${status}`)
    return status
  }
  return key
}
