import { Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { ChangeEvent, FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import { Classification } from '../../../services/data/types/classifications'
import { AddModelPayload } from '../../../services/data/types/models'
import useAddModelMutation from '../data/use-add-model-mutation'

type AddModelModalProps = {
  datasetRef: string
  datasetName: string
  isOpen: boolean
  onClose: () => void
  classifications: Classification[]
}

type ModelFormValues = {
  name: string
  basis: string
}

const initialFormValues: ModelFormValues = {
  name: '',
  basis: '',
}

function AddModelModal(props: AddModelModalProps) {
  const { datasetRef, datasetName, isOpen, onClose, classifications } = props

  const { t } = useTranslation('dataEngine')

  const classificationsOptions = classifications.map((classification) => ({
    name: `${classification.classification_name} (${datasetName})`,
    value: classification.classification_id,
  }))

  const basisOptions = [{ name: 'Security', value: 'null' }, ...classificationsOptions]

  const addModel = useAddModelMutation()

  const [formValues, setFormValues] = useState(initialFormValues)

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) {
    const { name, value } = event.target
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    const payload: AddModelPayload = {
      model_name: formValues.name,
      classification_id: formValues.basis === 'null' ? null : formValues.basis,
    }

    addModel.mutate(
      { datasetRef, payload },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['dataset', datasetRef, 'models'])
          handleClose()
        },
      }
    )
  }

  function handleClose() {
    setFormValues(initialFormValues)
    addModel.reset()
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('add_model_modal.title')} onClose={handleClose} />
        <Alert severity="error" message={addModel.error} />
        <ModalContent>
          <TextField
            required
            name="name"
            label={t('add_model_modal.input_name_label')}
            value={formValues.name}
            onChange={handleChange}
          />

          <FormControl required>
            <InputLabel>{t('add_model_modal.select_label')}</InputLabel>
            <Select
              displayEmpty
              name="basis"
              label={t('add_model_modal.select_label')}
              value={formValues.basis}
              onChange={handleChange}
            >
              {basisOptions.map((dropdownValue) => {
                return (
                  <MenuItem key={dropdownValue.value} value={dropdownValue.value}>
                    {dropdownValue.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('common:create')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default AddModelModal
