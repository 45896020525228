import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import config from '../../../../config'
import TransactionsGrid from '../../components/transactions-grid'
import usePortfoliosQuery from '../../data/use-portfolios-query'

function TransactionsGridPage() {
  const navigate = useNavigate()
  const { portfolioRef } = useParams()

  const portfoliosQuery = usePortfoliosQuery()
  const portfolios = portfoliosQuery.data?.data

  useEffect(() => {
    if (!portfolioRef && portfoliosQuery.isSuccess) {
      const portfolio = portfolios?.[0]
      if (portfolio) {
        navigate(`/transaltions/${portfolio.portfolio_ref}`)
      }
    }
  }, [portfolioRef, portfoliosQuery])

  function handlePortfolioSelect(portfolioRef: string) {
    navigate(`/transaltions/${portfolioRef}`)
  }

  function handleCorrectTransaction(transactionRef: string) {
    if (portfolioRef) {
      navigate(`${config.baseUrl}trade-ticket/${portfolioRef}/transactions/${transactionRef}/correct`)
    }
  }

  function handleCloseOutTransaction(segmentRef: string) {
    if (portfolioRef) {
      navigate(`${config.baseUrl}trade-ticket/${portfolioRef}/transactions/segments/${segmentRef}/close`)
    }
  }

  function handleConfirmTrade(dealRef: string) {
    if (portfolioRef) {
      navigate(`${config.baseUrl}trade-ticket/${portfolioRef}/transactions/deals/${dealRef}/confirm`)
    }
  }

  function handleNewTrade() {
    navigate(`/trade-ticket/${portfolioRef}`)
  }

  function handleNewCashflow() {
    navigate(`/trade-ticket/${portfolioRef}?cashflow`)
  }

  return (
    <TransactionsGrid
      portfolioRef={portfolioRef}
      onPortfolioSelect={handlePortfolioSelect}
      onCorrectTransaction={handleCorrectTransaction}
      onCloseOutTransaction={handleCloseOutTransaction}
      onConfirmTrade={handleConfirmTrade}
      onNewTrade={handleNewTrade}
      onNewCashflow={handleNewCashflow}
    />
  )
}

export default TransactionsGridPage
