import { Box, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

function Footer() {
  const { t } = useTranslation('common')

  return (
    <Stack
      direction="row"
      component="footer"
      justifyContent="center"
      alignItems="center"
      sx={{
        flexWrap: 'wrap',
        color: 'white',
        p: 1.5,
        fontSize: '14px',
      }}
    >
      <Typography sx={{ fontSize: '14px' }}>{t('copyright_text')}</Typography>
      <Box component="span" mx={2}>
        |
      </Box>
      <Link href={`tel:${t('contact.phone_number')}`} sx={{ color: 'white', textDecoration: 'none' }}>
        {t('contact.phone_number')}
      </Link>
      <Box component="span" mx={2}>
        |
      </Box>
      <Link href={`mailto:${t('contact.email_address')}`} sx={{ color: 'white', textDecoration: 'none' }}>
        {t('contact.email_address')}
      </Link>
    </Stack>
  )
}

export default Footer
