import { Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import NoMoreOptionsAlert from '../../../components/no-more-options-alert'
import { queryClient } from '../../../services/data/react-query'
import useAddUserToGroupMutation from '../data/use-add-user-to-group-mutation'
import useGroupsWithMembers from '../data/use-groups-with-members'
import { GroupRoles } from './user-details'

type AddGroupModalProps = {
  open: boolean
  userRef: string
  roles: { role_name: string; role_ref: string }[]
  onClose: () => void
}

function AddGroupModal(props: AddGroupModalProps) {
  const { open, userRef, roles, onClose } = props
  const { t } = useTranslation('settings')

  const { mutate: addGroup, error: addUserToGroupError, reset: resetAddUserToGroup } = useAddUserToGroupMutation()

  const [selectedGroupRef, setSelectedGroupRef] = useState('')
  const [selectedRoleRef, setSelectedRoleRef] = useState('')

  const isGroupAdmin = selectedRoleRef === GroupRoles.admin

  const groupsWithMembersResponse = useGroupsWithMembers()
  const allGroupsWithMembers = groupsWithMembersResponse.data?.data.groups || []
  const selectedUsersGroups = allGroupsWithMembers.filter((groupWithMembers) => {
    const allMembersRef = groupWithMembers.members.map((member) => member.user_ref)
    return !allMembersRef.includes(userRef)
  })
  const availableGroups = selectedUsersGroups.filter((group) => group.group_type !== 'personal')
  const hasOptions = availableGroups.length > 0

  useEffect(() => {
    if (open) {
      groupsWithMembersResponse.refetch()
    }
  }, [open])

  function handleGroupChange(event: SelectChangeEvent) {
    setSelectedGroupRef(event.target.value)
  }

  function handleRoleChange(event: SelectChangeEvent) {
    setSelectedRoleRef(event.target.value)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    const addGroupParams = {
      groupRef: selectedGroupRef,
      userRef,
      isGroupAdmin: isGroupAdmin,
    }

    addGroup(addGroupParams, {
      onSuccess: () => {
        queryClient.invalidateQueries(['tenant', 'current', 'groupswithmembers'])
        handleClose()
      },
    })
  }

  function handleClose() {
    resetAddUserToGroup()
    setSelectedGroupRef('')
    setSelectedRoleRef('')
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('groups.add_user')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={addUserToGroupError} />
          {!hasOptions && <NoMoreOptionsAlert />}
          <FormControl disabled={!hasOptions}>
            <InputLabel required>{t('groups.group')}</InputLabel>
            <Select
              required
              displayEmpty
              label={t('groups.group')}
              value={selectedGroupRef}
              onChange={handleGroupChange}
            >
              {availableGroups.map((group) => (
                <MenuItem key={group.group_ref} value={group.group_ref}>
                  {group.group_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl disabled={!hasOptions}>
            <InputLabel required>{t('groups.role')}</InputLabel>
            <Select required displayEmpty label={t('groups.role')} value={selectedRoleRef} onChange={handleRoleChange}>
              {roles.map((role) => (
                <MenuItem key={role.role_name} value={role.role_ref}>
                  {role.role_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('common:add')} confirmDisabled={!hasOptions} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default AddGroupModal
