import { useState } from 'react'
import AppLayout from '../../../../components/layouts/app-layout'
import Account from '../../components/account'
import AccountHeader from '../../components/account-header'

function AccountPage() {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  function handleSwitchTenantSuccess() {
    window.location.reload()
  }

  return (
    <AppLayout>
      <AccountHeader isLoading={isLoading} onSwitchTenantSuccess={handleSwitchTenantSuccess} />
      <Account onLoadingChange={setIsLoading} onSwitchTenantSuccess={handleSwitchTenantSuccess} />
    </AppLayout>
  )
}

export default AccountPage
