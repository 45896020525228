import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useSecurityMonikerQuery(identifierType: string, identifier: string) {
  return useQuery({
    queryKey: ['symbology', 'lookup', identifierType, identifier],
    queryFn: () => api.getSecurityMoniker(identifierType, identifier),
    cacheTime: 0,
    enabled: !!identifierType && !!identifier,
  })
}

export default useSecurityMonikerQuery
