import { Dialog } from '@mui/material'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import { ClassificationPayload } from '../../../services/data/types/classifications'
import { Datapoint } from '../../../services/data/types/datapoint'
import useCreateClassificationMutation from '../data/use-create-classification-mutation'
import ClassificationForm, { ClassificationFormValues } from './classification-form'

type AddClassificationModalProps = {
  open: boolean
  datasetRef: string
  datapoints: Datapoint[]
  onClose: () => void
}

const initialFormValues: ClassificationFormValues = {
  name: '',
  type: 'fixed',
  bucketRef: '',
}

function AddClassificationModal(props: AddClassificationModalProps) {
  const { open, datasetRef, datapoints, onClose } = props
  const { t } = useTranslation('dataEngine')

  const {
    error: createClassificationError,
    mutate: createClassification,
    reset: createClassificationReset,
  } = useCreateClassificationMutation()

  const [formValues, setFormValues] = useState(initialFormValues)

  function handleChange(name: string, value: string) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    const bucketRef = formValues.bucketRef === '' ? null : formValues.bucketRef

    const payload: ClassificationPayload = {
      name: formValues.name,
      classification_type: formValues.type,
      bucket_datapoint_ref: formValues.type === 'auto' ? bucketRef : null,
    }

    createClassification(
      { datasetRef, payload },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['dataset', datasetRef, 'classifications'])
          handleClose()
        },
      }
    )
  }

  function handleClose() {
    onClose()
    createClassificationReset()
    setFormValues(initialFormValues)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('classifications_table.new')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={createClassificationError} />
          <ClassificationForm datapoints={datapoints} values={formValues} onChange={handleChange} />
        </ModalContent>
        <ModalActions confirmLabel={t('common:create')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default AddClassificationModal
