import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { ClassificationValuePayload } from '../../../services/data/types/classifications'

function useCreateClassificationValueMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, MutationFnParams>({
    mutationFn: ({ classificationId, payload }) => api.createClassificationValue(classificationId, payload),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type MutationFnParams = {
  classificationId: string | number
  payload: ClassificationValuePayload
}

export default useCreateClassificationValueMutation
