import { ArrowDropDown } from '@mui/icons-material'
import { Box, Button, Stack, SvgIconTypeMap, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { forwardRef } from 'react'

type FilterButtonProps = {
  label: string
  count?: number
  isActive?: boolean
  showArrow?: boolean
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const FilterButton = forwardRef((props: FilterButtonProps, ref) => {
  const { label, count, isActive, showArrow, Icon, onClick } = props

  return (
    <Box ref={ref}>
      <Button
        variant="outlined"
        sx={{
          height: '32px',
          backgroundColor: 'gray.main',
          borderColor: isActive ? 'primary.main' : 'gray.main',
          '&:hover': { backgroundColor: 'gray.main' },
        }}
        onClick={onClick}
      >
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Icon fontSize="small" sx={{ color: 'gray.300' }} />
          <Typography fontSize="12px" textTransform="none" whiteSpace="nowrap">
            {label}
          </Typography>
          {count !== undefined && (
            <Typography fontSize="12px" sx={{ color: 'primary.main' }}>
              {count}
            </Typography>
          )}
          {showArrow && <ArrowDropDown fontSize="small" sx={{ color: 'gray.300' }} />}
        </Stack>
      </Button>
    </Box>
  )
})

export default FilterButton
