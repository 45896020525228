import { Box, Checkbox, Stack, Typography } from '@mui/material'
import { ColDef, GetRowIdParams, ICellRendererParams, IHeaderParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { MouseEvent, RefObject, useEffect, useMemo, useRef } from 'react'
import DatapointCell from '../../../components/data-table/datapoint-cell'
import { formatValueToCopy } from '../../../services/data/datapoint-formatting'
import { ReconGrid, ReconGridCell, ReconRecord, ReconTxn } from '../../../services/data/types/reconciliation'

type BankAccountsGridProps = {
  grid: ReconGrid | null
  hide: boolean
  onContextMenuOpen: (event: MouseEvent, data: AgRowData) => void
  onForwardGridRef: (ref: RefObject<AgGridReact>) => void
  onSelectRowChange: (record: ReconRecord | null, txn: ReconTxn | null, checked: boolean) => void
}

export type AgRowData = {
  rowKey: string
  record: ReconRecord | null
  txn: ReconTxn | null
  selected: boolean
  showSelect: boolean
  mergeRow: boolean
  rowSpan: number
  cells: {
    [headingKey: string]: AgCell
  }
}

type AgCell = ReconGridCell

function BankAccountsGrid(props: BankAccountsGridProps) {
  const { grid, hide, onContextMenuOpen, onForwardGridRef, onSelectRowChange } = props

  const gridRef = useRef<AgGridReact>(null)

  useEffect(() => onForwardGridRef(gridRef), [grid])

  const [cols, rows] = useMemo(() => {
    const cols =
      grid?.headings.map((heading) => {
        const col: ColDef = {
          colId: heading.key,
          field: `cells.${heading.key}`,
          headerName: heading.title,
          hide: heading.hide,
          pinned: heading.pinned,
          width: heading.width,
          initialWidth: heading.initialWidth,
        }
        return col
      }) || []

    const rows =
      grid?.rows.map((row) => {
        return row.cells.reduce<AgRowData>(
          (acc, cell) => {
            acc.cells[cell.headingKey] = {
              headingKey: cell.headingKey,
              value: cell.value,
              color: cell.color,
              alert: cell.alert,
            }
            return acc
          },
          {
            rowKey: row.rowKey,
            record: row.record,
            txn: row.txn,
            selected: row.selected,
            showSelect: row.showSelect,
            mergeRow: row.mergeRow,
            rowSpan: row.rowSpan,
            cells: {},
          }
        )
      }) || []

    return [cols, rows] as const
  }, [grid])

  if (!grid) {
    return null
  }

  return (
    <>
      <div style={{ flex: hide ? 0 : 1 }}>
        <AgGridReact
          ref={gridRef}
          className="ag-theme-alpine-dark"
          columnDefs={cols}
          rowData={rows}
          rowHeight={32}
          rowSelection="single"
          animateRows
          enableRangeSelection
          suppressColumnVirtualisation
          suppressRowHoverHighlight
          processCellForClipboard={(params) => {
            return formatValueToCopy(params.value.value)
          }}
          getContextMenuItems={() => {
            // disable context menu by giving it no items,
            // as setting suppressContextMenu prevents row selection on right click
            return []
          }}
          onCellContextMenu={(event) => {
            // select row on right click
            event.node.setSelected(true)
          }}
          getRowId={(params: GetRowIdParams<AgRowData>) => {
            return params.data.rowKey
          }}
          components={{
            agColumnHeader: AgColumnHeader,
          }}
          defaultColDef={{
            resizable: true,
            suppressMovable: true,
            suppressMenu: true,
            valueParser: () => {
              // used when editing cell, but we don't need it as we have our own component
              // but there was a warning on the console when not provided
              // https://www.ag-grid.com/react-data-grid/value-parsers/#value-parser
            },
            cellRenderer: (props: ICellRendererParams<AgRowData, AgCell>) => {
              const row = props.data
              const cell = props.value

              // shouldn't happen
              if (!row) {
                return null
              }

              if (cell?.headingKey === 'action') {
                if (!row.showSelect || row.mergeRow) {
                  return null
                }
                return (
                  <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                    <Checkbox
                      size="small"
                      disableRipple
                      checked={row.selected}
                      onChange={(_event, checked) => {
                        onSelectRowChange(row.record, row.txn, checked)
                      }}
                      sx={{ p: 0, m: 0 }}
                    />
                  </Stack>
                )
              }

              return (
                <Box
                  sx={{ height: '100%', color: cell?.color }}
                  onContextMenu={(event) => {
                    if (row) {
                      onContextMenuOpen(event, row)
                    }
                  }}
                >
                  {!!cell && <DatapointCell datapoint={cell.value} alert={cell.alert} />}
                </Box>
              )
            },
          }}
        />
      </div>
    </>
  )
}

export default BankAccountsGrid

function AgColumnHeader(params: IHeaderParams) {
  const colDef = params.column.getUserProvidedColDef()

  return (
    <Typography fontSize="14px" fontWeight="500">
      {colDef?.headerName || ''}
    </Typography>
  )
}
