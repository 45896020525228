import { Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import NoMoreOptionsAlert from '../../../components/no-more-options-alert'
import { queryClient } from '../../../services/data/react-query'
import useAddPortfolioMutation from '../data/use-add-portfolio-mutation'
import useAvailablePortfoliosQuery from '../data/use-available-portfolios-query'

type AddPortfolioModalProps = {
  open: boolean
  groupRef: string
  onClose: () => void
}

function AddPortfolioModal(props: AddPortfolioModalProps) {
  const { open, groupRef, onClose } = props
  const { t } = useTranslation('settings')

  const { mutate: addPortfolio, error: addPortfolioModalError, reset: resetAddPortfolio } = useAddPortfolioMutation()

  const availablePortfoliosResponse = useAvailablePortfoliosQuery(groupRef)
  const availablePortfolios = availablePortfoliosResponse.data?.data || []

  const [selectedPortfolioRef, setSelectedPortfolioRef] = useState('')

  const hasOptions = availablePortfolios.length > 0

  useEffect(() => {
    if (open) {
      availablePortfoliosResponse.refetch()
    }
  }, [open])

  function handleSelectChange(event: SelectChangeEvent) {
    setSelectedPortfolioRef(event.target.value)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    const addPortfolioParams = {
      groupRef,
      portfolioRef: selectedPortfolioRef,
    }
    addPortfolio(addPortfolioParams, {
      onSuccess: () => {
        queryClient.invalidateQueries(['portfolio', 'group', groupRef, 'portfolios', 'current'])
        handleClose()
      },
    })
  }

  function handleClose() {
    resetAddPortfolio()
    setSelectedPortfolioRef('')
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('portfolio.add_portfolio')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={addPortfolioModalError} />
          {!hasOptions && <NoMoreOptionsAlert />}
          <FormControl disabled={!hasOptions}>
            <InputLabel required>{t('portfolio.portfolio')}</InputLabel>
            <Select
              required
              displayEmpty
              label={t('portfolio.portfolio')}
              value={selectedPortfolioRef}
              onChange={handleSelectChange}
            >
              {availablePortfolios.map((portfolio) => (
                <MenuItem key={portfolio.portfolio_name} value={portfolio.portfolio_ref}>
                  {portfolio.portfolio_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('common:add')} confirmDisabled={!hasOptions} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default AddPortfolioModal
