import { Dialog } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import PasswordField from '../../../components/fields/password-field'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import useChangePasswordMutation from '../data/use-change-password-mutation'

type ChangePasswordModalProps = {
  open: boolean
  onClose: () => void
}

function ChangePasswordModal(props: ChangePasswordModalProps) {
  const { open, onClose } = props
  const { t } = useTranslation('account')

  const { mutate: changePassword } = useChangePasswordMutation()

  const [error, setError] = useState<string | null>(null)

  function handleClose() {
    onClose()
    setError(null)
  }

  function handleChangePasswordSubmit(event: any) {
    event.preventDefault()
    setError(null)

    const currentPassword = event.target.elements.currentPassword.value
    const newPassword = event.target.elements.newPassword.value
    const newPasswordConfirmation = event.target.elements.newPasswordConfirmation.value

    if (!currentPassword || !newPassword || !newPasswordConfirmation) {
      setError(t('fill_all_fields'))
      return
    }

    if (newPassword !== newPasswordConfirmation) {
      setError(t('passwords_need_to_match'))
      return
    }
    const changePasswordParams = {
      currentPassword,
      newPassword,
    }

    changePassword(changePasswordParams, {
      onSuccess: handleClose,
      onError: () => {
        setError(t('password_change_error'))
      },
    })
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleChangePasswordSubmit}>
        <ModalTitle title={t('change_password_modal.title')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={error} />

          <PasswordField
            required
            name="currentPassword"
            autoComplete="current-password"
            placeholder={t('change_password_modal.current_password')}
          />
          <PasswordField
            required
            name="newPassword"
            autoComplete="new-password"
            placeholder={t('change_password_modal.new_password')}
          />
          <PasswordField
            required
            name="newPasswordConfirmation"
            autoComplete="new-password"
            placeholder={t('change_password_modal.repeat_password')}
          />
        </ModalContent>
        <ModalActions confirmLabel={t('common:save')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default ChangePasswordModal
