export function getInitials(name: string) {
  const splitName = name.split(' ')

  let initials = ''

  splitName.forEach((part) => {
    if (initials.length >= 2) {
      return
    }
    initials += part.charAt(0)
  })

  return initials
}
