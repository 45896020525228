import { Button, Checkbox, Typography } from '@mui/material'
import { Stack, SxProps, Theme } from '@mui/system'

type CheckboxButtonProps = {
  checked: boolean
  disabled?: boolean
  label: string
  onChange: (checked: boolean) => void
  sx?: SxProps<Theme>
}

export function CheckboxButton(props: CheckboxButtonProps) {
  const { checked, disabled, label, onChange, sx } = props

  return (
    <Button
      variant="outlined"
      disabled={disabled}
      onClick={() => onChange(!checked)}
      sx={{
        px: 1.5,
        height: '32px',
        color: 'white',
        backgroundColor: 'gray.main',
        borderColor: 'gray.main',
        whiteSpace: 'nowrap',
        '&:hover': {
          backgroundColor: 'gray.main',
        },
        ...sx,
      }}
    >
      <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
        <Checkbox size="small" value={checked} checked={checked} disabled={disabled} disableRipple sx={{ p: 0 }} />
        <Typography color="inherit" textTransform="none" fontSize="12px">
          {label}
        </Typography>
      </Stack>
    </Button>
  )
}
