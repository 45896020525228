import { RemoveCircleOutline } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import TableContainerHeader from '../../../components/table-container-header'
import { queryClient } from '../../../services/data/react-query'
import Permission from '../../../services/data/types/permission'
import useOpenState from '../../../utils/hooks/use-open-state'
import useRemovePermissionMutation from '../data/use-remove-permission-mutation'
import AddPermissionToGroupModal from './add-permission-to-group-modal'

type GroupPermissionsSectionProps = {
  groupRef: string
  groupCurrentPermissions: Permission[]
}

function GroupPermissionsSection(props: GroupPermissionsSectionProps) {
  const { groupRef, groupCurrentPermissions } = props

  const { t } = useTranslation('settings')

  const [selectedPermission, setSelectedPermission] = useState<Permission>()

  const {
    mutate: removePermissionFromGroup,
    error: removePermissionFromGroupErrorMessage,
    reset: resetRemovePermissionFromGroup,
  } = useRemovePermissionMutation()

  const addPermissionToGroupModal = useOpenState()
  const deletePermissionFromModal = useOpenState()

  function handleRemovePermissionFromGroup() {
    if (groupRef && selectedPermission) {
      const removePermissionFromGroupParams = {
        groupRef: groupRef,
        permissionTag: selectedPermission.permission_tag,
      }
      removePermissionFromGroup(removePermissionFromGroupParams, {
        onSuccess: () => {
          queryClient.invalidateQueries(['group', groupRef, 'permissions', 'available'])
          queryClient.invalidateQueries(['permissions', 'current', groupRef])
          handleRemovePermisisonFromGroupClose()
        },
      })
    }
  }

  function handleRemoveIconClick(permission: Permission) {
    setSelectedPermission(permission)
    deletePermissionFromModal.open()
  }

  function handleRemovePermisisonFromGroupClose() {
    deletePermissionFromModal.close()
    resetRemovePermissionFromGroup()
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ my: 3 }}>
        <TableContainerHeader
          title={t('user_details_page.group_permission_table.title')}
          action={
            <IconButton onClick={addPermissionToGroupModal.open}>
              <AddIcon />
            </IconButton>
          }
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('user_details_page.group_permission_table.permission')}</TableCell>
              <TableCell>{t('user_details_page.group_permission_table.description')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {groupCurrentPermissions?.map((permission) => {
              return (
                <TableRow hover key={permission.permission_tag}>
                  <TableCell>{permission.name}</TableCell>
                  <TableCell>{permission.description}</TableCell>
                  <TableCell className="action-cell" align="right">
                    <Tooltip title={t('remove')} arrow disableInteractive>
                      <IconButton onClick={() => handleRemoveIconClick(permission)}>
                        <RemoveCircleOutline />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <AddPermissionToGroupModal
        onClose={addPermissionToGroupModal.close}
        groupRef={groupRef}
        isOpen={addPermissionToGroupModal.isOpen}
      />
      <ConfirmationModal
        error={removePermissionFromGroupErrorMessage}
        title={t('group_tables.remove_permission_modal_title')}
        confirmButtonText={t('common:remove')}
        open={deletePermissionFromModal.isOpen}
        onCloseButtonClick={handleRemovePermisisonFromGroupClose}
        onConfirmButtonClick={handleRemovePermissionFromGroup}
      >
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey="group_tables.are_you_sure_you_want_to_remove_the_permission"
            values={{ subject: `${selectedPermission?.name}` }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </ConfirmationModal>
    </>
  )
}

export default GroupPermissionsSection
