import { ChevronRight, Edit, FilterAlt, RestartAlt } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import RemoveIcon from '@mui/icons-material/Remove'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { GridDataFilterOption } from '../../services/data/filter-parsing'
import { ParsedGridDataHeading } from '../../services/data/grid-data-parsing'
import { DatapointType } from '../../services/data/types/datapoint'
import { GridDataViewSortBy } from '../../services/data/types/grid-data-view'

type ColumnHeaderMenuProps = {
  heading: ParsedGridDataHeading
  sortBy: GridDataViewSortBy | null
  isGroupByDisabled?: boolean
  anchorEl: HTMLElement | null
  isFilterMenuOpen: boolean
  filterOptions: GridDataFilterOption[]
  onCloseMenu: () => void
  onHideColumn: () => void
  onFilterByColumn: (event: MouseEvent<HTMLElement>) => void
  onMoveToPanel?: () => void
  onRemoveFromPanel?: () => void
  onRenameColumn?: () => void
  onGroupByCol?: () => void
  onSortAsc: () => void
  onSortDesc: () => void
  onSortReset: () => void
}

function ColumnHeaderMenu(props: ColumnHeaderMenuProps) {
  const { t } = useTranslation('dataTable')
  const {
    heading,
    sortBy,
    isGroupByDisabled,
    anchorEl,
    isFilterMenuOpen,
    filterOptions,
    onCloseMenu,
    onHideColumn,
    onFilterByColumn,
    onMoveToPanel,
    onRemoveFromPanel,
    onRenameColumn,
    onGroupByCol,
    onSortAsc,
    onSortDesc,
    onSortReset,
  } = props

  const canHide = heading.meta.position === 'maingrid' || heading.meta.position === 'panel'
  const canFilter = filterOptions.map((option) => option.datapointRef).includes(heading.meta.datapoint_ref)
  const canRename = !!onRenameColumn && (heading.meta.position === 'maingrid' || heading.meta.position === 'panel')
  const canGroupBy = !!onGroupByCol && heading.groupable
  const canMoveToPanel = onMoveToPanel && heading.canMoveToPanel
  const canRemoveFromPanel = onRemoveFromPanel && heading.canRemoveFromPanel
  const isSorted = !!sortBy && heading.meta.datapoint_ref === sortBy.column
  const isSortedAsc = isSorted && sortBy.direction === 'asc'
  const isSortedDsc = isSorted && sortBy.direction === 'dsc'

  const numberTypes: DatapointType[] = ['Float', 'Int', 'DateTime', 'Percent']
  const isNumberType = numberTypes.includes(heading.meta.datapoint_type)

  return (
    <Menu
      className="context-menu"
      transitionDuration={0}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={!!anchorEl}
      onClose={onCloseMenu}
      onContextMenu={(event) => {
        event.preventDefault()
        onCloseMenu()
      }}
    >
      {canHide && (
        <MenuItem onClick={onHideColumn}>
          <ListItemIcon>
            <VisibilityOffIcon fontSize="small" sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography fontSize="14px">{t('column_menu.hide_column')}</Typography>
          </ListItemText>
        </MenuItem>
      )}

      {canFilter && (
        <MenuItem
          selected={isFilterMenuOpen}
          onClick={onFilterByColumn}
          sx={{
            '&:hover .MuiSvgIcon-root': {
              opacity: 1,
            },
            '&.Mui-selected': {
              background: 'rgba(120, 220, 232, 0.08)',
              '& .MuiSvgIcon-root': {
                opacity: 1,
              },
            },
          }}
        >
          <ListItemIcon>
            <FilterAlt fontSize="small" sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography fontSize="14px">{t('filter_by')}</Typography>
          </ListItemText>
          <ChevronRight color="primary" sx={{ fontSize: 18, opacity: 0 }} />
        </MenuItem>
      )}

      {canRename && (
        <MenuItem onClick={onRenameColumn}>
          <ListItemIcon>
            <Edit fontSize="small" sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography fontSize="14px">{t('column_menu.rename_column')}</Typography>
          </ListItemText>
        </MenuItem>
      )}

      {canMoveToPanel && (
        <MenuItem onClick={onMoveToPanel}>
          <ListItemIcon>
            <DoubleArrowIcon fontSize="small" sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography fontSize="14px">{t('column_menu.move_to_panel')}</Typography>
          </ListItemText>
        </MenuItem>
      )}

      {canRemoveFromPanel && (
        <MenuItem onClick={onRemoveFromPanel}>
          <ListItemIcon>
            <RemoveIcon fontSize="small" sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography fontSize="14px">{t('column_menu.remove_from_panel')}</Typography>
          </ListItemText>
        </MenuItem>
      )}

      {canGroupBy && (
        <MenuItem onClick={onGroupByCol} disabled={isGroupByDisabled}>
          <ListItemIcon>
            <AddIcon fontSize="small" sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography fontSize="14px">{t('column_menu.group_by_column')}</Typography>
          </ListItemText>
        </MenuItem>
      )}

      <MenuItem onClick={onSortAsc} disabled={isSortedAsc}>
        <ListItemIcon>
          <ArrowDownwardIcon fontSize="small" sx={{ color: 'gray.300' }} />
        </ListItemIcon>
        <ListItemText>
          <Typography fontSize="14px">
            {isNumberType ? t('column_menu.sort_num_asc') : t('column_menu.sort_text_asc')}
          </Typography>
        </ListItemText>
      </MenuItem>

      <MenuItem onClick={onSortDesc} disabled={isSortedDsc}>
        <ListItemIcon>
          <ArrowUpwardIcon fontSize="small" sx={{ color: 'gray.300' }} />
        </ListItemIcon>
        <ListItemText>
          <Typography fontSize="14px">
            {isNumberType ? t('column_menu.sort_num_desc') : t('column_menu.sort_text_desc')}
          </Typography>
        </ListItemText>
      </MenuItem>

      {isSorted && (
        <MenuItem onClick={onSortReset}>
          <ListItemIcon>
            <RestartAlt fontSize="small" sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography fontSize="14px">{t('column_menu.sort_reset')}</Typography>
          </ListItemText>
        </MenuItem>
      )}
    </Menu>
  )
}

export default ColumnHeaderMenu
