import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useRemoveDatasetMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, removeDatasetParams>({
    mutationFn: ({ groupRef, datasetRef }) => api.removeDataset(groupRef, datasetRef),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type removeDatasetParams = {
  groupRef: string
  datasetRef: string
}

export default useRemoveDatasetMutation
