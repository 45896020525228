import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useDatasetQuery(datasetRef?: string) {
  return useQuery({
    queryKey: ['dataset', datasetRef],
    queryFn: () => {
      if (datasetRef) {
        return api.getDataset(datasetRef)
      }
    },
    enabled: !!datasetRef,
  })
}

export default useDatasetQuery
