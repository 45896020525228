import Add from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import {
  Avatar,
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { MouseEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TableContainerHeader from '../../../components/table-container-header'
import useOpenState from '../../../utils/hooks/use-open-state'
import useDatasetsQuery from '../data/use-datasets-query'
import DataEngineShell from './data-engine-shell'
import DeleteDatasetModal from './delete-dataset-modal'
import EditDatasetModal from './edit-dataset-modal'
import NewDatasetModal from './new-dataset-modal'
import { useDataEngineUpdateLoadingState } from './use-data-engine-outlet-context'

type DatasetsProps = {
  onDatasetClick: (datasetRef: string) => void
}

function Datasets(props: DatasetsProps) {
  const { onDatasetClick } = props
  const { t } = useTranslation('dataEngine')

  const datasetsResponse = useDatasetsQuery()
  const datasets = datasetsResponse.data?.data

  const newDatasetModal = useOpenState()
  const editDatasetModal = useOpenState()
  const deleteDatasetModal = useOpenState()
  const [activeDatasetRef, setActiveDatasetRef] = useState('')

  const activeDataset = datasets?.find((dataset) => dataset.dataset_ref === activeDatasetRef)

  useDataEngineUpdateLoadingState(datasetsResponse.isFetching)

  function handleEdit(event: MouseEvent, datasetRef: string) {
    event.stopPropagation()
    setActiveDatasetRef(datasetRef)
    editDatasetModal.open()
  }

  function handleEditClose() {
    editDatasetModal.close()
    setActiveDatasetRef('')
  }

  function handleDelete(event: MouseEvent, datasetRef: string) {
    event.stopPropagation()
    setActiveDatasetRef(datasetRef)
    deleteDatasetModal.open()
  }

  function handleDeleteClose() {
    deleteDatasetModal.close()
    setActiveDatasetRef('')
  }

  if (datasetsResponse.isLoading) {
    return <DataEngineShell />
  }

  return (
    <DataEngineShell>
      <Box p={2}>
        <TableContainer component={Paper}>
          <TableContainerHeader
            title={t('datasets_table.title')}
            action={
              <Tooltip title={t('datasets_table.add')} arrow disableInteractive>
                <IconButton onClick={newDatasetModal.open}>
                  <Add />
                </IconButton>
              </Tooltip>
            }
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('datasets_table.dataset_name')}</TableCell>
                <TableCell>{t('datasets_table.dataset_owner')}</TableCell>
                <TableCell>{t('datasets_table.dataset_shared_to')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {datasets?.map((dataset) => {
                const sharedToWithoutOwner = dataset.shared_to.filter(
                  (group) => group.group_ref !== dataset.owner_group_ref
                )

                return (
                  <TableRow
                    key={dataset.dataset_ref}
                    hover
                    onClick={() => onDatasetClick(dataset.dataset_ref)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>{dataset.dataset_name}</TableCell>
                    <TableCell>{dataset.owner_group_name}</TableCell>
                    <TableCell>
                      <SharedToLink sharedToGroups={sharedToWithoutOwner} />
                    </TableCell>
                    <TableCell className="action-cell" align="right">
                      <Tooltip title={t('datasets_table.edit')} arrow disableInteractive>
                        <IconButton onClick={(event) => handleEdit(event, dataset.dataset_ref)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('datasets_table.remove')} arrow disableInteractive>
                        <IconButton onClick={(event) => handleDelete(event, dataset.dataset_ref)}>
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <NewDatasetModal open={newDatasetModal.isOpen} onClose={newDatasetModal.close} />
      <EditDatasetModal dataset={activeDataset} open={editDatasetModal.isOpen} onClose={handleEditClose} />
      <DeleteDatasetModal datasetRef={activeDatasetRef} open={deleteDatasetModal.isOpen} onClose={handleDeleteClose} />
    </DataEngineShell>
  )
}

export default Datasets

type SharedToLinkProps = {
  sharedToGroups: { group_ref: string; group_name: string }[]
}

function SharedToLink({ sharedToGroups }: SharedToLinkProps) {
  const { t } = useTranslation('dataEngine')
  const anchorElement = useRef<HTMLAnchorElement>(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const groupsLength = sharedToGroups.length

  function handleClick(event: MouseEvent) {
    event.stopPropagation()

    setIsMenuOpen(true)
  }

  return (
    <>
      {groupsLength ? (
        <Link ref={anchorElement} onClick={handleClick}>
          {t('group', { count: groupsLength })}
        </Link>
      ) : (
        <Typography color={(theme) => theme.palette.gray[700]}>-</Typography>
      )}
      <Menu
        open={isMenuOpen}
        anchorEl={anchorElement.current}
        onClose={() => setIsMenuOpen(false)}
        onClick={(event) => event.stopPropagation()}
        sx={{ '& .MuiPaper-root': { minWidth: 'unset' } }}
      >
        <List sx={{ p: 0 }}>
          {sharedToGroups.map((group) => {
            const groupName = group.group_name
            const initial = groupName[0] || ''

            return (
              <ListItem key={group.group_ref}>
                <ListItemAvatar sx={{ minWidth: 36 }}>
                  <Avatar sx={{ width: 24, height: 24, bgcolor: 'gray.400', color: 'gray.light' }}>
                    <Typography fontSize={12}>{initial.toUpperCase()}</Typography>
                  </Avatar>
                </ListItemAvatar>
                <Typography fontSize={13}>{groupName}</Typography>
              </ListItem>
            )
          })}
        </List>
      </Menu>
    </>
  )
}
