import { Table, TableBody, TableCell, TableHead, TableProps, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DatapointValueType } from '../../../services/data/types/datapoint'
import { DealTransaction } from '../../../services/data/types/deal'
import { formatTPPercentageValue, formatTPValue } from '../../../services/data/types/pricing'
import { TradeProfile, TradeTicketResponse } from '../../../services/data/types/trade-ticket'
import { formatDatetime } from '../../../utils/dates'
import { formatFloat } from '../../../utils/numbers'

type SecurityLegDealTransactionsProps = {
  transactions: DealTransaction[]
  cashflowTypes: TradeTicketResponse['cashflow_types']
  currencySymbol: string
  contractSize: DatapointValueType
}

export function SecurityLegDealTransactions(props: SecurityLegDealTransactionsProps) {
  const { transactions, cashflowTypes, currencySymbol, contractSize } = props

  const { t } = useTranslation('tradeTicket')

  const transactionsPerProfile: { [key in TradeProfile]: DealTransaction[] } = {
    standard: [],
    standard_bond: [],
    open_bond_by_spread: [],
    close_bond_by_spread: [],
    standard_repo: [],
    trs: [],
    future: [],
    fx_spot: [],
    fx_forward: [],
    ndf: [],
    cashflow: [],
    // close profiles don't have transactions
    close_repo: [],
    close_fx_forward: [],
    close_ndf: [],
    close_trs: [],
  }
  transactions.forEach((transaction) => {
    transactionsPerProfile[transaction.trade_profile]?.push(transaction)
  })

  return (
    <>
      {!!transactionsPerProfile.standard.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:value')}</TableCell>
              <TableCell>{t('common:price')}</TableCell>
              <TableCell>{t('common:num_securities')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.standard.map((t) => (
              <TableRow key={t.txn_ref}>
                <TableCell>{formatDatetime(t.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.consideration, currencySymbol)}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.price, currencySymbol)}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.number_of)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.standard_bond.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:sink_factor')}</TableCell>
              <TableCell>{t('common:clean_price')}</TableCell>
              <TableCell>{t('common:accrued_interest')}</TableCell>
              <TableCell>{t('common:consideration')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.standard_bond.map((t) => (
              <TableRow key={t.txn_ref}>
                <TableCell>{formatDatetime(t.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.notional)}</TableCell>
                <TableCell>{formatTPPercentageValue(t.trade_particulars.sink_factor)}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.clean_price, '%')}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.accrued_interest, currencySymbol)}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.consideration, currencySymbol)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.open_bond_by_spread.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('indicative_value')}</TableCell>
              <TableCell>{t('spread')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.open_bond_by_spread.map((t) => (
              <TableRow key={t.txn_ref}>
                <TableCell>{formatDatetime(t.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.consideration, currencySymbol)}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.contract_spread, 'bps')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.close_bond_by_spread.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('spread')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.close_bond_by_spread.map((t) => (
              <TableRow key={t.txn_ref}>
                <TableCell>{formatDatetime(t.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.notional)}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.contract_spread, 'bps')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.standard_repo.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:sink_factor')}</TableCell>
              <TableCell>{t('common:clean_price')}</TableCell>
              <TableCell>{t('common:accrued_interest')}</TableCell>
              <TableCell>{t('common:haircut')}</TableCell>
              <TableCell>{t('common:funding_amount_lc')}</TableCell>
              <TableCell>{t('common:inception_fx')}</TableCell>
              <TableCell>{t('common:repo_funding_amount')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.standard_repo.map((tr) => (
              <TableRow key={tr.txn_ref}>
                <TableCell>{formatDatetime(tr.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.notional)}</TableCell>
                <TableCell>{formatTPPercentageValue(tr.trade_particulars.sink_factor)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.clean_price, '%')}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.accrued_interest)}</TableCell>
                <TableCell>{formatTPPercentageValue(tr.trade_particulars.haircut)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.funding_amount_lc)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.inception_fx)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.repo_funding_amount)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.trs.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:sink_factor')}</TableCell>
              <TableCell>{t('common:clean_price')}</TableCell>
              <TableCell>{t('common:accrued_interest')}</TableCell>
              <TableCell>{t('common:haircut')}</TableCell>
              <TableCell>{t('common:funding_amount_lc')}</TableCell>
              <TableCell>{t('common:inception_fx')}</TableCell>
              <TableCell>{t('common:trs_funding_amount')}</TableCell>
              <TableCell>{t('common:is_fully_funded')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.trs.map((tr) => (
              <TableRow key={tr.txn_ref}>
                <TableCell>{formatDatetime(tr.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.notional)}</TableCell>
                <TableCell>{formatTPPercentageValue(tr.trade_particulars.sink_factor)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.clean_price, '%')}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.accrued_interest)}</TableCell>
                <TableCell>{formatTPPercentageValue(tr.trade_particulars.haircut)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.funding_amount_lc)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.inception_fx)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.trs_funding_amount)}</TableCell>
                <TableCell>{tr.trade_particulars.is_fully_funded ? t('common:yes') : t('common:no')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.future.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:price')}</TableCell>
              <TableCell>{t('contract_size')}</TableCell>
              <TableCell>{t('common:num_contracts')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.future.map((t) => (
              <TableRow key={t.txn_ref}>
                <TableCell>{formatDatetime(t.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.notional, currencySymbol)}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.contract_price)}</TableCell>
                <TableCell>{formatFloat(contractSize) || '-'}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.number_of)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.fx_spot.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:spot_rate')}</TableCell>
              <TableCell>{t('common:consideration')}</TableCell>
              <TableCell>{t('common:settlement_date')}</TableCell>
              <TableCell>{t('common:is_give_up')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.fx_spot.map((tr) => (
              <TableRow key={tr.txn_ref}>
                <TableCell>{formatDatetime(tr.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.notional)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.spot_rate)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.consideration)}</TableCell>
                <TableCell>{tr.trade_particulars.settlement_date || '-'}</TableCell>
                <TableCell>{tr.trade_particulars.is_give_up ? t('common:yes') : t('common:no')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.fx_forward.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:forward_rate')}</TableCell>
              <TableCell>{t('common:quote_amount')}</TableCell>
              <TableCell>{t('common:settlement_date')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.fx_forward.map((t) => (
              <TableRow key={t.txn_ref}>
                <TableCell>{formatDatetime(t.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.notional)}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.forward_rate)}</TableCell>
                <TableCell>{formatTPValue(t.trade_particulars.quote_amount)}</TableCell>
                <TableCell>{t.trade_particulars.settlement_date || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.ndf.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:ndf_rate')}</TableCell>
              <TableCell>{t('common:quote_amount')}</TableCell>
              <TableCell>{t('common:fixing_date')}</TableCell>
              <TableCell>{t('common:settlement_date')}</TableCell>
              <TableCell>{t('common:settle_on_quote')}</TableCell>
              <TableCell>{t('common:initial_margin_rate')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.ndf.map((tr) => (
              <TableRow key={tr.txn_ref}>
                <TableCell>{formatDatetime(tr.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.notional)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.ndf_rate)}</TableCell>
                <TableCell>{formatTPValue(tr.trade_particulars.quote_amount)}</TableCell>
                <TableCell>{tr.trade_particulars.fixing_date || '-'}</TableCell>
                <TableCell>{tr.trade_particulars.settlement_date || '-'}</TableCell>
                <TableCell>{tr.trade_particulars.is_give_up ? t('common:yes') : t('common:no')}</TableCell>
                <TableCell>{tr.trade_particulars.initial_margin_rate || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.cashflow.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:cashflow_type')}</TableCell>
              <TableCell>{t('common:amount')}</TableCell>
              <TableCell>{t('common:settlement_date')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.cashflow.map((tr) => {
              const cashflowType = cashflowTypes.find((cf) => cf.tag === tr.trade_particulars.cashflow_type)

              return (
                <TableRow key={tr.txn_ref}>
                  <TableCell>{formatDatetime(tr.txn_datetime)}</TableCell>
                  <TableCell>{cashflowType?.name || '-'}</TableCell>
                  <TableCell>{formatTPValue(tr.trade_particulars.quantity)}</TableCell>
                  <TableCell>{tr.trade_particulars.settlement_date || '-'}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </TransactionsTable>
      )}
    </>
  )
}

function TransactionsTable({ children, ...rest }: TableProps) {
  return (
    <Table
      size="small"
      {...rest}
      sx={{
        '& .MuiTableCell-head': { border: 0, pt: 1, pb: 0, fontSize: 12, fontWeight: 400, color: 'gray.300' },
        '& .MuiTableCell-head:first-of-type': { pl: 0 },
        '& .MuiTableCell-head:last-of-type': { pr: 0 },
        '& .MuiTableCell-body': { color: 'white', fontSize: 12 },
        '& .MuiTableCell-body:first-of-type': { pl: 0 },
        '& .MuiTableCell-body:last-of-type': { pr: 0 },
        ...rest.sx,
      }}
    >
      {children}
    </Table>
  )
}
