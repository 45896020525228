import { Stack } from '@mui/material'
import { ReactNode } from 'react'

type ModalContentProps = {
  children: ReactNode
}

function ModalContent({ children }: ModalContentProps) {
  return (
    <Stack p={3} gap={3}>
      {children}
    </Stack>
  )
}

export default ModalContent
