import { DeleteOutline, Edit, MoreVert } from '@mui/icons-material'
import { Chip, Divider, IconButton, Link, Menu, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useMutation } from '@tanstack/react-query'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import { MenuItemButton } from '../../../components/menu-item-button'
import { MenuItemLabel } from '../../../components/menu-item-label'
import { ModalDetailRow } from '../../../components/modal-detail-row'
import { i18n } from '../../../i18n'
import api from '../../../services/data/api'
import { extractDatapointFormatAndValue, formatDatapoint } from '../../../services/data/datapoint-formatting'
import { PricingPayload } from '../../../services/data/types/pricing'
import { Security } from '../../../services/data/types/security'
import {
  EditLegAsset,
  Leg,
  TradeDirection,
  TradeProfile,
  TradeTicketResponse,
  TradeTicketResponseAssetType,
  isTradeProfileSupported,
} from '../../../services/data/types/trade-ticket'
import useOpenState from '../../../utils/hooks/use-open-state'
import useSecurityDetailsQuery from '../../portfolios/data/use-security-details-query'
import { SecurityLegDealTransactions } from './security-leg-deal-transactions'
import { SecurityLegProfiles } from './security-leg-profiles'
import { mapTradeParticularsToLeg } from './trade-ticket-root'

type SecurityLegProps = {
  index: number
  leg: Leg
  currencies: TradeTicketResponse['currencies']
  cashflowTypes: TradeTicketResponse['cashflow_types']
  tradeTicket: TradeTicketResponse | null
  isTradeConfirmation: boolean
  isCloseOutPosition: boolean
  isCloseOutTransaction: boolean
  onEdit?: (leg: Leg) => void
  onUpdate: (leg: Leg) => void
  onDelete?: (legId: number) => void
  onEditAsset: (editLegAsset: EditLegAsset) => void
  onRemoveAsset: (editLegAsset: EditLegAsset) => void
  onVerifyCloseOut: () => void
}

export function SecurityLeg(props: SecurityLegProps) {
  const {
    index,
    leg,
    currencies,
    cashflowTypes,
    tradeTicket,
    isTradeConfirmation,
    isCloseOutPosition,
    isCloseOutTransaction,
    onEdit,
    onUpdate,
    onDelete,
    onEditAsset,
    onRemoveAsset,
    onVerifyCloseOut,
  } = props

  const { t } = useTranslation('tradeTicket')

  const menu = useOpenState()
  const menuAnchor = useRef(null)

  const pricingMutation = useMutation((payload: PricingPayload) => {
    return api.getPricing(payload)
  })

  const staticFields = leg.tradeAssetType?.static_fields || []

  const securityResponse = useSecurityDetailsQuery(leg.security.assetRef)
  const securityFields = securityResponse.data?.data.fields || []

  const displayFields = securityFields.filter((securityField) => {
    return staticFields.find((staticField) => staticField.datapoint_ref === securityField.datapoint_ref)
  })
  const editableFields = staticFields.filter((staticField) => {
    return !securityFields.find((securityField) => securityField.datapoint_ref === staticField.datapoint_ref)
  })

  const isStandard = leg.tradeProfile === 'standard'
  const isStandardBond = leg.tradeProfile === 'standard_bond'
  const isOpenBondBySpread = leg.tradeProfile === 'open_bond_by_spread'
  const isCloseBondBySpread = leg.tradeProfile === 'close_bond_by_spread'
  const isIndexFuture = leg.tradeProfile === 'future'
  const isStandardRepo = leg.tradeProfile === 'standard_repo'
  const isFxSpot = leg.tradeProfile === 'fx_spot'
  const isFxForward = leg.tradeProfile === 'fx_forward'
  const isNdf = leg.tradeProfile === 'ndf'
  const isTrs = leg.tradeProfile === 'trs'
  const isCashflow = leg.tradeProfile === 'cashflow'
  const isCloseRepo = leg.tradeProfile === 'close_repo'
  const isCloseFxForward = leg.tradeProfile === 'close_fx_forward'
  const isCloseNdf = leg.tradeProfile === 'close_ndf'
  const isCloseTrs = leg.tradeProfile === 'close_trs'

  const contractSize = getValueForAlias(leg, staticFields, securityFields, 'contract_size')
  const parValue = getValueForAlias(leg, staticFields, securityFields, 'par_value')
  const currencyRef = getValueForAlias(leg, staticFields, securityFields, 'asset_currency') || ''
  const currencySymbol = currencies[String(currencyRef)] || ''

  const dealTransactions = leg.dealTransactions || []

  function handleEditLeg() {
    onEdit?.(leg)
    menu.close()
  }

  function handleDeleteLeg() {
    onDelete?.(leg.id)
    menu.close()
  }

  function handleSelectProfile(tradeProfile: TradeProfile) {
    const aliasError = checkAliasError(staticFields, tradeProfile)
    onUpdate({ ...leg, tradeProfile, aliasError })
    menu.close()
  }

  function handleTradeDirectionChange(tradeDirection: TradeDirection) {
    onUpdate({ ...leg, tradeDirection })
  }

  async function updatePricing(payload: PricingPayload) {
    pricingMutation.mutate(payload, {
      onSuccess: (tradeParticulars) => {
        onUpdate({
          ...leg,
          ...mapTradeParticularsToLeg(leg.tradeProfile, tradeParticulars, null),
        })
      },
    })
  }

  if (!isTradeProfileSupported(leg.tradeProfile)) {
    return (
      <Box sx={{ p: 3, border: '1px solid rgba(255, 255, 255, 0.23)', borderRadius: 2 }}>
        <Typography>{t('unsupported_trade_ticket')}</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ p: 3, border: '1px solid rgba(255, 255, 255, 0.23)', borderRadius: 2 }}>
      <Stack direction="row" alignItems="center" sx={{ gap: 1, mb: 2 }}>
        <Chip label={index} sx={{ backgroundColor: 'grey.600' }} />
        <Typography>{leg.security.assetName}</Typography>
        <Chip label={leg.security.assetTypeName} />

        <IconButton ref={menuAnchor} onClick={menu.open} sx={{ ml: 'auto' }}>
          <MoreVert />
        </IconButton>
      </Stack>

      {!!staticFields.length && (
        <Stack sx={{ gap: 1 }}>
          {displayFields.map((field) => (
            <ModalDetailRow
              key={field.datapoint_ref}
              label={field.datapoint_name}
              value={field.display_as || formatDatapoint(field.value)}
            />
          ))}

          {editableFields.map((staticField) => {
            const legField = leg.staticFields.find((legStaticField) => {
              return legStaticField.datapointRef === staticField.datapoint_ref
            })
            const datapointValue = legField?.value
            const displayValue = legField?.security?.assetName || formatDatapoint(datapointValue)
            const label = staticField.is_mandatory ? `${staticField.datapoint_name} *` : staticField.datapoint_name

            return (
              <ModalDetailRow key={staticField.datapoint_ref} label={label}>
                <Stack direction="row" alignItems="center" gap={1}>
                  {!!datapointValue && <Typography fontSize="small">{displayValue}</Typography>}

                  <Link
                    component="button"
                    type="button"
                    underline="hover"
                    fontSize="small"
                    onClick={() => {
                      onEditAsset({
                        legId: leg.id,
                        assetRef: leg.security.assetRef,
                        assetDescription: leg.security.assetName,
                        datapointRef: staticField.datapoint_ref,
                        datapointType: staticField.datapoint_type,
                        datapointName: staticField.datapoint_name,
                        datapointValue: datapointValue,
                        classificationId: staticField.classification_id,
                        assetCategory: staticField.asset_category,
                      })
                    }}
                  >
                    {!!datapointValue ? t('common:edit') : t('common:add')}
                  </Link>

                  {!!datapointValue && (
                    <Link
                      component="button"
                      type="button"
                      underline="hover"
                      fontSize="small"
                      onClick={() => {
                        onRemoveAsset({
                          legId: leg.id,
                          assetRef: leg.security.assetRef,
                          assetDescription: leg.security.assetName,
                          datapointRef: staticField.datapoint_ref,
                          datapointType: staticField.datapoint_type,
                          datapointName: staticField.datapoint_name,
                          datapointValue: datapointValue,
                          classificationId: staticField.classification_id,
                          assetCategory: staticField.asset_category,
                        })
                      }}
                    >
                      {t('common:remove')}
                    </Link>
                  )}
                </Stack>
              </ModalDetailRow>
            )
          })}

          <Alert severity="error" message={leg.aliasError} sx={{ mt: 1 }} />
        </Stack>
      )}

      <Divider sx={{ my: 3 }} />

      {!!dealTransactions.length && (
        <>
          <SecurityLegDealTransactions
            transactions={dealTransactions}
            cashflowTypes={cashflowTypes}
            currencySymbol={currencySymbol}
            contractSize={contractSize}
          />
          <Divider sx={{ my: 3 }} />
        </>
      )}

      <SecurityLegProfiles
        leg={leg}
        currencies={currencies}
        cashflowTypes={cashflowTypes}
        currencySymbol={currencySymbol}
        tradeTicket={tradeTicket}
        contractSize={contractSize}
        parValue={parValue}
        isTradeConfirmation={isTradeConfirmation}
        isCloseOutPosition={isCloseOutPosition}
        isCloseOutTransaction={isCloseOutTransaction}
        isStandardProfile={isStandard}
        isStandardBondProfile={isStandardBond}
        isOpenBondBySpreadProfile={isOpenBondBySpread}
        isCloseBondBySpreadProfile={isCloseBondBySpread}
        isIndexFutureProfile={isIndexFuture}
        isStandardRepoProfile={isStandardRepo}
        isFxSpotProfile={isFxSpot}
        isFxForwardProfile={isFxForward}
        isNdfProfile={isNdf}
        isTrsProfile={isTrs}
        isCashflowProfile={isCashflow}
        isCloseRepoProfile={isCloseRepo}
        isCloseFxForwardProfile={isCloseFxForward}
        isCloseNdfProfile={isCloseNdf}
        isCloseTrsProfile={isCloseTrs}
        onUpdateLeg={onUpdate}
        onUpdatePricing={updatePricing}
        onVerifyCloseOut={onVerifyCloseOut}
        onTradeDirectionChange={handleTradeDirectionChange}
      />

      <Menu
        open={menu.isOpen}
        anchorEl={menuAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={menu.close}
      >
        {onEdit && <MenuItemButton label={t('edit_security')} Icon={Edit} onClick={handleEditLeg} />}
        {onDelete && <MenuItemButton label={t('remove_leg')} Icon={DeleteOutline} onClick={handleDeleteLeg} />}
        {!!(onEdit || onDelete) && <Divider />}

        {!!leg.profiles.length && <MenuItemLabel label={t('trade_profiles')} />}
        {!!leg.profiles.length &&
          leg.profiles.map((profile) => (
            <MenuItemButton
              key={profile.tag}
              label={profile.name}
              selected={profile.tag === leg.tradeProfile}
              onClick={() => handleSelectProfile(profile.tag)}
            />
          ))}
      </Menu>
    </Box>
  )
}

function getValueForAlias(
  leg: Leg,
  staticFields: TradeTicketResponseAssetType['static_fields'],
  securityFields: Security['fields'],
  alias: string
) {
  const staticField = staticFields.find((sf) => sf.active_alias === alias)
  const existingField = staticField && securityFields.find((sf) => sf.datapoint_ref === staticField.datapoint_ref)
  const legField = staticField && leg.staticFields.find((sf) => sf.datapointRef === staticField.datapoint_ref)

  const [, existingValue] = extractDatapointFormatAndValue(existingField?.value)
  const [, legValue] = extractDatapointFormatAndValue(legField?.value)

  return existingValue || legValue
}

export function checkAliasError(staticFields: TradeTicketResponseAssetType['static_fields'], profile: TradeProfile) {
  const isStandardBondProfile = profile === 'standard_bond'
  const isIndexFutureProfile = profile === 'future'

  const contractSize = staticFields.find((sf) => sf.active_alias === 'contract_size')
  const parValue = staticFields.find((sf) => sf.active_alias === 'par_value')
  const contractSizeError =
    isIndexFutureProfile && !contractSize
      ? i18n.t('tradeTicket:missing_asset_static_alias', { alias: 'contract_size' })
      : ''
  const parValueError =
    isStandardBondProfile && !parValue ? i18n.t('tradeTicket:missing_asset_static_alias', { alias: 'par_value' }) : ''

  return contractSizeError || parValueError
}
