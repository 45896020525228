import { Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'

type SelectItemModalProps = {
  open: boolean
  required?: boolean
  title: string
  inputLabel: string
  selectedId?: string
  options: { id: string; label: string }[]
  onClose: () => void
  onSave: (optionId: string) => Promise<void>
}

function SelectItemModal(props: SelectItemModalProps) {
  const { open, required, title, inputLabel, selectedId, options, onClose, onSave } = props

  const { t } = useTranslation()

  const [selectedOptionId, setSelectedOptionId] = useState('')
  const [loading, setLoading] = useState(false)

  const canSave = !required || !!selectedOptionId

  useEffect(() => {
    if (open) {
      setSelectedOptionId(selectedId || '')
    } else {
      setSelectedOptionId('')
    }
  }, [open, selectedId])

  function handleOptionChange(event: SelectChangeEvent) {
    setSelectedOptionId(event.target.value)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    setLoading(true)
    onSave(selectedOptionId).finally(() => {
      setLoading(false)
    })
  }

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={title} onClose={onClose} />
        <ModalContent>
          <FormControl required={required}>
            <InputLabel>{inputLabel}</InputLabel>
            <Select name="item" label={inputLabel} value={selectedOptionId} onChange={handleOptionChange}>
              {!required && <MenuItem value="">---</MenuItem>}
              {options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalActions
          confirmDisabled={!canSave}
          isLoading={loading}
          confirmLabel={t('common:select')}
          onCancel={onClose}
        />
      </form>
    </Dialog>
  )
}

export default SelectItemModal
