import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useAssetMetaQuery(assetRef?: string) {
  return useQuery({
    queryKey: ['asset', assetRef, 'currency'],
    queryFn: () => {
      if (assetRef) {
        return api.getAssetMeta(assetRef)
      }
    },
    enabled: !!assetRef,
    cacheTime: 0,
  })
}

export default useAssetMetaQuery
