import { Attachment, ChevronRight, DeleteOutline, Edit, RemoveCircleOutline } from '@mui/icons-material'
import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

type DatapointMenuProps = {
  anchorEl: HTMLElement | null
  aliasMenuActive?: boolean
  showEdit?: boolean
  showDelete?: boolean
  showRemove?: boolean
  onActiveAliasClick: MouseEventHandler
  onEdit: MouseEventHandler
  onDelete: MouseEventHandler
  onRemove: MouseEventHandler
  onClose?: MouseEventHandler
}

function DatapointMenu(props: DatapointMenuProps) {
  const {
    anchorEl,
    aliasMenuActive,
    showEdit,
    showDelete,
    showRemove,
    onActiveAliasClick,
    onEdit,
    onDelete,
    onRemove,
    onClose,
  } = props

  const { t } = useTranslation('dataEngine')

  return (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      transitionDuration={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
    >
      {showEdit && (
        <MenuItem onClick={onEdit}>
          <ListItemIcon>
            <Edit sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography>{t('datapoint_table.edit_datapoint')}</Typography>
          </ListItemText>
        </MenuItem>
      )}

      <MenuItem
        onClick={onActiveAliasClick}
        selected={aliasMenuActive}
        sx={{
          '&:hover .chevron': {
            opacity: 1,
          },
          '&.Mui-selected': {
            background: 'rgba(120, 220, 232, 0.08)',
            '& .MuiSvgIcon-root': {
              opacity: 1,
            },
          },
        }}
      >
        <ListItemIcon>
          <Attachment sx={{ color: 'gray.300' }} />
        </ListItemIcon>
        <ListItemText>
          <Typography>{t('datapoint_table.active_alias')}</Typography>
        </ListItemText>
        <ChevronRight className="chevron" color="primary" sx={{ opacity: 0 }} />
      </MenuItem>

      {showDelete && (
        <MenuItem onClick={onDelete}>
          <ListItemIcon>
            <DeleteOutline sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography>{t('common:delete')}</Typography>
          </ListItemText>
        </MenuItem>
      )}

      {showRemove && (
        <MenuItem onClick={onRemove}>
          <ListItemIcon>
            <RemoveCircleOutline sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography>{t('common:remove')}</Typography>
          </ListItemText>
        </MenuItem>
      )}
    </Menu>
  )
}

export default DatapointMenu
