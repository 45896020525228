import { ArrowBack, DeleteOutline, Edit } from '@mui/icons-material'
import { Button, Chip, Container, IconButton, Paper, Stack, TableBody, TableContainer, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ConfirmationModal from '../../../components/confirmation-modal'
import { DetailTable, DetailTableRow } from '../../../components/detail-table'
import TableContainerHeader from '../../../components/table-container-header'
import useOpenState from '../../../utils/hooks/use-open-state'
import useDeletePortfolioMutation from '../data/use-delete-portfolio-mutation'
import useRegularPortfolioDetailsQuery from '../data/use-regular-portfolio-details-query'
import EditPortfolioModal from './edit-portfolio-modal'
import { useSettingsUpdateLoadingState } from './use-settings-set-loading'

type PortfolioDetailsProps = {
  onBack: () => void
}

function PortfolioDetails(props: PortfolioDetailsProps) {
  const { onBack } = props
  const { t } = useTranslation('settings')

  const { portfolioRef } = useParams()
  const editPortfolioModal = useOpenState()
  const deletePortfolioModal = useOpenState()

  const { mutate: deletePortfolio, error: deletePortfolioError } = useDeletePortfolioMutation()

  const portfolioDetailResponse = useRegularPortfolioDetailsQuery(portfolioRef!)
  const portfolio = portfolioDetailResponse.data?.data

  const isLoading = portfolioDetailResponse.isLoading

  async function handleDeletePortfolio() {
    if (portfolio) {
      deletePortfolio(
        { portfolioRef: portfolio.portfolio_ref },
        {
          onSuccess: () => {
            onBack()
          },
        }
      )
    }
  }

  useSettingsUpdateLoadingState(isLoading)

  if (isLoading) {
    return null
  }

  return (
    <Container maxWidth="md">
      <Stack direction="row" sx={{ alignItems: 'center', py: 4, gap: 1 }}>
        <IconButton onClick={onBack}>
          <ArrowBack sx={{ color: 'gray.300' }} />
        </IconButton>
        <Typography variant="h5">{portfolio?.portfolio_name}</Typography>
        <Button variant="text" startIcon={<DeleteOutline />} onClick={deletePortfolioModal.open} sx={{ ml: 'auto' }}>
          {t('settings:delete_portfolio')}
        </Button>
      </Stack>

      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <TableContainerHeader
          title={t('portfolio_details.title')}
          action={
            <IconButton onClick={editPortfolioModal.open}>
              <Edit />
            </IconButton>
          }
        />
        <DetailTable>
          <TableBody>
            <DetailTableRow label={t('portfolio_details.portfolio_name')}>
              <Typography variant="body1">{portfolio?.portfolio_name}</Typography>
            </DetailTableRow>
            <DetailTableRow label={t('portfolio_details.legal_entity')}>
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography variant="body1">{portfolio?.legal_entity_name}</Typography>
                <Chip
                  label={
                    <Stack direction="row" gap={0.5}>
                      <Typography variant="inherit" sx={{ textTransform: 'uppercase' }}>
                        {t('portfolio_details.lei')}:
                      </Typography>
                      <Typography variant="inherit" color="gray.700">
                        {portfolio?.lei}
                      </Typography>
                    </Stack>
                  }
                  sx={{
                    cursor: 'inherit',
                    height: '24px',
                  }}
                />
              </Stack>
            </DetailTableRow>
            <DetailTableRow label={t('portfolio_details.base_currency')}>
              <Chip
                label={portfolio?.base_currency}
                sx={{
                  cursor: 'inherit',
                  height: '24px',
                }}
              />
            </DetailTableRow>
            <DetailTableRow label={t('portfolio_details.chart_of_accounts')}>
              <Typography variant="body1">{portfolio?.chart_name}</Typography>
            </DetailTableRow>
            <DetailTableRow label={t('portfolio_details.ledger_tag')}>
              <Typography variant="body1">{portfolio?.pot_tag}</Typography>
            </DetailTableRow>
          </TableBody>
        </DetailTable>
      </TableContainer>
      <EditPortfolioModal
        currentPortfolio={portfolio!}
        open={editPortfolioModal.isOpen}
        onClose={editPortfolioModal.close}
      />
      <ConfirmationModal
        error={deletePortfolioError}
        title={t('settings:delete_portfolio')}
        confirmButtonText={t('common:delete')}
        open={deletePortfolioModal.isOpen}
        onCloseButtonClick={deletePortfolioModal.close}
        onConfirmButtonClick={handleDeletePortfolio}
      >
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey={'common:are_you_sure_you_want_to_delete'}
            values={{ subject: `${portfolio?.portfolio_name}` || '' }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </ConfirmationModal>
    </Container>
  )
}
export default PortfolioDetails
