import { RemoveCircleOutline } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Avatar,
  Button,
  Chip,
  Container,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ConfirmationModal from '../../../components/confirmation-modal'
import { DetailTable, DetailTableRow } from '../../../components/detail-table'
import TableContainerHeader from '../../../components/table-container-header'
import { UserDetailsDataset } from '../../../services/data/types/dataset'
import useOpenState from '../../../utils/hooks/use-open-state'
import useUserInfo from '../../auth/data/use-user-info'
import useChangeTenantAdminStatusMutation from '../data/use-change-tenant-admin-status-mutation'
import useCurrentDatasetsQuery from '../data/use-current-datasets-query'
import useCurrentPermissionsQuery from '../data/use-current-permissions-query'
import useCurrentPortfoliosQuery from '../data/use-current-portfolios-query'
import useDeleteUserMutation from '../data/use-delete-user-mutation'
import useUserDetailQuery from '../data/use-get-user-detail-query'
import useGroupsWithMembers from '../data/use-groups-with-members'
import useRemoveDatasetMutation from '../data/use-remove-dataset-mutation'
import useRemovePermissionMutation from '../data/use-remove-permission-mutation'
import useRemovePortfolioMutation from '../data/use-remove-portfolio-mutation'
import useRemoveUserFromGroupMutation from '../data/use-remove-user-from-group-mutation'
import AddDatasetModal from './add-dataset-modal'
import AddGroupModal from './add-group-modal'
import AddPermissionModal from './add-permission-modal'
import AddPortfolioModal from './add-portfolio-modal'
import EditDatasetModal from './edit-dataset-modal'
import EditGroupModal from './edit-group-modal'
import EditUserModal from './edit-user-modal'
import { useSettingsUpdateLoadingState } from './use-settings-set-loading'

type UserDetailsProps = {
  onBack: () => void
}

export enum GroupRoles {
  admin = 'admin',
  member = 'member',
}

export type GroupRole = {
  role_name: string
  role_ref: GroupRoles
}

function UserDetails({ onBack }: UserDetailsProps) {
  const { t } = useTranslation('settings')

  const {
    mutate: changeTenantAdminStatus,
    error: changeTenantAdminStatusError,
    reset: resetChangeTenantAdminStatus,
  } = useChangeTenantAdminStatusMutation()
  const {
    mutate: removePermission,
    error: removePermissionError,
    reset: resetRemovePermissionStatus,
  } = useRemovePermissionMutation()
  const {
    mutate: removeUserFromGroup,
    error: removeUserFromGroupError,
    reset: resetRemoveUserFromGroupStatus,
  } = useRemoveUserFromGroupMutation()
  const { mutate: removeDataset, error: removeDatasetError, reset: resetRemoveDataset } = useRemoveDatasetMutation()
  const { mutate: removeUser, error: deleteUserError, reset: resetDeleteUserStatus } = useDeleteUserMutation()
  const {
    mutate: removePortfolio,
    error: removePortfolioError,
    reset: resetRemovePortfolioStatus,
  } = useRemovePortfolioMutation()

  const removePermissionOrChangeTenantAdminStatusErrorMessage = changeTenantAdminStatusError || removePermissionError

  const { userRef } = useParams()

  const removeUserModal = useOpenState()
  const removePermissionModal = useOpenState()
  const removePortfolioModal = useOpenState()
  const removeGroupModal = useOpenState()
  const removeDatasetModal = useOpenState()
  const addPermissionModal = useOpenState()
  const addPortfolioModal = useOpenState()
  const addGroupModal = useOpenState()
  const addDatasetModal = useOpenState()
  const editUserModal = useOpenState()
  const editGroupModal = useOpenState()
  const editDatasetModal = useOpenState()

  const userInfoResult = useUserInfo()
  const userDetailResponse = useUserDetailQuery(userRef!)
  const groupsWithMembersResponse = useGroupsWithMembers()

  const [selectedPermissionTag, setSelectedPermissionTag] = useState<string>()
  const [selectedPortfolioRef, setSelectedPortfolioRef] = useState<string>()
  const [selectedGroupRef, setSelectedGroupRef] = useState('')
  const [userRole, setIsGroupAdmin] = useState<GroupRoles | ''>('')
  const [selectedDatasetRef, setSelectedDatasetRef] = useState<string>()
  const [selectedDataset, setSelectedDataset] = useState<UserDetailsDataset>()

  const allGroupsWithMembers = groupsWithMembersResponse.data?.data.groups || []
  const selectedUsersGroups = allGroupsWithMembers.filter((groupWithMembers) => {
    const allMembersRef = groupWithMembers.members.map((member) => member.user_ref)
    return allMembersRef.includes(userRef!)
  })
  const currentGroupsWithMembers = selectedUsersGroups.filter((group) => group.group_type !== 'personal')
  const currentGroups = currentGroupsWithMembers.map((group) => {
    return {
      groupRef: group.group_ref,
      isUserAdmin: group.members.find((member) => member.user_ref === userRef!)?.is_group_admin,
      groupName: group.group_name,
    }
  })

  const groupRoles: GroupRole[] = [
    { role_name: t('groups.admin'), role_ref: GroupRoles.admin },
    { role_name: t('groups.member'), role_ref: GroupRoles.member },
  ]

  const currentUser = userInfoResult.user
  const userDetails = userDetailResponse.data?.data
  const fullName = userDetails ? `${userDetails.firstname} ${userDetails.lastname}` : ''
  const personalGroupRef = userDetails?.personal_group
  const canEditDetails = currentUser?.is_superuser || userDetails?.user_ref === currentUser?.user_ref

  const currentPermissionsResponse = useCurrentPermissionsQuery(personalGroupRef)
  const currentPermissions = currentPermissionsResponse.data?.data || []

  const adminPermission = {
    permission_tag: 'admin',
    name: t('permission.admin'),
    description: t('permission.admin_description'),
  }
  const allCurrentPermissions = userDetails?.is_tenant_admin
    ? currentPermissions.concat(adminPermission)
    : currentPermissions
  const selectedPermission = allCurrentPermissions.find(
    (permission) => permission.permission_tag === selectedPermissionTag
  )

  const currentPortfoliosResponse = useCurrentPortfoliosQuery(personalGroupRef)
  const currentPortfolios = currentPortfoliosResponse.data?.data || []
  const selectedPortfolio = currentPortfolios.find((portfolio) => portfolio.portfolio_ref === selectedPortfolioRef)

  const selectedGroupName = allGroupsWithMembers.find((group) => group.group_ref === selectedGroupRef)?.group_name || ''

  const currentDatasetsResponse = useCurrentDatasetsQuery(personalGroupRef)
  const currentDatasets = currentDatasetsResponse.data?.data || []

  const isAnythingLoading =
    userDetailResponse.isLoading ||
    groupsWithMembersResponse.isLoading ||
    currentPermissionsResponse.isLoading ||
    currentPortfoliosResponse.isLoading ||
    currentDatasetsResponse.isLoading

  useSettingsUpdateLoadingState(isAnythingLoading)

  async function handleRemoveUser() {
    if (userRef) {
      removeUser(
        { userRef },
        {
          onSuccess: () => {
            resetDeleteUserStatus()
            onBack()
          },
        }
      )
    }
  }
  function handleRemoveUserModalClose() {
    resetDeleteUserStatus()
    removeUserModal.close()
  }

  function handleRemovePermissionModalOpen(permissionTag: string) {
    setSelectedPermissionTag(permissionTag)
    removePermissionModal.open()
  }

  function handlePermissionModalClose() {
    resetChangeTenantAdminStatus()
    resetRemovePermissionStatus()
    removePermissionModal.close()
  }

  function handleRemovePermission() {
    if (selectedPermissionTag === 'admin' && userRef) {
      const adminStatusParams = {
        userRef,
        body: { is_tenant_admin: false },
      }
      changeTenantAdminStatus(adminStatusParams, {
        onSuccess: () => {
          userDetailResponse.refetch()
          handlePermissionModalClose()
        },
      })
      return
    }

    if (personalGroupRef && selectedPermissionTag) {
      const removePermissionParams = {
        groupRef: personalGroupRef,
        permissionTag: selectedPermissionTag,
      }
      removePermission(removePermissionParams, {
        onSuccess: () => {
          handlePermissionModalClose()
          currentPermissionsResponse.refetch()
        },
      })
    }
  }

  function handleRemovePortfolioModalOpen(portfolioRef: string) {
    setSelectedPortfolioRef(portfolioRef)
    removePortfolioModal.open()
  }

  function handlePortfolioRemoveClose() {
    resetRemovePortfolioStatus()
    removePortfolioModal.close()
  }

  function handleRemovePortfolio() {
    if (personalGroupRef && selectedPortfolioRef) {
      const removePortfolioParams = { personalGroupRef, selectedPortfolioRef }
      removePortfolio(removePortfolioParams, {
        onSuccess: () => {
          resetRemovePortfolioStatus()
          currentPortfoliosResponse.refetch()
          removePortfolioModal.close()
        },
      })
    }
  }

  function handleEditGroupModalOpen(groupRef: string, isAdmin: boolean | undefined) {
    if (isAdmin !== undefined) {
      setSelectedGroupRef(groupRef)
      setIsGroupAdmin(isAdmin ? GroupRoles.admin : GroupRoles.member)
      editGroupModal.open()
    }
  }

  function handleRemoveGroupModalOpen(groupRef: string) {
    setSelectedGroupRef(groupRef)
    removeGroupModal.open()
  }

  function handleRemoveGroupModalClose() {
    resetRemoveUserFromGroupStatus()
    removeGroupModal.close()
  }

  function handleRemoveGroup() {
    if (!!userRef && !!selectedGroupRef) {
      const removeUserFromGroupParams = {
        groupRef: selectedGroupRef,
        userRef,
      }
      removeUserFromGroup(removeUserFromGroupParams, {
        onSuccess: () => {
          handleRemoveGroupModalClose()
          groupsWithMembersResponse.refetch()
        },
      })
    }
  }

  function handleEditDatasetModalOpen(dataset: UserDetailsDataset) {
    setSelectedDataset(dataset)
    setSelectedDatasetRef(dataset.dataset_ref)
    editDatasetModal.open()
  }

  function handleRemoveDatasetModalOpen(dataset: UserDetailsDataset) {
    setSelectedDataset(dataset)
    setSelectedDatasetRef(dataset.dataset_ref)
    removeDatasetModal.open()
  }
  function handleRemoveDatasetModalClose() {
    resetRemoveDataset()
    removeDatasetModal.close()
  }

  function handleRemoveDataset() {
    if (personalGroupRef && selectedDatasetRef) {
      const removeDatasetParams = {
        groupRef: personalGroupRef,
        datasetRef: selectedDatasetRef,
      }
      removeDataset(removeDatasetParams, {
        onSuccess: () => {
          currentDatasetsResponse.refetch()
          handleRemoveDatasetModalClose()
        },
      })
    }
  }

  if (isAnythingLoading || (!userDetails && !personalGroupRef)) {
    return null
  }

  return (
    <>
      <Container maxWidth="md">
        <Stack direction="row" sx={{ alignItems: 'center', py: 4, gap: 1 }}>
          <IconButton onClick={onBack}>
            <ArrowBackIcon sx={{ color: 'gray.300' }} />
          </IconButton>
          <Avatar sx={{ mr: 1, bgcolor: 'gray.400', color: 'gray.light' }}>{userDetails.firstname[0]}</Avatar>
          <Typography variant="h5">{fullName}</Typography>
          <Button
            variant="text"
            startIcon={<RemoveCircleOutlineIcon />}
            onClick={removeUserModal.open}
            sx={{ ml: 'auto' }}
          >
            {t('settings:remove_user')}
          </Button>
        </Stack>

        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <TableContainerHeader
            title={t('user_details.user_details')}
            action={
              canEditDetails && (
                <IconButton onClick={editUserModal.open}>
                  <EditIcon />
                </IconButton>
              )
            }
          />
          <DetailTable>
            <TableBody>
              <DetailTableRow label={t('user_details.username')}>
                <Typography variant="body1">{userDetails?.username}</Typography>
              </DetailTableRow>
              <DetailTableRow label={t('user_details.firstname')}>
                <Typography variant="body1">{userDetails?.firstname}</Typography>
              </DetailTableRow>
              <DetailTableRow label={t('user_details.lastname')}>
                <Typography variant="body1">{userDetails?.lastname}</Typography>
              </DetailTableRow>
              <DetailTableRow label={t('user_details.email')}>
                <Typography variant="body1">{userDetails?.email}</Typography>
              </DetailTableRow>
              <DetailTableRow label={t('user_details.timezone')}>
                <Typography variant="body1">{userDetails?.timezone}</Typography>
              </DetailTableRow>
            </TableBody>
          </DetailTable>
        </TableContainer>

        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <TableContainerHeader
            title={t('permission.user_permissions')}
            action={
              <IconButton onClick={addPermissionModal.open}>
                <AddIcon />
              </IconButton>
            }
          />
          <Table sx={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: 'solid 1px', borderColor: 'divider' }}>
                  {t('permission.permission')}
                </TableCell>
                <TableCell sx={{ borderBottom: 'solid 1px', borderColor: 'divider' }}>
                  {t('permission.description')}
                </TableCell>
                <TableCell sx={{ borderBottom: 'solid 1px', borderColor: 'divider', width: 64 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {allCurrentPermissions.map((permission) => {
                return (
                  <TableRow hover key={permission.name} sx={{ cursor: 'pointer' }}>
                    <TableCell>{permission.name}</TableCell>
                    <TableCell>{permission.description}</TableCell>
                    <TableCell className="action-cell" align="right">
                      <Tooltip title={t('remove')} arrow disableInteractive>
                        <IconButton onClick={() => handleRemovePermissionModalOpen(permission.permission_tag)}>
                          <RemoveCircleOutline />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <TableContainerHeader
            title={t('portfolio.title')}
            action={
              <IconButton onClick={addPortfolioModal.open}>
                <AddIcon />
              </IconButton>
            }
          />
          <Table sx={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell>{t('portfolio.portfolio')}</TableCell>
                <TableCell>{t('portfolio.legal_entity')}</TableCell>
                <TableCell>{t('portfolio.base_currency')}</TableCell>
                <TableCell sx={{ width: 64 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPortfolios.map((portfolio) => {
                return (
                  <TableRow hover key={portfolio.portfolio_name} sx={{ cursor: 'pointer' }}>
                    <TableCell>{portfolio.portfolio_name}</TableCell>
                    <TableCell>{portfolio.legal_entity_name}</TableCell>
                    <TableCell>{portfolio.base_currency}</TableCell>
                    <TableCell className="action-cell" align="right">
                      <Tooltip title={t('common:remove')} arrow disableInteractive>
                        <IconButton onClick={() => handleRemovePortfolioModalOpen(portfolio.portfolio_ref)}>
                          <RemoveCircleOutline />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ my: 3 }}>
          <TableContainerHeader
            title={t('groups.group_memberships')}
            action={
              <IconButton onClick={addGroupModal.open}>
                <AddIcon />
              </IconButton>
            }
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('groups.group_name')}</TableCell>
                <TableCell>{t('groups.role')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {currentGroups.map((group) => {
                return (
                  <TableRow hover key={group.groupRef}>
                    <TableCell>
                      <Chip avatar={<Avatar>{group.groupName[0]}</Avatar>} label={group.groupName} />
                    </TableCell>
                    <TableCell>{group.isUserAdmin ? t('groups.admin') : t('groups.member')}</TableCell>
                    <TableCell className="action-cell" align="right">
                      <Stack direction="row" gap={1} justifyContent="flex-end">
                        <Tooltip title={t('edit')} arrow disableInteractive>
                          <IconButton
                            onClick={() => {
                              handleEditGroupModalOpen(group.groupRef, group.isUserAdmin)
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t('remove')} arrow disableInteractive>
                          <IconButton onClick={() => handleRemoveGroupModalOpen(group.groupRef)}>
                            <RemoveCircleOutline />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <TableContainerHeader
            title={t('datasets.title')}
            action={
              <IconButton onClick={addDatasetModal.open}>
                <AddIcon />
              </IconButton>
            }
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('datasets.dataset')}</TableCell>
                <TableCell>{t('datasets.owner')}</TableCell>
                <TableCell>{t('datasets.authorised_until')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {currentDatasets.map((dataset) => {
                return (
                  <TableRow hover key={dataset.dataset_ref} sx={{ cursor: 'pointer' }}>
                    <TableCell>{dataset.dataset_name}</TableCell>
                    <TableCell>
                      {dataset.owner_name === null ? (
                        <Chip avatar={<Avatar>{userDetails.firstname[0]}</Avatar>} label={fullName} />
                      ) : (
                        dataset.owner_name
                      )}
                    </TableCell>
                    <TableCell>{dataset.expiry || '-'}</TableCell>
                    <TableCell className="action-cell" align="right">
                      <Stack direction="row" gap={1} justifyContent="flex-end">
                        <Tooltip title={t('edit')} arrow disableInteractive>
                          <IconButton onClick={() => handleEditDatasetModalOpen(dataset)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={t(dataset.owner_name === null ? 'datasets.cant_remove_access' : 'remove')}
                          arrow
                          disableInteractive
                        >
                          <span>
                            <IconButton
                              disabled={dataset.owner_name === null}
                              onClick={() => handleRemoveDatasetModalOpen(dataset)}
                            >
                              <RemoveCircleOutline />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      <EditUserModal user={userDetails} open={editUserModal.isOpen} onClose={editUserModal.close} />

      <ConfirmationModal
        error={deleteUserError}
        title={t('settings:remove_user')}
        confirmButtonText={t('common:remove')}
        open={removeUserModal.isOpen}
        onCloseButtonClick={handleRemoveUserModalClose}
        onConfirmButtonClick={handleRemoveUser}
      >
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey={'common:are_you_sure_to_remove'}
            values={{ subject: fullName }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </ConfirmationModal>

      <ConfirmationModal
        error={removePermissionOrChangeTenantAdminStatusErrorMessage}
        title={t('settings:permission.remove_permission')}
        confirmButtonText={t('common:remove')}
        open={removePermissionModal.isOpen}
        onCloseButtonClick={handlePermissionModalClose}
        onConfirmButtonClick={handleRemovePermission}
      >
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey="settings:permission.are_you_sure_to_remove_permission"
            values={{ subject: selectedPermission?.name, target: fullName }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </ConfirmationModal>

      <ConfirmationModal
        error={removePortfolioError}
        title={t('portfolio.remove_portfolio')}
        confirmButtonText={t('common:remove')}
        open={removePortfolioModal.isOpen}
        onCloseButtonClick={handlePortfolioRemoveClose}
        onConfirmButtonClick={handleRemovePortfolio}
      >
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey="portfolio.are_you_sure_to_remove_portfolio"
            values={{ target: selectedPortfolio?.portfolio_name }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </ConfirmationModal>

      <ConfirmationModal
        error={removeUserFromGroupError}
        title={t('groups.remove_group_membership')}
        confirmButtonText={t('common:remove')}
        open={removeGroupModal.isOpen}
        onCloseButtonClick={handleRemoveGroupModalClose}
        onConfirmButtonClick={handleRemoveGroup}
      >
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey="groups.are_you_sure_to_remove_group"
            values={{
              subject: fullName,
              target: selectedGroupName,
            }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </ConfirmationModal>

      <ConfirmationModal
        error={removeDatasetError}
        title={t('datasets.remove_dataset')}
        confirmButtonText={t('common:remove')}
        open={removeDatasetModal.isOpen}
        onCloseButtonClick={handleRemoveDatasetModalClose}
        onConfirmButtonClick={handleRemoveDataset}
      >
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey="datasets.are_you_sure_to_remove_dataset"
            values={{ subject: `${selectedDataset?.dataset_name}` }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </ConfirmationModal>

      <AddPermissionModal
        groupRef={personalGroupRef!}
        userRef={userRef!}
        isTenantAdmin={userDetails.is_tenant_admin}
        adminPermission={adminPermission}
        open={addPermissionModal.isOpen}
        onClose={addPermissionModal.close}
      />

      <AddPortfolioModal
        groupRef={personalGroupRef!}
        open={addPortfolioModal.isOpen}
        onClose={addPortfolioModal.close}
      />

      <AddGroupModal
        open={addGroupModal.isOpen}
        userRef={userDetails.user_ref}
        roles={groupRoles}
        onClose={addGroupModal.close}
      />

      <EditGroupModal
        open={editGroupModal.isOpen}
        groupRef={selectedGroupRef}
        groupName={selectedGroupName}
        userRole={userRole}
        userRef={userDetails.user_ref}
        onClose={editGroupModal.close}
        roles={groupRoles}
      />

      <AddDatasetModal groupRef={personalGroupRef!} open={addDatasetModal.isOpen} onClose={addDatasetModal.close} />

      {selectedDataset && (
        <EditDatasetModal
          open={editDatasetModal.isOpen}
          groupRef={personalGroupRef!}
          dataset={selectedDataset}
          onClose={() => {
            setSelectedDataset(undefined)
            editDatasetModal.close()
          }}
        />
      )}
    </>
  )
}

export default UserDetails
