import { Chip, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import { currencies } from '../../../services/data/types/portfolio'
import usePortfolioOptionsQuery from '../data/use-portfolio-options-query'

type PortfolioFormProps = {
  values: PortfolioFormValues
  errorMessage: string | null
  onChange: (name: string, value: string) => void
}

type PortfolioFormValues = {
  portfolioName: string
  ledgerTag: string
  legalEntity: string
  baseCurrency: string
  chartOfAccounts: string
}

function PortfolioForm(props: PortfolioFormProps) {
  const { errorMessage, values, onChange } = props
  const { t } = useTranslation('settings')

  const portfolioOptionsResponse = usePortfolioOptionsQuery()
  const portfolioOptions = portfolioOptionsResponse.data?.data
  const isLoading = portfolioOptionsResponse.isLoading

  const legalEntities = portfolioOptions?.legal_entities
  const chartOfAccounts = portfolioOptions?.nominal_charts

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target
    onChange(name, value)
  }

  function handleSelectChange(event: SelectChangeEvent) {
    const { name, value } = event.target
    onChange(name, value)
  }

  if (isLoading) {
    return null
  }

  return (
    <>
      {errorMessage && <Alert severity="error" message={t(errorMessage)} />}
      <Stack direction="row" gap={2}>
        <TextField
          required
          name="portfolioName"
          label={t('portfolio_form.portfolio_name')}
          value={values.portfolioName}
          onChange={handleChange}
          sx={{ flex: 1 }}
        />
        <TextField
          required
          name="ledgerTag"
          label={t('portfolio_form.ledger_tag')}
          value={values.ledgerTag}
          onChange={handleChange}
          sx={{ flex: 1 }}
        />
      </Stack>
      <Stack direction="row" gap={2}>
        <FormControl sx={{ width: '66%' }}>
          <InputLabel required>{t('portfolio_form.legal_entity')}</InputLabel>
          <Select
            required
            name="legalEntity"
            label={t('portfolio_form.legal_entity')}
            value={values.legalEntity}
            onChange={handleSelectChange}
          >
            {legalEntities?.map((legalEntity) => (
              <MenuItem key={legalEntity.lei} value={legalEntity.lei}>
                {legalEntity.legal_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: '33%' }}>
          <InputLabel required>{t('portfolio_form.base_currency')}</InputLabel>
          <Select
            required
            name="baseCurrency"
            label={t('portfolio_form.base_currency')}
            value={values.baseCurrency}
            onChange={handleSelectChange}
          >
            {currencies.map((currency) => {
              return (
                <MenuItem key={currency} value={currency}>
                  <Stack direction="row" sx={{ height: '23px' }}>
                    <Chip key={currency} label={currency} size="small" />
                  </Stack>
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Stack>
      <FormControl>
        <InputLabel required>{t('portfolio_form.chart_of_accounts')}</InputLabel>
        <Select
          required
          name="chartOfAccounts"
          label={t('portfolio_form.chart_of_accounts')}
          value={values.chartOfAccounts}
          onChange={handleSelectChange}
        >
          {chartOfAccounts?.map((chartOfAccount) => (
            <MenuItem key={chartOfAccount.chart_ref} value={chartOfAccount.chart_ref}>
              {chartOfAccount.chart_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default PortfolioForm
