import { Slide, Snackbar, SnackbarCloseReason } from '@mui/material'
import { SyntheticEvent } from 'react'
import Alert from './alert'

type ErrorSnackbarProps = {
  open: boolean
  message: string | null
  onClose?: (event?: SyntheticEvent | Event, reason?: SnackbarCloseReason) => void
}

function ErrorSnackbar(props: ErrorSnackbarProps) {
  const { open, message, onClose } = props

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={onClose}
      TransitionComponent={(tProps) => <Slide {...tProps} direction="up" />}
      ClickAwayListenerProps={{ mouseEvent: false }}
    >
      <div>
        <Alert severity="error" message={message} onClose={onClose} />
      </div>
    </Snackbar>
  )
}

export default ErrorSnackbar
