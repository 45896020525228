import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useCurrentPortfoliosQuery(groupRef?: string) {
  return useQuery(
    ['portfolio', 'group', groupRef, 'portfolios', 'current'],
    () => {
      if (groupRef) {
        return api.getCurrentPortfolios(groupRef)
      }
    },
    {
      enabled: !!groupRef,
    }
  )
}

export default useCurrentPortfoliosQuery
