import { Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import NoMoreOptionsAlert from '../../../components/no-more-options-alert'
import { queryClient } from '../../../services/data/react-query'
import useAddPermissionMutation from '../data/use-add-permission-mutation'
import useAvailablePermissionsQuery from '../data/use-available-permissions-query'

type AddPermissionToGroupModalProps = {
  groupRef: string
  isOpen: boolean
  onClose: () => void
}

function AddPermissionToGroupModal(props: AddPermissionToGroupModalProps) {
  const { groupRef, isOpen, onClose } = props

  const { t } = useTranslation('settings')

  const { mutate: addPermission, error: errorMessage, reset: resetAddPermission } = useAddPermissionMutation()

  const [selectedPermission, setSelectedPermission] = useState('')

  const availablePermissionsResponse = useAvailablePermissionsQuery(groupRef)
  const availablePermissions = availablePermissionsResponse.data?.data || []

  const hasOptions = availablePermissions.length > 0

  function handleSelectChange(event: SelectChangeEvent) {
    setSelectedPermission(event.target.value)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    if (groupRef && selectedPermission) {
      const addPermissionParams = {
        groupRef,
        permissionTag: selectedPermission,
      }
      addPermission(addPermissionParams, {
        onSuccess: () => {
          queryClient.invalidateQueries(['permissions', 'current', groupRef])
          queryClient.invalidateQueries(['group', groupRef, 'permissions', 'available'])
          handleClose()
        },
      })
    }
  }

  function handleClose() {
    setSelectedPermission('')
    resetAddPermission()
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('group_tables.add_group_permission')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={errorMessage} />
          {!hasOptions && <NoMoreOptionsAlert />}
          <FormControl disabled={!hasOptions}>
            <InputLabel required>{t('group_tables.permission')}</InputLabel>
            <Select
              required
              displayEmpty
              label={t('group_tables.permission')}
              value={selectedPermission}
              onChange={handleSelectChange}
            >
              {availablePermissions.map((permission) => (
                <MenuItem key={permission.name} value={permission.permission_tag}>
                  {permission.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('common:add')} confirmDisabled={!hasOptions} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default AddPermissionToGroupModal
