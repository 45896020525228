import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useDeleteClassificationValueMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, MutationFnParams>({
    mutationFn: ({ classificationId, classificationValueId }) => {
      return api.deleteClassificationValue(classificationId, classificationValueId)
    },
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type MutationFnParams = {
  classificationId: string | number
  classificationValueId: string | number
}

export default useDeleteClassificationValueMutation
