import { Box, Stack } from '@mui/material'
import OpenFin from '@openfin/core'
import { useEffect, useState } from 'react'
import NavBar, { NavItemName } from '../../components/nav-bar'
import { useAuth } from '../../modules/auth/data/auth-context'
import { ChannelAction, ChannelEvent, connectAuthChannel, createAuthChannel, openView, platformLayoutInit } from './fin'
import './frame.css'

const CONTAINER_ID = 'cwb-layout-container'

const viewPaths: { [key in NavItemName]: string } = {
  [NavItemName.PortfolioGrid]: 'openfin/views/portfolios/grid',
  [NavItemName.Transactions]: 'openfin/views/transactions',
  [NavItemName.DataEngine]: 'openfin/views/data-engine',
  [NavItemName.OrderBlotter]: 'openfin/views/order-blotter',
  [NavItemName.Compliance]: 'openfin/views/compliance',
  [NavItemName.Liquidity]: 'openfin/views/liquidity',
  [NavItemName.BankAccounts]: 'openfin/views/bank-accounts',
  [NavItemName.Account]: 'openfin/views/account',
  [NavItemName.Settings]: 'openfin/views/settings',
}

export default function OpenFinFrame() {
  const { logout, refreshUser } = useAuth()

  const [authChannelClient, setAuthChannelClient] = useState<OpenFin.ChannelClient>()

  useEffect(() => {
    platformLayoutInit(CONTAINER_ID)

    initAuthenticationChannel().finally(async () => {
      const client = await connectAuthChannel()
      client.register(ChannelEvent.loggedIn, refreshUser)
      client.register(ChannelEvent.loggedOut, refreshUser)

      setAuthChannelClient(client)
    })
  }, [])

  async function handleLogoutClick() {
    await logout()
    authChannelClient?.dispatch(ChannelAction.logout)
  }

  function handleNavigate(view: NavItemName) {
    openView({ viewPath: viewPaths[view] })
  }

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: 'background.default',
        flexDirection: 'row',
      }}
    >
      <div id="cwb-nav-bar">
        <NavBar onNavigate={handleNavigate} onLogoutClick={handleLogoutClick} />
      </div>
      <Stack id="cwb-view-container">
        <Box id={CONTAINER_ID} />
      </Stack>
    </Stack>
  )
}

async function initAuthenticationChannel() {
  const provider = await createAuthChannel()

  provider.register(ChannelAction.login, () => {
    return Promise.all(provider.publish(ChannelEvent.loggedIn, null))
  })

  provider.register(ChannelAction.logout, () => {
    return Promise.all(provider.publish(ChannelEvent.loggedOut, null))
  })

  provider.register(ChannelAction.switchTenant, () => {
    return Promise.all(provider.publish(ChannelEvent.tenantSwitched, null))
  })
}
