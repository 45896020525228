import { Box } from '@mui/system'

type ProfileOperatorProps = {
  operator?: string
}

export function ProfileOperator(props: ProfileOperatorProps) {
  return (
    <Box
      className="math-operator"
      component="span"
      sx={{
        flex: 0,
        flexBasis: 8,
        alignSelf: 'center',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        color: 'purple.400',
        mx: 1,
      }}
    >
      {props.operator || ''}
    </Box>
  )
}
