import { Edit } from '@mui/icons-material'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { ColDef, GetRowIdParams, ICellRendererParams, IHeaderParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { MouseEvent, RefObject, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import DatapointContent from '../../../components/data-table/datapoint-content'
import { StatusBar } from '../../../components/data-table/status-bar'
import { extractDatapointFormatAndValue, formatCellToCopy } from '../../../services/data/datapoint-formatting'
import { DataDocType } from '../../../services/data/types/asset-static-data'
import { DatapointValue } from '../../../services/data/types/datapoint'
import { ParsedAssetGridCell, ParsedAssetGridData, ParsedAssetGridHeading } from '../data/asset-static-parsing'
import DatapointExplainer from './datapoint-explainer'

type AssetStaticGridProps = {
  data: ParsedAssetGridData | null
  onEditAssetStatic: (cellKey: string) => void
  onContextMenuOpen: (event: MouseEvent, data: ParsedAssetGridCell) => void
  onForwardGridRef: (ref: RefObject<AgGridReact>) => void
  onMoveColumn: (fromDatapointRef: string, toDatapointRef: string) => void
  onHideColumn: (datapointRef: string) => void
  onSetColumnWidth?: (datapointRef: string, width: number) => void
}

export type AgCellData = {
  rowKey: string
  cells: {
    [headingKey: string]: ParsedAssetGridCell
  }
}

type AgCellValue = AgCellData['cells'][string]

type AgHeaderComponentParams = {
  sourceLabel: string
  heading: ParsedAssetGridHeading
  onMoveColumn: (fromDatapointRef: string, toDatapointRef: string) => void
  onHideColumn: (datapointRef: string) => void
  onSetColumnWidth?: (datapointRef: string, width: number) => void
}

function AssetStaticGrid(props: AssetStaticGridProps) {
  const { data, onEditAssetStatic, onContextMenuOpen, onForwardGridRef, onHideColumn, onMoveColumn, onSetColumnWidth } =
    props
  const { t } = useTranslation('dataEngine')

  const gridRef = useRef<AgGridReact>(null)

  useEffect(() => onForwardGridRef(gridRef), [data])

  if (!data) {
    return null
  }

  const cols = data.headings.map((heading, index) => {
    const col: ColDef = {
      colId: heading.headingKey,
      field: `cells.${heading.headingKey}`,
      headerName: heading.title,
      lockPinned: index > 0,
      pinned: index === 0,
      initialWidth: index === 0 ? 300 : heading.width,
      headerComponentParams: {
        sourceLabel: t('source_label'),
        heading,
        onHideColumn,
        onMoveColumn,
        onSetColumnWidth,
      } satisfies AgHeaderComponentParams,
    }
    return col
  })

  const rows = data.rows.map((row) => {
    return row.cells.reduce<AgCellData>(
      (data, cell) => {
        data.cells[cell.headingKey] = cell
        return data
      },
      {
        rowKey: row.rowKey,
        cells: {},
      }
    )
  })

  return (
    <div style={{ height: '100%' }}>
      <AgGridReact
        ref={gridRef}
        className="ag-theme-alpine-dark"
        columnDefs={cols}
        rowData={rows}
        rowHeight={32}
        rowSelection="single"
        animateRows
        enableRangeSelection
        suppressRowHoverHighlight
        processCellForClipboard={(params) => {
          const value: ParsedAssetGridCell = params.value
          return formatCellToCopy(value)
        }}
        getContextMenuItems={() => {
          // disable context menu by giving it no items,
          // as setting suppressContextMenu prevents row selection on right click
          return []
        }}
        onCellContextMenu={(event) => {
          // select row on right click
          event.node.setSelected(true)
        }}
        getRowId={(params: GetRowIdParams<AgCellData>) => {
          return params.data.rowKey
        }}
        components={{
          agColumnHeader: AgColumnHeader,
        }}
        statusBar={{
          statusPanels: [{ statusPanel: StatusBar }],
        }}
        onColumnResized={(event) => {
          if (onSetColumnWidth && event.finished && event.column && event.source === 'uiColumnResized') {
            const colDef = event.column.getColDef()
            const headerComponentParams = colDef.headerComponentParams as AgHeaderComponentParams
            if (headerComponentParams.heading.meta) {
              onSetColumnWidth(headerComponentParams.heading.meta.datapoint_ref, event.column.getActualWidth())
            }
          }
        }}
        defaultColDef={{
          resizable: true,
          suppressMovable: true,
          suppressMenu: true,
          valueParser: () => {
            // used when editing cell, but we don't need it as we have our own component
            // but there was a warning on the console when not provided
            // https://www.ag-grid.com/react-data-grid/value-parsers/#value-parser
          },
          cellRenderer: (props: ICellRendererParams<AgCellData, AgCellValue>) => {
            const data = props.data
            const value = props.value

            if (!value || !data) {
              return <div style={{ height: '100%' }} />
            }

            return (
              <div
                style={{ height: '100%' }}
                onContextMenu={(event) => {
                  if (data) {
                    onContextMenuOpen(event, value)
                  }
                }}
              >
                <DatapointCell
                  key={data.rowKey}
                  value={value.value}
                  displayAs={value.displayAs}
                  asOnDate={value.asOnDate}
                  shadowedValue={value.shadowedValue}
                  shadowedDisplayAs={value.shadowedDisplayAs}
                  modifiedAt={value.modifiedAt}
                  modifiedBy={value.modifiedBy}
                  modifiedByDisplay={value.modifiedByDisplay}
                  datapointName={value.meta?.datapoint_name || ''}
                  datasetName={value.meta?.source_dataset_name || ''}
                  canEdit={value.canEdit}
                  datadocType={value.datadocType}
                  onEdit={() => onEditAssetStatic(value.cellKey)}
                />
              </div>
            )
          },
        }}
      />
    </div>
  )
}

export default AssetStaticGrid

function AgColumnHeader(params: IHeaderParams) {
  const colDef = params.column.getUserProvidedColDef()
  const headerParams: AgHeaderComponentParams = colDef?.headerComponentParams

  const datapointRef = headerParams.heading.meta?.datapoint_ref
  const canReorder = !!datapointRef

  if (!headerParams) {
    return null
  }

  return (
    <div
      draggable={canReorder}
      onDragStart={(event) => {
        if (!!datapointRef) {
          event.dataTransfer.setData('text/plain', datapointRef)

          setTimeout(() => {
            const target = event.target as any
            target.classList.add('dragging-me')
          }, 0)
        }
      }}
      onDragEnter={(event) => {
        event.preventDefault()
        event.currentTarget.classList.add('dragging-over')
      }}
      onDragOver={(event) => {
        event.preventDefault()
        event.currentTarget.classList.add('dragging-over')
      }}
      onDragLeave={(event) => {
        event.preventDefault()
        event.currentTarget.classList.remove('dragging-over')
      }}
      onDrop={(event) => {
        if (datapointRef) {
          event.preventDefault()
          const draggedDatapointRef = event.dataTransfer.getData('text/plain')
          event.currentTarget.classList.remove('dragging-over')

          headerParams.onMoveColumn(draggedDatapointRef, datapointRef)
        }
      }}
      onDragEnd={(event) => {
        const target = event.target as any
        target.classList.remove('dragging-me')
      }}
    >
      <Typography variant="inherit" fontSize="14px" fontWeight="500">
        {headerParams.heading.title}
      </Typography>
      {headerParams.heading.sourceName && (
        <div>
          <Typography display="inline" fontSize="10px" fontWeight="500">
            {headerParams.sourceLabel}
          </Typography>
          <Typography display="inline" fontSize="10px" color="gray.700">
            {headerParams.heading.sourceName}
          </Typography>
        </div>
      )}
    </div>
  )
}

type DatapointCellProps = {
  value: DatapointValue | undefined
  displayAs: string | undefined
  asOnDate: Date | undefined
  shadowedValue: DatapointValue | undefined
  shadowedDisplayAs: string | undefined
  modifiedAt: Date | undefined
  modifiedBy: string | undefined
  modifiedByDisplay: string | undefined
  datasetName: string
  datapointName: string
  datadocType: DataDocType | null | undefined
  canEdit: boolean
  hideAsOnDate?: boolean
  decimalScale?: number
  onEdit: () => void
}

export function DatapointCell(props: DatapointCellProps) {
  const [format, value] = extractDatapointFormatAndValue(props.value)
  const isDataDoc = format === 'DataDoc'

  return (
    <Box
      sx={{
        height: '100%',
        ':hover': {
          '.edit-datapoint-button': {
            opacity: 1,
          },
        },
      }}
    >
      {
        <Stack
          sx={{
            gap: 2,
            height: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: isDataDoc ? 'space-between' : null,
          }}
        >
          {props.canEdit && isDataDoc && <Box sx={{ width: 26 }} />}

          <DatapointContent
            format={format}
            value={value}
            displayAs={props.displayAs}
            datadocType={props.datadocType}
            decimalScale={props.decimalScale}
          />

          {props.canEdit && (
            <IconButton
              className="edit-datapoint-button"
              size="small"
              onClick={props.onEdit}
              sx={{
                marginLeft: isDataDoc ? null : 'auto',
                opacity: 0,
                ':focus': {
                  opacity: 1,
                },
              }}
            >
              <Edit sx={{ color: 'gray.300', fontSize: 16 }} />
            </IconButton>
          )}

          <DatapointExplainer
            datapointName={props.datapointName}
            datasetName={props.datasetName}
            value={props.value}
            displayAs={props.displayAs}
            asOnDate={props.asOnDate}
            shadowedValue={props.shadowedValue}
            shadowedDisplayAs={props.shadowedDisplayAs}
            modifiedAt={props.modifiedAt}
            modifiedBy={props.modifiedBy}
            modifiedByDisplay={props.modifiedByDisplay}
            hideAsOnDate={props.hideAsOnDate}
          />
        </Stack>
      }
    </Box>
  )
}
