import { DatapointType, DatapointValue } from './datapoint'
import { GridDataDataset } from './grid-data'
import { GridDataView } from './grid-data-view'

const assetTypes = [
  'currency',
  'margin',
  'bond',
  'convbond',
  'repo',
  'cds',
  'irs',
  'trs',
  'tbill',
  'loan_note',
  'equity',
  'fx_forward',
  'ndf',
  'future',
  'generic',
  'ticker_currency',
  'ticker_fxfwd',
  'ticker_ndf',
  'ticker_index',
  'ticker_refrate',
  'currency_pair',
  'regular_curve',
] as const

export type AssetType = (typeof assetTypes)[number]

const cashSecurities: AssetType[] = ['bond', 'convbond', 'tbill', 'equity', 'loan_note', 'margin', 'generic']

export function isCashSecurity(assetType: AssetType): boolean {
  return cashSecurities.includes(assetType)
}

export type AssetCategory = 'security' | 'currency' | 'index' | 'ref_rate' | 'curve'

export type DataDocType = 'date_schedule' | 'tenor_curve' | 'curve_definition' | 'subsequent_interest_periods'

export type AssetStaticOptions = {
  default_view_ref: string | null
  asset_types: {
    tag: string
    name: string
  }[]
  datasets: GridDataDataset[]
  views: GridDataView[]
}

export type AssetStaticData = {
  dataset: string
  headings: AssetStaticHeading[]
  rows: AssetStaticRow[]
}

export type AssetStaticHeading = {
  datapoint_ref: string
  datapoint_name: string
  datapoint_type: DatapointType
  asset_category?: AssetCategory
  datadoc_type: DataDocType | null
  classification_id?: number
  source_dataset_ref: string
  source_dataset_name: string
  can_edit: boolean
  can_autogenerate?: true
  position: 'maingrid' | 'panel' | 'change'
}

export type AssetStaticRow = {
  asset_ref: string
  asset_desc: string
  datapoints: {
    [datapoint_ref: string]: {
      value: DatapointValue
      display_as?: string
      as_on_date?: Date
      shadowed_value?: DatapointValue
      shadowed_display_as?: string
      modified_at: Date
      modified_by: string
      modified_by_display: string
    }
  }
}

export type StaticUploadOptions = {
  identifier_types: {
    [key: string]: string
  }
  static_fields: {
    datapoint_ref: string
    datapoint_name: string
    datapoint_type: DatapointType
    active_alias?: string
  }[]
}

export type StaticUploadInspection = {
  headings: (string | null)[]
  rows: (string | null)[][]
}

export type StaticUploadParams = {
  datapoints: string[]
  useDate: boolean
  datasetRef?: string
}

export type GenerateDatatocPayload = {
  asset_ref: string
  datapoint_ref: string
}

export type GenerateDatatocResponse = {
  asset_ref: string
  datapoint_ref: string
  value: DatapointValue
}

export type SymbologyDisplayHeader = {
  asset_ref: string
  asset_type: AssetType
  display_as: string
}
