import { Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import useCreateExistingDatapointMutation from '../data/use-create-existing-datapoint-mutation'
import useDatasetsWithDatapointsQuery from '../data/use-datasets-with-datapoints-query'

type AddExistingDatapointModalProps = {
  open: boolean
  currentDatasetRef: string
  onClose: () => void
}

const initialFormValues = {
  dataset: '',
  datapoint: '',
}

function AddExistingDatapointModal(props: AddExistingDatapointModalProps) {
  const { currentDatasetRef, open, onClose } = props
  const { t } = useTranslation('dataEngine')

  const datasetsWithDatapointsResponse = useDatasetsWithDatapointsQuery()

  const {
    mutate: createExistingDatapoint,
    error: createExistingDatapointError,
    reset: createExistingDatapointReset,
  } = useCreateExistingDatapointMutation()

  const [formValues, setFormValues] = useState(initialFormValues)

  const datasetsWithDatapoints = datasetsWithDatapointsResponse.data?.data || []
  const filteredDatasets = datasetsWithDatapoints.filter((dataset) => dataset.dataset_ref !== currentDatasetRef)
  const selectedDataset = datasetsWithDatapoints.find((dataset) => dataset.dataset_ref === formValues.dataset)
  const datapoints = selectedDataset?.datapoints || []

  function handleSelectChange(event: SelectChangeEvent) {
    const { name, value } = event.target
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    const params = {
      datasetRef: currentDatasetRef,
      datapointRef: formValues.datapoint,
    }

    createExistingDatapoint(params, {
      onSuccess: () => {
        queryClient.invalidateQueries(['dataset', currentDatasetRef, 'datapoints'])
        handleClose()
      },
    })
  }

  function handleClose() {
    createExistingDatapointReset()
    onClose()
    setFormValues(initialFormValues)
  }

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('datapoint_table.add_existing_datapoint')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={createExistingDatapointError} />
          <FormControl>
            <InputLabel required>{t('dataset')}</InputLabel>
            <Select
              required
              name="dataset"
              label={t('dataset')}
              value={formValues.dataset}
              onChange={handleSelectChange}
              MenuProps={{
                sx: { maxHeight: 300 },
              }}
            >
              {filteredDatasets.map((dataset) => (
                <MenuItem key={dataset.dataset_ref} value={dataset.dataset_ref}>
                  {dataset.dataset_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel required>{t('datapoint')}</InputLabel>
            <Select
              required
              name="datapoint"
              label={t('datapoint')}
              value={formValues.datapoint}
              onChange={handleSelectChange}
              MenuProps={{
                sx: { maxHeight: 300 },
              }}
            >
              {!datapoints.length && <MenuItem disabled>{t('datapoint_table.select_dataset_first')}</MenuItem>}
              {datapoints.map((datapoint) => (
                <MenuItem key={datapoint.datapoint_ref} value={datapoint.datapoint_ref}>
                  {datapoint.datapoint_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('datapoint_table.add_datapoint')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default AddExistingDatapointModal
