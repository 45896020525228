import { Box, Stack } from '@mui/system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../../components/fields/numeric-field'
import { TradeDirectionField } from '../../../../components/fields/trade-direction-field'
import { Leg } from '../../../../services/data/types/trade-ticket'
import { defaultScale, priceScale } from '../../../../utils/math'
import { CurrencyAdorment, PercentAdorment } from './shared/fields/adorments'
import BooleanSelector from './shared/fields/boolean-selector'
import DateSelector from './shared/fields/date-selector'
import { ProfileOperator } from './shared/profile-operator'
import { ProfileRow } from './shared/profile-row'

type CloseTrsProfileProps = {
  leg: Leg
  currencySymbol: string
  onUpdateLeg: (leg: Leg) => void
  onVerifyCloseOut: () => void
}

export function CloseTrsProfile(props: CloseTrsProfileProps) {
  const { leg, currencySymbol, onUpdateLeg, onVerifyCloseOut } = props

  const { t } = useTranslation('tradeTicket')

  useEffect(() => {
    verifyCloseOut()
  }, [leg.actualSettlementDate?.getTime()])

  function verifyCloseOut() {
    if (!leg.actualSettlementDate || !leg.settlementPrice || !leg.settlementAmount) {
      return
    }
    onVerifyCloseOut()
  }

  return (
    <Stack gap={3}>
      <ProfileRow>
        <TradeDirectionField value={leg.tradeDirection} disabled sx={{ maxWidth: '150px', mr: 2 }} />

        <ProfileOperator operator="(" />
        <NumericField
          disabled
          fixedLabel
          name="notional"
          label={t('common:notional')}
          value={leg.notional}
          decimalScale={defaultScale}
        />
        <ProfileOperator operator="x" />
        <NumericField
          disabled
          fixedLabel
          name="sinkFactor"
          label={t('common:sink_factor')}
          value={leg.sinkFactor}
          decimalScale={priceScale}
          endAdornment={<PercentAdorment />}
        />
        <ProfileOperator operator="@" />
        <NumericField
          disabled
          fixedLabel
          name="clean_price"
          label={t('common:clean_price')}
          value={leg.price}
          decimalScale={priceScale}
          endAdornment={<PercentAdorment />}
        />
        <ProfileOperator operator=") +" />
        <NumericField
          disabled
          fixedLabel
          name="accruedInterest"
          label={t('common:accrued_interest')}
          value={leg.accruedInterest}
          decimalScale={defaultScale}
        />
      </ProfileRow>

      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <ProfileOperator operator="x" />
        <NumericField
          disabled
          fixedLabel
          name="haircut"
          label={t('common:haircut')}
          value={leg.haircut}
          decimalScale={priceScale}
          endAdornment={<PercentAdorment />}
        />
        <ProfileOperator operator="=" />
        <NumericField
          disabled
          fixedLabel
          name="funding_amount_lc"
          label={t('common:funding_amount_lc')}
          value={leg.fundingAmountLc}
          decimalScale={defaultScale}
        />
      </ProfileRow>

      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <ProfileOperator operator="/" />
        <NumericField
          disabled
          fixedLabel
          name="inception_fx"
          label={t('common:inception_fx')}
          value={leg.inceptionFx}
          decimalScale={priceScale}
        />
        <ProfileOperator operator="=" />
        <NumericField
          disabled
          fixedLabel
          name="trs_funding_amount"
          label={t('common:trs_funding_amount')}
          value={leg.trsFundingAmount}
          decimalScale={defaultScale}
          endAdornment={<CurrencyAdorment symbol={currencySymbol} />}
        />
      </ProfileRow>

      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <ProfileOperator />
        <DateSelector disabled label={t('common:settlement_date')} value={leg.settlementDate} />
        <ProfileOperator />
        <BooleanSelector disabled label={t('common:is_fully_funded')} value={leg.isFullyFunded} />
      </ProfileRow>
      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <ProfileOperator />
        <DateSelector
          required
          label={t('common:actual_settlement_date')}
          value={leg.actualSettlementDate}
          onChange={(actualSettlementDate) => onUpdateLeg({ ...leg, actualSettlementDate })}
          onBlur={verifyCloseOut}
          onEnterDown={verifyCloseOut}
        />
        <ProfileOperator />
        <NumericField
          required
          fixedLabel
          name="settlement_price"
          label={t('common:settlement_price')}
          value={leg.settlementPrice}
          decimalScale={defaultScale}
          onValueChange={(settlementPrice) => onUpdateLeg({ ...leg, settlementPrice })}
          onBlur={verifyCloseOut}
          onEnterDown={verifyCloseOut}
        />
        <ProfileOperator />
        <NumericField
          required
          fixedLabel
          name="settlement_amount"
          label={t('common:settlement_amount')}
          value={leg.settlementAmount}
          decimalScale={defaultScale}
          onValueChange={(settlementAmount) => onUpdateLeg({ ...leg, settlementAmount })}
          onBlur={verifyCloseOut}
          onEnterDown={verifyCloseOut}
        />
      </ProfileRow>
    </Stack>
  )
}
