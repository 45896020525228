import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useAssetStaticGridOptionsQuery(assetType: string | null) {
  return useQuery(['asset_type', assetType, 'options'], () => {
    if (!assetType) {
      return null
    }

    return api.getAssetStaticOptions(assetType)
  })
}

export default useAssetStaticGridOptionsQuery
