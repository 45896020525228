import OpenFin from '@openfin/core'
import config from '../../config'

const fin: any = (window as any).fin
type CreateViewProps = { viewPath: string }

export function close() {
  fin.me.close().catch(console.error)
}

export function minimize() {
  fin.me.minimize().catch(console.error)
}

export async function maximizeOrRestore() {
  const windowState = await fin.me.getState()

  if (windowState === 'normal') {
    await fin.me.maximize()
  } else {
    fin.me.restore()
  }
}

export async function openView({ viewPath }: CreateViewProps) {
  let windowIdentity

  if (fin.me.isWindow) {
    windowIdentity = fin.me.identity
  } else {
    const currentWindow = await fin.me.getCurrentWindow()
    windowIdentity = currentWindow.identity
  }

  const platform = fin.Platform.getCurrentSync()

  platform.createView({ url: `${location.origin}${config.baseUrl}${viewPath}` }, windowIdentity)
}

export function createWindow(options: OpenFin.WindowCreationOptions) {
  return fin.Window.create(options)
}

export function getCurrentWindow(): Promise<OpenFin.Window> {
  return fin.Window.getCurrent()
}

export function platformLayoutInit(containerId: string) {
  fin.Platform.Layout.init({ containerId })
}

export enum ChannelName {
  authentication = 'authentication-channel',
}

export enum ChannelAction {
  login = 'login',
  logout = 'logout',
  switchTenant = 'switchTenant',
}

export enum ChannelEvent {
  loggedIn = 'loggedIn',
  loggedOut = 'loggedOut',
  tenantSwitched = 'tenantSwitched',
}

export function createAuthChannel(): Promise<OpenFin.ChannelProvider> {
  return fin.InterApplicationBus.Channel.create(ChannelName.authentication)
}

export function connectAuthChannel(): Promise<OpenFin.ChannelClient> {
  return fin.InterApplicationBus.Channel.connect(ChannelName.authentication)
}

export function createLoginWindow() {
  const winOption = {
    name: 'login',
    url: `${location.origin}${config.baseUrl}openfin/views/login`,
    frame: true,
    autoShow: true,
    minWidth: 800,
    minHeight: 900,
  }

  return createWindow(winOption)
}

export async function getLoginWindow(): Promise<OpenFin.Window> {
  const platform = await fin.Platform.getCurrent()
  return fin.Window.wrap({ name: 'login', uuid: platform.identity.uuid })
}
