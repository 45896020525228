import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'
import { GridDataViewFilters } from '../../../services/data/types/grid-data-view'

function useLiquidityMonitorQuery(
  assetRef: string | undefined,
  assetType: string | undefined,
  filters: GridDataViewFilters | null
) {
  return useQuery({
    queryKey: ['liquidity', 'monitor', assetRef, assetType, filters],
    queryFn: () => {
      if (!assetRef && !assetType) {
        return
      }

      return api.getLiquidityMonitor({
        asset_ref: assetRef,
        asset_type: assetType,
        filters: filters || {},
      })
    },
    enabled: !!assetRef || !!assetType,
    cacheTime: 0,
    keepPreviousData: true,
  })
}

export default useLiquidityMonitorQuery
