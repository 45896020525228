import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

export function useOrderQuery(orderRef: string) {
  return useQuery(
    ['order', orderRef],
    () => {
      if (!orderRef) {
        return null
      }
      return api.getOrder(orderRef)
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
    }
  )
}
