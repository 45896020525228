import { Stack } from '@mui/system'
import { IStatusPanelParams } from 'ag-grid-community'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AgCellData as DataEngineAgCellData } from '../../modules/data-engine/components/asset-static-grid'
import { DatapointValue } from '../../services/data/types/datapoint'
import { formatFloat } from '../../utils/numbers'
import { AgCellData as DataTableAgCellData } from './data-table'

export function StatusBar(props: IStatusPanelParams) {
  const [stats, setStats] = useState({ count: 0, sum: 0, sumCount: 0 })

  const { t } = useTranslation()

  useEffect(() => {
    function update() {
      const ranges = props.api.getCellRanges()

      let count = 0
      let sum = 0
      let sumCount = 0

      ranges?.forEach((range) => {
        let startRowIdx = range.startRow?.rowIndex
        let endRowIdx = range.endRow?.rowIndex

        const firstColumn: any = range.columns[0]
        const rowData: (DataTableAgCellData | DataEngineAgCellData | undefined)[] =
          firstColumn?.gridOptionsService.gridOptions.rowData

        if (startRowIdx === undefined || endRowIdx === undefined || !rowData) {
          return
        }

        if (startRowIdx > endRowIdx) {
          ;[startRowIdx, endRowIdx] = [endRowIdx, startRowIdx]
        }

        for (let i = startRowIdx; i <= endRowIdx; i++) {
          const row = rowData[i]

          if (row) {
            range.columns.forEach((column) => {
              let value: DatapointValue | undefined
              const colId = column.getColId()

              if ('datapoints' in row) {
                value = row.datapoints[colId]?.cell.value
              } else if ('cells' in row) {
                value = row.cells[colId]?.value
              }

              if (typeof value === 'object') {
                if (!('Error' in value)) {
                  // do not count empty or error cells
                  count++
                }

                if ('Float' in value) {
                  sumCount++
                  sum += value.Float
                } else if ('Int' in value) {
                  sumCount++
                  sum += value.Int
                } else if ('Percent' in value) {
                  sumCount++
                  sum += value.Percent * 100
                }
              }
            })
          }
        }
      })

      setStats({ count, sum, sumCount })
    }

    props.api.addEventListener('rangeSelectionChanged', update)

    return () => {
      props.api.removeEventListener('rangeSelectionChanged', update)
    }
  }, [])

  const hideStats = stats.count <= 1
  const showCount = stats.count !== 0
  const showAgg = stats.sumCount !== 0

  if (hideStats) {
    return <div />
  }

  return (
    <Stack direction="row" gap={2} sx={{ mb: '3px' }}>
      {showAgg && (
        <div>
          <span>{`${t('common:average')}: `}</span>
          <span>{formatFloat(stats.sum / stats.sumCount)}</span>
        </div>
      )}
      {showCount && (
        <div>
          <span>{`${t('common:count')}: `}</span>
          <span>{stats.count}</span>
        </div>
      )}
      {showAgg && (
        <div>
          <span>{`${t('common:sum')}: `}</span>
          <span>{formatFloat(stats.sum)}</span>
        </div>
      )}
    </Stack>
  )
}
