import { Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import { queryClient } from '../../../services/data/react-query'
import useDeleteClassificationMutation from '../data/use-delete-classification-mutation'

type DeleteClassificationModalProps = {
  open: boolean
  datasetRef: string
  classificationId?: string | number
  classificationName?: string
  onClose: () => void
}

function DeleteClassificationModal(props: DeleteClassificationModalProps) {
  const { open, datasetRef, classificationId, classificationName, onClose } = props
  const { t } = useTranslation('dataEngine')

  const {
    mutate: deleteClassification,
    error: deleteClassificationError,
    reset: deleteClassificationReset,
  } = useDeleteClassificationMutation()

  function handleDelete() {
    if (!classificationId) {
      return
    }

    deleteClassification(
      { classificationId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['dataset', datasetRef, 'classifications'])
          handleClose()
        },
      }
    )
  }

  function handleClose() {
    deleteClassificationReset()
    onClose()
  }

  return (
    <ConfirmationModal
      error={deleteClassificationError}
      open={open}
      title={t('classifications_table.delete')}
      confirmButtonText={t('common:delete')}
      onCloseButtonClick={handleClose}
      onConfirmButtonClick={handleDelete}
    >
      <Typography variant="body1">
        <Trans
          t={t}
          i18nKey={'classifications_table.delete_confirmation_text'}
          values={{ name: classificationName }}
          components={{ bold: <strong /> }}
        />
      </Typography>
    </ConfirmationModal>
  )
}

export default DeleteClassificationModal
