import { Checkbox, FormControlLabel } from '@mui/material'
import { Box } from '@mui/system'

type BooleanSelectorProps = {
  label: string
  value: boolean
  disabled?: boolean
  onChange?: (value: boolean) => void
}

function BooleanSelector(props: BooleanSelectorProps) {
  const { label, value, disabled, onChange } = props

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <FormControlLabel
        label={label}
        disabled={disabled}
        control={<Checkbox />}
        checked={value}
        onChange={(_, checked) => onChange?.(checked)}
      />
    </Box>
  )
}

export default BooleanSelector
