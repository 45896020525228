import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { ClassificationPayload } from '../../../services/data/types/classifications'

function useCreateClassificationMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, MutationFnParams>({
    mutationFn: ({ datasetRef, payload }) => api.createClassification(datasetRef, payload),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type MutationFnParams = {
  datasetRef: string
  payload: ClassificationPayload
}

export default useCreateClassificationMutation
