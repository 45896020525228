import { FocusEvent, FormEvent, useState } from 'react'
import { PercentAdorment } from '../../modules/trade-ticket/components/profiles/shared/fields/adorments'
import { DatapointType, DatapointValueType } from '../../services/data/types/datapoint'
import { defaultScale } from '../../utils/math'
import NumericField from '../fields/numeric-field'

type DatapointInputProps = {
  value: DatapointInputValue
  onChange: (value: DatapointInputValue) => void
}

export type DatapointInputValue = {
  datapointType: DatapointType
  value: DatapointValueType
  isDirty: boolean
}

export function DatapointInput(props: DatapointInputProps) {
  const { value, onChange } = props

  const isPercent = value.datapointType === 'Percent'

  const [cellValue, setCellValue] = useState(() => {
    if (typeof value.value === 'number') {
      const multiplier = isPercent ? 100 : 1
      const relativeValue = Number(value.value) * multiplier

      return { ...value, value: relativeValue }
    }

    return value
  })

  const isStringOrNumber = ['string', 'number'].includes(typeof cellValue.value)
  const formattedValue = String(isStringOrNumber ? cellValue.value : '')

  function handleValueChange(newValue: string) {
    setCellValue((prev) => ({ ...prev, value: newValue, isDirty: true }))
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    onChange(cellValue)
  }

  function handleFocus(event: FocusEvent<HTMLInputElement>) {
    event.target.select()
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingInline: '14px',
        border: '1px solid var(--af-cell-input-border-color)',
      }}
    >
      <NumericField
        value={formattedValue}
        decimalScale={defaultScale}
        autoFocus
        endAdornment={isPercent && <PercentAdorment />}
        TextFieldProps={{
          variant: 'standard',
          InputProps: {
            disableUnderline: true,
            sx: { fontSize: 14 },
          },
        }}
        onValueChange={handleValueChange}
        onBlur={handleSubmit}
        onFocus={handleFocus}
      />
    </form>
  )
}
