import { Box, Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import { Member } from '../../../services/data/types/group'
import useUpdateUserGroupStatusMutation from '../data/use-update-user-group-status-mutation'
import { GroupRole, GroupRoles } from './user-details'

type EditUserInGroupSectionModalProps = {
  isOpen: boolean
  groupRef: string
  member: Member | null
  onClose: () => void
  setSelectedMember: (member: Member | null) => void
}

function EditUserInGroupSectionModal(props: EditUserInGroupSectionModalProps) {
  const { isOpen, groupRef, member, onClose, setSelectedMember } = props

  const { t } = useTranslation('settings')

  const {
    mutate: editGroup,
    error: updateUserGroupStatusError,
    reset: updateUserGroupStatusReset,
    isLoading: updateUserIsLoading,
  } = useUpdateUserGroupStatusMutation()

  const groupRoles: GroupRole[] = [
    { role_name: t('groups.admin'), role_ref: GroupRoles.admin },
    { role_name: t('groups.member'), role_ref: GroupRoles.member },
  ]

  const [selectedRoleRef, setSelectedRoleRef] = useState('')

  const userRole = member?.is_group_admin ? GroupRoles.admin : GroupRoles.member

  const isGroupAdmin = selectedRoleRef === GroupRoles.admin

  function handleRoleChange(event: SelectChangeEvent) {
    setSelectedRoleRef(event.target.value)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    if (member?.user_ref) {
      const editGroupParams = {
        groupRef,
        userRef: member?.user_ref,
        isGroupAdmin: isGroupAdmin,
      }
      editGroup(editGroupParams, {
        onSuccess: () => {
          queryClient.invalidateQueries(['settings', 'groups', groupRef])
          handleClose()
        },
      })
    }
  }

  function handleClose() {
    setSelectedMember(null)
    updateUserGroupStatusReset()
    setSelectedRoleRef('')
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('user_details_page.group_members_table.edit_modal_title')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={updateUserGroupStatusError} />
          <Box>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography sx={{ width: '55%' }} variant="caption" color={'gray.700'}>
                {t('user_details_page.group_members_table.member')}
              </Typography>
              <Typography variant="body1">{`${member?.firstname} ${member?.lastname}`}</Typography>
            </Stack>
          </Box>
          <FormControl>
            <InputLabel required>{t('groups.role')}</InputLabel>
            <Select
              required
              displayEmpty
              label={t('groups.role')}
              value={selectedRoleRef || userRole}
              onChange={handleRoleChange}
            >
              {groupRoles.map((role) => (
                <MenuItem key={role.role_name} value={role.role_ref}>
                  {role.role_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalActions isLoading={updateUserIsLoading} confirmLabel={t('common:save')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default EditUserInGroupSectionModal
