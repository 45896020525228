import { Autocomplete, Dialog, FormControl, TextField } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import NumericField from '../../../components/fields/numeric-field'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import { ModalDetailRow } from '../../../components/modal-detail-row'
import ModalTitle from '../../../components/modal-title'
import { PortfolioHeader } from '../../../services/data/types/portfolio'
import { datetimeFormat, datetimePlaceholder } from '../../../utils/dates'
import { defaultScale } from '../../../utils/math'
import useCorrectCustodianTransactionMutation from '../data/use-correct-custodian-transaction-mutation'
import useCustodianTransactionQuery from '../data/use-custodian-transaction-query'
import useInsertCustodianTransactionMutation from '../data/use-insert-custodian-transaction-mutation'

type EnterBankTransactionModalProps = {
  open: boolean
  bankAccountId: string
  txnRef: string | null
  portfolios: PortfolioHeader[]
  onSaved: () => void
  onClose: () => void
}

function EnterBankTransactionModal(props: EnterBankTransactionModalProps) {
  const { open, bankAccountId, txnRef, portfolios, onSaved, onClose } = props
  const { t } = useTranslation('bankAccounts')

  const formRef = useRef<HTMLFormElement>(null)

  const [date, setDate] = useState<Date | null>(null)
  const [portfolio, setPortfolio] = useState<PortfolioHeader | null>(null)
  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')

  const insertMutation = useInsertCustodianTransactionMutation()
  const correctMutation = useCorrectCustodianTransactionMutation()

  const transactionQuery = useCustodianTransactionQuery(bankAccountId, txnRef)
  const transaction = transactionQuery.data?.data || null

  const isEditing = !!txnRef
  const isLoading = transactionQuery.isFetching
  const isSaving = insertMutation.isLoading || correctMutation.isLoading

  const canSave = !isLoading && !!date && !!portfolio && !!amount

  const error = insertMutation.error || correctMutation.error || transactionQuery.error

  const modalTitle = isEditing
    ? t('create_bank_transaction_modal.edit_title')
    : t('create_bank_transaction_modal.new_title')

  useEffect(() => {
    if (open) {
      setDate(new Date())
    }
  }, [open])

  useEffect(() => {
    if (transaction) {
      setDate(new Date(transaction.txn_datetime))
      setPortfolio(portfolios.find((p) => p.portfolio_ref === transaction.portfolio_ref) || null)
      setAmount(String(transaction.amount))
      setDescription(transaction.description)

      // @ts-ignore
      formRef.current?.elements['amount'].focus()
    }
  }, [transaction])

  function handleClose() {
    transactionQuery.remove()
    insertMutation.reset()
    correctMutation.reset()

    onClose()

    setDate(null)
    setPortfolio(null)
    setAmount('')
    setDescription('')
  }

  function handleFormSubmit(event: FormEvent) {
    event.preventDefault()
    handleSubmit()
  }

  function handleSubmit() {
    insertMutation.reset()
    correctMutation.reset()

    if (isEditing) {
      correctTransaction()
    } else {
      createTransaction()
    }
  }

  function createTransaction() {
    if (!canSave) {
      return
    }

    insertMutation.mutate(
      {
        bankAccountId,
        payload: {
          datetime: date.toISOString(),
          portfolio_ref: portfolio.portfolio_ref,
          quantity: Number(amount),
          description,
        },
      },
      {
        onSuccess: handleSubmitSuccess,
      }
    )
  }

  function correctTransaction() {
    if (!canSave || !transaction) {
      return
    }

    correctMutation.mutate(
      {
        bankAccountId,
        txnRef: transaction.txn_ref,
        payload: {
          datetime: date.toISOString(),
          quantity: Number(amount),
          description,
        },
      },
      {
        onSuccess: handleSubmitSuccess,
      }
    )
  }

  function handleSubmitSuccess() {
    onSaved()

    if (isEditing) {
      handleClose()
    } else {
      setDate(new Date())
      setAmount('')
      setDescription('')

      // @ts-ignore
      formRef.current?.elements['amount'].focus()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
    >
      <form ref={formRef} onSubmit={handleFormSubmit}>
        <ModalTitle title={modalTitle} onClose={handleClose} />

        <ModalContent>
          <Alert severity="error" message={error} />

          {txnRef && <ModalDetailRow label={t('transaction')} value={txnRef} />}

          <FormControl required>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label={t('common:date')}
                value={date}
                inputFormat={datetimeFormat}
                onChange={setDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    error={false}
                    autoComplete="off"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: datetimePlaceholder,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>

          <Autocomplete
            disabled={isEditing}
            value={portfolio}
            options={portfolios}
            getOptionLabel={(p) => p.portfolio_name}
            isOptionEqualToValue={(option, value) => option.portfolio_ref === value.portfolio_ref}
            onChange={(_, p) => setPortfolio(p)}
            renderInput={(props) => <TextField {...props} autoFocus required label={t('common:portfolio')} />}
          />

          <NumericField
            required
            name="amount"
            label={t('common:amount')}
            value={amount}
            decimalScale={defaultScale}
            onValueChange={setAmount}
          />

          <TextField
            name="description"
            label={t('create_bank_transaction_modal.description')}
            value={description}
            multiline
            minRows={4}
            maxRows={4}
            onChange={(event) => setDescription(event.target.value)}
            onKeyDown={(event) => {
              if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
                handleSubmit()
              }
            }}
          />
        </ModalContent>

        <ModalActions
          confirmLabel={t('common:post')}
          confirmDisabled={!canSave}
          isLoading={isSaving}
          onCancel={handleClose}
        />
      </form>
    </Dialog>
  )
}

export default EnterBankTransactionModal
