import { useNavigate, useParams } from 'react-router-dom'
import DatasetDetails from '../../components/dataset-details'

function DatasetDetailsPage() {
  const { datasetRef } = useParams()
  const navigate = useNavigate()

  function handleClassificationClick(classificationId: string | number) {
    navigate(`/data-engine/datasets/${datasetRef}/classifications/${classificationId}`)
  }

  function handleModelClick(modelRef: string) {
    navigate(`/data-engine/datasets/${datasetRef}/models/${modelRef}`)
  }

  if (!datasetRef) {
    return null
  }

  return (
    <DatasetDetails
      onModelClick={handleModelClick}
      datasetRef={datasetRef}
      onClassificationClick={handleClassificationClick}
    />
  )
}

export default DatasetDetailsPage
