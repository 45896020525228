import TextField, { TextFieldProps } from '@mui/material/TextField'
import { MuiTextFieldProps } from '@mui/x-date-pickers/internals'
import { ChangeEvent, FocusEventHandler, KeyboardEvent, ReactNode } from 'react'
import { isSafeNumber } from '../../utils/math'

type NumericFieldProps = {
  name?: string
  value?: string
  label?: string
  required?: boolean
  disabled?: boolean
  autoFocus?: boolean
  error?: string | null
  placeholder?: string
  decimalScale: number
  fixedLabel?: true
  endAdornment?: ReactNode
  size?: TextFieldProps['size']
  TextFieldProps?: MuiTextFieldProps
  onBlur?: FocusEventHandler
  onFocus?: FocusEventHandler
  onEnterDown?: () => void
  onValueChange?: (value: string) => void
}

function NumericField(props: NumericFieldProps) {
  const {
    name,
    value = '',
    label,
    required,
    disabled,
    autoFocus,
    error,
    placeholder,
    decimalScale,
    fixedLabel,
    endAdornment,
    size,
    TextFieldProps,
    onBlur,
    onFocus,
    onEnterDown,
    onValueChange,
  } = props

  function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    handleValueChange(event.target.value)
  }

  function handleOnKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && onEnterDown) {
      event.preventDefault()
      onEnterDown()
      return
    }

    if (!['k', 'm'].includes(event.key)) {
      return
    }

    if (!value || Number.isNaN(Number(value))) {
      return
    }

    const multiplier = event.key === 'k' ? 1000 : 1000000
    const newValue = String(Number(value) * multiplier)

    handleValueChange(newValue)
  }

  function handleValueChange(newValue: string) {
    if (newValue === '-' || newValue === '.' || newValue === '-.') {
      onValueChange?.(newValue)
      return
    }

    const number = Number(newValue)

    if (Number.isNaN(number) || !isSafeNumber(number)) {
      return
    }

    if (decimalScale === 0 && (!Number.isInteger(number) || newValue.endsWith('.'))) {
      return
    }

    if (decimalScale && decimalScale > 0 && newValue.includes('.') && !newValue.endsWith('.')) {
      const [_, decimals] = newValue.split('.')
      const isDeletingChars = value.length > newValue.length
      if (decimals && decimals.length > decimalScale && !isDeletingChars) {
        return
      }
    }

    onValueChange?.(newValue)
  }

  return (
    <TextField
      {...TextFieldProps}
      name={name}
      value={value}
      label={label}
      required={required}
      disabled={disabled}
      autoFocus={autoFocus}
      error={!!error}
      helperText={error}
      placeholder={placeholder}
      size={size}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      fullWidth
      InputLabelProps={{
        shrink: fixedLabel,
      }}
      InputProps={{
        ...TextFieldProps?.InputProps,
        autoComplete: 'off',
        endAdornment,
      }}
    />
  )
}

export default NumericField
