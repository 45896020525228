import { useNavigate, useParams } from 'react-router-dom'
import AssetStaticDetails from '../../components/asset-static-details'

function AssetStaticDetailsPage() {
  const { assetTag } = useParams()
  const navigate = useNavigate()

  function handleDatapointDetailsOpen(assetRef: string, datapointRef: string, datasetRef: string) {
    navigate(`/data-engine/datasets/${datasetRef}/datapoints/${datapointRef}/assets/${assetRef}`)
  }

  if (!assetTag) {
    return null
  }

  return <AssetStaticDetails assetTag={assetTag} onDatapointDetailsOpen={handleDatapointDetailsOpen} />
}

export default AssetStaticDetailsPage
