import { useNavigate } from 'react-router-dom'
import Users from '../../components/users'

function UsersFlexView() {
  const navigate = useNavigate()

  return <Users onUserClick={(userRef) => navigate(`/settings/users/${userRef}`)} />
}

export default UsersFlexView
