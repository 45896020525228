export type Classification = {
  classification_id: number
  classification_name: string
  classification_type: ClassificationType
  bucket_datapoint_ref: string | null
  bucket_datapoint_name: string | null
  values: {
    value_id: number
    tag: string
    desc: string
    expired: string | null
    bucket_start: number | null
  }[]
}

export type ClassificationValue = {
  tag: string
  description: string
}

export const classificationTypes = ['fixed', 'variable', 'auto'] as const

export type ClassificationType = (typeof classificationTypes)[number]

export type ClassificationPayload = {
  name: string
  classification_type: ClassificationType
  bucket_datapoint_ref: string | null
}

export type ClassificationValuePayload = {
  tag: string
  desc: string
  expired: string | null
  bucket_start: number | null
}
