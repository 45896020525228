import { Alert, Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

function ErrorPageForUser() {
  const { t } = useTranslation()

  return (
    <Stack
      direction="column"
      sx={{ alignItems: 'center', px: 2, py: 1.5, borderBottom: '1px solid', borderColor: 'divider' }}
    >
      <Alert severity="error">{t('common:error_message_fallback_ui_error_page')}</Alert>
      <Button
        variant="contained"
        onClick={() => {
          window.location.reload()
        }}
        sx={{ mt: '8px' }}
      >
        {t('common:refresh')}
      </Button>
    </Stack>
  )
}

export default ErrorPageForUser
