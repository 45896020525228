import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useAddGroupMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, AddGroupParams>({
    mutationFn: ({ groupName, groupType }) => api.addGroup({ group_name: groupName, group_type: groupType }),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type AddGroupParams = {
  groupName: string
  groupType: string
}

export default useAddGroupMutation
