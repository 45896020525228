import { BookmarkAdded, DeleteOutline, Edit, Share } from '@mui/icons-material'
import { ClickAwayListener, ListItemIcon, ListItemText, Menu, MenuItem, PopoverOrigin, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type ViewOptionsContextMenuProps = {
  anchorEl: HTMLElement | undefined
  anchorOrigin?: PopoverOrigin
  canModify: boolean
  onEditClick: () => void
  onShareClick: () => void
  onSetAsDefaultClick?: () => void
  onDeleteClick: () => void
  onClose: () => void
}

function ViewOptionsContextMenu(props: ViewOptionsContextMenuProps) {
  const { anchorEl, anchorOrigin, canModify, onEditClick, onShareClick, onSetAsDefaultClick, onDeleteClick, onClose } =
    props

  const { t } = useTranslation('portfolio')

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        disableScrollLock
        transitionDuration={{ exit: 0 }}
        anchorOrigin={
          anchorOrigin ?? {
            vertical: 'bottom',
            horizontal: 'right',
          }
        }
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={onClose}
        sx={{ position: 'static' }}
        PaperProps={{
          sx: { zIndex: 5, mt: 1 },
        }}
      >
        {canModify && (
          <MenuItem onClick={onEditClick}>
            <ListItemIcon>
              <Edit sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('common:edit')}</Typography>
            </ListItemText>
          </MenuItem>
        )}
        {canModify && (
          <MenuItem onClick={onShareClick}>
            <ListItemIcon>
              <Share sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('common:share')}</Typography>
            </ListItemText>
          </MenuItem>
        )}
        {!!onSetAsDefaultClick && (
          <MenuItem onClick={onSetAsDefaultClick}>
            <ListItemIcon>
              <BookmarkAdded sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('set_as_default')}</Typography>
            </ListItemText>
          </MenuItem>
        )}
        {canModify && (
          <MenuItem onClick={onDeleteClick}>
            <ListItemIcon>
              <DeleteOutline sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('common:delete')}</Typography>
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    </ClickAwayListener>
  )
}

export default ViewOptionsContextMenu
