import { ContentCopy, Edit, SupervisorAccount } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { DetailTable, DetailTableRow } from '../../../components/detail-table'
import TableContainerHeader from '../../../components/table-container-header'
import { UserSelf } from '../../../services/data/types/auth'
import Tenant from '../../../services/data/types/tenant'
import session from '../../../services/session'
import { copyToClipboard } from '../../../utils/clipboard'
import useOpenState from '../../../utils/hooks/use-open-state'
import useTenantsQuery from '../../auth/data/use-tenants-query'
import useUserInfo from '../../auth/data/use-user-info'
import ChangePasswordModal from './change-password-modal'
import EditAccountModal from './edit-account-modal'
import SwitchTenantModal from './switch-tenant-modal'

type AccountProps = {
  onLoadingChange?: Dispatch<SetStateAction<boolean>>
  onSwitchTenantSuccess: () => void
}

function Account(props: AccountProps) {
  const { onLoadingChange, onSwitchTenantSuccess } = props

  const { user } = useUserInfo()
  const fullName = `${user?.firstname} ${user?.lastname}`
  const tenantsResponse = useTenantsQuery()
  const tenants = tenantsResponse.data?.data || []

  useEffect(() => {
    if (onLoadingChange) {
      onLoadingChange(tenantsResponse.isLoading)
    }
  }, [tenantsResponse.isLoading])

  if (!user || tenantsResponse.isLoading) {
    return null
  }

  return (
    <Box flex={1} height="100%" overflow="auto">
      <Container maxWidth="md" component={Stack} my={3}>
        <Stack direction="row" alignItems="center" gap={2} my={4.5}>
          <Avatar sx={{ bgcolor: 'gray.400', color: 'gray.light', width: 48, height: 48 }}>{fullName[0]}</Avatar>
          <Typography variant="h5">{fullName}</Typography>
        </Stack>
        <Stack gap={3}>
          <AccountDetails user={user} />
          <Tenants currentTenantRef={user.tenant_ref} onSwitchTenantSuccess={onSwitchTenantSuccess} tenants={tenants} />
        </Stack>
      </Container>
    </Box>
  )
}

export default Account

type AccountDetailsProps = {
  user: UserSelf
}

function AccountDetails(props: AccountDetailsProps) {
  const { user } = props
  const { t } = useTranslation('account')

  const editAccountModal = useOpenState()
  const passwordChangeModal = useOpenState()

  const fullName = `${user?.firstname} ${user?.lastname}`

  return (
    <>
      <TableContainer component={Paper}>
        <TableContainerHeader
          title={t('user_details')}
          action={
            <IconButton onClick={editAccountModal.open}>
              <Edit />
            </IconButton>
          }
        />
        <DetailTable>
          <TableBody>
            <DetailTableRow label={t('username')}>
              <Typography variant="body1">{user?.username}</Typography>
            </DetailTableRow>
            <DetailTableRow label={t('name')}>
              <Typography variant="body1">{fullName}</Typography>
            </DetailTableRow>
            <DetailTableRow label={t('email')}>
              <Typography variant="body1">{user?.email}</Typography>
            </DetailTableRow>
            <DetailTableRow label={t('password')}>
              <Link component="button" onClick={passwordChangeModal.open}>
                <Typography variant="body1" color="primary">
                  {t('change_password')}
                </Typography>
              </Link>
            </DetailTableRow>
            <DetailTableRow label={t('timezone')}>
              <Typography variant="body1">{user?.timezone}</Typography>
            </DetailTableRow>
            <DetailTableRow label={t('api_key')}>
              <CopyButton />
            </DetailTableRow>
          </TableBody>
        </DetailTable>
      </TableContainer>
      <EditAccountModal user={user} open={editAccountModal.isOpen} onClose={editAccountModal.close} />
      <ChangePasswordModal open={passwordChangeModal.isOpen} onClose={passwordChangeModal.close} />
    </>
  )
}

type TenantsProps = {
  currentTenantRef: string
  tenants: Tenant[]
  onSwitchTenantSuccess: () => void
}

function Tenants(props: TenantsProps) {
  const { currentTenantRef, tenants, onSwitchTenantSuccess } = props
  const { t } = useTranslation('account')

  const switchTenantModal = useOpenState()

  const isTenantListEmpty = tenants.length === 0
  const canSwitchTenant = tenants.length > 1

  return (
    <>
      <TableContainer component={Paper}>
        <TableContainerHeader
          title={t('tenant_details')}
          action={
            canSwitchTenant && (
              <Button
                variant="outlined"
                size="small"
                startIcon={<SupervisorAccount />}
                onClick={switchTenantModal.open}
              >
                {t('account:switch_tenant')}
              </Button>
            )
          }
        />

        {isTenantListEmpty ? (
          <Typography py={1} px={1.5}>
            <Typography variant="body1">{t('not_assigned_to_tenant')}</Typography>
            <Trans
              i18nKey={'common:contact_us_at'}
              values={{ email: t('common:contact.email_address') }}
              components={{ email: <Typography variant="body1" color="primary" display="inline-flex" /> }}
            />
          </Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('tenant_name')}</TableCell>
                <TableCell>{t('tenant_code')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tenants.map((tenant) => (
                <TableRow key={tenant.tenant_ref} sx={{ height: 48 }}>
                  <TableCell>
                    {tenant.tenant_ref === currentTenantRef ? (
                      <Chip label={tenant.tenant_name} sx={{ color: 'inherit' }} />
                    ) : (
                      tenant.tenant_name
                    )}
                  </TableCell>
                  <TableCell>{tenant.tenant_ref}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <SwitchTenantModal
        open={switchTenantModal.isOpen}
        onClose={switchTenantModal.close}
        onSuccess={onSwitchTenantSuccess}
      />
    </>
  )
}

function CopyButton() {
  const copied = useOpenState()
  const copiedRef = useRef<NodeJS.Timeout | null>(null)

  const { t } = useTranslation('account')

  return (
    <Button
      size="small"
      startIcon={<ContentCopy />}
      onClick={async () => {
        await copyToClipboard(session.getToken() || '')

        copied.open()
        if (copiedRef.current) {
          clearTimeout(copiedRef.current)
        }
        copiedRef.current = setTimeout(copied.close, 3000)
      }}
    >
      {copied.isOpen ? t('common:copied') : t('common:copy')}
    </Button>
  )
}
