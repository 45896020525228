import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useRemoveUserFromGroupMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, RemoveUserToGroupParams>({
    mutationFn: ({ groupRef, userRef }) => api.removeUserFromGroup(groupRef, userRef),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type RemoveUserToGroupParams = {
  groupRef: string
  userRef: string
}

export default useRemoveUserFromGroupMutation
