import { Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import NoMoreOptionsAlert from '../../../components/no-more-options-alert'
import { queryClient } from '../../../services/data/react-query'
import { Member } from '../../../services/data/types/group'
import useAddUserToGroupMutation from '../data/use-add-user-to-group-mutation'
import useUserQuery from '../data/use-users-query'
import { GroupRole, GroupRoles } from './user-details'

type AddUserToGroupModalProps = {
  isOpen: boolean
  groupRef: string
  groupMembers: Member[]
  onClose: () => void
}

function AddUserToGroupModal(props: AddUserToGroupModalProps) {
  const { t } = useTranslation('settings')

  const { isOpen, groupRef, groupMembers, onClose } = props

  const groupRoles: GroupRole[] = [
    { role_name: t('groups.admin'), role_ref: GroupRoles.admin },
    { role_name: t('groups.member'), role_ref: GroupRoles.member },
  ]

  const [selectedUserRef, setSelectedUserRef] = useState('')
  const [selectedRoleRef, setSelectedRoleRef] = useState('')

  const usersResponse = useUserQuery()
  const allUsers = usersResponse.data?.data || []

  const availableUsers = allUsers?.filter((user) => {
    const currentGroupMembers = groupMembers.map((members) => members.user_ref)
    return !currentGroupMembers.includes(user.user_ref)
  })

  const { mutate: addGroup, error: addUserToGroupError, reset: resetAddUserToGroup } = useAddUserToGroupMutation()

  const hasOptions = availableUsers.length > 0

  const isGroupAdmin = selectedRoleRef === GroupRoles.admin

  function handleClose() {
    resetAddUserToGroup()
    setSelectedUserRef('')
    setSelectedRoleRef('')
    onClose()
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    const addGroupParams = {
      groupRef,
      userRef: selectedUserRef,
      isGroupAdmin: isGroupAdmin,
    }

    addGroup(addGroupParams, {
      onSuccess: () => {
        queryClient.invalidateQueries(['settings', 'groups', groupRef])
        handleClose()
      },
    })
  }

  function handleUserChange(event: SelectChangeEvent) {
    setSelectedUserRef(event.target.value)
  }

  function handleRoleChange(event: SelectChangeEvent) {
    setSelectedRoleRef(event.target.value)
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('groups.add_user')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={addUserToGroupError} />
          {!hasOptions && <NoMoreOptionsAlert />}
          <FormControl disabled={!hasOptions}>
            <InputLabel required>{t('groups.member')}</InputLabel>
            <Select
              required
              displayEmpty
              label={t('groups.member')}
              value={selectedUserRef}
              onChange={handleUserChange}
            >
              {availableUsers.map((member) => (
                <MenuItem key={member.user_ref} value={member.user_ref}>
                  {`${member.firstname} ${member.lastname}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl disabled={!hasOptions}>
            <InputLabel required>{t('groups.role')}</InputLabel>
            <Select required displayEmpty label={t('groups.role')} value={selectedRoleRef} onChange={handleRoleChange}>
              {groupRoles.map((role) => (
                <MenuItem key={role.role_name} value={role.role_ref}>
                  {role.role_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('common:add')} confirmDisabled={!hasOptions} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default AddUserToGroupModal
