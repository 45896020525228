import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useCustodianTransactionQuery(bankAccountId: string | undefined, txnRef: string | null) {
  const response = useQuery({
    queryKey: ['bank-accounts', bankAccountId, 'transactions', txnRef],
    queryFn: () => {
      if (!bankAccountId || !txnRef) {
        return null
      }
      return api.getCustodianTransaction(bankAccountId, txnRef)
    },
  })

  return { ...response, error: parseError(response.error as AxiosError) }
}

export default useCustodianTransactionQuery
