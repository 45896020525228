import { Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import { queryClient } from '../../../services/data/react-query'
import useDeleteClassificationValueMutation from '../data/use-delete-classification-value-mutation'

type DeleteClassificationValueModalProps = {
  open: boolean
  classificationValueId?: number | null
  classificationValueTag?: string
  classificationId?: string | number
  onClose: () => void
}

function DeleteClassificationValueModal(props: DeleteClassificationValueModalProps) {
  const { open, classificationValueTag, classificationValueId, classificationId, onClose } = props
  const { t } = useTranslation('dataEngine')

  const {
    mutate: deleteClassificationValue,
    error: deleteClassificationValueError,
    reset: deleteClassificationValueReset,
  } = useDeleteClassificationValueMutation()

  function handleDelete() {
    if (!classificationId || !classificationValueId) {
      return
    }

    deleteClassificationValue(
      { classificationId, classificationValueId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['classification', classificationId])
          handleClose()
        },
      }
    )
  }

  function handleClose() {
    deleteClassificationValueReset()
    onClose()
  }

  return (
    <ConfirmationModal
      open={open}
      title={t('classification_values_table.delete')}
      confirmButtonText={t('common:delete')}
      error={deleteClassificationValueError}
      onCloseButtonClick={handleClose}
      onConfirmButtonClick={handleDelete}
    >
      <Typography variant="body1">
        <Trans
          t={t}
          i18nKey={'classification_values_table.delete_confirmation_text'}
          values={{ name: classificationValueTag }}
          components={{ bold: <strong /> }}
        />
      </Typography>
    </ConfirmationModal>
  )
}

export default DeleteClassificationValueModal
