import { FormControlLabel, Menu, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { isSameDay } from 'date-fns'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FilterFormButtons from '../../../components/filters/filter-form-buttons'
import { dateFormat, datePlaceholder, formatNaiveDate } from '../../../utils/dates'

type AsOfDateMenuProps = {
  anchorEl: HTMLElement | null
  currentAsOfDate: string | null | undefined
  onSetAsOfDate: (asOfDate: string | null) => void
  onClose: () => void
}

type AsOfDateType = 'current' | 'custom'

function AsOfDateMenu(props: AsOfDateMenuProps) {
  const { anchorEl, currentAsOfDate, onSetAsOfDate, onClose } = props

  const { t } = useTranslation('portfolio')

  const [dateType, setDateType] = useState<AsOfDateType>(!!currentAsOfDate ? 'custom' : 'current')
  const [customDate, setCustomDate] = useState<string | null>(currentAsOfDate || null)

  const isDatePickerVisible = dateType === 'custom'

  function isSubmitDisabled() {
    const isCustomDateEmpty = dateType === 'custom' && !customDate
    const isDateSame =
      (currentAsOfDate && customDate && isSameDay(new Date(currentAsOfDate), new Date(customDate))) ||
      (!currentAsOfDate && !customDate)

    return isCustomDateEmpty || isDateSame
  }

  function handleDateTypeChange(value: string) {
    if (value === 'current') {
      setCustomDate(null)
    }

    setDateType(value as AsOfDateType)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    const date = customDate ? formatNaiveDate(customDate) : null

    onSetAsOfDate(date)
    onClose()
  }

  return (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
      PaperProps={{
        sx: {
          mt: 0.5,
          width: 330,
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <Stack sx={{ px: 1, pt: 1 }}>
          <Typography variant="overline" color="gray.700" sx={{ px: 2, py: 0.5 }}>
            {t('as_of_date')}
          </Typography>
          <RadioGroup
            value={dateType}
            onChange={(_, value) => handleDateTypeChange(value)}
            sx={{
              gap: 0.25,
              px: 2,
              '& .MuiSvgIcon-root': {
                fontSize: 16,
              },
            }}
          >
            <FormControlLabel value="current" control={<Radio />} label={t('current')} />
            <FormControlLabel value="custom" control={<Radio />} label={t('custom_date')} />
            {isDatePickerVisible && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={customDate}
                  inputFormat={dateFormat}
                  onChange={(value) => setCustomDate(value || '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      error={false}
                      autoComplete="off"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: datePlaceholder,
                      }}
                      sx={{ my: 2 }}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          </RadioGroup>
        </Stack>
        <FilterFormButtons clearDisabled submitDisabled={isSubmitDisabled()} onCancel={onClose} />
      </form>
    </Menu>
  )
}

export default AsOfDateMenu
