import { ChevronRight } from '@mui/icons-material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Breadcrumbs, Chip, CircularProgress, IconButton, Link, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import AppLayout from '../../../components/layouts/app-layout'
import PageHeaderContainer from '../../../components/page-header-container'
import TenantMenu from '../../account/components/tenant-menu'
import useUserInfo from '../../auth/data/use-user-info'
import SettingsNav from './settings-nav'

export enum SettingsModule {
  Tenants = 'tenants',
  Tenant = 'tenant',
  Users = 'users',
  Groups = 'groups_title',
  Portfolios = 'portfolios',
  Views = 'views',
  Compliance = 'compliance',
  Counterparties = 'counterparties',
  GeneralLedger = 'general-ledger',
  LegalEntities = 'legal-entities',
  NonTenantedUsers = 'non-tenanted-users',
  Chains = 'chains',
  AuditTrail = 'audit-trail',
}

type SettingsProps = {
  selectedModule: SettingsModule
  isSubModuleOpen: boolean
  onModuleClick: (title: SettingsModule) => void
  onSwitchTenantSuccess: () => void
}

function Settings(props: SettingsProps) {
  const { selectedModule, isSubModuleOpen, onModuleClick, onSwitchTenantSuccess } = props

  const { t } = useTranslation('settings')
  const { user } = useUserInfo()

  const [isNavOpen, setIsNavOpen] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tenantMenuAnchorElement, setTenantMenuAnchorElement] = useState<HTMLElement | null>(null)

  function handleNavOpen() {
    setIsNavOpen(true)
  }

  function handleNavClose() {
    setIsNavOpen(false)
  }

  function handleTenantMenuOpen(event: MouseEvent<HTMLElement>) {
    setTenantMenuAnchorElement(event.currentTarget)
  }

  return (
    <>
      <AppLayout direction="row">
        <SettingsNav
          user={user}
          open={isNavOpen}
          selectedModule={selectedModule}
          onModuleClick={onModuleClick}
          onCloseClick={handleNavClose}
        />
        <Stack flex="1">
          <PageHeaderContainer>
            <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
              {!isNavOpen && (
                <IconButton onClick={handleNavOpen}>
                  <ChevronRight color="primary" />
                </IconButton>
              )}
              <Breadcrumbs separator={<ChevronRight />} sx={{ flex: 1 }}>
                {isSubModuleOpen && (
                  <Link
                    variant="h6"
                    underline="hover"
                    color={'gray.700'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => onModuleClick(selectedModule)}
                  >
                    {t(selectedModule)}
                  </Link>
                )}
                <Typography variant="h6">
                  {isSubModuleOpen ? t(`modules.${selectedModule}.details`) : t(selectedModule)}
                </Typography>
              </Breadcrumbs>
              {isLoading && <CircularProgress size={24} sx={{ m: '8px' }} />}
            </Stack>
            <Chip
              icon={<AccountCircleIcon />}
              label={`${t('tenant')}: ${user?.tenant_name}`}
              onClick={handleTenantMenuOpen}
            />
          </PageHeaderContainer>
          <Box overflow="auto">
            <Outlet context={{ setIsLoading }} />
          </Box>
        </Stack>
      </AppLayout>
      <TenantMenu
        anchorEl={tenantMenuAnchorElement}
        onClose={() => setTenantMenuAnchorElement(null)}
        onSwitchTenantSuccess={onSwitchTenantSuccess}
      />
    </>
  )
}

export default Settings
