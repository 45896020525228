import {
  Dialog,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { AssetStaticOptions } from '../../../services/data/types/asset-static-data'
import { GridDataDataset } from '../../../services/data/types/grid-data'

type ViewNewModalProps = {
  open: boolean
  datasets: GridDataDataset[]
  assetTypes: AssetStaticOptions['asset_types'] | undefined
  onSubmit: (params: NewViewParams) => void
  onClose: () => void
}

export type NewViewParams = {
  datasetRef: string
  viewName: string
  assetTypes?: string[]
}

function ViewNewModal(props: ViewNewModalProps) {
  const { open, datasets, assetTypes, onSubmit, onClose } = props

  const { t } = useTranslation('portfolio')

  const [datasetRef, setDatasetRef] = useState('')
  const [viewName, setViewName] = useState('')
  const assetTypeState = useViewAssetTypesState()

  const canSubmit = !!datasetRef && !!viewName && assetTypeState.isValid

  function handleChange(event: SelectChangeEvent) {
    setDatasetRef(event.target.value)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (canSubmit) {
      onSubmit({ datasetRef, viewName, assetTypes: assetTypeState.selected })
    }
    handleClose()
  }

  function handleClose() {
    onClose()

    setDatasetRef('')
    setViewName('')
    assetTypeState.reset()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('new_view')} onClose={handleClose} />
        <ModalContent>
          <FormControl>
            <TextField
              required
              autoFocus
              autoComplete="off"
              label={t('common:name')}
              value={viewName}
              onChange={(event) => setViewName(event.target.value)}
              inputProps={{ maxLength: 24 }}
            />
          </FormControl>

          <FormControl required>
            <InputLabel>{t('dataset')}</InputLabel>
            <Select displayEmpty label={t('dataset')} value={datasetRef} onChange={handleChange}>
              {datasets.map((dataset) => (
                <MenuItem key={dataset.dataset_ref} value={dataset.dataset_ref}>
                  {dataset.dataset_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <ViewAssetTypeFormFields
            mode={assetTypeState.mode}
            selected={assetTypeState.selected}
            options={assetTypes}
            onModeChange={assetTypeState.onModeChange}
            onSelectedChange={assetTypeState.onSelectedChange}
          />
        </ModalContent>
        <ModalActions confirmLabel={t('common:open')} confirmDisabled={!canSubmit} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default ViewNewModal

export function useViewAssetTypesState(initialAssetTypes?: string[]) {
  const [mode, setMode] = useState<AssetTypeMode>('all')
  const [selected, setSelected] = useState<string[]>()

  const isValid = mode === 'all' || !!selected

  useEffect(() => {
    if (initialAssetTypes) {
      setMode('some')
      setSelected(initialAssetTypes)
    } else {
      setMode('all')
      setSelected(initialAssetTypes)
    }
  }, [initialAssetTypes])

  function onModeChange(newMode: AssetTypeMode) {
    setMode(newMode)
  }

  function onSelectedChange(newTags: string[] | undefined) {
    setSelected(newTags)
  }

  function reset() {
    setMode('all')
    setSelected(undefined)
  }

  return { mode, selected, isValid, onModeChange, onSelectedChange, reset }
}

type AssetTypeMode = 'all' | 'some'

type ViewAssetTypeFormFieldsProps = {
  mode: AssetTypeMode
  selected: string[] | undefined
  options: AssetStaticOptions['asset_types'] | undefined
  onModeChange: (mode: AssetTypeMode) => void
  onSelectedChange: (assetTypes: string[] | undefined) => void
}

export function ViewAssetTypeFormFields(props: ViewAssetTypeFormFieldsProps) {
  const { mode, selected, options, onModeChange, onSelectedChange } = props

  const { t } = useTranslation('portfolio')

  if (!options) {
    return null
  }

  return (
    <>
      <FormControl>
        <RadioGroup
          row
          value={mode}
          onChange={(_event, value) => {
            if (value === 'all') {
              onModeChange('all')
              onSelectedChange(undefined)
            } else {
              onModeChange('some')
            }
          }}
        >
          <FormControlLabel value="all" control={<Radio />} label={t('all_asset_types')} />
          <FormControlLabel value="some" control={<Radio />} label={t('select_asset_types')} />
        </RadioGroup>
      </FormControl>

      {mode === 'some' && (
        <FormControl required>
          <InputLabel>{t('asset_types')}</InputLabel>
          <Select
            multiple
            value={selected || []}
            label={t('asset_types')}
            onChange={(event: SelectChangeEvent<string[]>) => {
              const value = event.target.value
              const newValue = typeof value === 'string' ? value.split(',') : value
              onSelectedChange(newValue)
            }}
          >
            {options.map((assetType) => (
              <MenuItem key={assetType.tag} value={assetType.tag}>
                {assetType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  )
}
