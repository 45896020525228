import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useCloseOutTransactionQuery(lei: string | undefined, segmentRef: string | undefined) {
  const response = useQuery({
    queryKey: ['lei', lei, 'transactions', 'segments', segmentRef, 'close-out'],
    queryFn: () => {
      if (!lei || !segmentRef) {
        return
      }
      return api.getCloseOutTransaction(lei, segmentRef)
    },
    enabled: !!lei && !!segmentRef,
  })

  return { ...response, error: parseError(response.error as AxiosError) }
}

export default useCloseOutTransactionQuery
