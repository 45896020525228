import { ChevronRight } from '@mui/icons-material'
import { Breadcrumbs, CircularProgress, IconButton, Link, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import PageHeaderContainer from '../../../components/page-header-container'
import { DataEngineOutletContext } from './use-data-engine-outlet-context'

type DataEnginerHeaderProps = {
  outletContext: DataEngineOutletContext
  rightMenu?: ReactNode
}

export function DataEngineHeader(props: DataEnginerHeaderProps) {
  const { outletContext, rightMenu } = props
  const navigate = useNavigate()

  return (
    <PageHeaderContainer>
      <Stack direction="row" sx={{ flex: 1, alignItems: 'center', gap: 1 }}>
        {!outletContext.isNavOpen && (
          <IconButton onClick={outletContext.onNavOpen} sx={{ p: 1 }}>
            <ChevronRight color="primary" />
          </IconButton>
        )}

        <Breadcrumbs separator={<ChevronRight />}>
          {outletContext.breadcrumbs.map((item, index) => {
            const isLast = index === outletContext.breadcrumbs.length - 1

            return isLast ? (
              <Typography key={item.path} variant="h6">
                {item.name}
              </Typography>
            ) : (
              <Link
                key={item.path}
                variant="h6"
                underline="hover"
                color="gray.700"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(item.path)}
              >
                {item.name}
              </Link>
            )
          })}
        </Breadcrumbs>

        {outletContext.showLoading && <CircularProgress size={24} sx={{ mx: '8px' }} />}

        <Box ml="auto">{rightMenu}</Box>
      </Stack>
    </PageHeaderContainer>
  )
}
