import { Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import { queryClient } from '../../../services/data/react-query'
import useDeleteGroupMutation from '../data/use-delete-group-mutation'

type DeleteGroupModalProps = {
  isOpen: boolean
  groupName: string
  groupRef: string
  onClose: () => void
  onBack: () => void
}

const DeleteGroupModal = (props: DeleteGroupModalProps) => {
  const { isOpen, groupName, groupRef, onClose, onBack } = props

  const { t } = useTranslation('settings')

  const { mutate: deleteGroup, error: errorMessage, reset: resetDeleteGroup } = useDeleteGroupMutation()

  function handleDeleteGroup() {
    if (groupRef) {
      deleteGroup(
        { groupRef },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['tenant', 'current', 'groupswithmembers'])
            handleClose()
            onBack()
          },
        }
      )
    }
  }

  function handleClose() {
    resetDeleteGroup()
    onClose()
  }

  return (
    <ConfirmationModal
      error={errorMessage}
      title={t('delete_group')}
      open={isOpen}
      confirmButtonText={t('common:delete')}
      onCloseButtonClick={handleClose}
      onConfirmButtonClick={handleDeleteGroup}
    >
      <Typography variant="body1">
        <Trans
          t={t}
          i18nKey={'common:are_you_sure_to_remove'}
          values={{ subject: `${groupName}` || '' }}
          components={{ bold: <strong /> }}
        />
      </Typography>{' '}
    </ConfirmationModal>
  )
}

export default DeleteGroupModal
