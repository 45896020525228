import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { PortfolioPayload } from '../../../services/data/types/portfolio'

function useCreatePortfolioMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, CreatePortfolioParams>({
    mutationFn: ({ portfolioPayload }) => api.createPortfolio(portfolioPayload),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type CreatePortfolioParams = {
  portfolioPayload: PortfolioPayload
}

export default useCreatePortfolioMutation
