import { Dialog, FormControl, InputLabel, ListSubheader, MenuItem, Select, TextField } from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { AssetStaticOptions } from '../../../services/data/types/asset-static-data'
import { Group } from '../../../services/data/types/group'
import useViewShareOptionsQuery from '../data/use-view-share-options-query'
import { ViewAssetTypeFormFields, useViewAssetTypesState } from './view-new-modal'

type ViewEditModalProps = {
  open: boolean
  viewRef: string | undefined
  currentOwner: Group | undefined
  currentName: string
  initialAssetTypes: string[] | null | undefined
  assetTypes: AssetStaticOptions['asset_types'] | undefined
  error?: string | null
  isLoading: boolean
  onSubmit: (params: ViewEditParams) => void
  onClose: () => void
}

export type ViewEditParams = {
  viewName: string
  ownerGroupRef: string
  assetTypes: string[] | undefined
}

function ViewEditModal(props: ViewEditModalProps) {
  const {
    open,
    viewRef,
    currentName,
    currentOwner,
    initialAssetTypes,
    assetTypes,
    error,
    isLoading,
    onSubmit,
    onClose,
  } = props

  const { t } = useTranslation('portfolio')

  const [name, setName] = useState(currentName)
  const [ownerGroupRef, setOwnerGroupRef] = useState(currentOwner?.group_ref || '')
  const assetTypeState = useViewAssetTypesState(initialAssetTypes ?? undefined)

  const viewShareOptionsResponse = useViewShareOptionsQuery(viewRef, { enabled: open })
  const viewShareOptions = viewShareOptionsResponse.data?.data

  useEffect(() => {
    setName(currentName)
    setOwnerGroupRef(currentOwner?.group_ref || '')
  }, [currentName, currentOwner])

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (!isLoading) {
      onSubmit({
        viewName: name,
        ownerGroupRef,
        assetTypes: assetTypeState.selected,
      })
    }
  }

  if (!currentOwner || !viewShareOptions) {
    return null
  }

  const allViewShareOptions = viewShareOptions?.available.concat(viewShareOptions?.shared)

  const allTenantShareOptions = allViewShareOptions.filter((option) => option.group_class === 'tenant')
  const allGroupShareOptions = allViewShareOptions.filter((option) => option.group_class === 'group')
  const allUserShareOptions = allViewShareOptions.filter((option) => option.group_class === 'user')

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('edit_view')} onClose={onClose} />
        <ModalContent>
          <Alert severity="error" message={error} />
          <FormControl>
            <TextField
              required
              autoFocus
              label={t('common:name')}
              value={name}
              onChange={(event) => setName(event.target.value)}
              fullWidth
              inputProps={{ maxLength: 24 }}
              InputLabelProps={{ required: false }}
            />
          </FormControl>

          <FormControl>
            <InputLabel>{t('owner')}</InputLabel>
            <Select label={t('owner')} value={ownerGroupRef} onChange={(event) => setOwnerGroupRef(event.target.value)}>
              {allTenantShareOptions.length > 0 && <ListSubheader>{t('company')}</ListSubheader>}
              {allTenantShareOptions.map((option) => (
                <MenuItem key={option.group_name + option.group_ref} value={option.group_ref}>
                  {option.group_name}
                </MenuItem>
              ))}
              {allGroupShareOptions.length > 0 && <ListSubheader>{t('groups')}</ListSubheader>}
              {allGroupShareOptions.map((option) => (
                <MenuItem key={option.group_name + option.group_ref} value={option.group_ref}>
                  {option.group_name}
                </MenuItem>
              ))}
              {allUserShareOptions.length > 0 && <ListSubheader>{t('users')}</ListSubheader>}
              {allUserShareOptions.map((option) => (
                <MenuItem key={option.group_name + option.group_ref} value={option.group_ref}>
                  {option.group_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {false && (
            <ViewAssetTypeFormFields
              mode={assetTypeState.mode}
              selected={assetTypeState.selected}
              options={assetTypes}
              onModeChange={assetTypeState.onModeChange}
              onSelectedChange={assetTypeState.onSelectedChange}
            />
          )}
        </ModalContent>
        <ModalActions isLoading={isLoading} confirmLabel={t('common:save')} onCancel={onClose} />
      </form>
    </Dialog>
  )
}

export default ViewEditModal
