import { Check, MoreVert } from '@mui/icons-material'
import { Badge, IconButton, ListItemIcon, ListItemText, MenuItem, SvgIconTypeMap, Tooltip } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { useTranslation } from 'react-i18next'

type MenuItemButtonProps = {
  label: string
  disabled?: boolean
  selected?: boolean
  active?: boolean
  showBadge?: boolean
  description?: string
  Icon?: OverridableComponent<SvgIconTypeMap>
  onClick?: () => void
  onOptionsClick?: (event: React.MouseEvent<HTMLElement>) => void
}

export function MenuItemButton(props: MenuItemButtonProps) {
  const { label, disabled, selected, active, showBadge, description, Icon, onClick, onOptionsClick } = props

  const { t } = useTranslation()

  return (
    <MenuItem
      disabled={disabled}
      onClick={onClick}
      sx={{
        py: 1,
        my: 0.5,
        minHeight: '32px',
        maxHeight: description ? '52px' : '32px',
        backgroundColor: selected || active ? 'gray.50' : 'transparent',
        borderRadius: '4px',
        '&:hover .menu-item-button-options': {
          opacity: 1,
        },
      }}
    >
      {Icon && (
        <ListItemIcon>
          <Badge variant="dot" invisible={!showBadge} sx={{ '.MuiBadge-dot': { backgroundColor: 'arc.info' } }}>
            <Icon sx={{ color: 'gray.300' }} />
          </Badge>
        </ListItemIcon>
      )}

      <ListItemText
        primary={label}
        primaryTypographyProps={{ variant: 'body2', overflow: 'hidden', textOverflow: 'ellipsis' }}
        secondary={description}
        secondaryTypographyProps={{ fontSize: '12px' }}
      />

      {selected && <Check color="primary" fontSize="small" />}

      {onOptionsClick && (
        <Tooltip title={t('common:actions')} placement="top" arrow disableInteractive>
          <IconButton
            className="menu-item-button-options"
            onClick={(event) => {
              event.stopPropagation()
              onOptionsClick(event)
            }}
            sx={{ mr: -1, p: 0.5, opacity: 0 }}
          >
            <MoreVert fontSize="small" sx={{ color: 'gray.300' }} />
          </IconButton>
        </Tooltip>
      )}
    </MenuItem>
  )
}
