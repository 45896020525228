import { useNavigate } from 'react-router-dom'
import { BankAccountsRoot } from '../../components/bank-accounts-root'

function BankAccountsFlexView() {
  const navigate = useNavigate()

  function handleAccountSelect(bankAccountId: number, holderLei: string) {
    navigate(`/bank-accounts/${bankAccountId}/${holderLei}`)
  }

  return <BankAccountsRoot onBankAccountSelect={handleAccountSelect} />
}

export default BankAccountsFlexView
