import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material'
import { ColorPartial } from '@mui/material/styles/createPalette'
import { SxProps, Theme, alpha } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { TradeDirection } from '../../services/data/types/trade-ticket'

type TradeDirectionFieldProps = {
  value: TradeDirection | null
  disabled?: boolean
  buyLabel?: string
  sellLabel?: string
  onChange?: (tradeDirection: TradeDirection) => void
  sx?: SxProps<Theme>
}

export function TradeDirectionField(props: TradeDirectionFieldProps) {
  const { value, disabled, buyLabel, sellLabel, onChange, sx } = props

  const { t } = useTranslation('common')
  const theme = useTheme()

  const greenWithOpacity = alpha(theme.palette.success.main, 0.5)
  const errorColor = theme.palette.error as ColorPartial
  const redWithOpacity = alpha(errorColor[500]!, 0.5)

  const colorMap = { buy: greenWithOpacity, sell: redWithOpacity, cover: greenWithOpacity, short: redWithOpacity }
  const color = value ? colorMap[value] : undefined

  function handleChange(event: SelectChangeEvent) {
    onChange?.(event.target.value as TradeDirection)
  }

  return (
    <FormControl required disabled={disabled} sx={sx}>
      {!value && <InputLabel shrink={false}>{t('trade_direction_field.label')}</InputLabel>}
      <Select
        name="tradeDirection"
        required
        value={String(value || '')}
        onChange={handleChange}
        sx={{
          backgroundColor: color,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: color,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: color,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: color,
          },
        }}
      >
        <MenuItem value="buy">{buyLabel || t('trade_direction_field.buy')}</MenuItem>
        <MenuItem value="sell">{sellLabel || t('trade_direction_field.sell')}</MenuItem>
      </Select>
    </FormControl>
  )
}
