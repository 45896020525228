import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { ClassificationValuePayload } from '../../../services/data/types/classifications'

function useEditClassificationValueMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, MutationFnParams>({
    mutationFn: ({ classificationId, valueId, payload }) => {
      return api.updateClassificationValue(classificationId, valueId, payload)
    },
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type MutationFnParams = {
  classificationId: string | number
  valueId: string | number
  payload: ClassificationValuePayload
}

export default useEditClassificationValueMutation
