import { Navigate, useNavigate } from 'react-router-dom'
import AccountDetails from '../../components/account-details'
import { useAuth } from '../../data/auth-context'
import useUserInfo from '../../data/use-user-info'

function AccountDetailsView() {
  const navigate = useNavigate()

  const { logout } = useAuth()
  const { user, isLoading } = useUserInfo()

  async function handleLogout() {
    await logout()
    navigate('/openfin/views/login')
  }

  function handleSwitchTenantSuccess() {
    navigate('/openfin/views/login/tenant')
  }

  if (!isLoading && !user) {
    return <Navigate to="/openfin/views/login" />
  }

  return <AccountDetails onLogout={handleLogout} onSwitchTenantSuccess={handleSwitchTenantSuccess} />
}

export default AccountDetailsView
