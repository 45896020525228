import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useModelsQuery(datasetRef: string) {
  return useQuery({
    queryKey: ['dataset', datasetRef, 'models'],
    queryFn: () => api.getModels(datasetRef),
  })
}

export default useModelsQuery
