import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

export function useRequestAssetsSyncMutation() {
  const response = useMutation({
    mutationFn: api.requestAssetsSync,
  })

  return { ...response, error: parseError(response.error as AxiosError) }
}
