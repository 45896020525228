import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useTransactionQuery(lei: string | undefined, transactionRef: string | undefined) {
  const response = useQuery({
    queryKey: ['lei', lei, 'transactions', transactionRef],
    queryFn: () => {
      if (!lei || !transactionRef) {
        return
      }

      return api.getTransaction(lei, transactionRef)
    },
    enabled: !!lei && !!transactionRef,
  })

  return { ...response, error: parseError(response.error as AxiosError) }
}

export default useTransactionQuery
