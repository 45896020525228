import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import { CreateUserBody } from '../../../services/data/types/user'

function useCreateUserMutation() {
  return useMutation<AxiosResponse, CreateUserError, CreateUserBody>({
    mutationFn: api.createUser,
  })
}

export type CreateUserError = AxiosError<
  | {
      error: {
        code: CreateUserErrorCode
      }
    }
  | undefined
>

export enum CreateUserErrorCode {
  USERNAME_TAKEN = '0x0001000C',
}

export default useCreateUserMutation
