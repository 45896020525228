import { useNavigate, useParams } from 'react-router-dom'
import GroupDetails from '../../components/group-details'

const GroupDetailsFlexView = () => {
  const navigate = useNavigate()

  const { groupRef } = useParams()

  if (!groupRef) {
    return null
  }

  return <GroupDetails groupRef={groupRef} onBack={() => navigate('/settings/groups')} />
}

export default GroupDetailsFlexView
