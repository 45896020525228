import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useTransactionGridOptionsQuery(portfolioRef?: string | null) {
  return useQuery(['transaction', portfolioRef, 'options'], () => {
    if (!portfolioRef) {
      return null
    }

    return api.getTransactionGridOptions(portfolioRef)
  })
}

export default useTransactionGridOptionsQuery
