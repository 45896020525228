import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import { queryClient } from '../../../services/data/react-query'
import useDeleteDatapointMutation from '../data/use-delete-datapoint-mutation'

type DeleteDatapointModalProps = {
  open: boolean
  datasetRef: string
  datapointRef: string
  datapointName: string
  onClose: () => void
}

function DeleteDatapointModal(props: DeleteDatapointModalProps) {
  const { open, datasetRef, datapointRef, datapointName, onClose } = props
  const { t } = useTranslation('dataEngine')

  const {
    mutate: deleteDatapoint,
    error: deleteDatapointError,
    reset: deleteDatapointReset,
  } = useDeleteDatapointMutation()

  function handleModalClose() {
    deleteDatapointReset()
    onClose()
  }

  function handleDelete() {
    deleteDatapoint(
      { datapointRef },
      {
        onSuccess: () => {
          handleModalClose()
          queryClient.invalidateQueries(['dataset', datasetRef, 'datapoints'])
        },
      }
    )
  }

  return (
    <ConfirmationModal
      error={deleteDatapointError}
      open={open}
      title={t('datapoint_table.delete_datapoint')}
      confirmButtonText={t('common:delete')}
      onCloseButtonClick={handleModalClose}
      onConfirmButtonClick={handleDelete}
    >
      <Stack gap={6}>
        <Typography variant="body1">
          {t('datapoint_table.delete_datapoint_confirmation_text', { name: datapointName })}
        </Typography>
        <Typography variant="body2" color="gray.700">
          {t('datapoint_table.delete_datapoint_note')}
        </Typography>
      </Stack>
    </ConfirmationModal>
  )
}

export default DeleteDatapointModal
