import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resources, { Language } from './locales'

const DEFAULT_LANGUAGE: Language = 'en'

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANGUAGE,

  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
  },
})

export { i18n }
