import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useAddTenantMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, AddTenantParams>({
    mutationFn: (tenant_name) => api.addTenant(tenant_name),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type AddTenantParams = {
  tenant_name: string
}

export default useAddTenantMutation
