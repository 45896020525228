import { Stack, Typography } from '@mui/material'
import { BoxProps } from '@mui/system'
import { ReactNode } from 'react'

type ModalDetailRowProps = {
  label: string | ReactNode
  value?: string | number
  valueColor?: BoxProps['color']
  children?: ReactNode
}

export function ModalDetailRow(props: ModalDetailRowProps) {
  const { label, value, valueColor, children } = props

  let finalValue = String(value ?? '')
  if (!finalValue && !children) {
    finalValue = '-'
  }

  return (
    <Stack direction="row" alignItems="center">
      <Typography flex="1" variant="caption" fontSize="small" color="gray.700" maxWidth="200px" whiteSpace="pre-wrap">
        {label}
      </Typography>

      <Stack direction="row" alignItems="center" flex="1" gap="1">
        {finalValue && (
          <Typography variant="body2" fontSize="small" color={valueColor}>
            {finalValue}
          </Typography>
        )}
        {children}
      </Stack>
    </Stack>
  )
}
