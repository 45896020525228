import { Checkbox, FormControlLabel, FormGroup, PopoverOrigin, SxProps, Typography } from '@mui/material'
import { Box, Theme } from '@mui/system'
import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from 'react'
import QuickSearchField from '../../modules/data-engine/components/quick-search-field'
import { FilterValue, ParsedFilter } from '../../services/data/filter-parsing'
import FilterFormButtons from './filter-form-buttons'
import SelectFilterMenu from './select-filter-menu'

type ValuesFilterMenuProps = {
  options: ValuesFilterOptions[]
  selectedValues: ParsedFilter['filterValues'] | undefined
  isSelectedFilter: boolean
  onChange: (values: ParsedFilter['values']) => void
  onDelete: () => void
  onClose: () => void
  anchorEl: HTMLElement | null
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  disableBackdrop?: boolean
  sx?: SxProps<Theme>
}

export type ValuesFilterOptions = {
  value: FilterValue
  title?: string | null | undefined
}

function ValuesFilterMenu(props: ValuesFilterMenuProps) {
  const {
    options,
    selectedValues,
    isSelectedFilter,
    onChange,
    onDelete,
    onClose,
    anchorEl,
    anchorOrigin,
    transformOrigin,
    disableBackdrop,
    sx,
  } = props

  const [search, setSearch] = useState('')
  const [values, setValues] = useState<ParsedFilter['values']>([])

  const filtered = useMemo(() => {
    if (!search) {
      return options
    }

    return options.filter((option) => {
      const name = String(option.title || option.value)
      return name.toLowerCase().includes(search.toLowerCase())
    })
  }, [options, search])

  useEffect(() => {
    setValues(selectedValues?.map((v) => v.value) || [])
  }, [selectedValues])

  function handleChange(event: ChangeEvent<HTMLInputElement>, checked: boolean) {
    setValues((prev) => {
      let next = [...prev]
      if (checked) {
        next.push(event.target.value)
      } else {
        next = next.filter((v) => v !== event.target.value)
      }
      return next
    })
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()

    if (!values.length) {
      onDelete()
    } else {
      onChange(values)
    }
    onClose()
  }

  function isApplyDisabled() {
    if (!selectedValues) {
      return !values.length
    }
    if (values.length !== selectedValues.length) {
      return false
    }
    return selectedValues.every((v) => values.includes(v.value))
  }

  return (
    <SelectFilterMenu
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      disableBackdrop={disableBackdrop}
      sx={sx}
    >
      <Box sx={{ px: 2, py: 1 }}>
        <QuickSearchField autoFocus onValueChange={setSearch} />
      </Box>
      <form onSubmit={handleSubmit}>
        <FormGroup
          sx={{
            px: 3,
            gap: 0.25,
            flexWrap: 'unset',
            maxHeight: '450px',
            overflowY: 'auto',
            '& .MuiSvgIcon-root': {
              fontSize: 16,
            },
          }}
        >
          {filtered.map((option) => (
            <FormControlLabel
              key={String(option.value)}
              control={
                <Checkbox
                  value={option.value}
                  checked={!!values.find((v) => v === option.value)}
                  onChange={handleChange}
                />
              }
              label={<Typography variant="body2">{option.title || option.value}</Typography>}
            />
          ))}
        </FormGroup>
        <FilterFormButtons
          submitDisabled={isApplyDisabled()}
          clearDisabled={!isSelectedFilter}
          onCancel={onClose}
          onClear={onDelete}
        />
      </form>
    </SelectFilterMenu>
  )
}

export default ValuesFilterMenu
