import { Dialog } from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import useCreateDatasetMutation from '../data/use-create-dataset-mutation'
import DatasetForm from './dataset-form'

type NewDatasetModalProps = {
  open: boolean
  onClose: () => void
}

const initialFormValues = {
  owner: '',
  datasetName: '',
  published: false,
  sharedWith: [] as string[],
}

function NewDatasetModal(props: NewDatasetModalProps) {
  const { open, onClose } = props

  const { t } = useTranslation('dataEngine')
  const { mutate: createDataset, error: createDataserError, reset } = useCreateDatasetMutation()

  const [formValues, setFormValues] = useState(initialFormValues)

  useEffect(() => {
    const { owner, sharedWith } = formValues

    if (sharedWith.includes(owner)) {
      const filteredSharedGroups = sharedWith.filter((groupRef) => groupRef !== owner)
      handleChange('sharedWith', filteredSharedGroups)
    }
  }, [formValues.owner])

  function handleChange(name: string, value: string | string[] | boolean) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    const createDatasetProps = {
      groupRef: formValues.owner,
      body: {
        dataset_name: formValues.datasetName,
        is_published: formValues.published,
      },
    }

    createDataset(createDatasetProps, {
      onSuccess: () => {
        queryClient.invalidateQueries(['datasets'])
        handleClose()
      },
    })
  }

  function handleClose() {
    reset()
    onClose()
    setFormValues(initialFormValues)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('new_dataset')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={createDataserError} />
          <DatasetForm type="new" values={formValues} onChange={handleChange} />
        </ModalContent>
        <ModalActions confirmLabel={t('common:create')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default NewDatasetModal
