import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import { EditUserBody } from '../../../services/data/types/user'

function useEditAccoutMutation() {
  return useMutation<AxiosResponse, EditAccountError, EditUserBody>({
    mutationFn: api.editAccount,
  })
}

export type EditAccountError = AxiosError<
  | {
      error: {
        code: EditAccountCode
      }
    }
  | undefined
>

export enum EditAccountCode {
  USERNAME_TAKEN = '0x0001000C',
}

export default useEditAccoutMutation
