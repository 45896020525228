import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useAddPortfolioMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, AddPortfolioParams>({
    mutationFn: ({ groupRef, portfolioRef }) => api.addPortfolio(groupRef, portfolioRef),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type AddPortfolioParams = {
  groupRef: string
  portfolioRef: string
}

export default useAddPortfolioMutation
