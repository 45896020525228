import { Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'

type TableContainerHeaderProps = {
  title: string
  description?: string
  action?: ReactNode
}

function TableContainerHeader(props: TableContainerHeaderProps) {
  const { title, description, action } = props

  return (
    <Stack py={2} px={1.5}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          '& .MuiIconButton-root': {
            color: 'gray.300',
          },
        }}
      >
        <Typography variant="h5">{title}</Typography>
        {action}
      </Stack>
      {description && (
        <Typography variant="body2" color="gray.700" pt={1}>
          {description}
        </Typography>
      )}
    </Stack>
  )
}

export default TableContainerHeader
