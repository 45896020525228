import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import config from '../../../../config'
import PortfolioGrid from '../../components/portfolio-grid'
import usePortfoliosQuery from '../../data/use-portfolios-query'

function PortfoliosGridPage() {
  const navigate = useNavigate()

  const { portfolioRef } = useParams()

  const portfoliosQuery = usePortfoliosQuery()
  const portfolios = portfoliosQuery.data?.data

  useEffect(() => {
    if (!portfolioRef && portfoliosQuery.isSuccess) {
      const portfolio = portfolios?.[0]
      if (portfolio) {
        navigate(`/portfolios/${portfolio.portfolio_ref}`)
      }
    }
  }, [portfolioRef, portfoliosQuery])

  function handlePortfolioSelect(portfolioRef: string) {
    navigate(`/portfolios/${portfolioRef}`)
  }

  function handleSecuritySelect(assetRef: string) {
    navigate(`${config.baseUrl}portfolios/${portfolioRef}/${assetRef}`)
  }

  function handleLiquiditySelect(assetRef: string) {
    navigate(`/liquidity?assetRef=${assetRef}`)
  }

  function handleNewTrade() {
    navigate(`/trade-ticket/${portfolioRef}`)
  }

  function handleNewCashflow() {
    navigate(`/trade-ticket/${portfolioRef}?cashflow`)
  }

  function handleClosePosition(yRef: string, aggregations: string[], datasetRef: string | null) {
    let url = `/trade-ticket/${portfolioRef}/y-ref/${encodeURIComponent(yRef)}/close-out-position`
    const params = []

    if (aggregations.length) {
      params.push(`aggregations=${aggregations.join(',')}`)
    }

    if (datasetRef) {
      params.push(`datasetRef=${datasetRef}`)
    }

    if (params.length) {
      url += `?${params.join('&')}`
    }

    navigate(url)
  }

  function handleOpenCompliance() {
    navigate(`/compliance/${portfolioRef}`)
  }

  return (
    <PortfolioGrid
      portfolioRef={portfolioRef}
      onPortfolioSelect={handlePortfolioSelect}
      onSecuritySelect={handleSecuritySelect}
      onLiquiditySelect={handleLiquiditySelect}
      onNewTrade={handleNewTrade}
      onNewCashflow={handleNewCashflow}
      onClosePosition={handleClosePosition}
      onOpenCompliance={handleOpenCompliance}
    />
  )
}

export default PortfoliosGridPage
