import { AccountCircle, CloseOutlined, DateRange, Star } from '@mui/icons-material'
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { addDays, isValid, lastDayOfMonth, parse, setDate, subDays, subMonths } from 'date-fns'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import DateTimeFilterMenu, { getDateFilterLabel } from '../../../components/filters/date-time-filter-menu'
import FilterButton from '../../../components/filters/filter-button'
import ValueFilterMenu, { ValueFilterOptions } from '../../../components/filters/value-filter-menu'
import { FilterValue } from '../../../services/data/filter-parsing'
import { AuditLog } from '../../../services/data/types/audit-trail'
import { GridDataViewFilterOperator, filterOperators } from '../../../services/data/types/grid-data-view'
import { formatDate, formatNaiveDate } from '../../../utils/dates'
import useOpenState from '../../../utils/hooks/use-open-state'
import useAuditLogsQuery from '../data/use-audit-logs-query'
import useAuditNavigatorQuery from '../data/use-audit-navigator-query'
import useUsersQuery from '../data/use-users-query'
import { useSettingsUpdateLoadingState } from './use-settings-set-loading'

function AuditTrail() {
  const { t } = useTranslation('settings')

  const dateButtonRef = useRef<HTMLElement | null>(null)
  const usersButtonRef = useRef<HTMLElement | null>(null)
  const auditTypesButtonRef = useRef<HTMLElement | null>(null)

  const dateFilterModal = useOpenState()
  const usersFilterModal = useOpenState()
  const auditTypesFilterModal = useOpenState()

  const [searchParams, setSearchParams] = useSearchParams()
  const { operator, selectedValues, from, to, selectedUserRef, selectedAuditType } = parseParams(searchParams)

  const logsResponse = useAuditLogsQuery({
    from,
    to,
    userRef: selectedUserRef,
    auditType: selectedAuditType,
  })
  const usersReponse = useUsersQuery()
  const navigatorResponse = useAuditNavigatorQuery()

  const logs = logsResponse.data?.data || []
  const users = usersReponse.data?.data || []
  const auditTypes = navigatorResponse.data?.data.audit_types || []

  const isLoading = logsResponse.isFetching || usersReponse.isFetching || navigatorResponse.isFetching

  useSettingsUpdateLoadingState(isLoading)

  const usersOptions: ValueFilterOptions[] = [{ value: '', title: t('audit.all_users') }]
  const auditTypesOptions: ValueFilterOptions[] = [{ value: '', title: t('audit.all_audit_types') }]

  users.forEach((user) => {
    usersOptions.push({
      value: user.user_ref,
      title: `${user.firstname} ${user.lastname}`,
    })
  })

  auditTypes.forEach((auditType) => {
    auditTypesOptions.push({
      value: auditType.audit_type,
    })
  })

  const selectedUser = usersOptions.find((userOption) => userOption.value === selectedUserRef)
  const hasUserSelected = selectedUser?.value !== ''
  const hasAuditTypeSelected = selectedAuditType !== ''

  const dateFilterLabel = getDateFilterLabel(
    operator,
    selectedValues.map((value) => ({ value }))
  )
  const userLabel = selectedUser?.title || (t('audit.all_users') as string)
  const auditTypeLabel = selectedAuditType || t('audit.all_audit_types')

  function handleDateFilterChange(operator: GridDataViewFilterOperator, values: FilterValue[]) {
    searchParams.set('op', operator)
    searchParams.delete('values')

    values.forEach((value) => {
      searchParams.append('values', String(value))
    })

    setSearchParams(searchParams)
  }

  function handleUserRefChange(value: string) {
    if (value) {
      searchParams.set('user_ref', value)
    } else {
      searchParams.delete('user_ref')
    }
    setSearchParams(searchParams)
  }

  function handleAuditTypeChange(value: string) {
    if (value) {
      searchParams.set('audit_type', value)
    } else {
      searchParams.delete('audit_type')
    }
    setSearchParams(searchParams)
  }

  function handleClearFilters() {
    searchParams.delete('op')
    searchParams.delete('values')
    searchParams.delete('user_ref')
    searchParams.delete('audit_type')
    setSearchParams(searchParams)
  }

  return (
    <>
      <Stack p={2} height="100%" overflow="auto">
        <Stack direction="row" alignItems="center" py={2} gap={2}>
          <FilterButton
            ref={dateButtonRef}
            label={dateFilterLabel}
            showArrow
            Icon={DateRange}
            onClick={dateFilterModal.toggle}
          />
          <FilterButton
            ref={usersButtonRef}
            label={userLabel}
            isActive={hasUserSelected}
            showArrow
            Icon={AccountCircle}
            onClick={usersFilterModal.toggle}
          />
          <FilterButton
            ref={auditTypesButtonRef}
            label={auditTypeLabel}
            isActive={hasAuditTypeSelected}
            showArrow
            Icon={Star}
            onClick={auditTypesFilterModal.toggle}
          />
          <Button onClick={handleClearFilters}>
            <CloseOutlined fontSize="small" />
            <Typography color="primary" textTransform="none" mx="5px">
              {t('clear-filters')}
            </Typography>
          </Button>
        </Stack>
        <Stack sx={{ height: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table className="grid" size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{t('audit.table.created_at')}</TableCell>
                  <TableCell>{t('audit.table.user')}</TableCell>
                  <TableCell>{t('audit.table.action')}</TableCell>
                  <TableCell>{t('audit.table.audit_type')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((log: AuditLog) => (
                  <TableRow key={log.id} hover>
                    <TableCell>
                      <Box className="row-content">{formatDate(log.created_at, 'yyyy-MM-dd hh:mm:ss')}</Box>
                    </TableCell>
                    <TableCell>
                      <Box className="row-content">{log.user_full_name || `<${t('empty')}>`}</Box>
                    </TableCell>
                    <TableCell>
                      <Box className="row-content">{log.description}</Box>
                    </TableCell>
                    <TableCell>
                      <Box className="row-content">{log.audit_type}</Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      {dateFilterModal.isOpen && (
        <DateTimeFilterMenu
          selectedOperator={operator as GridDataViewFilterOperator}
          selectedValues={selectedValues}
          onChange={handleDateFilterChange}
          onClose={dateFilterModal.close}
          anchorEl={dateButtonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{ mt: 1 }}
        />
      )}
      {usersFilterModal.isOpen && (
        <ValueFilterMenu
          options={usersOptions}
          selectedValue={selectedUserRef}
          onChange={handleUserRefChange}
          onClose={usersFilterModal.close}
          anchorEl={usersButtonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{ mt: 1 }}
        />
      )}
      {auditTypesFilterModal.isOpen && (
        <ValueFilterMenu
          options={auditTypesOptions}
          selectedValue={selectedAuditType}
          onChange={handleAuditTypeChange}
          onClose={auditTypesFilterModal.close}
          anchorEl={auditTypesButtonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{ mt: 1 }}
        />
      )}
    </>
  )
}

export default AuditTrail

type DateFilter = {
  from?: Date
  to?: Date
}

function parseParams(searchParams: URLSearchParams) {
  const op = searchParams.get('op') || ''
  let values = searchParams.getAll('values')
  const selectedUserRef = searchParams.get('user_ref') || ''
  const selectedAuditType = searchParams.get('audit_type') || ''

  let operator = filterOperators.includes(op as any) ? (op as GridDataViewFilterOperator) : undefined

  if (!operator) {
    operator = 'lastxdays'
    values = ['7']
  }

  const selectedValues = parseValues(values)
  const dateValues = dateFilterParsers[operator](selectedValues)
  const from = dateValues.from && formatNaiveDate(dateValues.from)
  const to = dateValues.to && formatNaiveDate(dateValues.to)

  return { operator, selectedValues, from, to, selectedUserRef, selectedAuditType }
}

function parseValues(values: string[]): (string | number)[] {
  return values
    .map((value) => {
      const onlyNumbers = value.match(/^\d+$/)
      if (onlyNumbers) {
        return Number.parseInt(value)
      }

      const isValidDate = isValid(parse(value, 'yyyy-MM-dd', new Date()))
      return isValidDate ? value : ''
    })
    .filter((value) => !!value)
}

/**
 * Parses string dates into the correct filter representation for the given operation.
 *
 * Assuming that the selected values have the correct type (string)
 * and quantity (0, 1, or 2) for the operator, no extra validation is done.
 *
 * Eg.:
 *
 * operation | values
 * --------- | ----------
 * today     | []
 * lt        | ['2022-10-10T00:00:00Z']
 */
const dateFilterParsers: { [key in GridDataViewFilterOperator]: (values: Array<string | number>) => DateFilter } = {
  eq: function (values: (string | number)[]): DateFilter {
    const date = new Date(values[0]!)

    return { from: date, to: date }
  },
  lt: function (values: (string | number)[]): DateFilter {
    const date = new Date(values[0]!)

    return { to: date }
  },
  lte: function (values: (string | number)[]): DateFilter {
    const date = new Date(values[0]!)

    return { to: date }
  },
  gt: function (values: (string | number)[]): DateFilter {
    const date = new Date(values[0]!)

    return { from: date }
  },
  gte: function (values: (string | number)[]): DateFilter {
    const date = new Date(values[0]!)

    return { from: date }
  },
  btwn: function (values: (string | number)[]): DateFilter {
    const from = new Date(values[0]!)
    const to = new Date(values[1]!)

    return { from, to }
  },
  today: function (_values: (string | number)[]): DateFilter {
    const today = new Date()

    return { from: today, to: today }
  },
  yesterday: function (_values: (string | number)[]): DateFilter {
    const yesterday = subDays(new Date(), 1)

    return { from: yesterday, to: yesterday }
  },
  thismonth: function (_values: (string | number)[]): DateFilter {
    const today = new Date()
    const from = setDate(today, 1)

    return { from, to: today }
  },
  lastmonth: function (_values: (string | number)[]): DateFilter {
    const today = new Date()
    const from = setDate(subMonths(today, 1), 1)
    const to = lastDayOfMonth(subMonths(today, 1))

    return { from, to }
  },
  lastxdays: function (values: (string | number)[]): DateFilter {
    const today = new Date()
    const from = subDays(today, Number(values[0]))

    return { from }
  },
  nextxdaysinc: function (values: (string | number)[]): DateFilter {
    const today = new Date()
    const from = today
    const to = addDays(today, Number(values[0]))

    return { from, to }
  },
  nextxdaysexc: function (values: (string | number)[]): DateFilter {
    const today = new Date()
    const from = addDays(today, 1)
    const to = addDays(today, Number(values[0]))

    return { from, to }
  },
  contains: function (): DateFilter {
    return {}
  },
}
