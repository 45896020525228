import * as Sentry from '@sentry/react'
import { LicenseManager } from 'ag-grid-enterprise'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import AppProviders from './app-providers'
import FlexLayoutLayout from './components/layouts/flex-layout-layout'
import MainNavLayout from './components/layouts/main-nav-layout'
import config from './config'
import './i18n'
import AccountFlexView from './modules/account/screens/flex-layout/account-flex-view'
import AccountView from './modules/account/screens/openfin/account-view'
import AccountPage from './modules/account/screens/pages/account-page'
import RequiresAuth from './modules/auth/components/requires-auth'
import AccountDetailsFlexView from './modules/auth/screens/flex-layout/account-details-flex-view'
import ForgotPasswordFlexView from './modules/auth/screens/flex-layout/forgot-password-flex-view'
import LoginFlexView from './modules/auth/screens/flex-layout/login-flex-view'
import TenantLoginFlexView from './modules/auth/screens/flex-layout/tenant-login-flex-view'
import AccountDetailsView from './modules/auth/screens/openfin/account-details-view'
import ForgotPasswordView from './modules/auth/screens/openfin/forgot-password-view'
import LoginView from './modules/auth/screens/openfin/login-view'
import RequiresAuthOpenFin from './modules/auth/screens/openfin/requires-auth'
import TenantLoginView from './modules/auth/screens/openfin/tenant-login-view'
import AccountDetailsPage from './modules/auth/screens/pages/account-details-page'
import ForgotPasswordPage from './modules/auth/screens/pages/forgot-password-page'
import LoginPage from './modules/auth/screens/pages/login-page'
import ResetPasswordPage from './modules/auth/screens/pages/reset-password-page'
import TenantLoginPage from './modules/auth/screens/pages/tenant-login-page'
import BankAccountsFlexView from './modules/bank-accounts/screens/flex-layout/bank-accounts-flex-view'
import BankAccountsOpenfinView from './modules/bank-accounts/screens/openfin/bank-accounts-openfin-view'
import BankAccountsPage from './modules/bank-accounts/screens/pages/bank-accounts-page'
import ComplianceFlexView from './modules/compliance/screens/compliance-flex-view'
import AssetStaticDetailsFlexView from './modules/data-engine/screens/flex-layout/asset-static-details-flex-view'
import ClassificationDetailsFlexView from './modules/data-engine/screens/flex-layout/classification-details-flex-view'
import DataEngineFlexView from './modules/data-engine/screens/flex-layout/data-engine-flex-view'
import DatapointDetailsFlexView from './modules/data-engine/screens/flex-layout/datapoint-details-flex-view'
import DatasetDetailsFlexView from './modules/data-engine/screens/flex-layout/dataset-details-flex-view'
import DatasetsFlexView from './modules/data-engine/screens/flex-layout/datasets-flex-view'
import DummyFlexView from './modules/data-engine/screens/flex-layout/dummy-flex-view'
import ModelDetailsFlexView from './modules/data-engine/screens/flex-layout/model-details-flex-view'
import AssetStaticDetailsView from './modules/data-engine/screens/openfin/asset-static-details-view'
import ClassificationDetailsView from './modules/data-engine/screens/openfin/classification-details-view'
import DataEngineView from './modules/data-engine/screens/openfin/data-engine-view'
import DatapointDetailsView from './modules/data-engine/screens/openfin/datapoint-details-view'
import DatasetDetailsView from './modules/data-engine/screens/openfin/dataset-details-view'
import DatasetsView from './modules/data-engine/screens/openfin/datasets-view'
import ModelDetailsView from './modules/data-engine/screens/openfin/model-details-view'
import AssetStaticDetailsPage from './modules/data-engine/screens/pages/asset-static-details-page'
import ClassificationDetailsPage from './modules/data-engine/screens/pages/classification-details-page'
import DataEnginePage from './modules/data-engine/screens/pages/data-engine-page'
import DatapointDetailsPage from './modules/data-engine/screens/pages/datapoint-details-page'
import DatasetDetailsPage from './modules/data-engine/screens/pages/dataset-details-page'
import DatasetsPage from './modules/data-engine/screens/pages/datasets-page'
import DummyPage from './modules/data-engine/screens/pages/dummy-page'
import ModelDetailsPage from './modules/data-engine/screens/pages/model-details-page'
import LiquidityMonitorFlexView from './modules/liquidity/screens/flex-layout/liquidity-monitor-flex-view'
import LiquidityMonitorView from './modules/liquidity/screens/openfin/liquidity-monitor-view'
import LiquidityMonitorPage from './modules/liquidity/screens/pages/liquidity-monitor-page'
import OrderBlotterFlexView from './modules/order-blotter/screens/flex-layout/order-blotter-flex-view'
import OrderBlotterView from './modules/order-blotter/screens/openfin/order-blotter-view'
import OrderBlotterPage from './modules/order-blotter/screens/pages/order-blotter-page'
import PortfoliosGridFlexView from './modules/portfolios/screens/flex-layout/portfolios-grid-flex-view'
import SecurityDetailsFlexView from './modules/portfolios/screens/flex-layout/security-details-flex-view'
import TransactionsGridFlexView from './modules/portfolios/screens/flex-layout/transactions-grid-flex-view'
import PortfoliosGridView from './modules/portfolios/screens/openfin/portfolios-grid-view'
import SecurityDetaislView from './modules/portfolios/screens/openfin/security-details-view'
import TransactionsGridView from './modules/portfolios/screens/openfin/transactions-grid-view'
import PortfoliosGridPage from './modules/portfolios/screens/pages/portfolios-grid-page'
import SecurityDetailsPage from './modules/portfolios/screens/pages/security-details-page'
import TransactionsGridPage from './modules/portfolios/screens/pages/transactions-grid-page'
import DummySetting from './modules/settings/components/dummy-setting'
import AuditTrailFlexView from './modules/settings/screens/flex-layout/audit-trail-flex-view'
import GroupDetailsFlexView from './modules/settings/screens/flex-layout/group-details-flex-view'
import GroupsFlexView from './modules/settings/screens/flex-layout/groups-flex-view'
import PortfolioDetailsFlexView from './modules/settings/screens/flex-layout/portfolio-details-flex-view'
import PortfoliosSettingsFlexView from './modules/settings/screens/flex-layout/portfolios-settings-flex-view'
import SettingsFlexView from './modules/settings/screens/flex-layout/settings-flex-view'
import TenantsFlexView from './modules/settings/screens/flex-layout/tenants-flex-view'
import UserDetailsFlexView from './modules/settings/screens/flex-layout/user-details-flex-view'
import UsersFlexView from './modules/settings/screens/flex-layout/users-flex-view'
import ViewsFlexView from './modules/settings/screens/flex-layout/views-flex-view'
import AuditTrailView from './modules/settings/screens/openfin/audit-trail-view'
import GroupDetailsView from './modules/settings/screens/openfin/group-details-view'
import GroupsView from './modules/settings/screens/openfin/groups-view'
import PortfolioDetailsView from './modules/settings/screens/openfin/portfolio-details-view'
import PortfoliosSettingsView from './modules/settings/screens/openfin/portfolios-settings-view'
import SettingsView from './modules/settings/screens/openfin/settings-view'
import TenantsView from './modules/settings/screens/openfin/tenants-view'
import UserDetailsView from './modules/settings/screens/openfin/user-details-view'
import UsersView from './modules/settings/screens/openfin/users-view'
import ViewsView from './modules/settings/screens/openfin/views-view'
import AuditTrailPage from './modules/settings/screens/pages/audit-trail-page'
import GroupDetailsPage from './modules/settings/screens/pages/group-details-page'
import GroupsPage from './modules/settings/screens/pages/groups-page'
import PortfolioDetailsPage from './modules/settings/screens/pages/portfolio-details-page'
import PortfoliosSettingsPage from './modules/settings/screens/pages/portfolios-settings-page'
import SettingsPage from './modules/settings/screens/pages/settings-page'
import TenantsPage from './modules/settings/screens/pages/tenants-page'
import UserDetailsPage from './modules/settings/screens/pages/user-details-page'
import UsersPage from './modules/settings/screens/pages/users-page'
import ViewsPage from './modules/settings/screens/pages/views-page'
import TradeTicketFlexView from './modules/trade-ticket/screens/trade-ticket-flex-view'
import TradeTicketOpenfinView from './modules/trade-ticket/screens/trade-ticket-openfin-view'
import TradeTicketPage from './modules/trade-ticket/screens/trade-ticket-page'
import OpenFinFrame from './services/openfin/frame'
import './styles/global.css'

LicenseManager.setLicenseKey(config.agGridLicenseKey)

Sentry.init({
  dsn: config.sentryDsnUrl,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: import.meta.env.PROD,
})

function App() {
  if (location.pathname.startsWith('/reset-password')) {
    return (
      <AppProviders>
        <BrowserRouter basename={config.baseUrl}>
          <Routes>
            <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
          </Routes>
        </BrowserRouter>
      </AppProviders>
    )
  }

  if (!config.enableBrowserVersion) {
    return (
      <AppProviders>
        <FlexLayoutLayout>
          <Routes>
            <Route path="/login" element={<LoginFlexView />} />
            <Route path="/login/tenant" element={<TenantLoginFlexView />} />
            <Route path="/forgot-password" element={<ForgotPasswordFlexView />} />
            <Route path="/account-details" element={<AccountDetailsFlexView />} />
            <Route path="/" element={<RequiresAuth />}>
              <Route path="/account" element={<AccountFlexView />} />
              <Route path="/data-engine" element={<DataEngineFlexView />}>
                <Route path="/data-engine/datasets" element={<DatasetsFlexView />} />
                <Route path="/data-engine/datasets/:datasetRef">
                  <Route index element={<DatasetDetailsFlexView />} />
                  <Route path="classifications/:classificationId" element={<ClassificationDetailsFlexView />} />
                  <Route path="models/:modelRef" element={<ModelDetailsFlexView />} />
                </Route>
                <Route path="/data-engine/assets/:assetTag" element={<AssetStaticDetailsFlexView />} />
                <Route path="/data-engine/classifications/:classificationId" element={<DummyFlexView />} />
                <Route path="/data-engine/models/:modelRef" element={<DummyFlexView />} />
              </Route>
              <Route
                path="/data-engine/datasets/:datasetRef/datapoints/:datapointRef/assets/:assetRef"
                element={<DatapointDetailsFlexView />}
              />
              <Route path="/liquidity" element={<LiquidityMonitorFlexView />} />
              <Route path="/bank-accounts" element={<BankAccountsFlexView />} />
              <Route path="/bank-accounts/:bankAccountId/:holderLei" element={<BankAccountsFlexView />} />
              <Route path="/settings" element={<SettingsFlexView />}>
                <Route index element={<Navigate replace to="/settings/users" />} />
                <Route path="/settings/tenant" element={<DummySetting />} />
                <Route path="/settings/users" element={<UsersFlexView />} />
                <Route path="/settings/users/:userRef" element={<UserDetailsFlexView />} />
                <Route path="/settings/groups" element={<GroupsFlexView />} />
                <Route path="/settings/groups/:groupRef" element={<GroupDetailsFlexView />} />
                <Route path="/settings/views" element={<ViewsFlexView />} />
                <Route path="/settings/portfolios" element={<PortfoliosSettingsFlexView />} />
                <Route path="/settings/portfolios/:portfolioRef" element={<PortfolioDetailsFlexView />} />
                <Route path="/settings/compliance" element={<DummySetting />} />
                <Route path="/settings/counterparties" element={<DummySetting />} />
                <Route path="/settings/general-ledger" element={<DummySetting />} />
                <Route path="/settings/tenants" element={<TenantsFlexView />} />
                <Route path="/settings/legal-entities" element={<DummySetting />} />
                <Route path="/settings/non-tenanted-users" element={<DummySetting />} />
                <Route path="/settings/chains" element={<DummySetting />} />
                <Route path="/settings/audit-trail" element={<AuditTrailFlexView />} />
              </Route>
              <Route path="/portfolios" element={<PortfoliosGridFlexView />} />
              <Route path="/portfolios/:portfolioRef" element={<PortfoliosGridFlexView />} />
              <Route path="/portfolios/:portfolioRef/:assetRef" element={<SecurityDetailsFlexView />} />
              <Route path="/transactions" element={<TransactionsGridFlexView />} />
              <Route path="/transactions/:portfolioRef" element={<TransactionsGridFlexView />} />
              <Route path="/compliance" element={<ComplianceFlexView />} />
              <Route path="/compliance/:portfolioRef" element={<ComplianceFlexView />} />
              <Route path="/order-blotter" element={<OrderBlotterFlexView />} />
              <Route path="/order-blotter/:orderBatchRef" element={<OrderBlotterFlexView />} />
              <Route path="/trade-ticket/:portfolioRef" element={<TradeTicketFlexView />} />
              <Route
                path="/trade-ticket/:portfolioRef/transactions/:transactionRef/correct"
                element={<TradeTicketFlexView />}
              />
              <Route
                path="/trade-ticket/:portfolioRef/transactions/segments/:closeTransactionRef/close"
                element={<TradeTicketFlexView />}
              />
              <Route
                path="/trade-ticket/:portfolioRef/transactions/deals/:dealRef/confirm"
                element={<TradeTicketFlexView />}
              />
              <Route
                path="/trade-ticket/:portfolioRef/y-ref/:yRef/close-out-position"
                element={<TradeTicketFlexView />}
              />
            </Route>
          </Routes>
        </FlexLayoutLayout>
      </AppProviders>
    )
  }

  return (
    <AppProviders>
      <BrowserRouter basename={config.baseUrl}>
        <Routes>
          <Route path="/" element={<Navigate to="/portfolios" />} />
          <Route path="/frame" element={<OpenFinFrame />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/login/tenant" element={<TenantLoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/account-details" element={<AccountDetailsPage />} />
          <Route path="/" element={<RequiresAuth />}>
            <Route element={<MainNavLayout />}>
              <Route path="/account" element={<AccountPage />} />
              <Route path="/data-engine" element={<DataEnginePage />}>
                <Route path="/data-engine/datasets" element={<DatasetsPage />} />
                <Route path="/data-engine/datasets/:datasetRef">
                  <Route index element={<DatasetDetailsPage />} />
                  <Route path="classifications/:classificationId" element={<ClassificationDetailsPage />} />
                  <Route path="models/:modelRef" element={<ModelDetailsPage />} />
                </Route>
                <Route path="/data-engine/assets/:assetTag" element={<AssetStaticDetailsPage />} />
                <Route path="/data-engine/classifications/:classificationId" element={<DummyPage />} />
                <Route path="/data-engine/models/:modelRef" element={<DummyPage />} />
              </Route>
              <Route
                path="/data-engine/datasets/:datasetRef/datapoints/:datapointRef/assets/:assetRef"
                element={<DatapointDetailsPage />}
              />
              <Route path="/liquidity" element={<LiquidityMonitorPage />} />
              <Route path="/bank-accounts" element={<BankAccountsPage />} />
              <Route path="/bank-accounts/:bankAccountId/:holderLei" element={<BankAccountsPage />} />
              <Route path="/settings" element={<SettingsPage />}>
                <Route index element={<Navigate replace to="/settings/users" />} />
                <Route path="/settings/tenant" element={<DummySetting />} />
                <Route path="/settings/users" element={<UsersPage />} />
                <Route path="/settings/users/:userRef" element={<UserDetailsPage />} />
                <Route path="/settings/groups" element={<GroupsPage />} />
                <Route path="/settings/groups/:groupRef" element={<GroupDetailsPage />} />
                <Route path="/settings/views" element={<ViewsPage />} />
                <Route path="/settings/portfolios" element={<PortfoliosSettingsPage />} />
                <Route path="/settings/portfolios/:portfolioRef" element={<PortfolioDetailsPage />} />
                <Route path="/settings/compliance" element={<DummySetting />} />
                <Route path="/settings/counterparties" element={<DummySetting />} />
                <Route path="/settings/general-ledger" element={<DummySetting />} />
                <Route path="/settings/tenants" element={<TenantsPage />} />
                <Route path="/settings/legal-entities" element={<DummySetting />} />
                <Route path="/settings/non-tenanted-users" element={<DummySetting />} />
                <Route path="/settings/chains" element={<DummySetting />} />
                <Route path="/settings/audit-trail" element={<AuditTrailPage />} />
              </Route>
              <Route path="/portfolios" element={<PortfoliosGridPage />} />
              <Route path="/portfolios/:portfolioRef" element={<PortfoliosGridPage />} />
              <Route path="/portfolios/:portfolioRef/:assetRef" element={<SecurityDetailsPage />} />
              <Route path="/transactions" element={<TransactionsGridPage />} />
              <Route path="/transactions/:portfolioRef" element={<TransactionsGridPage />} />
              <Route path="/compliance" element={<ComplianceFlexView />} />
              <Route path="/compliance/:portfolioRef" element={<ComplianceFlexView />} />
              <Route path="/order-blotter" element={<OrderBlotterPage />} />
              <Route path="/order-blotter/:orderBatchRef" element={<OrderBlotterPage />} />
              <Route path="/trade-ticket/:portfolioRef" element={<TradeTicketPage />} />
              <Route
                path="/trade-ticket/:portfolioRef/transactions/:transactionRef/correct"
                element={<TradeTicketPage />}
              />
              <Route
                path="/trade-ticket/:portfolioRef/transactions/segments/:closeTransactionRef/close"
                element={<TradeTicketPage />}
              />
              <Route
                path="/trade-ticket/:portfolioRef/transactions/deals/:dealRef/confirm"
                element={<TradeTicketPage />}
              />
              <Route path="/trade-ticket/:portfolioRef/y-ref/:yRef/close-out-position" element={<TradeTicketPage />} />
            </Route>
          </Route>

          <Route path="/openfin/views/login" element={<LoginView />} />
          <Route path="/openfin/views/forgot-password" element={<ForgotPasswordView />} />
          <Route path="/openfin/views/login/tenant" element={<TenantLoginView />} />
          <Route path="/openfin/views/account-details" element={<AccountDetailsView />} />
          <Route path="/openfin" element={<RequiresAuthOpenFin />}>
            <Route path="/openfin/views/portfolios/grid" element={<PortfoliosGridView />} />
            <Route path="/openfin/views/portfolios/grid/:portfolioRef" element={<PortfoliosGridView />} />
            <Route path="/openfin/views/portfolios/:portfolioRef/:assetRef" element={<SecurityDetaislView />} />
            <Route path="/openfin/views/transactions" element={<TransactionsGridView />} />
            <Route path="/openfin/views/transactions/:portfolioRef" element={<TransactionsGridView />} />
            <Route path="/openfin/views/compliance" element={<ComplianceFlexView />} />
            <Route path="/openfin/views/compliance/:portfolioRef" element={<ComplianceFlexView />} />
            <Route path="/openfin/views/order-blotter" element={<OrderBlotterView />} />
            <Route path="/openfin/views/order-blotter/:orderBatchRef" element={<OrderBlotterView />} />
            <Route path="/openfin/views/trade-ticket/:portfolioRef" element={<TradeTicketOpenfinView />} />
            <Route
              path="/openfin/views/trade-ticket/:portfolioRef/transactions/:transactionRef/correct"
              element={<TradeTicketOpenfinView />}
            />
            <Route
              path="/openfin/views/trade-ticket/:portfolioRef/transactions/segments/:closeTransactionRef/close"
              element={<TradeTicketOpenfinView />}
            />
            <Route
              path="/openfin/views/trade-ticket/:portfolioRef/transactions/deals/:dealRef/confirm"
              element={<TradeTicketOpenfinView />}
            />
            <Route
              path="/openfin/views/trade-ticket/:portfolioRef/y-ref/:yRef/close-out-position"
              element={<TradeTicketOpenfinView />}
            />
            <Route path="/openfin/views/data-engine" element={<DataEngineView />}>
              <Route index element={<Navigate replace to="/openfin/views/data-engine/datasets" />} />
              <Route path="/openfin/views/data-engine/datasets" element={<DatasetsView />} />
              <Route path="/openfin/views/data-engine/datasets/:datasetRef">
                <Route index element={<DatasetDetailsView />} />
                <Route path="classifications/:classificationId" element={<ClassificationDetailsView />} />
                <Route path="models/:modelRef" element={<ModelDetailsView />} />
              </Route>
              <Route path="/openfin/views/data-engine/assets/:assetTag" element={<AssetStaticDetailsView />} />
              <Route path="/openfin/views/data-engine/classifications/:classificationId" element={<DummyPage />} />
              <Route path="/openfin/views/data-engine/models/:modelRef" element={<DummyPage />} />
              <Route path="*" element={<Navigate replace to="/openfin/views/data-engine/datasets" />} />
            </Route>
            <Route
              path="/openfin/views/data-engine/datasets/:datasetRef/datapoints/:datapointRef/assets/:assetRef"
              element={<DatapointDetailsView />}
            />
            <Route path="/openfin/views/liquidity" element={<LiquidityMonitorView />} />
            <Route path="/openfin/views/bank-accounts" element={<BankAccountsOpenfinView />} />
            <Route
              path="/openfin/views/bank-accounts/:bankAccountId/:holderLei"
              element={<BankAccountsOpenfinView />}
            />
            <Route path="/openfin/views/account" element={<AccountView />} />
            <Route path="/openfin/views/settings" element={<SettingsView />}>
              <Route index element={<Navigate replace to="/openfin/views/settings/users" />} />
              <Route path="/openfin/views/settings/tenant" element={<DummySetting />} />
              <Route path="/openfin/views/settings/users" element={<UsersView />} />
              <Route path="/openfin/views/settings/users/:userRef" element={<UserDetailsView />} />
              <Route path="/openfin/views/settings/groups" element={<GroupsView />} />
              <Route path="/openfin/views/settings/groups/:groupRef" element={<GroupDetailsView />} />
              <Route path="/openfin/views/settings/views" element={<ViewsView />} />
              <Route path="/openfin/views/settings/portfolios" element={<PortfoliosSettingsView />} />
              <Route path="/openfin/views/settings/portfolios/:portfolioRef" element={<PortfolioDetailsView />} />
              <Route path="/openfin/views/settings/compliance" element={<DummySetting />} />
              <Route path="/openfin/views/settings/counterparties" element={<DummySetting />} />
              <Route path="/openfin/views/settings/general-ledger" element={<DummySetting />} />
              <Route path="/openfin/views/settings/tenants" element={<TenantsView />} />
              <Route path="/openfin/views/settings/legal-entities" element={<DummySetting />} />
              <Route path="/openfin/views/settings/non-tenanted-users" element={<DummySetting />} />
              <Route path="/openfin/views/settings/chains" element={<DummySetting />} />
              <Route path="/openfin/views/settings/audit-trail" element={<AuditTrailView />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </AppProviders>
  )
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
