import { DeleteOutline } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'
import { Button, Container, IconButton, Paper, Stack, TableBody, TableContainer, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DetailTable, DetailTableRow } from '../../../components/detail-table'
import TableContainerHeader from '../../../components/table-container-header'
import useOpenState from '../../../utils/hooks/use-open-state'
import useCurrentDatasetsQuery from '../data/use-current-datasets-query'
import useCurrentPermissionsQuery from '../data/use-current-permissions-query'
import useCurrentPortfoliosQuery from '../data/use-current-portfolios-query'
import useGroupDetailsQuery from '../data/use-group-details-query'
import useGroupsWithMembers from '../data/use-groups-with-members'
import CreateGroupModal from './create-group-modal'
import DeleteGroupModal from './delete-group-modal'
import GroupDatasetsSection from './group-datasets-section'
import GroupMembersSection from './group-members-section'
import GroupPermissionsSection from './group-permissions-section'
import GroupPortfoliosSection from './group-portfolios-section'
import UpdateGroupModal from './update-group-modal'
import { useSettingsUpdateLoadingState } from './use-settings-set-loading'

type GroupDetailsProps = {
  onBack: () => void
  groupRef: string
}

function GroupDetails({ onBack, groupRef }: GroupDetailsProps) {
  const { t } = useTranslation('settings')

  const deleteGroupModal = useOpenState()
  const createGroupModal = useOpenState()
  const updateGroupModal = useOpenState()

  const getGroupDetailResponse = useGroupDetailsQuery(groupRef)
  const groupDetails = getGroupDetailResponse.data?.data
  const groupMembers = groupDetails?.members || []

  const groupsResponse = useGroupsWithMembers()
  const groupTypes = groupsResponse.data?.data.group_types || {}

  const currentPermissionResponse = useCurrentPermissionsQuery(groupRef)
  const currentPermissions = currentPermissionResponse.data?.data || []

  const currentPortfoliosResponse = useCurrentPortfoliosQuery(groupRef)
  const currentPortfolios = currentPortfoliosResponse.data?.data || []

  const currentDatasetsResponse = useCurrentDatasetsQuery(groupRef)
  const currentDatasets = currentDatasetsResponse.data?.data || []

  const isLoading =
    getGroupDetailResponse.isLoading ||
    currentPortfoliosResponse.isLoading ||
    currentDatasetsResponse.isLoading ||
    currentPermissionResponse.isLoading

  useSettingsUpdateLoadingState(isLoading)

  if (isLoading || !groupDetails) {
    return null
  }

  return (
    <>
      <Container maxWidth="md">
        <Stack direction="row" sx={{ alignItems: 'center', py: 4, gap: 1 }}>
          <IconButton onClick={onBack}>
            <ArrowBackIcon sx={{ color: 'gray.300' }} />
          </IconButton>
          <Typography variant="h5">{groupDetails.group_name}</Typography>
          <Button variant="text" startIcon={<DeleteOutline />} onClick={deleteGroupModal.open} sx={{ ml: 'auto' }}>
            {t('user_details_page.delete_button')}
          </Button>
        </Stack>
        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <TableContainerHeader
            title={t('user_details_page.details_table.title')}
            action={
              <IconButton onClick={updateGroupModal.open}>
                <EditIcon />
              </IconButton>
            }
          />
          <DetailTable>
            <TableBody>
              <DetailTableRow label={t('user_details_page.details_table.group_name')}>
                <Typography variant="body1">{groupDetails.group_name}</Typography>
              </DetailTableRow>
              <DetailTableRow label={t('user_details_page.details_table.group_type')}>
                <Typography variant="body1">
                  {groupTypes[groupDetails.group_type] || groupDetails.group_type}
                </Typography>
              </DetailTableRow>
            </TableBody>
          </DetailTable>
        </TableContainer>
        <GroupPermissionsSection groupCurrentPermissions={currentPermissions} groupRef={groupRef} />
        <GroupPortfoliosSection groupRef={groupRef} currentPortfolios={currentPortfolios} />
        <GroupMembersSection groupName={groupDetails.group_name} groupMembers={groupMembers} groupRef={groupRef} />
        <GroupDatasetsSection groupRef={groupRef} currentDatasets={currentDatasets} groupDetails={groupDetails} />
      </Container>
      <DeleteGroupModal
        isOpen={deleteGroupModal.isOpen}
        groupName={groupDetails.group_name}
        groupRef={groupRef}
        onClose={deleteGroupModal.close}
        onBack={onBack}
      />
      <CreateGroupModal open={createGroupModal.isOpen} onClose={createGroupModal.close} />
      <UpdateGroupModal open={updateGroupModal.isOpen} onClose={updateGroupModal.close} groupRef={groupRef} />
    </>
  )
}

export default GroupDetails
