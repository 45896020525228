import { Dialog } from '@mui/material'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import useCreatePortfolioMutation from '../data/use-create-portfolio-mutation'
import PortfolioForm from './portfolio-form'

type CreatePortfolioModalProps = {
  open: boolean
  onClose: () => void
}

const initialFormValues = {
  portfolioName: '',
  ledgerTag: '',
  legalEntity: '',
  baseCurrency: '',
  chartOfAccounts: '',
}

function CreatePortfolioModal(props: CreatePortfolioModalProps) {
  const { open, onClose } = props
  const { t } = useTranslation('settings')

  const {
    mutate: createPortfolio,
    error: createPortfolioError,
    reset: resetCreatePortfolio,
  } = useCreatePortfolioMutation()

  const [formValues, setFormValues] = useState(initialFormValues)

  function handleChange(name: string, value: string) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    const createPortfolioParams = {
      portfolio_name: formValues.portfolioName,
      lei: formValues.legalEntity,
      currency: formValues.baseCurrency,
      chart_ref: formValues.chartOfAccounts,
      pot_tag: formValues.ledgerTag,
    }

    createPortfolio(
      { portfolioPayload: createPortfolioParams },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['portfolios', 'regular'])
          handleClose()
        },
      }
    )
  }

  function handleClose() {
    setFormValues(initialFormValues)
    resetCreatePortfolio()
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('portfolio_form.create_portfolio')} onClose={handleClose} />
        <ModalContent>
          <PortfolioForm errorMessage={createPortfolioError} values={formValues} onChange={handleChange} />
        </ModalContent>
        <ModalActions confirmLabel={t('common:create')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default CreatePortfolioModal
