import { Stack } from '@mui/system'
import { ReactNode } from 'react'
import { DataEngineHeader } from './data-engine-header'
import { useDataEngineOutletContext } from './use-data-engine-outlet-context'

type DataEngineShellProps = {
  children?: ReactNode
  headerRightMenu?: ReactNode
}

function DataEngineShell(props: DataEngineShellProps) {
  const outletContext = useDataEngineOutletContext()

  return (
    <Stack flex="1" overflow="hidden">
      <DataEngineHeader outletContext={outletContext} rightMenu={props.headerRightMenu} />

      <Stack flex="1" overflow="auto">
        {props.children}
      </Stack>
    </Stack>
  )
}

export default DataEngineShell
