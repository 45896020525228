import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { DatapointPayload } from '../../../services/data/types/datapoint'

function useCreateNewDatapointMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, MutationFnParams>({
    mutationFn: ({ datasetRef, body }) => api.createNewDatapoint(datasetRef, body),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type MutationFnParams = {
  datasetRef: string
  body: DatapointPayload
}

export default useCreateNewDatapointMutation
