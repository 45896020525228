import {
  Button,
  Dialog,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material'
import { FormEvent, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import NumericField from '../../../components/fields/numeric-field'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import { ModalDetailRow } from '../../../components/modal-detail-row'
import ModalTitle from '../../../components/modal-title'
import { Broker } from '../../../services/data/types/broker'
import { CreateManualFillParams, Order } from '../../../services/data/types/order'
import { defaultScale, priceScale } from '../../../utils/math'
import { useCreateManualFillMutation } from '../data/use-create-manual-fill-mutation'

type OrderManualFillModalProps = {
  open: boolean
  order: Order | null
  brokers: Pick<Broker, 'account_id' | 'account_name'>[]
  onCreated: () => void
  onClose: () => void
}

export function OrderManualFillModal(props: OrderManualFillModalProps) {
  const { open, order, brokers, onCreated, onClose } = props

  const { t } = useTranslation('orderBlotter')

  const {
    mutate: createManualFill,
    error: createManualFillError,
    reset: createManualFillReset,
    isLoading: createManualFillIsLoading,
  } = useCreateManualFillMutation()

  const [brokerId, setBrokerId] = useState('')
  const [quantity, setQuantity] = useState('')
  const [price, setPrice] = useState('')

  const canSubmit = !!brokerId && !!quantity && !!price

  function handleBrokerSelect(event: SelectChangeEvent) {
    setBrokerId(event.target.value)
  }

  function setQuantityOutstanding() {
    setQuantity(String(order?.amount_unfilled ?? ''))
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    handleCreateManualFill()
  }

  function handleCreateManualFill() {
    if (order && canSubmit) {
      const params: CreateManualFillParams = {
        orderRef: order.order_ref,
        brokerAccountId: Number(brokerId),
        amount: Number(quantity),
        price: Number(price),
      }

      createManualFill(params, {
        onSuccess: () => {
          onCreated()
          handleClose()
        },
      })
    }
  }

  function handleClose() {
    createManualFillReset()
    setBrokerId('')
    setQuantity('')
    setPrice('')
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('manual_fill')} onClose={handleClose} />

        <ModalContent>
          <Alert severity="error" message={createManualFillError} />

          <ModalDetailRow label={t('order_ref')} value={order?.order_ref} />
          <ModalDetailRow label={t('security')} value={order?.asset_description} />
          <ModalDetailRow label={t('placed_outstanding')} value={order?.amount_unfilled} />

          <Divider />

          <FormControl>
            <InputLabel>{t('counterparty')}</InputLabel>
            <Select required name="broker" label={t('counterparty')} value={brokerId} onChange={handleBrokerSelect}>
              {brokers.map((broker) => (
                <MenuItem key={broker.account_id} value={broker.account_id}>
                  {broker.account_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Stack direction="row" sx={{ gap: 2 }}>
            <FormControl sx={{ flex: 1 }}>
              <NumericField
                required
                name="quantity"
                label={t('fill_quantity')}
                value={quantity}
                decimalScale={defaultScale}
                onValueChange={setQuantity}
              />
              <Button
                type="button"
                size="small"
                variant="outlined"
                onClick={setQuantityOutstanding}
                sx={{ mt: 1, alignSelf: 'self-start' }}
              >
                <Trans t={t} i18nKey="unplaced_label" values={{ quantity: order?.amount_unfilled ?? '-' }} />
              </Button>
            </FormControl>

            <FormControl sx={{ flex: 1 }}>
              <NumericField
                required
                name="price"
                label={t('fill_price')}
                value={price}
                decimalScale={priceScale}
                onValueChange={setPrice}
              />
            </FormControl>
          </Stack>
        </ModalContent>

        <ModalActions
          confirmLabel={t('fill')}
          confirmDisabled={!canSubmit}
          isLoading={createManualFillIsLoading}
          onCancel={handleClose}
        />
      </form>
    </Dialog>
  )
}
