import { Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import { queryClient } from '../../../services/data/react-query'
import { Model } from '../../../services/data/types/models'
import useDeleteModelMutation from '../data/use-delete-model-mutation'

type DeleteModelModalProps = {
  datasetRef: string
  isOpen: boolean
  model: Model | null
  onClose: () => void
}

function DeleteModelModal(props: DeleteModelModalProps) {
  const { datasetRef, isOpen, model, onClose } = props

  const { t } = useTranslation('dataEngine')

  const deleteModel = useDeleteModelMutation()

  function handleDelete() {
    if (!model?.model_ref) {
      return
    }

    deleteModel.mutate(
      { modelRef: model.model_ref },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['dataset', datasetRef, 'models'])
          handleClose()
        },
      }
    )
  }

  function handleClose() {
    deleteModel.reset()
    onClose()
  }

  return (
    <ConfirmationModal
      error={deleteModel.error}
      open={isOpen}
      title={t('models_table.delete_modal_title')}
      confirmButtonText={t('common:delete')}
      onCloseButtonClick={handleClose}
      onConfirmButtonClick={handleDelete}
    >
      <Typography variant="body1">
        <Trans
          t={t}
          i18nKey={'models_table.delete_confirmation_text'}
          values={{ name: model?.model_name }}
          components={{ bold: <strong /> }}
        />
      </Typography>
    </ConfirmationModal>
  )
}

export default DeleteModelModal
