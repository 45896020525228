import { Box, Typography } from '@mui/material'
import { ReactNode } from 'react'

type AuthCardProps = {
  title: string
  titleMarginBottom?: number
  children: ReactNode
}

function AuthCard({ title, titleMarginBottom, children }: AuthCardProps) {
  return (
    <Box
      sx={{
        p: 7,
        pt: 5,
        borderRadius: '4px',
        backgroundColor: 'gray.main',
        shadow: 'base',
      }}
    >
      <Typography variant="h6" marginBottom={titleMarginBottom ?? 6} align="center" fontWeight="500" fontSize="20px">
        {title}
      </Typography>
      {children}
    </Box>
  )
}

export default AuthCard
