import { CircularProgress, Stack } from '@mui/material'
import { useEffect, useRef } from 'react'
import { Outlet } from 'react-router-dom'
import axios, { createCheckAuthTokenOnErrorInterceptor } from '../../../../services/data/axios'
import { queryClient } from '../../../../services/data/react-query'
import { ChannelAction, ChannelEvent, connectAuthChannel, createLoginWindow } from '../../../../services/openfin/fin'
import { useAuth } from '../../data/auth-context'
import useUserInfo from '../../data/use-user-info'
import SessionExpiredLayout from './session-expired-layout'

function RequiresAuthOpenFin() {
  const { logout } = useAuth()
  const { user, isLoading, refreshUser } = useUserInfo()

  const authClient = useRef<OpenFin.ChannelClient>()

  useEffect(() => {
    connectAuthChannel().then((client) => {
      authClient.current = client

      client.register(ChannelEvent.loggedIn, refreshUser)
      client.register(ChannelEvent.loggedOut, refreshUser)
      client.register(ChannelEvent.tenantSwitched, () => queryClient.invalidateQueries())
    })
  }, [])

  useEffect(() => {
    if (!isLoading && !user) {
      createLoginWindow()
        .then(() => console.log('Window is created'))
        .catch((err: any) => console.log(err))
    }
  }, [isLoading, user])

  useEffect(() => {
    const interceptor = createCheckAuthTokenOnErrorInterceptor({
      onUnauthorized: async () => {
        await logout()
        authClient.current?.dispatch(ChannelAction.logout)
      },
    })

    return () => axios.interceptors.response.eject(interceptor)
  }, [])

  if (isLoading) {
    return (
      <Stack
        sx={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'gray.light',
        }}
      >
        <CircularProgress />
      </Stack>
    )
  }

  if ((!isLoading && !user) || !user?.tenant_ref) {
    return <SessionExpiredLayout />
  }

  return <Outlet />
}

export default RequiresAuthOpenFin
