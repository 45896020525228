import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useRemovePermissionMutation() {
  const { error, ...mutate } = useMutation<AxiosResponse, AxiosError, RemovePermissionParams>({
    mutationFn: ({ groupRef, permissionTag }) => api.removePermission(groupRef, permissionTag),
  })
  const errorMessage = parseError(error)
  return { ...mutate, error: errorMessage }
}

type RemovePermissionParams = {
  groupRef: string
  permissionTag: string
}

export default useRemovePermissionMutation
