import { DeleteFilterPayload } from '../../modules/portfolios/data/use-view-config-state'
import { ParsedFilter } from '../../services/data/filter-parsing'
import FilterChip from './filter-chip'

type FilterChipsProps = {
  dark?: boolean
  filters: ParsedFilter[]
  onDelete: (payload: DeleteFilterPayload) => void
}

function FilterChips(props: FilterChipsProps) {
  const { dark, filters, onDelete } = props

  const className = dark ? 'dark' : ''

  return (
    <>
      {filters.map((filter) => {
        if (
          filter.filterDataType === 'Classification' ||
          filter.filterDataType === 'MultiSelect' ||
          filter.filterDataType === 'Boolean'
        ) {
          return filter.filterValues.map((fv) => {
            return (
              <FilterChip
                key={`${filter.datapointRef}_${fv.value}`}
                className={className}
                filterName={filter.filterName}
                filterDataType={filter.filterDataType}
                operator={filter.operator}
                filterValues={[{ value: fv.value, title: String(fv.title || fv.value) }]}
                onDelete={() => {
                  if (filter.filterDataType === 'Boolean') {
                    onDelete({
                      datapointRef: filter.datapointRef,
                      singleValue: fv.value === 'true',
                    })
                  } else {
                    onDelete({
                      datapointRef: filter.datapointRef,
                      singleValue: fv.value,
                    })
                  }
                }}
              />
            )
          })
        } else {
          return (
            <FilterChip
              key={filter.datapointRef}
              className={className}
              filterName={filter.filterName}
              filterDataType={filter.filterDataType}
              operator={filter.operator}
              filterValues={filter.filterValues}
              onDelete={() =>
                onDelete({
                  datapointRef: filter.datapointRef,
                  singleValue: null,
                })
              }
            />
          )
        }
      })}
    </>
  )
}

export default FilterChips
