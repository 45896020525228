import { Cancel } from '@mui/icons-material'
import { Chip, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Stack } from '@mui/system'
import { ParsedFilter } from '../../services/data/filter-parsing'
import { DatapointType } from '../../services/data/types/datapoint'
import { GridDataViewFilterOperator } from '../../services/data/types/grid-data-view'
import { dateValueOperators, getFormattedFilterValues } from './date-time-filter-menu'

type FilterChipProps = {
  filterName: string
  filterDataType: DatapointType
  operator: GridDataViewFilterOperator
  filterValues: ParsedFilter['filterValues']
  className?: string
  onDelete: () => void
}

function FilterChip(props: FilterChipProps) {
  const { filterName, filterDataType, operator, filterValues, className, onDelete } = props

  function getLabel() {
    if (filterDataType === 'Int' || filterDataType === 'Float' || filterDataType === 'Percent') {
      return `${filterName} (${operator.toUpperCase()})`
    }

    if ((filterDataType === 'DateTime' || filterDataType === 'NaiveDate') && dateValueOperators.includes(operator)) {
      return `${filterName} (${operator.toUpperCase()})`
    }

    return filterName
  }

  const label = getLabel()
  const values = getFormattedFilterValues(filterValues, operator, filterDataType)
  const title = `${label}: ${values}`

  return (
    <Chip
      variant="filled"
      size="small"
      className={className}
      onDelete={onDelete}
      deleteIcon={<Cancel style={{ color: grey[400] }} />}
      label={
        <Stack direction="row" fontSize={13} gap={0.35} title={title}>
          <Typography fontSize="inherit" color="gray.700">
            {label}:
          </Typography>
          <Typography fontSize="inherit">{values}</Typography>
        </Stack>
      }
      sx={{ borderRadius: 'full' }}
    />
  )
}

export default FilterChip
