import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { ClassificationType, classificationTypes } from '../../../services/data/types/classifications'
import { Datapoint } from '../../../services/data/types/datapoint'

type ClassificationFormProps = {
  values: ClassificationFormValues
  datapoints: Datapoint[]
  onChange: (name: string, value: string) => void
}

export type ClassificationFormValues = {
  name: string
  type: ClassificationType
  bucketRef: string
}

function ClassificationForm(props: ClassificationFormProps) {
  const { values, datapoints, onChange } = props
  const { t } = useTranslation('dataEngine')

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) {
    const { name, value } = event.target
    onChange(name, value)
  }

  return (
    <>
      <TextField
        required
        name="name"
        label={t('classifications_table.name')}
        value={values.name}
        onChange={handleChange}
      />
      <FormControl required>
        <InputLabel>{t('classifications_table.type')}</InputLabel>
        <Select
          displayEmpty
          name="type"
          label={t('classifications_table.type')}
          value={values.type}
          onChange={handleChange}
        >
          {classificationTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {t(`classifications_table.classification_type.${type}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {values.type === 'auto' && (
        <FormControl required>
          <InputLabel>{t('classifications_table.bucketed_on')}</InputLabel>
          <Select
            displayEmpty
            name="bucketRef"
            label={t('classifications_table.bucketed_on')}
            value={values.bucketRef}
            onChange={handleChange}
          >
            {datapoints.map((datapoint) => (
              <MenuItem key={datapoint.datapoint_ref} value={datapoint.datapoint_ref}>
                {datapoint.datapoint_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  )
}

export default ClassificationForm
