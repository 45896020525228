import { Stack } from '@mui/system'
import { ReactNode } from 'react'

type ProfileRowProps = {
  children: ReactNode
}

export function ProfileRow(props: ProfileRowProps) {
  return (
    <Stack
      direction="row"
      sx={{
        '& > *': {
          flex: 1,
        },
      }}
    >
      {props.children}
    </Stack>
  )
}
