import { Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import { appModelStorage } from '../../../services/flex-layout/models'
import useSetCurrentTenantMutation from '../../auth/data/use-set-current-tenant-mutation'
import useTenantsQuery from '../../auth/data/use-tenants-query'
import useUserInfo from '../../auth/data/use-user-info'

type SwitchTenantModalProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

function SwitchTenantModal(props: SwitchTenantModalProps) {
  const { open, onClose, onSuccess } = props

  const { t } = useTranslation('account')

  const userInfoResponse = useUserInfo()
  const tenantsResponse = useTenantsQuery()
  const { mutate: setCurrentTenant, isError, reset } = useSetCurrentTenantMutation()

  const [tenantRef, setTenantRef] = useState('')

  const user = userInfoResponse.user
  const tenants = tenantsResponse.data?.data
  const filteredTenants = tenants?.filter((tenant) => tenant.tenant_ref !== user?.tenant_ref) || []

  function handleClose() {
    onClose()
    setTenantRef('')
    reset()
  }

  function handleTenantChange(event: SelectChangeEvent) {
    setTenantRef(event.target.value)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    setCurrentTenant(tenantRef, {
      onSuccess: async () => {
        appModelStorage.clear()
        queryClient.clear()
        onSuccess?.()
        handleClose()
      },
    })
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('switch_tenant')} onClose={handleClose} />
        <ModalContent>
          {isError && <Alert severity="error" message={t('common:general_error_message')} />}
          <FormControl fullWidth>
            <InputLabel>{t('tenant')}</InputLabel>
            <Select label={t('tenant')} value={tenantRef} onChange={handleTenantChange}>
              {filteredTenants.map((tenant) => (
                <MenuItem key={tenant.tenant_ref} value={tenant.tenant_ref}>
                  {tenant.tenant_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('auth:login')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default SwitchTenantModal
