import { ArrowBack } from '@mui/icons-material'
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { FormEvent, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import AuthLayout from '../../../components/layouts/auth-layout'
import useSetCurrentTenantMutation from '../data/use-set-current-tenant-mutation'
import useTenantsQuery from '../data/use-tenants-query'
import useUserInfo from '../data/use-user-info'
import AuthCard from './auth-card'

type TenantLoginProps = {
  onSuccess: () => void
  onBackToLogin: () => void
  onNavigateToAccount: () => void
}

function TenantLogin(props: TenantLoginProps) {
  const { onSuccess, onBackToLogin, onNavigateToAccount } = props

  const { t } = useTranslation('account')

  const tenantsResponse = useTenantsQuery()
  const { mutate: setCurrentTenant } = useSetCurrentTenantMutation()
  const { refreshUser } = useUserInfo()

  const [tenantRef, setTenantRef] = useState('')

  const tenants = tenantsResponse.data?.data || []
  const isTenantListEmpty = !tenantsResponse.isFetching && tenants.length === 0

  function handleTenantChange(event: SelectChangeEvent) {
    setTenantRef(event.target.value)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    setCurrentTenant(tenantRef, {
      onSuccess: async () => {
        await refreshUser()
        onSuccess()
      },
    })
  }

  return (
    <AuthLayout>
      <Stack maxWidth="560px" marginX="auto" mt={10.75}>
        <AuthCard title={isTenantListEmpty ? '' : t('select_tenant')} titleMarginBottom={isTenantListEmpty ? 2 : 6}>
          <form onSubmit={handleSubmit}>
            {isTenantListEmpty && (
              <Typography mb={4}>
                <Typography variant="body1">{t('not_assigned_to_tenant')}</Typography>
                <Trans
                  i18nKey={'common:contact_us_at'}
                  values={{ email: t('common:contact.email_address') }}
                  components={{ email: <Typography variant="body1" color="primary" display="inline-flex" /> }}
                />
              </Typography>
            )}
            <Stack gap={4}>
              {!isTenantListEmpty && (
                <FormControl>
                  <InputLabel>{t('tenant')}</InputLabel>
                  <Select label={t('tenant')} value={tenantRef} onChange={handleTenantChange}>
                    {tenants.map((tenant) => (
                      <MenuItem key={tenant.tenant_ref} value={tenant.tenant_ref}>
                        {tenant.tenant_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {isTenantListEmpty ? (
                <Stack direction="row" justifyContent="space-between">
                  <Button startIcon={<ArrowBack />} onClick={onBackToLogin}>
                    {t('auth:back_to_login')}
                  </Button>
                  <Button variant="contained" onClick={onNavigateToAccount}>
                    {t('account:view_account_details')}
                  </Button>
                </Stack>
              ) : (
                <Stack direction="row" justifyContent="space-between">
                  <Button startIcon={<ArrowBack />} onClick={onBackToLogin}>
                    {t('common:back')}
                  </Button>
                  <Button type="submit" variant="contained" disabled={!tenantRef}>
                    {t('auth:login')}
                  </Button>
                </Stack>
              )}
            </Stack>
          </form>
        </AuthCard>
      </Stack>
    </AuthLayout>
  )
}

export default TenantLogin
