import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ForgotPassword from '../../components/forgot-password'

function ForgotPasswordView() {
  const { t } = useTranslation('auth')
  const navigate = useNavigate()

  function handleSuccess() {
    const message = t('reset_password_email_sent')
    navigate('/login', { state: { message } })
  }

  function handleBack() {
    navigate(-1)
  }

  return <ForgotPassword onSuccess={handleSuccess} onBack={handleBack} />
}

export default ForgotPasswordView
